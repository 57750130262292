define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["de", {
    "(version longue)": "(lange Version)",
    "1. Collecte de l’information": "1. Collecte de l’information",
    "15 templates": "15 templates",
    "2. Utilisation des informations": "2. Utilisation des informations",
    "29,90€ / mois": "29,90€ / mois",
    "29,90€/mois": "29,90€/mois",
    "299,00€ / an": "299,00€ / an",
    "299,00€/an": "299,00€/an",
    "3 templates": "3 templates",
    "3. Divulgation à des tiers": "3. Divulgation à des tiers",
    "4. Protection des informations": "4. Protection des informations",
    "5 vidéos gratuites": "5 vidéos gratuites",
    "5. Cookies": "5. Cookies",
    "6. Se désabonner": "6. Se désabonner",
    "7. Consentement": "7. Consentement",
    "8 caractères minimum": "8 caractères minimum",
    "<": "<",
    "A ce titre, le Prestataire s’engage à prendre les mesures nécessaires afin d’assurer la protection, la sécurité et la confidentialité des données personnelles qui lui sont transmises par le Client.": "A ce titre, le Prestataire s’engage à prendre les mesures nécessaires afin d’assurer la protection, la sécurité et la confidentialité des données personnelles qui lui sont transmises par le Client.",
    "A défaut de dispositions spécifiques dans le devis, les délais d’exécution des Services sont communiqués au Client à titre indicatif. Les délais de réalisation des Services ne sont en aucun cas garantis par le Prestataire ni ne peuvent engager sa responsabilité, ni entraîner une obligation de payer une quelconque indemnité ou pénalité de retard, ni justifier l’annulation de la Commande en cause.": "A défaut de dispositions spécifiques dans le devis, les délais d’exécution des Services sont communiqués au Client à titre indicatif. Les délais de réalisation des Services ne sont en aucun cas garantis par le Prestataire ni ne peuvent engager sa responsabilité, ni entraîner une obligation de payer une quelconque indemnité ou pénalité de retard, ni justifier l’annulation de la Commande en cause.",
    "A défaut de solution amiable trouvée entre les Parties, seront seuls compétents pour connaître des litiges de toutes natures ou de contestations relatives à l'interprétation ou à l'exécution du présent Contrat, les tribunaux du ressort de la Cour d’Appel de Paris, nonobstant pluralité de défendeurs, demande incidente ou référé ou appels en garantie, à moins que le Prestataire ne préfère saisir toute autre juridiction compétente.": "A défaut de solution amiable trouvée entre les Parties, seront seuls compétents pour connaître des litiges de toutes natures ou de contestations relatives à l'interprétation ou à l'exécution du présent Contrat, les tribunaux du ressort de la Cour d’Appel de Paris, nonobstant pluralité de défendeurs, demande incidente ou référé ou appels en garantie, à moins que le Prestataire ne préfère saisir toute autre juridiction compétente.",
    "A l’expiration de ce délai de quinze (15) jours, les modifications des CGV seront définitivement considérées comme acceptées par le Client.": "A l’expiration de ce délai de quinze (15) jours, les modifications des CGV seront définitivement considérées comme acceptées par le Client.",
    "Abonnement": "Abonnement",
    "Abonnement actuel": "Abonnement actuel",
    "Abonnement clap.video Premium": "Abonnement clap.video Premium",
    "Abonnement en cours": "Abonnement en cours",
    "Accepter": "Accepter",
    "Accès à tous les templates": "Accès à tous les templates",
    "Activer AutoClap": "Activer AutoClap",
    "Administrer un concours, une promotion, ou un enquête": "Administrer un concours, une promotion, ou un enquête",
    "Adresse email": "Adresse email",
    "Adresse mail": "Adresse mail",
    "Adresse postale": "Adresse postale",
    "Afficher votre adresse postale professionnelle dans les vidéos": "Afficher votre adresse postale professionnelle dans les vidéos",
    "Afin de demander la réinitialisation de votre mot de passe, veuillez renseigner votre adresse email. Un email vous sera adressé afin de vous indiquer la procédure à suivre.": "Afin de demander la réinitialisation de votre mot de passe, veuillez renseigner votre adresse email. Un email vous sera adressé afin de vous indiquer la procédure à suivre.",
    "Ajouter un code promo": "Ajouter un code promo",
    "Améliorer le service client et vos besoins de prise en charge par notre équipe support": "Améliorer le service client et vos besoins de prise en charge par notre équipe support",
    "Analyser l'usage du site (données anonymisées et agrégées)": "Analyser l'usage du site (données anonymisées et agrégées)",
    "Annonce immobilière": "Annonce immobilière",
    "Annonce immobilière - Choix d'une annonce dans votre logiciel métier": "Annonce immobilière - Choix d'une annonce dans votre logiciel métier",
    "Annuel": "Annuel",
    "Annuler": "Annuler",
    "Appliquer": "Appliquer",
    "Après encaissement des factures par le Prestataire, celui-ci cède au Client l’ensemble des droits de propriété intellectuelle sur les œuvres créées spécifiquement et à la demande du Client, dans le cadre de l’exécution du Contrat, pour leur durée de protection et pour le monde entier.": "Après encaissement des factures par le Prestataire, celui-ci cède au Client l’ensemble des droits de propriété intellectuelle sur les œuvres créées spécifiquement et à la demande du Client, dans le cadre de l’exécution du Contrat, pour leur durée de protection et pour le monde entier.",
    "Assurance": "Assurance",
    "Astuce": "Astuce",
    "Aucun bien n'a été trouvé pour": "Aucun bien n'a été trouvé pour",
    "Aucun changement ni aucune modification du Contrat, notamment sur les caractéristiques des Services, ne sera pris en considération s'il n'a pas été accepté par écrit par le Prestataire.": "Aucun changement ni aucune modification du Contrat, notamment sur les caractéristiques des Services, ne sera pris en considération s'il n'a pas été accepté par écrit par le Prestataire.",
    "Aucun escompte n’est prévu en cas de paiement anticipé.": "Aucun escompte n’est prévu en cas de paiement anticipé.",
    "Aucun résultat": "Kein Ergebnis",
    "Aucun résultat.": "Aucun résultat.",
    "Aucune annonce n'a pu être trouvée.": "Aucune annonce n'a pu être trouvée.",
    "AutoClap génère automatiquement une video quand vous publiez un nouveau bien sur votre outil métier.": "AutoClap génère automatiquement une video quand vous publiez un nouveau bien sur votre outil métier.",
    "Autonomie des clauses": "Autonomie des clauses",
    "Avant chaque intervention du Prestataire, le Client s’engage à réaliser toutes les procédures de sauvegarde nécessaires à la protection et à la sauvegarde de ses données, programmes et fichiers informatiques.": "Avant chaque intervention du Prestataire, le Client s’engage à réaliser toutes les procédures de sauvegarde nécessaires à la protection et à la sauvegarde de ses données, programmes et fichiers informatiques.",
    "Avec l’abonnement Premium, vous profiterez de clap.video dans son intégralité et débloquerez les fonctionnalités suivantes": "Avec l’abonnement Premium, vous profiterez de clap.video dans son intégralité et débloquerez les fonctionnalités suivantes",
    "Avis clients": "Avis clients",
    "Bientôt disponible": "In Kürze verfügbar",
    "Bienvenue sur Clap !": "Bienvenue sur Clap !",
    "Bienvenue sur clap.video !": "Bienvenue sur clap.video !",
    "Carré": "Quadrat",
    "Carte bancaire": "Carte bancaire",
    "Ces CGV régissent intégralement les relations entre le Prestataire et le Client. Aucune condition générale d'achat ne pourra prévaloir ni être opposée par le Client au Prestataire et aucune condition particulière communiquée par le Client au Prestataire ne peut prévaloir sur les CGV, sauf acceptation formelle et écrite du Prestataire.": "Ces CGV régissent intégralement les relations entre le Prestataire et le Client. Aucune condition générale d'achat ne pourra prévaloir ni être opposée par le Client au Prestataire et aucune condition particulière communiquée par le Client au Prestataire ne peut prévaloir sur les CGV, sauf acceptation formelle et écrite du Prestataire.",
    "Ces cookies ne sont pas indispensables au fonctionnement du site, mais ils facilitent votre navigation. Vous pouvez les bloquer ou les supprimer, mais vous risquez alors d'empêcher le bon fonctionnement de certains éléments du site.": "Ces cookies ne sont pas indispensables au fonctionnement du site, mais ils facilitent votre navigation. Vous pouvez les bloquer ou les supprimer, mais vous risquez alors d'empêcher le bon fonctionnement de certains éléments du site.",
    "Ces pénalités de retard sont exigibles de plein droit et sans qu’un rappel par le Prestataire ne soit nécessaire.": "Ces pénalités de retard sont exigibles de plein droit et sans qu’un rappel par le Prestataire ne soit nécessaire.",
    "Cette abonnement sera automatiquement renouvelé le": "Cette abonnement sera automatiquement renouvelé le",
    "Cette action est irréversible.": "Cette action est irréversible.",
    "Cette disposition ne peut être remplacée par un accord verbal.": "Cette disposition ne peut être remplacée par un accord verbal.",
    "Cette publication sera postée sur": "Dieser Beitrag wird veröffentlicht auf",
    "Chacune des Parties renonce, sauf accord écrit préalable, à faire directement ou indirectement des offres d’engagement à un collaborateur de l’autre Partie ayant travaillé dans le cadre des Services, objet du présent Contrat, ou à le prendre à son service, sous quelque statut que ce soit. Cette renonciation est valable pour une durée de deux (2) ans à compter de la fin du présent Contrat.": "Chacune des Parties renonce, sauf accord écrit préalable, à faire directement ou indirectement des offres d’engagement à un collaborateur de l’autre Partie ayant travaillé dans le cadre des Services, objet du présent Contrat, ou à le prendre à son service, sous quelque statut que ce soit. Cette renonciation est valable pour une durée de deux (2) ans à compter de la fin du présent Contrat.",
    "Chacune des Parties s’engage à maintenir en vigueur, pendant toute la durée de réalisation du Contrat, auprès d’une compagnie d’assurance notoirement solvable, une police d’assurance garantissant les dommages pouvant survenir à ses biens et personnel, ainsi qu’une police couvrant sa responsabilité professionnelle, de manière à couvrir les conséquences pécuniaires des dommages corporels, matériels et immatériels dont elles auraient à répondre, causés par tout évènement et qui serait le fait de ses collaborateurs et/ou sociétés partenaires éventuels lors de l’exécution du Contrat.": "Chacune des Parties s’engage à maintenir en vigueur, pendant toute la durée de réalisation du Contrat, auprès d’une compagnie d’assurance notoirement solvable, une police d’assurance garantissant les dommages pouvant survenir à ses biens et personnel, ainsi qu’une police couvrant sa responsabilité professionnelle, de manière à couvrir les conséquences pécuniaires des dommages corporels, matériels et immatériels dont elles auraient à répondre, causés par tout évènement et qui serait le fait de ses collaborateurs et/ou sociétés partenaires éventuels lors de l’exécution du Contrat.",
    "Changer": "Changer",
    "Chaque Partie s’engage": "Chaque Partie s’engage",
    "Chargement...": "Chargement...",
    "Choisir une autre image sur l’annonce": "Wählen Sie ein anderes Bild aus der Anzeige aus",
    "Choisir une musique": "Musik auswählen",
    "Choisir une page": "Choisir une page",
    "Choisissez la langue de l'interface": "Wählen Sie die Sprache der Benutzeroberfläche",
    "Choisissez un template": "Vorlage wählen",
    "Choisissez votre type de vidéo": "Choisissez votre type de vidéo",
    "Code postal": "Code postal",
    "Code postale": "Code postale",
    "Code promo": "Code promo",
    "Code promotionnel": "Code promotionnel",
    "Collaborer activement à la réussite du projet en apportant au Prestataire dans les délais préalablement définis toutes les informations et tous les documents nécessaires à la bonne appréhension des besoins et à la bonne exécution des Services.": "Collaborer activement à la réussite du projet en apportant au Prestataire dans les délais préalablement définis toutes les informations et tous les documents nécessaires à la bonne appréhension des besoins et à la bonne exécution des Services.",
    "Collaborer étroitement avec le Prestataire et fournir toute information, documentation, prestation, et tous moyens utiles pour la réalisation des Services et s’engage à mettre à disposition du Prestataire tous les éléments permettant de satisfaire à son obligation, incluant le personnel dédié à la bonne réalisation des Services.": "Collaborer étroitement avec le Prestataire et fournir toute information, documentation, prestation, et tous moyens utiles pour la réalisation des Services et s’engage à mettre à disposition du Prestataire tous les éléments permettant de satisfaire à son obligation, incluant le personnel dédié à la bonne réalisation des Services.",
    "Commencer": "Commencer",
    "Complément d'adresse postale": "Complément d'adresse postale",
    "Concernant les logiciels, données ou documents utilisés par le Prestataire dans le cadre de la fourniture des Services, dont le Client a acquis les droits d’utilisation auprès de tiers ou dont il est propriétaire, le Client garantit le Prestataire de toutes les conséquences ou suites dommageables que le Prestataire aurait à subir au titre de l’utilisation desdits logiciels, données ou documents contre toute action de la part d’une personne revendiquant un droit de propriété intellectuelle ou se fondant sur une demande en concurrence déloyale et/ou parasitisme sur ces logiciels, données ou documents.": "Concernant les logiciels, données ou documents utilisés par le Prestataire dans le cadre de la fourniture des Services, dont le Client a acquis les droits d’utilisation auprès de tiers ou dont il est propriétaire, le Client garantit le Prestataire de toutes les conséquences ou suites dommageables que le Prestataire aurait à subir au titre de l’utilisation desdits logiciels, données ou documents contre toute action de la part d’une personne revendiquant un droit de propriété intellectuelle ou se fondant sur une demande en concurrence déloyale et/ou parasitisme sur ces logiciels, données ou documents.",
    "Conclusion du Contrat": "Conclusion du Contrat",
    "Conditions Générales de Vente - clap.video": "Conditions Générales de Vente - clap.video",
    "Conditions de paiement": "Conditions de paiement",
    "Confidentialité": "Confidentialité",
    "Confirmation de suppression": "Confirmation de suppression",
    "Confirmation du nouveau mot de passe": "Confirmation du nouveau mot de passe",
    "Confirmer le mot de passe": "Confirmer le mot de passe",
    "Confirmer le paiement": "Confirmer le paiement",
    "Confirmez votre mot de passe": "Confirmez votre mot de passe",
    "Conformément aux dispositions de la Loi Informatique et Libertés n° 78-17 du 6 janvier 1978 telle que modifiée, le Client dispose de la qualité de responsable de traitement dans le cadre de l’exécution du Contrat.": "Conformément aux dispositions de la Loi Informatique et Libertés n° 78-17 du 6 janvier 1978 telle que modifiée, le Client dispose de la qualité de responsable de traitement dans le cadre de l’exécution du Contrat.",
    "Conformément aux dispositions du Règlement Général sur la Protection des Données (RGPD) entré en vigueur le 25 mai 2018, le Client dispose notamment d’un droit d’accès, de rectification, d’opposition et de portabilité de ses données personnelles. La garantie de ces droits est affirmée au sein de la politique de confidentialité qui accompagne les présentes, et qui en conséquence, forment un ensemble contractuel.": "Conformément aux dispositions du Règlement Général sur la Protection des Données (RGPD) entré en vigueur le 25 mai 2018, le Client dispose notamment d’un droit d’accès, de rectification, d’opposition et de portabilité de ses données personnelles. La garantie de ces droits est affirmée au sein de la politique de confidentialité qui accompagne les présentes, et qui en conséquence, forment un ensemble contractuel.",
    "Connecter l'ensemble de vos plateformes à clap.video": "Connecter l'ensemble de vos plateformes à clap.video",
    "Connecteurs": "Connecteurs",
    "Connectez-vous !": "Connectez-vous !",
    "Connexion": "Connexion",
    "Connexion via Topi": "Connexion via Topi",
    "Conserver la musique par défaut": "Standardmusik beibehalten",
    "Copier le lien de la page publique": "Link der öffentlichen Seite kopieren",
    "Copier le lien de la vidéo": "Copier le lien de la vidéo",
    "Copier le lien public": "Copier le lien public",
    "Couleur du thème": "Farben der Vorlage",
    "Couleur incorrecte. Veuillez choisir une couleur au format hexadécimal sans transparence.": "Couleur incorrecte. Veuillez choisir une couleur au format hexadécimal sans transparence.",
    "Couleur précédente": "Couleur précédente",
    "Couleur préférée": "Lieblingsfarbe",
    "Couleurs prédéfinies": "Vorgegebene Farben",
    "Création d'un clap vidéo": "Création d'un clap vidéo",
    "Création d'une vidéo": "Création d'une vidéo",
    "Création manuelle": "Création manuelle",
    "Créer le nouveau mot de passe": "Créer le nouveau mot de passe",
    "Créer manuellement": "Créer manuellement",
    "Créer une vidéo": "Créer une vidéo",
    "Créez votre première vidéo en cliquant sur le bouton ci-dessous.": "Créez votre première vidéo en cliquant sur le bouton ci-dessous.",
    "Créez-en un": "Créez-en un",
    "Créér ou administrer votre compte clap.video": "Créér ou administrer votre compte clap.video",
    "Dans la file d'attente": "In der Warteschleife",
    "Dans le cadre de la fourniture des Services et en tant que de besoin, le Client concède également au Prestataire un droit d’utilisation de ses logiciels, données et documents, à titre personnel, gratuit, non exclusif et non transférable pour la durée de réalisation des Services.": "Dans le cadre de la fourniture des Services et en tant que de besoin, le Client concède également au Prestataire un droit d’utilisation de ses logiciels, données et documents, à titre personnel, gratuit, non exclusif et non transférable pour la durée de réalisation des Services.",
    "Dans le cadre des présentes CGV et la réalisation des Services, le Prestataire s’engage à se donner tous les moyens nécessaires et à mettre tout en œuvre pour la réalisation de sa mission dans les règles de l’art. Cette obligation ne saurait constituer une obligation de résultat, le Prestataire ne fournissant les Services que dans le cadre d’une obligation de moyen.": "Dans le cadre des présentes CGV et la réalisation des Services, le Prestataire s’engage à se donner tous les moyens nécessaires et à mettre tout en œuvre pour la réalisation de sa mission dans les règles de l’art. Cette obligation ne saurait constituer une obligation de résultat, le Prestataire ne fournissant les Services que dans le cadre d’une obligation de moyen.",
    "Dans le respect des articles L 8221-1 et suivants du Code du travail et conformément à l’article D 8222-5 du même code, le Prestataire s’engage à remettre au Client lors de la conclusion du Contrat, et tous les six mois jusqu’à la fin de son exécution, les documents suivants ": "Dans le respect des articles L 8221-1 et suivants du Code du travail et conformément à l’article D 8222-5 du même code, le Prestataire s’engage à remettre au Client lors de la conclusion du Contrat, et tous les six mois jusqu’à la fin de son exécution, les documents suivants ",
    "Dans l’hypothèse où le Client aurait signé un procès-verbal de réception des Services et/ou la maquette de la ou les créations ou a validé la réception des Services et/ou la maquette, par tout moyen et notamment par l’utilisation des Services et/ou la maquette, le Prestataire sera réputé avoir réalisé ses obligations de manière conforme au Contrat. Le Client sera alors réputé avoir renoncé irrévocablement à toutes réclamation de plein droit à ce titre.": "Dans l’hypothèse où le Client aurait signé un procès-verbal de réception des Services et/ou la maquette de la ou les créations ou a validé la réception des Services et/ou la maquette, par tout moyen et notamment par l’utilisation des Services et/ou la maquette, le Prestataire sera réputé avoir réalisé ses obligations de manière conforme au Contrat. Le Client sera alors réputé avoir renoncé irrévocablement à toutes réclamation de plein droit à ce titre.",
    "Demande de mot de passe": "Passwort anfordern",
    "Dernière modification": "Dernière modification",
    "Disposer des droits nécessaires sur les éléments fournis ci-dessus.": "Disposer des droits nécessaires sur les éléments fournis ci-dessus.",
    "Dispositions générales": "Dispositions générales",
    "Documents antérieurs ou autres accords": "Documents antérieurs ou autres accords",
    "Donnez vie à vos biens immobiliers en vidéo et améliorez votre engagement sur les réseaux sociaux.": "Donnez vie à vos biens immobiliers en vidéo et améliorez votre engagement sur les réseaux sociaux.",
    "Données personnelles": "Données personnelles",
    "Droit applicable et juridictions compétentes": "Droit applicable et juridictions compétentes",
    "Dupliquez cette vidéo": "Dupliquez cette vidéo",
    "Durée": "Durée",
    "Déconnexion": "Déconnexion",
    "Découvrez notre nouveau bien, une magnifique maison de campagne de 156m² et 3 chambres dont une suite parentale. Piscine, garage.": "Découvrez notre nouveau bien, une magnifique maison de campagne de 156m² et 3 chambres dont une suite parentale. Piscine, garage.",
    "Définissez votre nouveau mot de passe.": "Définissez votre nouveau mot de passe.",
    "Définitions": "Définitions",
    "Désolé, votre navigateur ne supporte pas ce type de vidéo.": "Désolé, votre navigateur ne supporte pas ce type de vidéo.",
    "D’une façon générale, le Client et le Prestataire s’engagent à collaborer activement afin d’assurer la bonne exécution du Contrat. Chacune des Parties s’engage à communiquer toutes les difficultés dont elle aurait connaissance au fur et à mesure de l'avancement du projet, pour permettre à l’autre Partie de prendre les décisions nécessaires.": "D’une façon générale, le Client et le Prestataire s’engagent à collaborer activement afin d’assurer la bonne exécution du Contrat. Chacune des Parties s’engage à communiquer toutes les difficultés dont elle aurait connaissance au fur et à mesure de l'avancement du projet, pour permettre à l’autre Partie de prendre les décisions nécessaires.",
    "E-commerce": "E-Commerce",
    "Email": "Email",
    "Email pro": "Email pro",
    "En aucun cas, le Prestataire ne sera tenu pour responsable des dommages indirects, accessoires ou particuliers tels que définis par la jurisprudence des tribunaux français, notamment, le coût de l’obtention de services de substitution, pertes de bénéfices, de données ou périodes d’immobilisation, que sa responsabilité soit contractuelle ou délictuelle et qu’elle ait ou non son fondement dans l’utilisation ou le fonctionnement des Services, même si le Prestataire a averti le Client de la possibilité de tels dommages.": "En aucun cas, le Prestataire ne sera tenu pour responsable des dommages indirects, accessoires ou particuliers tels que définis par la jurisprudence des tribunaux français, notamment, le coût de l’obtention de services de substitution, pertes de bénéfices, de données ou périodes d’immobilisation, que sa responsabilité soit contractuelle ou délictuelle et qu’elle ait ou non son fondement dans l’utilisation ou le fonctionnement des Services, même si le Prestataire a averti le Client de la possibilité de tels dommages.",
    "En cas de contradiction entre des dispositions du devis et les présentes CGV, les dispositions concernées du devis prévaudront sur les CGV.": "En cas de contradiction entre des dispositions du devis et les présentes CGV, les dispositions concernées du devis prévaudront sur les CGV.",
    "En cas de différend entre les Parties, celles-ci tenteront de trouver une solution amiable à ce différend dans un délai de trente jours à compter de la notification du différend de la Partie en demande à l’autre Partie par lettre recommandée avec accusé de réception.": "En cas de différend entre les Parties, celles-ci tenteront de trouver une solution amiable à ce différend dans un délai de trente jours à compter de la notification du différend de la Partie en demande à l’autre Partie par lettre recommandée avec accusé de réception.",
    "En cas de manquement quelconque du Prestataire dans l’exécution de ses obligations (défaut d’exécution ou mauvaise exécution), le Client devra en faire part à celui-ci dans le délai de huit (8) jours ouvrés à compter de la constatation du manquement par lettre recommandée avec accusé de réception. A défaut, le manquement sera inopposable au Prestataire.": "En cas de manquement quelconque du Prestataire dans l’exécution de ses obligations (défaut d’exécution ou mauvaise exécution), le Client devra en faire part à celui-ci dans le délai de huit (8) jours ouvrés à compter de la constatation du manquement par lettre recommandée avec accusé de réception. A défaut, le manquement sera inopposable au Prestataire.",
    "En cas de non-paiement à l’échéance, toute somme due fera courir des pénalités de retard. Celles-ci courent à compter du jour suivant la date d’échéance figurant sur la facture et jusqu’au jour du paiement effectif et intégral de la somme. Le taux des pénalités de retard est fixé à trois faux le taux d’intérêt légal en vigueur.": "En cas de non-paiement à l’échéance, toute somme due fera courir des pénalités de retard. Celles-ci courent à compter du jour suivant la date d’échéance figurant sur la facture et jusqu’au jour du paiement effectif et intégral de la somme. Le taux des pénalités de retard est fixé à trois faux le taux d’intérêt légal en vigueur.",
    "En cas de rupture du Contrat avant son terme par le Client, celui-ci s’engage formellement à régulariser et rétribuer les montants relatifs au calendrier en cours, aux postes réalisés ou en cours de réalisation, ainsi qu’aux services complémentaires effectués. Les fichiers et données sources créés et utilisés par le Prestataire ne sauraient dès lors être revendiqués par le Client sans une contribution financière. L’acompte déjà versé restera acquis par le Prestataire, constituant un dédommagement pour le travail entrepris.": "En cas de rupture du Contrat avant son terme par le Client, celui-ci s’engage formellement à régulariser et rétribuer les montants relatifs au calendrier en cours, aux postes réalisés ou en cours de réalisation, ainsi qu’aux services complémentaires effectués. Les fichiers et données sources créés et utilisés par le Prestataire ne sauraient dès lors être revendiqués par le Client sans une contribution financière. L’acompte déjà versé restera acquis par le Prestataire, constituant un dédommagement pour le travail entrepris.",
    "En cas d’atteinte avérée aux droits d’un tiers, le Prestataire pourra, à son choix ": "En cas d’atteinte avérée aux droits d’un tiers, le Prestataire pourra, à son choix ",
    "En cliquant sur le bouton d'inscription, vous acceptez les": "En cliquant sur le bouton d'inscription, vous acceptez les",
    "En cours de traitement": "In Bearbeitung",
    "En file d'attente": "En file d'attente",
    "En particulier, le Prestataire cède au Client les droits suivants ": "En particulier, le Prestataire cède au Client les droits suivants ",
    "En utilisant notre site, vous consentez à notre politique de confidentialité.": "En utilisant notre site, vous consentez à notre politique de confidentialité.",
    "Encodage en cours": "Speicherung in Arbeit",
    "Enfin, en cas de retard de paiement, le Prestataire se réserve le droit de suspendre ou de surseoir à l’exécution des Services prévus dans la Commande dont le paiement fait l’objet du retard.": "Enfin, en cas de retard de paiement, le Prestataire se réserve le droit de suspendre ou de surseoir à l’exécution des Services prévus dans la Commande dont le paiement fait l’objet du retard.",
    "Enfin, le Client fait son affaire et est seul responsable des lois et réglementations applicables aux Services notamment au regard de la protection des droits de propriété intellectuelle, mentions légales, protection des données personnelles, protection des mineurs (si applicable) et droit de la consommation (si applicable).": "Enfin, le Client fait son affaire et est seul responsable des lois et réglementations applicables aux Services notamment au regard de la protection des droits de propriété intellectuelle, mentions légales, protection des données personnelles, protection des mineurs (si applicable) et droit de la consommation (si applicable).",
    "Enregistrer les modifications": "Enregistrer les modifications",
    "Enregistrer l’image sélectionnée": "Ausgewähltes Bild speichern",
    "Entrer une couleur manuellement": "Farbe manuell eingeben",
    "Envoi en cours": "Senden in Arbeit",
    "Envoyer": "Envoyer",
    "Erreur": "Erreur",
    "Erreur de connexion au serveur, veuillez réessayer s'il vous plait.": "Erreur de connexion au serveur, veuillez réessayer s'il vous plait.",
    "Erreur lors de l'envoi sur Facebook. Merci de vérifier vos informations et réessayer.": "Erreur lors de l'envoi sur Facebook. Merci de vérifier vos informations et réessayer.",
    "Erreur lors de l'envoi sur Instagram. Merci de vérifier vos informations et réessayer.": "Erreur lors de l'envoi sur Instagram. Merci de vérifier vos informations et réessayer.",
    "Erreur lors de l'envoi sur Tiktok. Merci de vérifier vos informations et réessayer.": "Erreur lors de l'envoi sur Tiktok. Merci de vérifier vos informations et réessayer.",
    "Erreur lors de la copie du lien, veuillez réessayer": "Erreur lors de la copie du lien, veuillez réessayer",
    "Erreur pendant l'enregistrement de vos informations": "Erreur pendant l'enregistrement de vos informations",
    "Erreur pendant la suppression du projet.": "Erreur pendant la suppression du projet.",
    "Fait main": "Handgemacht",
    "Fonctionnalités": "Fonctionnalités",
    "Force Majeure": "Force Majeure",
    "Format": "Format",
    "Fournir tous les éléments documentaires, graphiques et textuels nécessaires à la bonne réalisation du Contrat (notamment dans les bons formats exploitables en fonction des supports visés), le Client s'engage à fournir toutes les informations légales à ajouter dans les documents et endosse la responsabilité de fournir le contenu des documents qu'il édite.": "Fournir tous les éléments documentaires, graphiques et textuels nécessaires à la bonne réalisation du Contrat (notamment dans les bons formats exploitables en fonction des supports visés), le Client s'engage à fournir toutes les informations légales à ajouter dans les documents et endosse la responsabilité de fournir le contenu des documents qu'il édite.",
    "Frais Annexes": "Frais Annexes",
    "Garantie d’éviction": "Garantie d’éviction",
    "Garanties": "Garanties",
    "Garantir le Prestataire contre toute action qui pourrait lui être intentée du fait du caractère des données ou informations (textes, images, sons) qui auraient été fournies ou choisies par le Client.": "Garantir le Prestataire contre toute action qui pourrait lui être intentée du fait du caractère des données ou informations (textes, images, sons) qui auraient été fournies ou choisies par le Client.",
    "Gratuit": "Gratuit",
    "Généralités": "Généralités",
    "HT": "HT",
    "Hormis les dommages corporels ou décès, et sauf en cas de négligence grossière ou de faute intentionnelle causant un dommage direct prouvé ou en cas de manquement à une obligation essentielle du Contrat la vidant de sa substance, le Client reconnaît que la responsabilité du Prestataire est limitée au montant versé pour les Services en cause.": "Hormis les dommages corporels ou décès, et sauf en cas de négligence grossière ou de faute intentionnelle causant un dommage direct prouvé ou en cas de manquement à une obligation essentielle du Contrat la vidant de sa substance, le Client reconnaît que la responsabilité du Prestataire est limitée au montant versé pour les Services en cause.",
    "Il semble y avoir une erreur de notre côté.": "Il semble y avoir une erreur de notre côté.",
    "Images": "Bilder",
    "Immobilier": "Immobilien",
    "Importer cette annonce": "Importer cette annonce",
    "Importer une image": "Bild importieren",
    "Informer le Prestataire d’une éventuelle mise en concurrence avec d’autres prestataires.": "Informer le Prestataire d’une éventuelle mise en concurrence avec d’autres prestataires.",
    "Infos générales": "Allgemeine Informationen",
    "Inscription": "Inscription",
    "L'email a été envoyé": "L'email a été envoyé",
    "L'enregistrement des champs a échoué.": "L'enregistrement des champs a échoué.",
    "L'importation de cette annonce n'a pas fonctionné. Si le problème persiste, veuillez contacter le support.": "L'importation de cette annonce n'a pas fonctionné. Si le problème persiste, veuillez contacter le support.",
    "L'url de votre site Internet": "L'url de votre site Internet",
    "La Partie touchée par un événement de force majeure en avisera l'autre Partie dans les cinq (5) jours ouvrables suivant la date à laquelle elle en aura eu connaissance. Les deux Parties conviendront alors des conditions dans lesquelles l'exécution du Contrat sera poursuivie.": "La Partie touchée par un événement de force majeure en avisera l'autre Partie dans les cinq (5) jours ouvrables suivant la date à laquelle elle en aura eu connaissance. Les deux Parties conviendront alors des conditions dans lesquelles l'exécution du Contrat sera poursuivie.",
    "La connexion automatique a échouée.": "La connexion automatique a échouée.",
    "La liaison avec Facebook a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Facebook.": "La liaison avec Facebook a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Facebook.",
    "La liaison avec Instagram a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Instagram.": "La liaison avec Instagram a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Instagram.",
    "La liaison avec Tiktok a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Tiktok.": "La liaison avec Tiktok a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Tiktok.",
    "La loi du Contrat est la Loi Française. Les parties conviennent expressément que la Convention de Viennes sur la vente internationale de marchandises en date du 11 avril 1980 n’est pas applicable au Contrat.": "La loi du Contrat est la Loi Française. Les parties conviennent expressément que la Convention de Viennes sur la vente internationale de marchandises en date du 11 avril 1980 n’est pas applicable au Contrat.",
    "La responsabilité du Prestataire est limitée aux seuls dommages directs et résultant d'un défaut des Services ou de la violation du Contrat, même si le défaut en question était prévisible au moment de la Commande.": "La responsabilité du Prestataire est limitée aux seuls dommages directs et résultant d'un défaut des Services ou de la violation du Contrat, même si le défaut en question était prévisible au moment de la Commande.",
    "La réalisation des Services confiés au Prestataire dépendant directement du respect par le Client de ses propres obligations, les Parties reconnaissent expressément que les délais de livraison visés au devis sont donnés à titre purement indicatif et sans garantie.": "La réalisation des Services confiés au Prestataire dépendant directement du respect par le Client de ses propres obligations, les Parties reconnaissent expressément que les délais de livraison visés au devis sont donnés à titre purement indicatif et sans garantie.",
    "La vidéo est en cours d'envoi sur Facebook. Vous recevrez une notification Facebook dès qu'elle sera prête.": "La vidéo est en cours d'envoi sur Facebook. Vous recevrez une notification Facebook dès qu'elle sera prête.",
    "La vidéo est en cours d'envoi sur Instagram. Vous recevrez une notification Instagram dès qu'elle sera prête.": "La vidéo est en cours d'envoi sur Instagram. Vous recevrez une notification Instagram dès qu'elle sera prête.",
    "La vidéo est en cours d'envoi sur Tiktok. Vous recevrez une notification Tiktok dès qu'elle sera prête.": "La vidéo est en cours d'envoi sur Tiktok. Vous recevrez une notification Tiktok dès qu'elle sera prête.",
    "Langue du Contrat": "Langue du Contrat",
    "Langue mise à jour avec succès.": "Sprache erfolgreich aktualisiert.",
    "Langues": "Sprachen",
    "Le Client déclare expressément avoir reçu du Prestataire toutes les informations et tous les conseils nécessaires à la réalisation des Services et renonce à rechercher la responsabilité du Prestataire de ce fait.": "Le Client déclare expressément avoir reçu du Prestataire toutes les informations et tous les conseils nécessaires à la réalisation des Services et renonce à rechercher la responsabilité du Prestataire de ce fait.",
    "Le Client s'engage à fournir des informations justes et sincères et s'engage aussi à prévenir le Prestataire de tout changement concernant les informations, données, documentations fournies.": "Le Client s'engage à fournir des informations justes et sincères et s'engage aussi à prévenir le Prestataire de tout changement concernant les informations, données, documentations fournies.",
    "Le Client sera seul responsable des éventuels dysfonctionnements qui pourraient résulter d'informations erronées. Le Client doit maintenir une adresse e-mail et une adresse postale valides.": "Le Client sera seul responsable des éventuels dysfonctionnements qui pourraient résulter d'informations erronées. Le Client doit maintenir une adresse e-mail et une adresse postale valides.",
    "Le Client sera également débiteur de plein droit d’une indemnité forfaitaire minimum de recouvrement de quarante (40) euros des sommes dues par le Client au Prestataire.": "Le Client sera également débiteur de plein droit d’une indemnité forfaitaire minimum de recouvrement de quarante (40) euros des sommes dues par le Client au Prestataire.",
    "Le Client s’engage à fournir au Prestataire sous forme exploitable l’ensemble des documents nécessaires à la réalisation des Services confiés au Prestataire.": "Le Client s’engage à fournir au Prestataire sous forme exploitable l’ensemble des documents nécessaires à la réalisation des Services confiés au Prestataire.",
    "Le Client s’engage à obtenir de tous tiers, si besoin est, le droit de concéder au Prestataire les droits d’utilisation des logiciels, données et équipements appartenant à ces tiers pour les besoins de la fourniture des Services.": "Le Client s’engage à obtenir de tous tiers, si besoin est, le droit de concéder au Prestataire les droits d’utilisation des logiciels, données et équipements appartenant à ces tiers pour les besoins de la fourniture des Services.",
    "Le Contrat est rédigé en langue française. Une traduction en langue étrangère peut être fournie à titre d’information. En cas de contradiction, seule la version française fera foi entre les Parties.": "Le Contrat est rédigé en langue française. Une traduction en langue étrangère peut être fournie à titre d’information. En cas de contradiction, seule la version française fera foi entre les Parties.",
    "Le Contrat se substitue à tout autre document antérieur, à tout autre accord écrit ou verbal en relation avec le même objet, à l’exception du devis, de la Commande et prévaut sur toute disposition contraire qui pourrait être contenue dans les documents émanant du Client.": "Le Contrat se substitue à tout autre document antérieur, à tout autre accord écrit ou verbal en relation avec le même objet, à l’exception du devis, de la Commande et prévaut sur toute disposition contraire qui pourrait être contenue dans les documents émanant du Client.",
    "Le Prestataire a la faculté de sous-traiter tout ou partie de la réalisation des Services à des sous-traitants. Dans ce cas, le Prestataire demeure responsable de la réalisation des Services vis-à-vis du Client.": "Le Prestataire a la faculté de sous-traiter tout ou partie de la réalisation des Services à des sous-traitants. Dans ce cas, le Prestataire demeure responsable de la réalisation des Services vis-à-vis du Client.",
    "Le Prestataire agissant au nom et pour le compte du Client dans le traitement des données personnelles qui lui sont communiquées par le Client, il dispose de la qualité de sous-traitant.": "Le Prestataire agissant au nom et pour le compte du Client dans le traitement des données personnelles qui lui sont communiquées par le Client, il dispose de la qualité de sous-traitant.",
    "Le Prestataire concède au Client, le cas échéant et dans la limite strictement nécessaire à l’exécution des Services, à titre personnel, non exclusif et non-transférable, le droit d'utiliser lesdits Droits de Propriété Intellectuelle pour la durée de réalisation des Services.": "Le Prestataire concède au Client, le cas échéant et dans la limite strictement nécessaire à l’exécution des Services, à titre personnel, non exclusif et non-transférable, le droit d'utiliser lesdits Droits de Propriété Intellectuelle pour la durée de réalisation des Services.",
    "Le Prestataire déclare être immatriculé au RCS, ainsi qu’auprès de l’URSSAF et que ses immatriculations couvrent expressément toutes ses activités pour l’exécution des Services définis dans le devis et/ou la Commande.": "Le Prestataire déclare être immatriculé au RCS, ainsi qu’auprès de l’URSSAF et que ses immatriculations couvrent expressément toutes ses activités pour l’exécution des Services définis dans le devis et/ou la Commande.",
    "Le Prestataire est quant à lui autorisé à utiliser la dénomination sociale/la marque du Client dans le cadre de ses activités à des fins de promotion commerciale.": "Le Prestataire est quant à lui autorisé à utiliser la dénomination sociale/la marque du Client dans le cadre de ses activités à des fins de promotion commerciale.",
    "Le Prestataire est une entreprise spécialisée notamment dans Génération automatique de vidéo basée sur des informations fournies statiques (textes, images).": "Le Prestataire est une entreprise spécialisée notamment dans Génération automatique de vidéo basée sur des informations fournies statiques (textes, images).",
    "Le Prestataire garantit le Client contre toute action, réclamation, revendication ou opposition de la part de toute personne invoquant un droit de propriété intellectuelle auxquels la fourniture des Services aurait porté atteinte, sous réserve que le Client informe le Prestataire, dès qu’il en a connaissance, de toute demande, réclamation ou instance présentée ou engagée pour un tel motif, par voie judiciaire ou extrajudiciaire. Le Client s’engage à apporter au Prestataire, tous les documents et renseignements en sa possession ainsi que toute l’assistance requise qui pourraient être nécessaires à sa défense.": "Le Prestataire garantit le Client contre toute action, réclamation, revendication ou opposition de la part de toute personne invoquant un droit de propriété intellectuelle auxquels la fourniture des Services aurait porté atteinte, sous réserve que le Client informe le Prestataire, dès qu’il en a connaissance, de toute demande, réclamation ou instance présentée ou engagée pour un tel motif, par voie judiciaire ou extrajudiciaire. Le Client s’engage à apporter au Prestataire, tous les documents et renseignements en sa possession ainsi que toute l’assistance requise qui pourraient être nécessaires à sa défense.",
    "Le Prestataire garantit que les Services sont fournis de manière substantiellement conforme à la Commande.": "Le Prestataire garantit que les Services sont fournis de manière substantiellement conforme à la Commande.",
    "Le Prestataire garantit que les créations sont juridiquement disponibles et ne sont pas grevées de droit des tiers pour les utilisations prévues au titre du Contrat.": "Le Prestataire garantit que les créations sont juridiquement disponibles et ne sont pas grevées de droit des tiers pour les utilisations prévues au titre du Contrat.",
    "Le Prestataire n'aura aucune obligation d’indemnisation ou autre obligation au titre d’une action en contrefaçon ayant pour origine (a) une utilisation des Services autrement que conformément au Contrat, (b) une combinaison des Services avec d’autres services ou matériels non fournis par le Prestataire.": "Le Prestataire n'aura aucune obligation d’indemnisation ou autre obligation au titre d’une action en contrefaçon ayant pour origine (a) une utilisation des Services autrement que conformément au Contrat, (b) une combinaison des Services avec d’autres services ou matériels non fournis par le Prestataire.",
    "Le Prestataire ne peut être tenu d’aucune garantie notamment lorsque le Client a modifié ou fait modifier les Services ou a utilisé d’autres services que les Services fournis par le Prestataire, sans son accord préalable et écrit ou lorsque le Client ou des tiers sont intervenus sur les éléments des Services sans l’accord préalable du Prestataire.": "Le Prestataire ne peut être tenu d’aucune garantie notamment lorsque le Client a modifié ou fait modifier les Services ou a utilisé d’autres services que les Services fournis par le Prestataire, sans son accord préalable et écrit ou lorsque le Client ou des tiers sont intervenus sur les éléments des Services sans l’accord préalable du Prestataire.",
    "Le Prestataire peut décider de céder ou transférer les droits ou obligations que lui confère le présent Contrat sous réserve que le Client bénéficie des Services dans les mêmes conditions.": "Le Prestataire peut décider de céder ou transférer les droits ou obligations que lui confère le présent Contrat sous réserve que le Client bénéficie des Services dans les mêmes conditions.",
    "Le Prestataire peut mettre fin aux Services fournis au Client en cas de manquement par le Client à ses obligations au titre du Contrat, non réparé dans un délai de quinze (15) jours à compter de la notification par le Prestataire de ce manquement par lettre recommandée avec accusé de réception, indépendamment de la possibilité pour le Prestataire de demander le paiement de dommages et intérêts.": "Le Prestataire peut mettre fin aux Services fournis au Client en cas de manquement par le Client à ses obligations au titre du Contrat, non réparé dans un délai de quinze (15) jours à compter de la notification par le Prestataire de ce manquement par lettre recommandée avec accusé de réception, indépendamment de la possibilité pour le Prestataire de demander le paiement de dommages et intérêts.",
    "Le Prestataire peut également mettre fin au Contrat en cas de non paiement de la ou des facture(s) non acquittée(s) par le Client.": "Le Prestataire peut également mettre fin au Contrat en cas de non paiement de la ou des facture(s) non acquittée(s) par le Client.",
    "Le Prestataire se réserve le droit de modifier ces CGV, les Services et les tarifs à tout moment et sans préavis. Ces modifications n'auront aucune incidence sur les Commandes en cours.": "Le Prestataire se réserve le droit de modifier ces CGV, les Services et les tarifs à tout moment et sans préavis. Ces modifications n'auront aucune incidence sur les Commandes en cours.",
    "Le Prestataire se réserve le droit de modifier les présentes CGV et d’en notifier le Client. Si une modification substantielle des termes des CGV n’est pas acceptable par le Client, celui-ci dispose d’un délai de quinze (15) jours à compter de la notification par le Prestataire des modifications pour en informer le Prestataire. En cas de désaccord des Parties sur ces modifications, les Parties pourront résilier le Contrat.": "Le Prestataire se réserve le droit de modifier les présentes CGV et d’en notifier le Client. Si une modification substantielle des termes des CGV n’est pas acceptable par le Client, celui-ci dispose d’un délai de quinze (15) jours à compter de la notification par le Prestataire des modifications pour en informer le Prestataire. En cas de désaccord des Parties sur ces modifications, les Parties pourront résilier le Contrat.",
    "Le Prestataire se réserve le droit de ne pas accepter une Commande de la part du Client lorsque le Prestataire a déjà rencontré des problèmes de paiement (non-paiement ou retard de paiement) avec le Client pour une ou plusieurs Commande(s) précédente(s).": "Le Prestataire se réserve le droit de ne pas accepter une Commande de la part du Client lorsque le Prestataire a déjà rencontré des problèmes de paiement (non-paiement ou retard de paiement) avec le Client pour une ou plusieurs Commande(s) précédente(s).",
    "Le Prestataire se réserve également le droit de mentionner les réalisations effectuées pour le Client sur ses documents de communication externe, de publicité (site internet, portfolio, etc.) et lors de démarchages de prospection commerciale.": "Le Prestataire se réserve également le droit de mentionner les réalisations effectuées pour le Client sur ses documents de communication externe, de publicité (site internet, portfolio, etc.) et lors de démarchages de prospection commerciale.",
    "Le Prestataire s’engage à informer de manière régulière le Client de l’avancée de la réalisation du projet et ce, notamment, au travers de validations soumises au Client dans le rétro-planning tel que prévu dans le devis.": "Le Prestataire s’engage à informer de manière régulière le Client de l’avancée de la réalisation du projet et ce, notamment, au travers de validations soumises au Client dans le rétro-planning tel que prévu dans le devis.",
    "Le changement de template a échoué.": "Le changement de template a échoué.",
    "Le code promo est expiré.": "Le code promo est expiré.",
    "Le code promo n'existe pas.": "Le code promo n'existe pas.",
    "Le droit de reproduction comprend notamment, et de manière non exhaustive": "Le droit de reproduction comprend notamment, et de manière non exhaustive",
    "Le droit de représentation comprend notamment, et de manière non exhaustive": "Le droit de représentation comprend notamment, et de manière non exhaustive",
    "Le fait que le Prestataire ne se prévale pas à un moment donné d'une quelconque condition des présentes CGV ne peut être interprété comme valant renonciation à se prévaloir ultérieurement de quelconque desdites conditions.": "Le fait que le Prestataire ne se prévale pas à un moment donné d'une quelconque condition des présentes CGV ne peut être interprété comme valant renonciation à se prévaloir ultérieurement de quelconque desdites conditions.",
    "Le lien a été copié": "Le lien a été copié",
    "Le message d'erreur": "Le message d'erreur",
    "Le paiement du solde des Services doit être effectué dans un délai de trente (30) jours à compter de la date de la facture émise par le Prestataire et pourra être effectué sous forme de chèque, de virement, ou de prélèvement automatique à l’ordre du Prestataire.": "Le paiement du solde des Services doit être effectué dans un délai de trente (30) jours à compter de la date de la facture émise par le Prestataire et pourra être effectué sous forme de chèque, de virement, ou de prélèvement automatique à l’ordre du Prestataire.",
    "Le projet": "Le projet",
    "Le template n'est pas disponible dans la langue sélectionnée.": "Die Vorlage ist nicht in der ausgewählten Sprache verfügbar.",
    "Le terme \"Client\" désigne toute personne morale ou physique, ayant requis les compétences du Prestataire.": "Le terme \"Client\" désigne toute personne morale ou physique, ayant requis les compétences du Prestataire.",
    "Le terme \"Tiers\" désigne toute personne physique ou morale non partie à ces Conditions Générales de Vente (CGV).": "Le terme \"Tiers\" désigne toute personne physique ou morale non partie à ces Conditions Générales de Vente (CGV).",
    "Le terme le \"Prestataire\" désigne la société SYNAPSE, Société par action simplifiée au capital de 200 000 euros, immatriculée au Registre du Commerce et des Sociétés de Grenoble sous le numéro RCS 452156102, et dont le siège social est situé 19 chemin des prés, 38240 Meylan.": "Le terme le \"Prestataire\" désigne la société SYNAPSE, Société par action simplifiée au capital de 200 000 euros, immatriculée au Registre du Commerce et des Sociétés de Grenoble sous le numéro RCS 452156102, et dont le siège social est situé 19 chemin des prés, 38240 Meylan.",
    "Le texte descriptif de votre clap (facultatif)": "Beschreibungstext ihres Clap's (optional)",
    "Le texte descriptif de votre vidéo (facultatif)": "Le texte descriptif de votre vidéo (facultatif)",
    "Le versement de l’acompte conditionnera la mise en œuvre des Services. Le paiement de l’acompte pourra être effectué sous forme de chèque, de virement, ou de prélèvement automatique à l’ordre du Prestataire.": "Le versement de l’acompte conditionnera la mise en œuvre des Services. Le paiement de l’acompte pourra être effectué sous forme de chèque, de virement, ou de prélèvement automatique à l’ordre du Prestataire.",
    "Les CGV s'appliquent aux seuls professionnels à l’exclusion des consommateurs. A ce titre, le Client reconnaît avoir la qualité de professionnel, conformément aux dispositions du Code de la consommation applicables.": "Les CGV s'appliquent aux seuls professionnels à l’exclusion des consommateurs. A ce titre, le Client reconnaît avoir la qualité de professionnel, conformément aux dispositions du Code de la consommation applicables.",
    "Les CGV sont systématiquement adressées ou remises à chaque Client avant la passation de toute commande (la Commande). En conséquence, le fait de passer Commande implique l'adhésion entière et sans réserve du Client à ces CGV, à l'exclusion de tous autres documents en sa possession tels que prospectus, catalogues ou plaquettes publicitaires émis par le Prestataire, lesquels n'auront qu'une valeur indicative et non contractuelle.": "Les CGV sont systématiquement adressées ou remises à chaque Client avant la passation de toute commande (la Commande). En conséquence, le fait de passer Commande implique l'adhésion entière et sans réserve du Client à ces CGV, à l'exclusion de tous autres documents en sa possession tels que prospectus, catalogues ou plaquettes publicitaires émis par le Prestataire, lesquels n'auront qu'une valeur indicative et non contractuelle.",
    "Les Parties": "Les Parties",
    "Les Parties ne peuvent être considérées comme responsables ou ayant failli à leurs obligations contractuelles, lorsque le défaut d'exécution des obligations respectives a pour origine la force majeure telle que définie par la jurisprudence des tribunaux français. Le Contrat entre les parties est suspendu jusqu'à l'extinction des causes ayant engendrées la force majeure. La force majeure prend en compte des faits ou circonstances irrésistibles, extérieurs aux parties, imprévisibles et indépendants de la volonté des parties, malgré tous les efforts raisonnablement possibles pour les empêcher.": "Les Parties ne peuvent être considérées comme responsables ou ayant failli à leurs obligations contractuelles, lorsque le défaut d'exécution des obligations respectives a pour origine la force majeure telle que définie par la jurisprudence des tribunaux français. Le Contrat entre les parties est suspendu jusqu'à l'extinction des causes ayant engendrées la force majeure. La force majeure prend en compte des faits ou circonstances irrésistibles, extérieurs aux parties, imprévisibles et indépendants de la volonté des parties, malgré tous les efforts raisonnablement possibles pour les empêcher.",
    "Les Services peuvent être fournis soit à distance, soit dans les locaux du Client, au choix du Prestataire et du Client.": "Les Services peuvent être fournis soit à distance, soit dans les locaux du Client, au choix du Prestataire et du Client.",
    "Les conditions tarifaires du Prestataire relatives à la fourniture des Services sont prévues dans le devis du Prestataire.": "Les conditions tarifaires du Prestataire relatives à la fourniture des Services sont prévues dans le devis du Prestataire.",
    "Les logiciels, données, documentations, procédés, méthodologies, technologies et documents appartenant au Prestataire (ci-après Droits de Propriété Intellectuelle) utilisés dans le cadre de la mise en œuvre des Services restent la propriété exclusive du Prestataire.": "Les logiciels, données, documentations, procédés, méthodologies, technologies et documents appartenant au Prestataire (ci-après Droits de Propriété Intellectuelle) utilisés dans le cadre de la mise en œuvre des Services restent la propriété exclusive du Prestataire.",
    "Les mots de passe ne correspondent pas": "Les mots de passe ne correspondent pas",
    "Les mots de passe ne correspondent pas.": "Les mots de passe ne correspondent pas.",
    "Les paramètres ont été enregistrés.": "Les paramètres ont été enregistrés.",
    "Les prix des Services n’incluent pas les éventuels frais de déplacement ou d’hébergement qui pourraient être facturés en sus par le Prestataire au Client selon les modalités indiquées dans le devis.": "Les prix des Services n’incluent pas les éventuels frais de déplacement ou d’hébergement qui pourraient être facturés en sus par le Prestataire au Client selon les modalités indiquées dans le devis.",
    "Les prix des Services sont exprimés et payables en Euros et sont exprimés hors taxe sur la valeur ajoutée et hors toute autre taxe, le Client étant responsable du paiement desdites taxes.": "Les prix des Services sont exprimés et payables en Euros et sont exprimés hors taxe sur la valeur ajoutée et hors toute autre taxe, le Client étant responsable du paiement desdites taxes.",
    "Les prix sont donnés à titre indicatif et sont donc susceptibles de variation. Le prix facturé est celui prévu dans la Commande validée par le Prestataire.": "Les prix sont donnés à titre indicatif et sont donc susceptibles de variation. Le prix facturé est celui prévu dans la Commande validée par le Prestataire.",
    "Les présentes CGV ont pour objet de définir les droits et obligations des Parties lors de la réalisation de prestations de services réalisées par le Prestataire pour le Client dans le cadre de ses activités.": "Les présentes CGV ont pour objet de définir les droits et obligations des Parties lors de la réalisation de prestations de services réalisées par le Prestataire pour le Client dans le cadre de ses activités.",
    "Les présentes Conditions Générales de Vente (ci-après désignées les \"CGV\") s'appliquent à tout contrat conclu entre le Prestataire et le Client, dans le cadre de la fourniture de prestations de services (les Services) telles que définies dans le devis validé par le Client.": "Les présentes Conditions Générales de Vente (ci-après désignées les \"CGV\") s'appliquent à tout contrat conclu entre le Prestataire et le Client, dans le cadre de la fourniture de prestations de services (les Services) telles que définies dans le devis validé par le Client.",
    "Les présentes Conditions Générales de Vente sont à jour au 1 janvier 2021.": "Les présentes Conditions Générales de Vente sont à jour au 1 janvier 2021.",
    "Les tarifs sont HT.": "Les tarifs sont HT.",
    "Les œuvres créées par le Prestataire pour le Client, dans le cadre de l’exécution du Contrat, demeurent la propriété entière et exclusive du Prestataire tant que les factures émises par le Prestataire ne sont pas entièrement acquittées par le Client.": "Les œuvres créées par le Prestataire pour le Client, dans le cadre de l’exécution du Contrat, demeurent la propriété entière et exclusive du Prestataire tant que les factures émises par le Prestataire ne sont pas entièrement acquittées par le Client.",
    "Lier un compte": "Lier un compte",
    "Logo de l'agence": "Logo de l'agence",
    "Légende pour": "Untertitel für",
    "L’acceptation du devis et de la Commande du Client qui en découle, doit s’accompagner du paiement d’un acompte tel qu’indiqué dans le devis.": "L’acceptation du devis et de la Commande du Client qui en découle, doit s’accompagner du paiement d’un acompte tel qu’indiqué dans le devis.",
    "L’intelligence artificielle est en bêta chez clap.video": "L’intelligence artificielle est en bêta chez clap.video",
    "Ma vidéo": "Mein Video",
    "Maison de campagne 156m²": "Maison de campagne 156m²",
    "Marques et dénominations sociales": "Marques et dénominations sociales",
    "Mensuel": "Mensuel",
    "Merci de réessayer en cliquant": "Merci de réessayer en cliquant",
    "Merci pour votre abonnement ! Bienvenue sur clap.video premium.": "Merci pour votre abonnement ! Bienvenue sur clap.video premium.",
    "Mes informations": "Mes informations",
    "Mes vidéos": "Meine Claps",
    "Mise à jour d'une vidéo": "Mise à jour d'une vidéo",
    "Modalités d’exécution des Services et livraison des livrables": "Modalités d’exécution des Services et livraison des livrables",
    "Modifications des CGV – cession du Contrat": "Modifications des CGV – cession du Contrat",
    "Modifier": "Modifier",
    "Mon compte": "Mon compte",
    "Mot de passe": "Mot de passe",
    "Mot de passe actuel": "Mot de passe actuel",
    "Mot de passe oublié": "Mot de passe oublié",
    "Moyen de paiement": "Moyen de paiement",
    "Musique": "Musik",
    "Médias": "Medien",
    "NOUVEAU": "NOUVEAU",
    "Nom": "Nom",
    "Nom de l'entreprise": "Nom de l'entreprise",
    "Non sollicitation": "Non sollicitation",
    "Notification": "Notification",
    "Notifications": "Notifications",
    "Nous avons trouvé des photos provenant votre annonce web, cliquez sur celle que vous souhaitez ajouter à votre vidéo": "Wir haben Fotos aus Ihrer Web-Anzeige gefunden. Klicken Sie auf das Bild, das Sie zu Ihrem Video hinzufügen möchten.",
    "Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles. Nous utilisons un cryptage pour protéger les informations sensibles transmises en ligne. Nous protégeons également vos informations hors ligne. Seuls les employés qui ont besoin d’effectuer un travail spécifique (par exemple, la facturation ou le service à la clientèle) ont accès aux informations personnelles identifiables. Les ordinateurs et serveurs utilisés pour stocker des informations personnelles identifiables sont conservés dans un environnement sécurisé.": "Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles. Nous utilisons un cryptage pour protéger les informations sensibles transmises en ligne. Nous protégeons également vos informations hors ligne. Seuls les employés qui ont besoin d’effectuer un travail spécifique (par exemple, la facturation ou le service à la clientèle) ont accès aux informations personnelles identifiables. Les ordinateurs et serveurs utilisés pour stocker des informations personnelles identifiables sont conservés dans un environnement sécurisé.",
    "Nous ne vendons, n’échangeons et ne transférons pas vos informations personnelles identifiables à des tiers. Cela ne comprend pas les tierce parties de confiance qui nous aident à exploiter notre site Web ou à mener nos affaires, tant que ces parties conviennent de garder ces informations confidentielles.": "Nous ne vendons, n’échangeons et ne transférons pas vos informations personnelles identifiables à des tiers. Cela ne comprend pas les tierce parties de confiance qui nous aident à exploiter notre site Web ou à mener nos affaires, tant que ces parties conviennent de garder ces informations confidentielles.",
    "Nous pensons qu’il est nécessaire de partager des informations afin d’enquêter, de prévenir ou de prendre des mesures concernant des activités illégales, fraudes présumées, situations impliquant des menaces potentielles à la sécurité physique de toute personne, violations de nos conditions d’utilisation, ou quand la loi nous y contraint.": "Nous pensons qu’il est nécessaire de partager des informations afin d’enquêter, de prévenir ou de prendre des mesures concernant des activités illégales, fraudes présumées, situations impliquant des menaces potentielles à la sécurité physique de toute personne, violations de nos conditions d’utilisation, ou quand la loi nous y contraint.",
    "Nous recueillons des informations lorsque vous visitez notre site, vous inscrivez sur notre site et lorsque vous vous connectez à votre compte. Les informations recueillies incluent votre prénom, votre nom, votre entreprise, votre adresse e-mail et votre numéro de téléphone (optionnel). En outre, nous recevons et enregistrons automatiquement des informations à partir de votre ordinateur et navigateur, y compris votre adresse IP, vos logiciels, votre matériel et la page que vous demandez.": "Nous recueillons des informations lorsque vous visitez notre site, vous inscrivez sur notre site et lorsque vous vous connectez à votre compte. Les informations recueillies incluent votre prénom, votre nom, votre entreprise, votre adresse e-mail et votre numéro de téléphone (optionnel). En outre, nous recevons et enregistrons automatiquement des informations à partir de votre ordinateur et navigateur, y compris votre adresse IP, vos logiciels, votre matériel et la page que vous demandez.",
    "Nous récupérons vos biens": "Nous récupérons vos biens",
    "Nous utilisons des cookies. Nos cookies améliorent l’accès à notre site et identifient les visiteurs réguliers. Les cookies sont également conservés par nos partenaires Google Analytics et Hubspot afin d'améliorer l’expérience utilisateur grâce au suivi et au ciblage de ses intérêts. Cependant, cette utilisation des cookies n’est en aucune façon liée à des informations personnelles identifiables sur notre site.": "Nous utilisons des cookies. Nos cookies améliorent l’accès à notre site et identifient les visiteurs réguliers. Les cookies sont également conservés par nos partenaires Google Analytics et Hubspot afin d'améliorer l’expérience utilisateur grâce au suivi et au ciblage de ses intérêts. Cependant, cette utilisation des cookies n’est en aucune façon liée à des informations personnelles identifiables sur notre site.",
    "Nous utilisons l’adresse e-mail que vous fournissez pour vous envoyer des informations et mises à jour relatives à votre commande, des nouvelles de l’entreprise de façon occasionnelle, des informations sur des produits liés, etc. Si à n’importe quel moment vous souhaitez vous désinscrire et ne plus recevoir d’e-mails, des instructions de désabonnement détaillées sont incluses en bas de chaque e-mail.": "Nous utilisons l’adresse e-mail que vous fournissez pour vous envoyer des informations et mises à jour relatives à votre commande, des nouvelles de l’entreprise de façon occasionnelle, des informations sur des produits liés, etc. Si à n’importe quel moment vous souhaitez vous désinscrire et ne plus recevoir d’e-mails, des instructions de désabonnement détaillées sont incluses en bas de chaque e-mail.",
    "Nouveau": "Neu",
    "Nouveau mot de passe": "Nouveau mot de passe",
    "Nouvelle vidéo": "Nouvelle vidéo",
    "N° de téléphone pro": "N° de téléphone pro",
    "Obligations des Parties": "Obligations des Parties",
    "Obligations du Client": "Obligations du Client",
    "Obligations du Prestataire": "Obligations du Prestataire",
    "Obtenir un lien de téléchargement": "Obtenir un lien de téléchargement",
    "On s'occupe de tout...": "On s'occupe de tout...",
    "Oui, supprimer": "Oui, supprimer",
    "Oups !": "Oups !",
    "Paiement": "Zahlung",
    "Par ailleurs, le Prestataire ne peut être tenu pour responsable de l’inexécution du Contrat en cas de force majeure telle que définie à l’article 15, et en cas de dommages du fait d’un tiers ou imputables à une mauvaise utilisation ou une utilisation non-conforme par le Client des Services, en violation des prescriptions du Prestataire ou des règles de l’art.": "Par ailleurs, le Prestataire ne peut être tenu pour responsable de l’inexécution du Contrat en cas de force majeure telle que définie à l’article 15, et en cas de dommages du fait d’un tiers ou imputables à une mauvaise utilisation ou une utilisation non-conforme par le Client des Services, en violation des prescriptions du Prestataire ou des règles de l’art.",
    "Par défaut": "Standard",
    "Paramètres": "Paramètres",
    "Partage automatique": "Partage automatique",
    "Partager sur les réseaux sociaux": "Partager sur les réseaux sociaux",
    "Passer Premium": "Passer Premium",
    "Passer Premium !": "Passer Premium !",
    "Paysage": "Querformat",
    "Personnalisation des couleurs": "Personnalisation des couleurs",
    "Personnaliser": "Anpassen",
    "Personnaliser votre expérience": "Personnaliser votre expérience",
    "Personnelles": "Personnelles",
    "Politique de confidentialité": "Politique de confidentialité",
    "Portrait": "Hochformat",
    "Pour l'intégrer à votre logiciel": "Pour l'intégrer à votre logiciel",
    "Pour partager cette page": "Pour partager cette page",
    "Pour permettre au Prestataire de réaliser sa mission, le Client s’engage à": "Pour permettre au Prestataire de réaliser sa mission, le Client s’engage à",
    "Pour récupérer vos photos et pré-remplir votre vidéo": "Pour récupérer vos photos et pré-remplir votre vidéo",
    "Premium": "Premium",
    "Prix": "Prix",
    "Prix de l'annonce": "Prix de l'annonce",
    "Prochain prélèvement le": "Prochain prélèvement le",
    "Professionnelles": "Professionnelles",
    "Profil": "Profil",
    "Projet supprimé.": "Projet supprimé.",
    "Propriété intellectuelle": "Propriété intellectuelle",
    "Prénom": "Prénom",
    "Publication en cours sur": "Publication en cours sur",
    "Publier": "Publier",
    "Publier votre clap sur vos réseaux sociaux": "Veröffentlichen Sie Ihren Clap in Ihren sozialen Netzwerken",
    "Publier votre vidéo sur vos réseaux sociaux": "Publier votre vidéo sur vos réseaux sociaux",
    "Pénalités de retard": "Pénalités de retard",
    "Quand un clap est généré": "Quand un clap est généré",
    "Recevoir un mail quand une vidéo est générée": "Recevoir un mail quand une vidéo est générée",
    "Recherche sur le web": "Recherche sur le web",
    "Rechercher mon annonce": "Rechercher mon annonce",
    "Rechercher un bien par titre, référence ou prix.": "Rechercher un bien par titre, référence ou prix.",
    "Rechercher un clap": "Einen Clap suchen",
    "Rechercher un clap...": "Rechercher un clap...",
    "Rechercher une annonce": "Rechercher une annonce",
    "Rechercher votre annonce sur le web en un coup de baguette": "Rechercher votre annonce sur le web en un coup de baguette",
    "Refus": "Refus",
    "Remettre au Prestataire le devis (daté, signé et tamponné).": "Remettre au Prestataire le devis (daté, signé et tamponné).",
    "Rendre publique et visible votre adresse postale professionnelle dans chaque vidéo que vous créez.": "Rendre publique et visible votre adresse postale professionnelle dans chaque vidéo que vous créez.",
    "Responsabilités": "Responsabilités",
    "Retapez votre mot de passe": "Retapez votre mot de passe",
    "Retapez votre nouveau mot de passe": "Retapez votre nouveau mot de passe",
    "Retour": "Zurück",
    "Retour aux connecteurs": "Retour aux connecteurs",
    "Retour à l'accueil": "Retour à l'accueil",
    "Retour à mes vidéos": "Retour à mes vidéos",
    "Retourner à la page de connexion": "Retourner à la page de connexion",
    "Réactiver mon abonnement et annuler ma résiliation": "Réactiver mon abonnement et annuler ma résiliation",
    "Référence": "Référence",
    "Régler dans les délais prédéfinis dans le devis et dans les présentes CGV, les sommes dues au Prestataire.": "Régler dans les délais prédéfinis dans le devis et dans les présentes CGV, les sommes dues au Prestataire.",
    "Réinitialisation du mot de passe": "Réinitialisation du mot de passe",
    "Réseaux sociaux": "Réseaux sociaux",
    "Résiliation": "Résiliation",
    "Résilier": "Résilier",
    "S'inscrire": "S'inscrire",
    "Sauf convention contraire dans le devis, le Contrat est réputé formé et prend effet entre les Parties à la date de réception par le Prestataire de la Commande du Client soit par email, soit par courrier postal à l’adresse du Prestataire.": "Sauf convention contraire dans le devis, le Contrat est réputé formé et prend effet entre les Parties à la date de réception par le Prestataire de la Commande du Client soit par email, soit par courrier postal à l’adresse du Prestataire.",
    "Sauf disposition légale contraire, toute autre garantie, expresse ou implicite est exclue.": "Sauf disposition légale contraire, toute autre garantie, expresse ou implicite est exclue.",
    "Se conformer strictement aux préconisations techniques et aux suggestions artistiques faites par le Prestataire.": "Se conformer strictement aux préconisations techniques et aux suggestions artistiques faites par le Prestataire.",
    "Se souvenir de moi": "Se souvenir de moi",
    "Si le problème persiste, n'hésitez pas à nous contacter par mail à l'adresse suivante": "Si le problème persiste, n'hésitez pas à nous contacter par mail à l'adresse suivante",
    "Si l’une quelconque disposition de ces CGV ou son application à toute personne ou circonstance est jugée nulle, cette nullité ne concernera pas les autres dispositions ou applications de ces CGV, qui resteront en vigueur, séparément de la disposition jugée nulle. A cette fin, les dispositions de ces CGV sont déclarées autonomes.": "Si l’une quelconque disposition de ces CGV ou son application à toute personne ou circonstance est jugée nulle, cette nullité ne concernera pas les autres dispositions ou applications de ces CGV, qui resteront en vigueur, séparément de la disposition jugée nulle. A cette fin, les dispositions de ces CGV sont déclarées autonomes.",
    "Si vous pensez que c'est une erreur, n'hésitez pas à nous contacter par mail à l'adresse suivante": "Si vous pensez que c'est une erreur, n'hésitez pas à nous contacter par mail à l'adresse suivante",
    "Site internet": "Site internet",
    "Sont à facturer en sus": "Sont à facturer en sus",
    "Souhaitez vous vraiment supprimer la vidéo": "Souhaitez vous vraiment supprimer la vidéo",
    "Souhaitez vous vraiment supprimer le clap": "Möchten Sie den Clap wirklich löschen",
    "Sous-traitance": "Sous-traitance",
    "Suite à votre demande, votre abonnement Premium ne sera pas renouvelé et prendra fin le": "Suite à votre demande, votre abonnement Premium ne sera pas renouvelé et prendra fin le",
    "Suppression du logo clap.video": "Suppression du logo clap.video",
    "Suppression du logo clap.video en fin de vidéo": "Suppression du logo clap.video en fin de vidéo",
    "Supprimer le clap": "Clap löschen",
    "Sélectionner": "Auswählen",
    "Sélectionner l'annonce à importer": "Sélectionner l'annonce à importer",
    "Sélectionner la couleur par défaut que vous souhaitez utiliser pour vos vidéos.": "Sélectionner la couleur par défaut que vous souhaitez utiliser pour vos vidéos.",
    "Sélectionnez une image provenant de l’annonce web": "Wählen Sie ein Bild aus der Web-Anzeige aus",
    "Sélectionnez votre page Facebook": "Sélectionnez votre page Facebook",
    "Sélectionné": "Ausgewählt",
    "Sélectionnée": "Ausgewählt",
    "S’assurer de la mise à disposition de tous les moyens nécessaires pour permettre au Prestataire de réaliser les Services dans ses locaux et/ou à distance.": "S’assurer de la mise à disposition de tous les moyens nécessaires pour permettre au Prestataire de réaliser les Services dans ses locaux et/ou à distance.",
    "Tapez un nouveau mot de passe": "Tapez un nouveau mot de passe",
    "Tapez votre mot de passe actuel": "Tapez votre mot de passe actuel",
    "Template": "Template",
    "Template sélectionné": "Ausgewählte Vorlage",
    "Terminer": "Fertigstellen",
    "Terminer et générer la vidéo": "Abschließen und Video generieren",
    "Terminé": "abgeschlossen",
    "Titre de la vidéo": "Titre de la vidéo",
    "Titre du clap": "Titel des Clap's",
    "Toute les informations que nous recueillons auprès de vous peuvent être utilisées pour": "Toute les informations que nous recueillons auprès de vous peuvent être utilisées pour",
    "Toute modification ultérieure ou demande complémentaire demandée par le Client fera l’objet d’une facturation supplémentaire.": "Toute modification ultérieure ou demande complémentaire demandée par le Client fera l’objet d’une facturation supplémentaire.",
    "Toute notification devra être faite par écrit et être soit remise en mains propres, soit adressée par lettre recommandée avec accusé de réception, soit faite par acte extra judiciaire à l’adresse indiquée dans la commande.": "Toute notification devra être faite par écrit et être soit remise en mains propres, soit adressée par lettre recommandée avec accusé de réception, soit faite par acte extra judiciaire à l’adresse indiquée dans la commande.",
    "Toute réserve concernant les CGV, avancée par le Client sera, donc, à défaut d'acceptation expresse par le Prestataire, inopposable à ce dernier, quel que soit le moment où elle aura pu être portée à sa connaissance.": "Toute réserve concernant les CGV, avancée par le Client sera, donc, à défaut d'acceptation expresse par le Prestataire, inopposable à ce dernier, quel que soit le moment où elle aura pu être portée à sa connaissance.",
    "Toute utilisation par le Client des dénominations sociales, marques et signes distincts appartenant au Prestataire est strictement prohibée sauf en cas d’accord exprès et préalable du Prestataire. En cas d’accord exprès et préalable du Prestataire, ce dernier concède alors au Client un droit strictement personnel, non exclusif, et non transférable d'utiliser ses dénominations sociales, marques et signes distincts, dans le monde entier et pour toute la durée de validité du Contrat.": "Toute utilisation par le Client des dénominations sociales, marques et signes distincts appartenant au Prestataire est strictement prohibée sauf en cas d’accord exprès et préalable du Prestataire. En cas d’accord exprès et préalable du Prestataire, ce dernier concède alors au Client un droit strictement personnel, non exclusif, et non transférable d'utiliser ses dénominations sociales, marques et signes distincts, dans le monde entier et pour toute la durée de validité du Contrat.",
    "Toutes dispositions dérogeant aux présentes CGV devront résulter d’un accord exprès des Parties, reflété dans les Commandes confirmées par le Prestataire ou tout autre document faisant foi de l’accord des deux Parties.": "Toutes dispositions dérogeant aux présentes CGV devront résulter d’un accord exprès des Parties, reflété dans les Commandes confirmées par le Prestataire ou tout autre document faisant foi de l’accord des deux Parties.",
    "Travail dissimulé": "Travail dissimulé",
    "Type": "Type",
    "Télécharger": "Télécharger",
    "Télécharger cette vidéo": "Télécharger cette vidéo",
    "Un extrait Kbis attestant de l’immatriculation au registre du commerce et des sociétés,": "Un extrait Kbis attestant de l’immatriculation au registre du commerce et des sociétés,",
    "Un problème a été rencontré lors de la création de la vidéo. Veuillez réessayer.": "Un problème a été rencontré lors de la création de la vidéo. Veuillez réessayer.",
    "Un problème a été rencontré lors de la création du clap. Veuillez réessayer.": "Beim Erstellen des Claps ist ein Problem aufgetreten. Bitte versuchen Sie es noch einmal.",
    "Un retard sur les délais indiqués ne pourra donc donner lieu au paiement de dommages et intérêts, ni autoriser le Client à résilier le Contrat où à refuser la livraison des Services.": "Un retard sur les délais indiqués ne pourra donc donner lieu au paiement de dommages et intérêts, ni autoriser le Client à résilier le Contrat où à refuser la livraison des Services.",
    "Une attestation sur l’honneur établie par le Prestataire, certifiant que le travail est réalisé par des salariés employés régulièrement eu égard aux articles D.8222-5, D.8222-7 et D.8222-8 du Code du travail.": "Une attestation sur l’honneur établie par le Prestataire, certifiant que le travail est réalisé par des salariés employés régulièrement eu égard aux articles D.8222-5, D.8222-7 et D.8222-8 du Code du travail.",
    "Une copie de l’avis d’imposition afférent à la taxe professionnelle,": "Une copie de l’avis d’imposition afférent à la taxe professionnelle,",
    "Une erreur est survenue": "Une erreur est survenue",
    "Une erreur est survenue lors de la récupération des biens.": "Une erreur est survenue lors de la récupération des biens.",
    "Une erreur est survenue lors de la sélection de cette musique. Veuillez en choisir une autre.": "Une erreur est survenue lors de la sélection de cette musique. Veuillez en choisir une autre.",
    "Une erreur est survenue lors du chargement de votre fichier. Veuillez essayer avec un fichier plus léger.": "Une erreur est survenue lors du chargement de votre fichier. Veuillez essayer avec un fichier plus léger.",
    "Une erreur est survenue lors du lancement du rendu. Veuillez réessayer.": "Une erreur est survenue lors du lancement du rendu. Veuillez réessayer",
    "Une erreur est survenue. Merci de réessayer.": "Une erreur est survenue. Merci de réessayer.",
    "Une erreur inconnue est survenue.": "Une erreur inconnue est survenue.",
    "Une erreur inconnue s'est produite ! Contactez support@clap.video.": "Une erreur inconnue s'est produite ! Contactez support@clap.video.",
    "Valable jusqu'au": "Valable jusqu'au",
    "Valider": "Bestätigen",
    "Veuillez accepter les conditions générales de vente.": "Veuillez accepter les conditions générales de vente.",
    "Veuillez compléter toutes les images.": "Veuillez compléter toutes les images.",
    "Veuillez entrer un code promo.": "Veuillez entrer un code promo.",
    "Veuillez entrer un email et un mot de passe.": "Veuillez entrer un email et un mot de passe.",
    "Veuillez entrer un titre valide": "Veuillez entrer un titre valide",
    "Veuillez remplir les informations bancaires.": "Veuillez remplir les informations bancaires.",
    "Veuillez remplir votre nom et prénom.": "Veuillez remplir votre nom et prénom.",
    "Veuillez utiliser la": "Veuillez utiliser la",
    "Vidéo automatique": "Vidéo automatique",
    "Vidéos": "Videos",
    "Vidéos illimitées": "Vidéos illimitées",
    "Ville": "Ville",
    "Voir mes autoclaps": "Voir mes autoclaps",
    "Vos clients apprécient votre travail et votre entreprise ? Faites-le savoir en créant une vidéo présentant vos meilleurs avis.": "Vos clients apprécient votre travail et votre entreprise ? Faites-le savoir en créant une vidéo présentant vos meilleurs avis.",
    "Vos modifications ont bien été enregistrées": "Vos modifications ont bien été enregistrées",
    "Votre adresse email": "Votre adresse email",
    "Votre adresse postale": "Votre adresse postale",
    "Votre clap est en file d'attente, veuillez patienter quelques instants avant le début de la génération.": "Ihr clap befindet sich in der Warteschleife. Bitte warten Sie einen Moment, bevor die Erstellung beginnt.",
    "Votre code postale": "Votre code postale",
    "Votre couleur personnalisée": "Votre couleur personnalisée",
    "Votre demande de résiliation a bien été prise en compte.": "Votre demande de résiliation a bien été prise en compte.",
    "Votre email personnel": "Votre email personnel",
    "Votre email professionnel": "Votre email professionnel",
    "Votre nom": "Votre nom",
    "Votre nom d'entreprise": "Votre nom d'entreprise",
    "Votre nouveau mot de passe a bien été enregistré": "Votre nouveau mot de passe a bien été enregistré",
    "Votre numéro de téléphone professionnel": "Votre numéro de téléphone professionnel",
    "Votre photo de profil a bien été modifiée": "Votre photo de profil a bien été modifiée",
    "Votre photo de profil professionnelle a bien été modifiée": "Votre photo de profil professionnelle a bien été modifiée",
    "Votre prénom": "Votre prénom",
    "Votre résiliation a bien été annulée.": "Votre résiliation a bien été annulée.",
    "Votre vidéo est en cours de génération !": "Votre vidéo est en cours de génération !",
    "Votre vidéo est en file d'attente, veuillez patienter quelques instants avant le début de la génération.": "Votre vidéo est en file d'attente, veuillez patienter quelques instants avant le début de la génération.",
    "Votre ville": "Votre ville",
    "Voulez-vous vraiment réactiver votre abonnement": "Voulez-vous vraiment réactiver votre abonnement",
    "Voulez-vous vraiment résilier votre abonnement Premium ? Votre abonnement continuera jusqu'à la date de fin d'abonnement et ne sera pas renouvelé.": "Voulez-vous vraiment résilier votre abonnement Premium ? Votre abonnement continuera jusqu'à la date de fin d'abonnement et ne sera pas renouvelé.",
    "Vous avez atteint le nombre maximum de vidéos gratuites.": "Vous avez atteint le nombre maximum de vidéos gratuites.",
    "Vous avez déjà un compte": "Vous avez déjà un compte",
    "Vous contacter pour vous présenter de nouvelles fonctionnalités et offres qui pourraient vous intéresser": "Vous contacter pour vous présenter de nouvelles fonctionnalités et offres qui pourraient vous intéresser",
    "Vous devez lier Facebook": "Vous devez lier Facebook",
    "Vous donnez accès à notre service de création vidéo": "Vous donnez accès à notre service de création vidéo",
    "Vous n'avez aucune vidéo pour le moment.": "Vous n'avez aucune vidéo pour le moment",
    "Vous n'avez pas de compte": "Vous n'avez pas de compte",
    "Vous n'avez pas lié de compte": "Vous n'avez pas lié de compte",
    "Vous possédez un abonnement": "Vous possédez un abonnement",
    "Vous pouvez envoyer cette vidéo sur votre page Facebook en vous": "Vous pouvez envoyer cette vidéo sur votre page Facebook en vous",
    "Vous pouvez quitter cette page, votre vidéo sera directement ajoutée dans votre logiciel métier.": "Vous pouvez quitter cette page, votre vidéo sera directement ajoutée dans votre logiciel métier.",
    "Vous pouvez résilier votre abonnement à tout moment": "Vous pouvez résilier votre abonnement à tout moment",
    "Vous serez de nouveau prélevé tous les": "Vous serez de nouveau prélevé tous les",
    "Vous serez prélevé automatiquement à chaque échéance.": "Vous serez prélevé automatiquement à chaque échéance.",
    "Vous êtes actuellement connecté via Topi": "Vous êtes actuellement connecté via Topi",
    "Zone de danger": "Gefahrenbereich",
    "agency": {
      "address": "Adresse der Agentur",
      "logo": "Logo",
      "name": "Name der Agentur",
      "photo": "Foto",
      "web": "Internetseite"
    },
    "agent": {
      "email": "E-Mailadresse",
      "name": "Name",
      "phone": "Telefonnummer",
      "photo": "Bild der Person",
      "web": "Internetseite"
    },
    "chambre(s)": "Zimmer",
    "conditions générales de vente": "conditions générales de vente",
    "connectant sur clap.video": "connectant sur clap.video",
    "connus par l’une des Parties sur une base non-confidentielle avant leur divulgation par l'autre Partie": "connus par l’une des Parties sur une base non-confidentielle avant leur divulgation par l'autre Partie",
    "création manuelle": "création manuelle",
    "d'un montant de": "d'un montant de",
    "de Clap.": "de Clap.",
    "depuis le": "depuis le",
    "divulgués en vertu d'une disposition législative ou réglementaire.": "divulgués en vertu d'une disposition législative ou réglementaire.",
    "développés de façon indépendante par la Partie réceptrice qui n’a eu accès à aucune information de la Partie divulgatrice": "développés de façon indépendante par la Partie réceptrice qui n’a eu accès à aucune information de la Partie divulgatrice",
    "fournir une solution de remplacement permettant au Client de pouvoir utiliser les Services conformément à la Commande": "fournir une solution de remplacement permettant au Client de pouvoir utiliser les Services conformément à la Commande",
    "ici": "ici",
    "la diffusion des œuvres créées par tout moyen, notamment par voie hertzienne, câble-satellite ainsi que par tout réseau, et plus généralement par tout moyen de transmission de données numérisées ou non.": "la diffusion des œuvres créées par tout moyen, notamment par voie hertzienne, câble-satellite ainsi que par tout réseau, et plus généralement par tout moyen de transmission de données numérisées ou non.",
    "le droit de diffuser et de communiquer à tout public les éléments, supports, composants des œuvres créées, par tout procédé de représentation connu ou inconnu à ce jour, pour toute utilisation quelle qu’elle soit ": "le droit de diffuser et de communiquer à tout public les éléments, supports, composants des œuvres créées, par tout procédé de représentation connu ou inconnu à ce jour, pour toute utilisation quelle qu’elle soit ",
    "le droit de mettre en circulation et d’exploiter les œuvres créées, commercialement ou non, les reproductions ainsi réalisées, en nombre illimité, à titre gratuit ou onéreux, et ce quelle qu’en soit la destination.": "le droit de mettre en circulation et d’exploiter les œuvres créées, commercialement ou non, les reproductions ainsi réalisées, en nombre illimité, à titre gratuit ou onéreux, et ce quelle qu’en soit la destination.",
    "le droit de reproduire et/ou faire reproduire les œuvres créées en nombre illimité, par tout procédé et sur tout support actuel ou futur, et nomment graphique, magnétique, numérique ou électronique (interactif ou non) ": "le droit de reproduire et/ou faire reproduire les œuvres créées en nombre illimité, par tout procédé et sur tout support actuel ou futur, et nomment graphique, magnétique, numérique ou électronique (interactif ou non) ",
    "les légendes de vos photos se sont complétées automatiquement, pensez à les vérifier !": "les légendes de vos photos se sont complétées automatiquement, pensez à les vérifier !",
    "les modifications demandées par le Client en cours de réalisation, si elles impliquent un remaniement du projet.": "les modifications demandées par le Client en cours de réalisation, si elles impliquent un remaniement du projet.",
    "légitimement obtenus d'un tiers non tenu par une obligation de confidentialité": "légitimement obtenus d'un tiers non tenu par une obligation de confidentialité",
    "ma photo": "ma photo",
    "mon logo": "mon logo",
    "n'existe pas ou vous ne vous appartient pas.": "n'existe pas ou vous ne vous appartient pas.",
    "nous vous notifions par email.": "nous vous notifions par email.",
    "obtenir toute concession de licence ou autorisation pour permettre au Client de continuer à utiliser les Services": "obtenir toute concession de licence ou autorisation pour permettre au Client de continuer à utiliser les Services",
    "ou": "ou",
    "ou la": "ou la",
    "page": "Seite",
    "paramètres de connecteurs": "paramètres de connecteurs",
    "photos": {
      "1": "Bild 1",
      "2": "Bild 2",
      "3": "Bild 3",
      "4": "Bild 4",
      "5": "Bild 5"
    },
    "publier votre vidéo sur Facebook en quelques clics, liez vos pages dans vos": "publier votre vidéo sur Facebook en quelques clics, liez vos pages dans vos",
    "quartier": {
      "1": "Quartier du bien"
    },
    "realty": {
      "bedrooms": "Anzahl der Schlafzimmer",
      "city": "Stadt",
      "furnished": "Möbliert oder unmöbliert",
      "living_area": "Wohnfläche",
      "operation": "Vermarktungsart (Verkauf, Vermietung)",
      "price": "Preis",
      "title": "Haupttitel",
      "type": "Objekttyp (Wohnung, Haus)"
    },
    "recherche sur le web": "recherche sur le web",
    "s": "n",
    "secondes": "Sekunden",
    "si aucune des deux possibilités n’est réalisable, rembourser le Client des sommes versées au titre des Services, déduction faite des sommes déjà payées par le Client pour la période d’utilisation effective des Services.": "si aucune des deux possibilités n’est réalisable, rembourser le Client des sommes versées au titre des Services, déduction faite des sommes déjà payées par le Client pour la période d’utilisation effective des Services.",
    "text": {
      "1": "Texte de la recherche",
      "2": "Titre du texte explicatif",
      "3": "Texte explicatif"
    },
    "tombés ou qui tomberont dans le domaine public au jour de leur divulgation": "tombés ou qui tomberont dans le domaine public au jour de leur divulgation",
    "validé": "validé",
    "via AutoClap ou manuellement": "via AutoClap ou manuellement",
    "Éditer cette vidéo": "Éditer cette vidéo",
    "Établir un cahier des charges détaillé qui ne subira plus de modification, sauf accord des Parties, après avoir été approuvé par le Prestataire. Au besoin, le Prestataire pourra intervenir dans l’élaboration du cahier des charges, conjointement avec le Client. Dans le cas où des modifications impliqueraient un remaniement substantiel du cahier des charges initial, ces dernières seront facturées en sus du devis initial.": "Établir un cahier des charges détaillé qui ne subira plus de modification, sauf accord des Parties, après avoir été approuvé par le Prestataire. Au besoin, le Prestataire pourra intervenir dans l’élaboration du cahier des charges, conjointement avec le Client. Dans le cas où des modifications impliqueraient un remaniement substantiel du cahier des charges initial, ces dernières seront facturées en sus du devis initial.",
    "à appliquer aux Informations Confidentielles les mêmes mesures de protection que celles qu'elle applique à ses propres informations confidentielles": "à appliquer aux Informations Confidentielles les mêmes mesures de protection que celles qu'elle applique à ses propres informations confidentielles",
    "à clap.video.": "à clap.video.",
    "à compter du": "à compter du",
    "à n'utiliser les Informations Confidentielles que pour les seuls besoins de réalisation des Services.": "à n'utiliser les Informations Confidentielles que pour les seuls besoins de réalisation des Services.",
    "à ne communiquer les Informations Confidentielles qu'à ses seuls employés et collaborateurs amenés à les connaître dans le cadre de la réalisation des Services": "à ne communiquer les Informations Confidentielles qu'à ses seuls employés et collaborateurs amenés à les connaître dans le cadre de la réalisation des Services",
    "à ne pas divulguer, publier ou transmettre à des tiers les Informations Confidentielles, sous quelque forme que ce soit, sans l’accord préalable écrit de l’autre Partie": "à ne pas divulguer, publier ou transmettre à des tiers les Informations Confidentielles, sous quelque forme que ce soit, sans l’accord préalable écrit de l’autre Partie"
  }], ["en", {
    "(version longue)": "(long version)",
    "1. Collecte de l’information": "1. Gathering information",
    "15 templates": "15 templates",
    "2. Utilisation des informations": "2. Use of information",
    "29,90€ / mois": "29,90€ / month",
    "29,90€/mois": "29,90€/month",
    "299,00€ / an": "299,00€ / year",
    "299,00€/an": "299,00€/year",
    "3 templates": "3 templates",
    "3. Divulgation à des tiers": "3. Disclosure to third parties",
    "4. Protection des informations": "4. Protection of infirmations",
    "5 vidéos gratuites": "5 free videos",
    "5. Cookies": "5. Cookies",
    "6. Se désabonner": "6. Unsubscribe",
    "7. Consentement": "7. Consent",
    "8 caractères minimum": "8 characters minimum",
    "<": "<",
    "A ce titre, le Prestataire s’engage à prendre les mesures nécessaires afin d’assurer la protection, la sécurité et la confidentialité des données personnelles qui lui sont transmises par le Client.": "In this respect, the Provider undertakes to take the necessary measures to ensure the protection, security and confidentiality of personal data transmitted to it by the Client.",
    "A défaut de dispositions spécifiques dans le devis, les délais d’exécution des Services sont communiqués au Client à titre indicatif. Les délais de réalisation des Services ne sont en aucun cas garantis par le Prestataire ni ne peuvent engager sa responsabilité, ni entraîner une obligation de payer une quelconque indemnité ou pénalité de retard, ni justifier l’annulation de la Commande en cause.": "In the absence of specific provisions in the quotation, lead times for Services are given to the Client as an indication only. The Provider does not guarantee or accept any liability for any delay in the performance of the Services, nor shall the Provider be obliged to pay any compensation or penalty for any delay, nor shall the Provider be liable for any cancellation of the Order in question.",
    "A défaut de solution amiable trouvée entre les Parties, seront seuls compétents pour connaître des litiges de toutes natures ou de contestations relatives à l'interprétation ou à l'exécution du présent Contrat, les tribunaux du ressort de la Cour d’Appel de Paris, nonobstant pluralité de défendeurs, demande incidente ou référé ou appels en garantie, à moins que le Prestataire ne préfère saisir toute autre juridiction compétente.": "In the absence of an amicable solution between the Parties, the courts within the jurisdiction of the Paris Court of Appeal shall have exclusive jurisdiction to hear any dispute of any nature or contest relating to the interpretation or performance of this Contract, notwithstanding multiple defendants, incidental or summary proceedings or warranty claims, unless the Provider prefers to bring the matter before any other competent court.",
    "A l’expiration de ce délai de quinze (15) jours, les modifications des CGV seront définitivement considérées comme acceptées par le Client.": "On expiry of this fifteen (15) day period, the modifications to the GTCS will be definitively deemed to have been accepted by the Client.",
    "Abonnement": "Subscription",
    "Abonnement actuel": "Current subscription",
    "Abonnement clap.video Premium": "Clap.video Premium Subscription",
    "Abonnement en cours": "Current subscription",
    "Accepter": "Accept",
    "Accès à tous les templates": "Access to all templates",
    "Activer AutoClap": "Activate AutoClap",
    "Administrer un concours, une promotion, ou un enquête": "Administering a competition, promotion or survey",
    "Adresse email": "Email address",
    "Adresse mail": "Email address",
    "Adresse postale": "Zipcode",
    "Afficher votre adresse postale professionnelle dans les vidéos": "Display your professional zipcode in the videos",
    "Afin de demander la réinitialisation de votre mot de passe, veuillez renseigner votre adresse email. Un email vous sera adressé afin de vous indiquer la procédure à suivre.": "To request a password reset, please enter your email address. An email will be sent to you indicating the procedure to follow.",
    "Ajouter un code promo": "Add promo code",
    "Améliorer le service client et vos besoins de prise en charge par notre équipe support": "Improve customer service and your support needs",
    "Analyser l'usage du site (données anonymisées et agrégées)": "Analyze site usage (anonymized and aggregated data)",
    "Annonce immobilière": "Real estate advertising",
    "Annonce immobilière - Choix d'une annonce dans votre logiciel métier": "Real estate advertising - Choosing an ad in your business software",
    "Annuel": "Annual",
    "Annuler": "Cancel",
    "Appliquer": "Apply",
    "Après encaissement des factures par le Prestataire, celui-ci cède au Client l’ensemble des droits de propriété intellectuelle sur les œuvres créées spécifiquement et à la demande du Client, dans le cadre de l’exécution du Contrat, pour leur durée de protection et pour le monde entier.": "Once invoices have been received by the Provider, the Provider assigns to the Client all intellectual property rights to the works created specifically and at the Client's request, within the framework of the performance of the Contract, for the duration of their protection and for the entire world.",
    "Assurance": "Assurance",
    "Astuce": "Tip",
    "Aucun bien n'a été trouvé pour": "No properties found for",
    "Aucun changement ni aucune modification du Contrat, notamment sur les caractéristiques des Services, ne sera pris en considération s'il n'a pas été accepté par écrit par le Prestataire.": "No change or modification to the Contract, in particular to the characteristics of the Services, will be taken into consideration unless accepted in writing by the Provider.",
    "Aucun escompte n’est prévu en cas de paiement anticipé.": "There is no discount for early payment.",
    "Aucun résultat": "No results",
    "Aucun résultat.": "No results",
    "Aucune annonce n'a pu être trouvée.": "No ads could be found.",
    "AutoClap génère automatiquement une video quand vous publiez un nouveau bien sur votre outil métier.": "AutoClap automatically generates a video when you publish a new property on your business tool.",
    "Autonomie des clauses": "Autonomy of clauses",
    "Avant chaque intervention du Prestataire, le Client s’engage à réaliser toutes les procédures de sauvegarde nécessaires à la protection et à la sauvegarde de ses données, programmes et fichiers informatiques.": "Prior to any intervention by the Provider, the Customer undertakes to carry out all backup procedures necessary to protect and safeguard its data, programs and computer files.",
    "Avec l’abonnement Premium, vous profiterez de clap.video dans son intégralité et débloquerez les fonctionnalités suivantes": "With the Premium subscription, you'll enjoy clap.video in its entirety and unlock the following features",
    "Avis clients": "Clients' Feedback",
    "Bientôt disponible": "Coming soon",
    "Bienvenue sur Clap !": "Welcome to Clap!",
    "Bienvenue sur clap.video !": "Welcome to clap.video!",
    "Carré": "Square",
    "Carte bancaire": "Credit card",
    "Ces CGV régissent intégralement les relations entre le Prestataire et le Client. Aucune condition générale d'achat ne pourra prévaloir ni être opposée par le Client au Prestataire et aucune condition particulière communiquée par le Client au Prestataire ne peut prévaloir sur les CGV, sauf acceptation formelle et écrite du Prestataire.": "These GTCS govern the entire relationship between the Provider and the Client. No general terms and conditions of purchase shall prevail over or be opposed by the Client to the Provider and no special terms and conditions communicated by the Customer to the Service Provider shall prevail over the GTCS, unless formally accepted in writing by the Service Provider.",
    "Ces cookies ne sont pas indispensables au fonctionnement du site, mais ils facilitent votre navigation. Vous pouvez les bloquer ou les supprimer, mais vous risquez alors d'empêcher le bon fonctionnement de certains éléments du site.": "These cookies are not essential to the operation of the site, but they do facilitate your browsing. You can block or delete them, but this may prevent certain elements of the site from functioning properly.",
    "Ces pénalités de retard sont exigibles de plein droit et sans qu’un rappel par le Prestataire ne soit nécessaire.": "These late payment penalties are payable by operation of law and without the need for a reminder from the Provider.",
    "Cette abonnement sera automatiquement renouvelé le": "This subscription will be automatically renewed on",
    "Cette action est irréversible.": "This action is irreversible.",
    "Cette disposition ne peut être remplacée par un accord verbal.": "This provision cannot be replaced by a verbal agreement.",
    "Cette publication sera postée sur": "This post will be published on",
    "Chacune des Parties renonce, sauf accord écrit préalable, à faire directement ou indirectement des offres d’engagement à un collaborateur de l’autre Partie ayant travaillé dans le cadre des Services, objet du présent Contrat, ou à le prendre à son service, sous quelque statut que ce soit. Cette renonciation est valable pour une durée de deux (2) ans à compter de la fin du présent Contrat.": "Each of the Parties hereby waives the right, without prior written agreement, to make direct or indirect offers of employment to any employee of the other Party who has worked on the Services covered by this Agreement, or to employ such employee under any status whatsoever. This waiver is valid for a period of two (2) years from the end of the present Contract.",
    "Chacune des Parties s’engage à maintenir en vigueur, pendant toute la durée de réalisation du Contrat, auprès d’une compagnie d’assurance notoirement solvable, une police d’assurance garantissant les dommages pouvant survenir à ses biens et personnel, ainsi qu’une police couvrant sa responsabilité professionnelle, de manière à couvrir les conséquences pécuniaires des dommages corporels, matériels et immatériels dont elles auraient à répondre, causés par tout évènement et qui serait le fait de ses collaborateurs et/ou sociétés partenaires éventuels lors de l’exécution du Contrat.": "Each of the Parties undertakes to maintain in force, for the entire duration of the Contract, an insurance policy with a reputable and solvent insurance company, covering damage to its property and personnel, as well as a policy covering its professional liability, so as to cover the financial consequences of bodily injury, property damage and consequential loss for which it would be liable, caused by any event and attributable to its employees and/or partner companies, if any, during the performance of the Contract.",
    "Changer": "Change",
    "Chaque Partie s’engage": "Each Party undertakes",
    "Chargement...": "Loading...",
    "Choisir une autre image sur l’annonce": "Choose another image from the ad",
    "Choisir une musique": "Choose a music",
    "Choisir une page": "Choose a page",
    "Choisissez la langue de l'interface": "Choose the interface language",
    "Choisissez un template": "Choose a template",
    "Choisissez votre type de vidéo": "Choose your type of video",
    "Code postal": "Zipcode",
    "Code postale": "Zipcode",
    "Code promo": "Promo code",
    "Code promotionnel": "Promotional code",
    "Collaborer activement à la réussite du projet en apportant au Prestataire dans les délais préalablement définis toutes les informations et tous les documents nécessaires à la bonne appréhension des besoins et à la bonne exécution des Services.": "Actively collaborate in the success of the project by providing the Provider with all the information and documents required for a proper understanding of the needs and proper execution of the Services, within the agreed deadlines.",
    "Collaborer étroitement avec le Prestataire et fournir toute information, documentation, prestation, et tous moyens utiles pour la réalisation des Services et s’engage à mettre à disposition du Prestataire tous les éléments permettant de satisfaire à son obligation, incluant le personnel dédié à la bonne réalisation des Services.": "Cooperate closely with the Provider and to provide all information, documentation, services and resources required for the performance of the Services and undertakes to make available to the Provider all elements required to meet its obligation, including personnel dedicated to the proper performance of the Services.",
    "Commencer": "Begin",
    "Complément d'adresse postale": "Zipcode complement",
    "Concernant les logiciels, données ou documents utilisés par le Prestataire dans le cadre de la fourniture des Services, dont le Client a acquis les droits d’utilisation auprès de tiers ou dont il est propriétaire, le Client garantit le Prestataire de toutes les conséquences ou suites dommageables que le Prestataire aurait à subir au titre de l’utilisation desdits logiciels, données ou documents contre toute action de la part d’une personne revendiquant un droit de propriété intellectuelle ou se fondant sur une demande en concurrence déloyale et/ou parasitisme sur ces logiciels, données ou documents.": "With respect to software, data or documents used by the Provider in connection with the provision of the Services, for which the Client has acquired the rights of use from third parties or of which it is the owner, the Customer indemnifies the Provider against all consequences or harmful consequences that the Provider may suffer as a result of the use of said software, data or documents against any action by a person claiming an intellectual property right or relying on a claim for unfair competition and/or parasitism on said software, data or documents.",
    "Conclusion du Contrat": "Contract Conclusion",
    "Conditions Générales de Vente - clap.video": "General Terms and Conditions of Sale - clap video",
    "Conditions de paiement": "Payment Conditions",
    "Confidentialité": "Privacy",
    "Confirmation de suppression": "Confirmation of deletion",
    "Confirmation du nouveau mot de passe": "Confirmation of the new password",
    "Confirmer le mot de passe": "Confirm the password",
    "Confirmer le paiement": "Confirm the payment",
    "Confirmez votre mot de passe": "Confirm your password",
    "Conformément aux dispositions de la Loi Informatique et Libertés n° 78-17 du 6 janvier 1978 telle que modifiée, le Client dispose de la qualité de responsable de traitement dans le cadre de l’exécution du Contrat.": "In accordance with the provisions of the French Data Protection Act (Loi Informatique et Libertés) no. 78-17 of January 6, 1978, as amended, the Client manages the performance of the Contract.",
    "Conformément aux dispositions du Règlement Général sur la Protection des Données (RGPD) entré en vigueur le 25 mai 2018, le Client dispose notamment d’un droit d’accès, de rectification, d’opposition et de portabilité de ses données personnelles. La garantie de ces droits est affirmée au sein de la politique de confidentialité qui accompagne les présentes, et qui en conséquence, forment un ensemble contractuel.": "In accordance with the provisions of the General Data Protection Regulation (GDPR) that came into force on May 25, 2018, the Client has, in particular, a right of access, rectification, opposition and portability of its personal data. The guarantee of these rights is affirmed within the privacy policy that accompanies the present, and which consequently form a contractual whole.",
    "Connecter l'ensemble de vos plateformes à clap.video": "Connect all your platforms to clap.video",
    "Connecteurs": "Connectors",
    "Connectez-vous !": "Log in !",
    "Connexion": "Connection",
    "Connexion via Topi": "Connection via Topi",
    "Conserver la musique par défaut": "Keep the default music",
    "Copier le lien de la page publique": "Copy public page link",
    "Copier le lien de la vidéo": "Copy the link of the video",
    "Copier le lien public": "Copy the public link",
    "Couleur du thème": "Theme color",
    "Couleur incorrecte. Veuillez choisir une couleur au format hexadécimal sans transparence.": "Incorrect color. Please choose a color in hexadecimal format without transparency.",
    "Couleur précédente": "Previous color",
    "Couleur préférée": "Favorite color",
    "Couleurs prédéfinies": "Predefined colors",
    "Création d'un clap vidéo": "Creation of a clap video",
    "Création d'une vidéo": "Creation of a video",
    "Création manuelle": "Manual creation",
    "Créer le nouveau mot de passe": "Create the new password",
    "Créer manuellement": "Create manually",
    "Créer une vidéo": "Create a video",
    "Créez votre première vidéo en cliquant sur le bouton ci-dessous.": "Create your first video by clicking on the button below.",
    "Créez-en un": "Create one",
    "Créér ou administrer votre compte clap.video": "Create or manage your clap.video account",
    "Dans la file d'attente": "In the queue",
    "Dans le cadre de la fourniture des Services et en tant que de besoin, le Client concède également au Prestataire un droit d’utilisation de ses logiciels, données et documents, à titre personnel, gratuit, non exclusif et non transférable pour la durée de réalisation des Services.": "As part of the provision of the Services and where necessary, the Client also grants the Provider a personal, free, non-exclusive and non-transferable right to use its software, data and documents for the duration of the Services.",
    "Dans le cadre des présentes CGV et la réalisation des Services, le Prestataire s’engage à se donner tous les moyens nécessaires et à mettre tout en œuvre pour la réalisation de sa mission dans les règles de l’art. Cette obligation ne saurait constituer une obligation de résultat, le Prestataire ne fournissant les Services que dans le cadre d’une obligation de moyen.": "The Provider undertakes to use its best endeavours and resources to carry out the Services in accordance with these GTCS. This obligation does not constitute an obligation of result, as the Provider provides the Services on a best-efforts basis only.",
    "Dans le respect des articles L 8221-1 et suivants du Code du travail et conformément à l’article D 8222-5 du même code, le Prestataire s’engage à remettre au Client lors de la conclusion du Contrat, et tous les six mois jusqu’à la fin de son exécution, les documents suivants ": "In compliance with Articles L 8221-1 et seq. of the French Labor Code, and in accordance with Article D 8222-5 of the same code, the Provider undertakes to provide the Client with the following documents when the Contract is concluded, and every six months until the end of its performance",
    "Dans l’hypothèse où le Client aurait signé un procès-verbal de réception des Services et/ou la maquette de la ou les créations ou a validé la réception des Services et/ou la maquette, par tout moyen et notamment par l’utilisation des Services et/ou la maquette, le Prestataire sera réputé avoir réalisé ses obligations de manière conforme au Contrat. Le Client sera alors réputé avoir renoncé irrévocablement à toutes réclamation de plein droit à ce titre.": "In the event that the Client has signed an acceptance report for the Services and/or the mock-up of the creation(s) or has validated the acceptance of the Services and/or the mock-up, by any means and in particular by using the Services and/or the mock-up, the Provider shall be deemed to have fulfilled its obligations in accordance with the Contract. The Customer will then be deemed to have irrevocably waived all claims in this respect.",
    "Demande de mot de passe": "Password request",
    "Dernière modification": "Last modification",
    "Disposer des droits nécessaires sur les éléments fournis ci-dessus.": "Have the necessary rights to the elements provided above.",
    "Dispositions générales": "General provisions",
    "Documents antérieurs ou autres accords": "Previous documents or other agreements",
    "Donnez vie à vos biens immobiliers en vidéo et améliorez votre engagement sur les réseaux sociaux.": "Bring your real estate to life in video and improve your engagement on social networks.",
    "Données personnelles": "Personal data",
    "Droit applicable et juridictions compétentes": "Applicable right and jurisdiction",
    "Dupliquez cette vidéo": "Duplicate this video",
    "Durée": "Duration",
    "Déconnexion": "Log off",
    "Découvrez notre nouveau bien, une magnifique maison de campagne de 156m² et 3 chambres dont une suite parentale. Piscine, garage.": "Discover our new property, a magnificent 156m² country house with 3 bedrooms including a master suite. Swimming pool, garage.",
    "Définissez votre nouveau mot de passe.": "Define your new password",
    "Définitions": "Definitions",
    "Désolé, votre navigateur ne supporte pas ce type de vidéo.": "Sorry, your browser doesn't support this type of video.",
    "D’une façon générale, le Client et le Prestataire s’engagent à collaborer activement afin d’assurer la bonne exécution du Contrat. Chacune des Parties s’engage à communiquer toutes les difficultés dont elle aurait connaissance au fur et à mesure de l'avancement du projet, pour permettre à l’autre Partie de prendre les décisions nécessaires.": "In general, the Client and the Provider undertake to cooperate actively to ensure the proper performance of the Contract. Each Party undertakes to communicate any difficulties of which it becomes aware as the project progresses, to enable the other Party to take the necessary decisions.",
    "E-commerce": "E-commerce",
    "Email": "Email",
    "Email pro": "Professional email",
    "En aucun cas, le Prestataire ne sera tenu pour responsable des dommages indirects, accessoires ou particuliers tels que définis par la jurisprudence des tribunaux français, notamment, le coût de l’obtention de services de substitution, pertes de bénéfices, de données ou périodes d’immobilisation, que sa responsabilité soit contractuelle ou délictuelle et qu’elle ait ou non son fondement dans l’utilisation ou le fonctionnement des Services, même si le Prestataire a averti le Client de la possibilité de tels dommages.": "In no event will the Provider be liable for any indirect, incidental or special damages as defined by the case law of the French courts, including, without limitation, the cost of procurement of substitute services, loss of profits, loss of data or downtime, whether in contract or tort and whether or not arising out of the use or operation of the Services, even if the Provider has advised the Client of the possibility of such damages.",
    "En cas de contradiction entre des dispositions du devis et les présentes CGV, les dispositions concernées du devis prévaudront sur les CGV.": "In the event of any contradiction between the provisions of the quotation and these GTCS, the relevant provisions of the quotation shall prevail over the GTCS.",
    "En cas de différend entre les Parties, celles-ci tenteront de trouver une solution amiable à ce différend dans un délai de trente jours à compter de la notification du différend de la Partie en demande à l’autre Partie par lettre recommandée avec accusé de réception.": "In the event of a dispute between the Parties, they will attempt to find an amicable solution to the dispute within thirty days of notification of the dispute by the requesting Party to the other Party by registered letter with acknowledgement of receipt.",
    "En cas de manquement quelconque du Prestataire dans l’exécution de ses obligations (défaut d’exécution ou mauvaise exécution), le Client devra en faire part à celui-ci dans le délai de huit (8) jours ouvrés à compter de la constatation du manquement par lettre recommandée avec accusé de réception. A défaut, le manquement sera inopposable au Prestataire.": "In the event of any breach by the Provider in the performance of its obligations (failure to perform or improper performance), the Client must notify the Provider by registered letter with acknowledgement of receipt within eight (8) working days of becoming aware of the breach. Failure to do so will render the breach unenforceable against the Provider.",
    "En cas de non-paiement à l’échéance, toute somme due fera courir des pénalités de retard. Celles-ci courent à compter du jour suivant la date d’échéance figurant sur la facture et jusqu’au jour du paiement effectif et intégral de la somme. Le taux des pénalités de retard est fixé à trois faux le taux d’intérêt légal en vigueur.": "In the event of non-payment on the due date, any sum due will incur late payment penalties. These will run from the day following the due date shown on the invoice until the day of full and effective payment. The rate of late payment penalties is set at three times the current legal interest rate.",
    "En cas de rupture du Contrat avant son terme par le Client, celui-ci s’engage formellement à régulariser et rétribuer les montants relatifs au calendrier en cours, aux postes réalisés ou en cours de réalisation, ainsi qu’aux services complémentaires effectués. Les fichiers et données sources créés et utilisés par le Prestataire ne sauraient dès lors être revendiqués par le Client sans une contribution financière. L’acompte déjà versé restera acquis par le Prestataire, constituant un dédommagement pour le travail entrepris.": "In the event that the Client terminates the Contract before its term, the Client formally undertakes to settle and pay the amounts relating to the current schedule, the jobs completed or in progress, and the additional services performed. The files and source data created and used by the Provider cannot be claimed by the Client without a financial contribution. Any deposit already paid shall remain the property of the Provider, constituting compensation for the work undertaken.",
    "En cas d’atteinte avérée aux droits d’un tiers, le Prestataire pourra, à son choix ": "In the event of proven infringement of the rights of a third party, the Provider may, at its option",
    "En cliquant sur le bouton d'inscription, vous acceptez les": "By clicking on the registration button, you accept the",
    "En cours de traitement": "In process",
    "En file d'attente": "In queue",
    "En particulier, le Prestataire cède au Client les droits suivants ": "In particular, the Provider transfers the following rights to the Client",
    "En utilisant notre site, vous consentez à notre politique de confidentialité.": "By using our site, you consent to our privacy policy.",
    "Encodage en cours": "Encoding in progress",
    "Enfin, en cas de retard de paiement, le Prestataire se réserve le droit de suspendre ou de surseoir à l’exécution des Services prévus dans la Commande dont le paiement fait l’objet du retard.": "Finally, in the event of late payment, the Provider reserves the right to suspend or postpone performance of the Services provided for in the Order for which payment is overdue.",
    "Enfin, le Client fait son affaire et est seul responsable des lois et réglementations applicables aux Services notamment au regard de la protection des droits de propriété intellectuelle, mentions légales, protection des données personnelles, protection des mineurs (si applicable) et droit de la consommation (si applicable).": "Finally, the Client is solely responsible for complying with the laws and regulations applicable to the Services, in particular with regard to the protection of intellectual property rights, legal notices, protection of personal data, protection of minors (if applicable) and consumer law (if applicable).",
    "Enregistrer les modifications": "Save the modifications",
    "Enregistrer l’image sélectionnée": "Save the selected image",
    "Entrer une couleur manuellement": "Enter a color manually",
    "Envoi en cours": "Sending in progress",
    "Envoyer": "Send",
    "Erreur": "Error",
    "Erreur de connexion au serveur, veuillez réessayer s'il vous plait.": "Server connection error, please try again.",
    "Erreur lors de l'envoi sur Facebook. Merci de vérifier vos informations et réessayer.": "Error sending to Facebook. Please check your information and try again.",
    "Erreur lors de l'envoi sur Instagram. Merci de vérifier vos informations et réessayer.": "Error while uploading to Instagram. Please check your information and try again.",
    "Erreur lors de l'envoi sur Tiktok. Merci de vérifier vos informations et réessayer.": "Error while uploading to Tiktok. Please check your information and try again.",
    "Erreur lors de la copie du lien, veuillez réessayer": "Error copying link, please try again",
    "Erreur pendant l'enregistrement de vos informations": "Error while saving your information",
    "Erreur pendant la suppression du projet.": "Error while deleting project.",
    "Fait main": "Handmade",
    "Fonctionnalités": "Features",
    "Force Majeure": "Force Majeure",
    "Format": "Format",
    "Fournir tous les éléments documentaires, graphiques et textuels nécessaires à la bonne réalisation du Contrat (notamment dans les bons formats exploitables en fonction des supports visés), le Client s'engage à fournir toutes les informations légales à ajouter dans les documents et endosse la responsabilité de fournir le contenu des documents qu'il édite.": "Supply all the documentary, graphic and textual elements required for the proper execution of the Contract (in particular in the correct formats for the media concerned). The Client undertakes to supply all the legal information to be included in the documents, and assumes responsibility for supplying the content of the documents he edits.",
    "Frais Annexes": "Additional Charges",
    "Garantie d’éviction": "Warranties of eviction",
    "Garanties": "Warranties",
    "Garantir le Prestataire contre toute action qui pourrait lui être intentée du fait du caractère des données ou informations (textes, images, sons) qui auraient été fournies ou choisies par le Client.": "Guarantee the Provider against any action that could be brought against it due to the nature of the data or information (texts, images, sounds) provided or chosen by the Client.",
    "Gratuit": "Free",
    "Généralités": "General Information",
    "HT": "Tax Free",
    "Hormis les dommages corporels ou décès, et sauf en cas de négligence grossière ou de faute intentionnelle causant un dommage direct prouvé ou en cas de manquement à une obligation essentielle du Contrat la vidant de sa substance, le Client reconnaît que la responsabilité du Prestataire est limitée au montant versé pour les Services en cause.": "With the exception of personal injury or death, and except in the case of gross negligence or wilful misconduct causing proven direct damage, or in the case of breach of an essential obligation of the Contract which renders it null and void, the Client acknowledges that the Provider's liability is limited to the amount paid for the Services in question.",
    "Il semble y avoir une erreur de notre côté.": "We seem to have made a mistake.",
    "Images": "Images",
    "Immobilier": "Real estate",
    "Importer cette annonce": "Import this ad",
    "Importer une image": "Import an image",
    "Informer le Prestataire d’une éventuelle mise en concurrence avec d’autres prestataires.": "Inform the Provider of any competition with other providers.",
    "Infos générales": "General information",
    "Inscription": "Registration",
    "L'email a été envoyé": "The mail has been sent",
    "L'enregistrement des champs a échoué.": "Field registration failed.",
    "L'importation de cette annonce n'a pas fonctionné. Si le problème persiste, veuillez contacter le support.": "Importing this ad did not work. If the problem persists, please contact support.",
    "L'url de votre site Internet": "The url of your website",
    "La Partie touchée par un événement de force majeure en avisera l'autre Partie dans les cinq (5) jours ouvrables suivant la date à laquelle elle en aura eu connaissance. Les deux Parties conviendront alors des conditions dans lesquelles l'exécution du Contrat sera poursuivie.": "The Party affected by an event of force majeure shall notify the other Party within five (5) working days of becoming aware of the event. The two Parties will then agree on the conditions under which performance of the Contract will continue.",
    "La connexion automatique a échouée.": "Automatic connection failed.",
    "La liaison avec Facebook a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Facebook.": "The link with Facebook has been successfully established. You can now send your videos directly to Facebook.",
    "La liaison avec Instagram a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Instagram.": "The link with Instagram has successfully been set up. You can now send your videos directly to Instagram.",
    "La liaison avec Tiktok a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Tiktok.": "The link with Tiktok has successfully been set up. You can now send your videos directly to Tiktok.",
    "La loi du Contrat est la Loi Française. Les parties conviennent expressément que la Convention de Viennes sur la vente internationale de marchandises en date du 11 avril 1980 n’est pas applicable au Contrat.": "The Contract shall be governed by French law. The parties expressly agree that the Vienna Convention on the International Sale of Goods dated April 11, 1980 shall not apply to the Contract.",
    "La responsabilité du Prestataire est limitée aux seuls dommages directs et résultant d'un défaut des Services ou de la violation du Contrat, même si le défaut en question était prévisible au moment de la Commande.": "The Provider's liability is limited solely to direct damage resulting from a defect in the Services or breach of the Contract, even if the defect in question was foreseeable at the time the Order was placed.",
    "La réalisation des Services confiés au Prestataire dépendant directement du respect par le Client de ses propres obligations, les Parties reconnaissent expressément que les délais de livraison visés au devis sont donnés à titre purement indicatif et sans garantie.": "As the performance of the Services entrusted to the Provider is directly dependent on the Client's compliance with its own obligations, the Parties expressly acknowledge that the delivery times specified in the quotation are given purely as an indication and without guarantee.",
    "La vidéo est en cours d'envoi sur Facebook. Vous recevrez une notification Facebook dès qu'elle sera prête.": "The video is currently being uploaded to Facebook. You'll receive a Facebook notification as soon as it's ready.",
    "La vidéo est en cours d'envoi sur Instagram. Vous recevrez une notification Instagram dès qu'elle sera prête.": "The video is currently being uploaded to Instagram. You'll receive an Instagram notification as soon as it's ready.",
    "La vidéo est en cours d'envoi sur Tiktok. Vous recevrez une notification Tiktok dès qu'elle sera prête.": "The video is currently being uploaded to Tiktok. You'll receive a Tiktok notification as soon as it's ready.",
    "Langue du Contrat": "Language of the Contrat",
    "Langue mise à jour avec succès.": "Language successfully updated.",
    "Langues": "Languages",
    "Le Client déclare expressément avoir reçu du Prestataire toutes les informations et tous les conseils nécessaires à la réalisation des Services et renonce à rechercher la responsabilité du Prestataire de ce fait.": "The Client expressly declares that he has received from the Provider all the information and advice necessary to carry out the Services and waives the right to hold the Provider liable in this respect.",
    "Le Client s'engage à fournir des informations justes et sincères et s'engage aussi à prévenir le Prestataire de tout changement concernant les informations, données, documentations fournies.": "The Client undertakes to provide accurate and truthful information and also undertakes to notify the Service Provider of any change in the information, data or documentation provided.",
    "Le Client sera seul responsable des éventuels dysfonctionnements qui pourraient résulter d'informations erronées. Le Client doit maintenir une adresse e-mail et une adresse postale valides.": "The Client shall be solely responsible for any malfunctions that may result from incorrect information. The Client must maintain a valid e-mail address and postal address.",
    "Le Client sera également débiteur de plein droit d’une indemnité forfaitaire minimum de recouvrement de quarante (40) euros des sommes dues par le Client au Prestataire.": "The Client will also be liable to pay a minimum fixed recovery indemnity of forty (40) euros of the sums owed by the Client to the Provider.",
    "Le Client s’engage à fournir au Prestataire sous forme exploitable l’ensemble des documents nécessaires à la réalisation des Services confiés au Prestataire.": "The Client undertakes to provide the Provider with all documents required to perform the Services entrusted to the Provider in usable form.",
    "Le Client s’engage à obtenir de tous tiers, si besoin est, le droit de concéder au Prestataire les droits d’utilisation des logiciels, données et équipements appartenant à ces tiers pour les besoins de la fourniture des Services.": "The Client undertakes to obtain from all third parties, if necessary, the right to grant the Provider the rights to use software, data and equipment belonging to such third parties for the purposes of providing the Services.",
    "Le Contrat est rédigé en langue française. Une traduction en langue étrangère peut être fournie à titre d’information. En cas de contradiction, seule la version française fera foi entre les Parties.": "The Contract is written in French. A foreign language translation may be provided for information purposes. In the event of contradiction, only the French version will prevail between the Parties.",
    "Le Contrat se substitue à tout autre document antérieur, à tout autre accord écrit ou verbal en relation avec le même objet, à l’exception du devis, de la Commande et prévaut sur toute disposition contraire qui pourrait être contenue dans les documents émanant du Client.": "The Contract supersedes any previous document or any other written or verbal agreement relating to the same subject matter, with the exception of the quotation or the Order, and takes precedence over any provision to the contrary that may be contained in documents issued by the Client.",
    "Le Prestataire a la faculté de sous-traiter tout ou partie de la réalisation des Services à des sous-traitants. Dans ce cas, le Prestataire demeure responsable de la réalisation des Services vis-à-vis du Client.": "The Provider may subcontract all or part of the Services to subcontractors. In this case, the Provider remains liable to the Client for the performance of the Services.",
    "Le Prestataire agissant au nom et pour le compte du Client dans le traitement des données personnelles qui lui sont communiquées par le Client, il dispose de la qualité de sous-traitant.": "The Provider acts in the name of and on behalf of the Client in the processing of personal data communicated to it by the Client, and is therefore a subcontractor.",
    "Le Prestataire concède au Client, le cas échéant et dans la limite strictement nécessaire à l’exécution des Services, à titre personnel, non exclusif et non-transférable, le droit d'utiliser lesdits Droits de Propriété Intellectuelle pour la durée de réalisation des Services.": "The Provider grants the Client, where applicable and to the extent strictly necessary for the performance of the Services, on a personal, non-exclusive and non-transferable basis, the right to use said Intellectual Property Rights for the duration of the performance of the Services.",
    "Le Prestataire déclare être immatriculé au RCS, ainsi qu’auprès de l’URSSAF et que ses immatriculations couvrent expressément toutes ses activités pour l’exécution des Services définis dans le devis et/ou la Commande.": "The Provider declares that it is registered with the RCS and URSSAF and that its registrations expressly cover all its activities for the performance of the Services defined in the Quotation and/or Order.",
    "Le Prestataire est quant à lui autorisé à utiliser la dénomination sociale/la marque du Client dans le cadre de ses activités à des fins de promotion commerciale.": "The Provider is authorized to use the Client's company name/brand within the framework of its activities for commercial promotion purposes.",
    "Le Prestataire est une entreprise spécialisée notamment dans Génération automatique de vidéo basée sur des informations fournies statiques (textes, images).": "The provider is a company specialized to the automatic video generation based on static information (text, images).",
    "Le Prestataire garantit le Client contre toute action, réclamation, revendication ou opposition de la part de toute personne invoquant un droit de propriété intellectuelle auxquels la fourniture des Services aurait porté atteinte, sous réserve que le Client informe le Prestataire, dès qu’il en a connaissance, de toute demande, réclamation ou instance présentée ou engagée pour un tel motif, par voie judiciaire ou extrajudiciaire. Le Client s’engage à apporter au Prestataire, tous les documents et renseignements en sa possession ainsi que toute l’assistance requise qui pourraient être nécessaires à sa défense.": "The Service Provider indemnifies the Customer against any action, claim, demand or opposition by any person claiming an intellectual property right infringed by the provision of the Services, provided that the Customer informs the Provider, as soon as it becomes aware of any such claim, demand or proceeding made or brought on such grounds, by judicial or extrajudicial means. The Client undertakes to provide the Provider with all documents and information in its possession, as well as all assistance required, which may be necessary for its defense.",
    "Le Prestataire garantit que les Services sont fournis de manière substantiellement conforme à la Commande.": "The Provider guarantees that the Services are provided in substantial conformity with the Order.",
    "Le Prestataire garantit que les créations sont juridiquement disponibles et ne sont pas grevées de droit des tiers pour les utilisations prévues au titre du Contrat.": "The Provider guarantees that the creations are legally available and are not encumbered by third-party rights for the uses provided for under the Contract.",
    "Le Prestataire n'aura aucune obligation d’indemnisation ou autre obligation au titre d’une action en contrefaçon ayant pour origine (a) une utilisation des Services autrement que conformément au Contrat, (b) une combinaison des Services avec d’autres services ou matériels non fournis par le Prestataire.": "The Provider shall have no obligation to indemnify or otherwise satisfy any claim of infringement arising out of (a) any use of the Services other than in accordance with the Agreement, (b) any combination of the Services with other services or materials not provided by the Provider.",
    "Le Prestataire ne peut être tenu d’aucune garantie notamment lorsque le Client a modifié ou fait modifier les Services ou a utilisé d’autres services que les Services fournis par le Prestataire, sans son accord préalable et écrit ou lorsque le Client ou des tiers sont intervenus sur les éléments des Services sans l’accord préalable du Prestataire.": "The Provider may not be held liable for any warranty, in particular where the Client has modified the Services or had them modified, or has used services other than those provided by the Provider, without the Provider's prior written consent, or where the Client or third parties have tampered with elements of the Services without the Provider's prior consent.",
    "Le Prestataire peut décider de céder ou transférer les droits ou obligations que lui confère le présent Contrat sous réserve que le Client bénéficie des Services dans les mêmes conditions.": "The Provider may decide to assign or transfer the rights or obligations conferred on it by this Agreement, provided that the Client benefits from the Services under the same conditions.",
    "Le Prestataire peut mettre fin aux Services fournis au Client en cas de manquement par le Client à ses obligations au titre du Contrat, non réparé dans un délai de quinze (15) jours à compter de la notification par le Prestataire de ce manquement par lettre recommandée avec accusé de réception, indépendamment de la possibilité pour le Prestataire de demander le paiement de dommages et intérêts.": "The Provider may terminate the Services provided to the Cleint in the event of a breach by the Client of its obligations under the Agreement which has not been remedied within fifteen (15) days of the Provider's notification of such breach by registered letter with acknowledgement of receipt, irrespective of the Provider's right to claim damages.",
    "Le Prestataire peut également mettre fin au Contrat en cas de non paiement de la ou des facture(s) non acquittée(s) par le Client.": "The Provider may also terminate the Contract in the event of non-payment of the invoice(s) outstanding by the Client.",
    "Le Prestataire se réserve le droit de modifier ces CGV, les Services et les tarifs à tout moment et sans préavis. Ces modifications n'auront aucune incidence sur les Commandes en cours.": "The Provider reserves the right to modify these GTCS, the Services and the prices at any time and without prior notice. These modifications will have no effect on Orders in progress.",
    "Le Prestataire se réserve le droit de modifier les présentes CGV et d’en notifier le Client. Si une modification substantielle des termes des CGV n’est pas acceptable par le Client, celui-ci dispose d’un délai de quinze (15) jours à compter de la notification par le Prestataire des modifications pour en informer le Prestataire. En cas de désaccord des Parties sur ces modifications, les Parties pourront résilier le Contrat.": "The Provider reserves the right to modify these GTCS and to notify the Client accordingly. If a substantial change to the terms of the GTCS is not acceptable to the Client, the Client has fifteen (15) days from the date of notification by the Provider to inform the Provider. In the event of disagreement between the Parties on these modifications, the Parties may terminate the Contract.",
    "Le Prestataire se réserve le droit de ne pas accepter une Commande de la part du Client lorsque le Prestataire a déjà rencontré des problèmes de paiement (non-paiement ou retard de paiement) avec le Client pour une ou plusieurs Commande(s) précédente(s).": "The Provider reserves the right not to accept an Order from the Client where the Provider has already encountered payment problems (non-payment or late payment) with the Client in respect of one or more previous Orders.",
    "Le Prestataire se réserve également le droit de mentionner les réalisations effectuées pour le Client sur ses documents de communication externe, de publicité (site internet, portfolio, etc.) et lors de démarchages de prospection commerciale.": "The Provider also reserves the right to mention the work carried out for the Client on its external communication and advertising documents (website, portfolio, etc.) and when canvassing for business.",
    "Le Prestataire s’engage à informer de manière régulière le Client de l’avancée de la réalisation du projet et ce, notamment, au travers de validations soumises au Client dans le rétro-planning tel que prévu dans le devis.": "The Provider undertakes to inform the Client on a regular basis of the progress of the project, in particular through validations submitted to the Client in the back-planning as provided for in the quotation.",
    "Le changement de template a échoué.": "The template change has failed.",
    "Le code promo est expiré.": "The promo code has expired.",
    "Le code promo n'existe pas.": "The promo code does not exist.",
    "Le droit de reproduction comprend notamment, et de manière non exhaustive": "Reproduction rights include, but are not limited to",
    "Le droit de représentation comprend notamment, et de manière non exhaustive": "The right of representation includes, but is not limited to",
    "Le fait que le Prestataire ne se prévale pas à un moment donné d'une quelconque condition des présentes CGV ne peut être interprété comme valant renonciation à se prévaloir ultérieurement de quelconque desdites conditions.": "The fact that the Provider does not take advantage at a given time of any condition of the present GTCS shall not be interpreted as a waiver of the right to take advantage at a later date of any of the said conditions.",
    "Le lien a été copié": "The link has been copied",
    "Le message d'erreur": "The error message",
    "Le paiement du solde des Services doit être effectué dans un délai de trente (30) jours à compter de la date de la facture émise par le Prestataire et pourra être effectué sous forme de chèque, de virement, ou de prélèvement automatique à l’ordre du Prestataire.": "The payment of the balance of the Services must be made within thirty (30) days of the date of the invoice issued by the Provider and may be made by cheque, bank transfer or direct debit to the order of the Provider.",
    "Le projet": "The project",
    "Le template n'est pas disponible dans la langue sélectionnée.": "The template is not available in the selected language.",
    "Le terme \"Client\" désigne toute personne morale ou physique, ayant requis les compétences du Prestataire.": "The term \"Client\" refers to any moral or physical person whi has requested the services of the provider.",
    "Le terme \"Tiers\" désigne toute personne physique ou morale non partie à ces Conditions Générales de Vente (CGV).": "The term \"Third Party\" refers to any moral or physical person who is not a party of these General Terms and Conditions of Sale (GTCS).",
    "Le terme le \"Prestataire\" désigne la société SYNAPSE, Société par action simplifiée au capital de 200 000 euros, immatriculée au Registre du Commerce et des Sociétés de Grenoble sous le numéro RCS 452156102, et dont le siège social est situé 19 chemin des prés, 38240 Meylan.": "The term \"Provider\" refers to SYNAPSE, a simplified joint stock company with share capital of 200 000 euros, registered with the commercial register and the Grenoble trade under the number RCS 452156102, and whose registered office is located at 19 chemin dès prés, 3840 Meylan.",
    "Le texte descriptif de votre clap (facultatif)": "Clap description (optional)",
    "Le texte descriptif de votre vidéo (facultatif)": "Descriptive text for your video (optional)",
    "Le versement de l’acompte conditionnera la mise en œuvre des Services. Le paiement de l’acompte pourra être effectué sous forme de chèque, de virement, ou de prélèvement automatique à l’ordre du Prestataire.": "The payment of the deposit is a condition for the implementation of the Services. The payment of the deposit may be made by cheque, bank transfer or direct debit to the order of the Provider.",
    "Les CGV s'appliquent aux seuls professionnels à l’exclusion des consommateurs. A ce titre, le Client reconnaît avoir la qualité de professionnel, conformément aux dispositions du Code de la consommation applicables.": "The GTCS apply to professionals only, to the exclusion of consumers. As such, the Client acknowledges that he/she is a professional, in accordance with the applicable provisions of the French Consumer Code.",
    "Les CGV sont systématiquement adressées ou remises à chaque Client avant la passation de toute commande (la Commande). En conséquence, le fait de passer Commande implique l'adhésion entière et sans réserve du Client à ces CGV, à l'exclusion de tous autres documents en sa possession tels que prospectus, catalogues ou plaquettes publicitaires émis par le Prestataire, lesquels n'auront qu'une valeur indicative et non contractuelle.": "The GTCS are systematically sent or given to every Client before any order is placed (the Order). Therefore, placing an Order implies the Client's full and unreserved acceptance of these GTCS, excluding any other documents in the Client's possession, such as brochures, catalogs or advertising leaflets issued by the Provider, which shall only be indicative and non-contractual.",
    "Les Parties": "The parties",
    "Les Parties ne peuvent être considérées comme responsables ou ayant failli à leurs obligations contractuelles, lorsque le défaut d'exécution des obligations respectives a pour origine la force majeure telle que définie par la jurisprudence des tribunaux français. Le Contrat entre les parties est suspendu jusqu'à l'extinction des causes ayant engendrées la force majeure. La force majeure prend en compte des faits ou circonstances irrésistibles, extérieurs aux parties, imprévisibles et indépendants de la volonté des parties, malgré tous les efforts raisonnablement possibles pour les empêcher.": "The Parties shall not be held liable or in breach of their contractual obligations if the failure to perform their respective obligations is due to force majeure as defined by the case law of the French courts. The contract between the parties is suspended until the causes of force majeure have ceased to exist. Force majeure takes into account irresistible facts or circumstances, external to the parties, unforeseeable and beyond the parties' control, despite all reasonable efforts to prevent them.",
    "Les Services peuvent être fournis soit à distance, soit dans les locaux du Client, au choix du Prestataire et du Client.": "The Services can be provided either remotely or at the Client's permises, at the discretion of the Provider and the Client.",
    "Les conditions tarifaires du Prestataire relatives à la fourniture des Services sont prévues dans le devis du Prestataire.": "The Provider's pricing terms for the provision of Services are set out in the Provider's quotation.",
    "Les logiciels, données, documentations, procédés, méthodologies, technologies et documents appartenant au Prestataire (ci-après Droits de Propriété Intellectuelle) utilisés dans le cadre de la mise en œuvre des Services restent la propriété exclusive du Prestataire.": "The software, data, documentation, processes, methodologies, technologies and documents belonging to the Provider (hereinafter referred to as Intellectual Property Rights) used in connection with the implementation of the Services remain the exclusive property of the Provider.",
    "Les mots de passe ne correspondent pas": "The passwords do not match",
    "Les mots de passe ne correspondent pas.": "The passwords do not match.",
    "Les paramètres ont été enregistrés.": "The settings have been saved.",
    "Les prix des Services n’incluent pas les éventuels frais de déplacement ou d’hébergement qui pourraient être facturés en sus par le Prestataire au Client selon les modalités indiquées dans le devis.": "The prices of the Services do not include any travel or accommodation expenses which may be invoiced in addition by the Provider to the Client in accordance with the terms and conditions indicated in the quotation.",
    "Les prix des Services sont exprimés et payables en Euros et sont exprimés hors taxe sur la valeur ajoutée et hors toute autre taxe, le Client étant responsable du paiement desdites taxes.": "The prices of the Services are expressed and payable in Euros and are exclusive of value added tax and any other tax, the Client being responsible for the payment of said taxes.",
    "Les prix sont donnés à titre indicatif et sont donc susceptibles de variation. Le prix facturé est celui prévu dans la Commande validée par le Prestataire.": "Prices are given for information only and are therefore subject to change. The price invoiced is that specified in the Order validated by the Provider.",
    "Les présentes CGV ont pour objet de définir les droits et obligations des Parties lors de la réalisation de prestations de services réalisées par le Prestataire pour le Client dans le cadre de ses activités.": "The purpose of these GTCS is to define the rights and obligations of the parties while the provision of services by the provider to the client within the scope of its activities.",
    "Les présentes Conditions Générales de Vente (ci-après désignées les \"CGV\") s'appliquent à tout contrat conclu entre le Prestataire et le Client, dans le cadre de la fourniture de prestations de services (les Services) telles que définies dans le devis validé par le Client.": "These General Terms and Conditions of Sale (refered as GTCS) apply to any contrat entered between the Provider and the Client, for the provision of services (the Services) as defined in the quotation approved by the Client.",
    "Les présentes Conditions Générales de Vente sont à jour au 1 janvier 2021.": "These General Terms and Conditions of Sale are updated as of January 1st 2021.",
    "Les tarifs sont HT.": "Prices are Tax Free.",
    "Les œuvres créées par le Prestataire pour le Client, dans le cadre de l’exécution du Contrat, demeurent la propriété entière et exclusive du Prestataire tant que les factures émises par le Prestataire ne sont pas entièrement acquittées par le Client.": "The works created by the Provider for the Client in the performance of the Contract remain the full and exclusive property of the Provider until the invoices issued by the Provider are paid in full by the Client.",
    "Lier un compte": "Link an account",
    "Logo de l'agence": "Agency logo",
    "Légende pour": "Caption for",
    "L’acceptation du devis et de la Commande du Client qui en découle, doit s’accompagner du paiement d’un acompte tel qu’indiqué dans le devis.": "Acceptance of the quotation and the resulting Client Order must be accompanied by payment of a deposit as indicated in the quotation.",
    "L’intelligence artificielle est en bêta chez clap.video": "Artificial intelligence is in beta at clap.video",
    "Ma vidéo": "My video",
    "Maison de campagne 156m²": "156m² country house",
    "Marques et dénominations sociales": "Brands and company names",
    "Mensuel": "Montly",
    "Merci de réessayer en cliquant": "Please try again clicking",
    "Merci pour votre abonnement ! Bienvenue sur clap.video premium.": "Thank you for subscribing! Welcome to clap.video premium.",
    "Mes informations": "My informations",
    "Mes vidéos": "My videos",
    "Mise à jour d'une vidéo": "Updating a video",
    "Modalités d’exécution des Services et livraison des livrables": "Terms of performance of Services and delivery of deliverables",
    "Modifications des CGV – cession du Contrat": "Changes to the GTCS- Assignment of the Contract",
    "Modifier": "Modify",
    "Mon compte": "My account",
    "Mot de passe": "Password",
    "Mot de passe actuel": "Current password",
    "Mot de passe oublié": "Forgotten password",
    "Moyen de paiement": "Payment method",
    "Musique": "Music",
    "Médias": "Media",
    "NOUVEAU": "NEW",
    "Nom": "Last name",
    "Nom de l'entreprise": "Company name",
    "Non sollicitation": "Non-solicitation",
    "Notification": "Notification",
    "Notifications": "Notifications",
    "Nous avons trouvé des photos provenant votre annonce web, cliquez sur celle que vous souhaitez ajouter à votre vidéo": "We found photos from your web ad, click on the one you want to add to your video",
    "Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles. Nous utilisons un cryptage pour protéger les informations sensibles transmises en ligne. Nous protégeons également vos informations hors ligne. Seuls les employés qui ont besoin d’effectuer un travail spécifique (par exemple, la facturation ou le service à la clientèle) ont accès aux informations personnelles identifiables. Les ordinateurs et serveurs utilisés pour stocker des informations personnelles identifiables sont conservés dans un environnement sécurisé.": "We implement a variety of security measures to keep your personal information safe. We use encryption to protect sensitive information transmitted online. We also protect your information offline. Only employees who need to perform a specific job (for example, billing or customer service) have access to personally identifiable information. Computers and servers used to store personally identifiable information are kept in a secure environment.",
    "Nous ne vendons, n’échangeons et ne transférons pas vos informations personnelles identifiables à des tiers. Cela ne comprend pas les tierce parties de confiance qui nous aident à exploiter notre site Web ou à mener nos affaires, tant que ces parties conviennent de garder ces informations confidentielles.": "We do not sell, trade or transfer your personally identifiable information to third parties. This does not include trusted third parties who assist us in operating our Website or conducting our business, so long as those parties agree to keep this information confidential.",
    "Nous pensons qu’il est nécessaire de partager des informations afin d’enquêter, de prévenir ou de prendre des mesures concernant des activités illégales, fraudes présumées, situations impliquant des menaces potentielles à la sécurité physique de toute personne, violations de nos conditions d’utilisation, ou quand la loi nous y contraint.": "We believe it is necessary to share information in order to investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our terms of use, or when we are required to do so by law.",
    "Nous recueillons des informations lorsque vous visitez notre site, vous inscrivez sur notre site et lorsque vous vous connectez à votre compte. Les informations recueillies incluent votre prénom, votre nom, votre entreprise, votre adresse e-mail et votre numéro de téléphone (optionnel). En outre, nous recevons et enregistrons automatiquement des informations à partir de votre ordinateur et navigateur, y compris votre adresse IP, vos logiciels, votre matériel et la page que vous demandez.": "We collect information when you visit our site, register on our site and log in to your account. The information collected includes your first name, last name, company, e-mail address and telephone number (optional). In addition, we automatically receive and record information from your computer and browser, including your IP address, software, hardware and the page you request.",
    "Nous récupérons vos biens": "We retrieve your properties",
    "Nous utilisons des cookies. Nos cookies améliorent l’accès à notre site et identifient les visiteurs réguliers. Les cookies sont également conservés par nos partenaires Google Analytics et Hubspot afin d'améliorer l’expérience utilisateur grâce au suivi et au ciblage de ses intérêts. Cependant, cette utilisation des cookies n’est en aucune façon liée à des informations personnelles identifiables sur notre site.": "We use cookies. Our cookies improve access to our site and identify repeat visitors. Cookies are also stored by our partners Google Analytics and Hubspot to improve the user experience by tracking and targeting user interests. However, this use of cookies is in no way linked to any personally identifiable information on our site.",
    "Nous utilisons l’adresse e-mail que vous fournissez pour vous envoyer des informations et mises à jour relatives à votre commande, des nouvelles de l’entreprise de façon occasionnelle, des informations sur des produits liés, etc. Si à n’importe quel moment vous souhaitez vous désinscrire et ne plus recevoir d’e-mails, des instructions de désabonnement détaillées sont incluses en bas de chaque e-mail.": "We use the e-mail address you provide to send you information and updates relating to your order, occasional company news, information on related products, etc. If at any time you wish to unsubscribe and no longer receive e-mails, detailed unsubscribe instructions are included at the bottom of each e-mail.",
    "Nouveau": "New",
    "Nouveau mot de passe": "New password",
    "Nouvelle vidéo": "New video",
    "N° de téléphone pro": "Professional telephone number",
    "Obligations des Parties": "Parties Obligations",
    "Obligations du Client": "Client's Obligations",
    "Obligations du Prestataire": "Provider's Obligations",
    "Obtenir un lien de téléchargement": "Get a link of the download",
    "On s'occupe de tout...": "We take care of everything...",
    "Oui, supprimer": "Yes, delete",
    "Oups !": "Oops!",
    "Paiement": "Payment",
    "Par ailleurs, le Prestataire ne peut être tenu pour responsable de l’inexécution du Contrat en cas de force majeure telle que définie à l’article 15, et en cas de dommages du fait d’un tiers ou imputables à une mauvaise utilisation ou une utilisation non-conforme par le Client des Services, en violation des prescriptions du Prestataire ou des règles de l’art.": "Furthermore, the Provider cannot be held liable for non-performance of the Contract in the event of force majeure as defined in Article 15, or in the event of damage caused by a third party or attributable to the Client's misuse or non-conforming use of the Services, in violation of the Provider's instructions or good practice.",
    "Par défaut": "Default",
    "Paramètres": "Settings",
    "Partage automatique": "Automatic sharing",
    "Partager sur les réseaux sociaux": "Share to social media",
    "Passer Premium": "Change to Premium",
    "Passer Premium !": "Change to Premium!",
    "Paysage": "Landscape",
    "Personnalisation des couleurs": "Color customization",
    "Personnaliser": "Customize",
    "Personnaliser votre expérience": "Customize your experience",
    "Personnelles": "Personal",
    "Politique de confidentialité": "Privacy policy",
    "Portrait": "Portrait",
    "Pour l'intégrer à votre logiciel": "To integrate it into your software",
    "Pour partager cette page": "To share this page",
    "Pour permettre au Prestataire de réaliser sa mission, le Client s’engage à": "To enable the Provider to carry out its mission, the Client undertakes to",
    "Pour récupérer vos photos et pré-remplir votre vidéo": "To retrieve your photos and pre-populate your video",
    "Premium": "Premium",
    "Prix": "Price",
    "Prix de l'annonce": "Price of the ad",
    "Prochain prélèvement le": "Next direct debit on",
    "Professionnelles": "Professionals",
    "Profil": "Profile",
    "Projet supprimé.": "Project deleted.",
    "Propriété intellectuelle": "Intellectual property",
    "Prénom": "Name",
    "Publication en cours sur": "Publication in progress on",
    "Publier": "Post",
    "Publier votre clap sur vos réseaux sociaux": "Post your clap on your social networks",
    "Publier votre vidéo sur vos réseaux sociaux": "Post your video on the social media",
    "Pénalités de retard": "Late payment penalties",
    "Quand un clap est généré": "When a clap is generated",
    "Recevoir un mail quand une vidéo est générée": "Receive an email when a video is generated",
    "Recherche sur le web": "Web search",
    "Rechercher mon annonce": "Search my ad",
    "Rechercher un bien par titre, référence ou prix.": "Search for a product by title, reference or price.",
    "Rechercher un clap": "Search a clap",
    "Rechercher un clap...": "Look for a clap...",
    "Rechercher une annonce": "Search an ad",
    "Rechercher votre annonce sur le web en un coup de baguette": "Search for your ad on the web with a single click",
    "Refus": "Refusal",
    "Remettre au Prestataire le devis (daté, signé et tamponné).": "Provide the Provider with the quotation (dated, signed and stamped).",
    "Rendre publique et visible votre adresse postale professionnelle dans chaque vidéo que vous créez.": "Make your professional zipcode public and visible in every video you create.",
    "Responsabilités": "Responsibilities",
    "Retapez votre mot de passe": "Confirm your password",
    "Retapez votre nouveau mot de passe": "Reconfirm your new password",
    "Retour": "Back",
    "Retour aux connecteurs": "Return to connectors",
    "Retour à l'accueil": "Back to home page",
    "Retour à mes vidéos": "Back to my videos",
    "Retourner à la page de connexion": "Get back to the login page",
    "Réactiver mon abonnement et annuler ma résiliation": "Reactivate my subscription and cancel my cancellation",
    "Référence": "Reference",
    "Régler dans les délais prédéfinis dans le devis et dans les présentes CGV, les sommes dues au Prestataire.": "Pay the sums due to the Provider within the time limits specified in the quotation and in these GTCS.",
    "Réinitialisation du mot de passe": "Password reset",
    "Réseaux sociaux": "Social media",
    "Résiliation": "Cancellation",
    "Résilier": "Unsubscribe",
    "S'inscrire": "Subscribe",
    "Sauf convention contraire dans le devis, le Contrat est réputé formé et prend effet entre les Parties à la date de réception par le Prestataire de la Commande du Client soit par email, soit par courrier postal à l’adresse du Prestataire.": "Unless otherwise agreed in the quotation, the Contract is deemed to be formed and takes effect between the Parties on the date of receipt by the Provider of the Client's Order either by email or by mail to the Provider's address.",
    "Sauf disposition légale contraire, toute autre garantie, expresse ou implicite est exclue.": "Unless otherwise provided by law, all other warranties, express or implied, are excluded.",
    "Se conformer strictement aux préconisations techniques et aux suggestions artistiques faites par le Prestataire.": "Strictly comply with the technical recommendations and artistic suggestions made by the Provider.",
    "Se souvenir de moi": "Remember me",
    "Si le problème persiste, n'hésitez pas à nous contacter par mail à l'adresse suivante": "If the problem persists, please contact us by e-mail at the following address",
    "Si l’une quelconque disposition de ces CGV ou son application à toute personne ou circonstance est jugée nulle, cette nullité ne concernera pas les autres dispositions ou applications de ces CGV, qui resteront en vigueur, séparément de la disposition jugée nulle. A cette fin, les dispositions de ces CGV sont déclarées autonomes.": "If any provision of these GTCS or its application to any person or circumstance is held invalid, such invalidity shall not affect the remaining provisions or applications of these GTCS, which shall remain in full force and effect, separately from the provision held invalid. To this end, the provisions of these GTCS are declared autonomous.",
    "Si vous pensez que c'est une erreur, n'hésitez pas à nous contacter par mail à l'adresse suivante": "If you think this is a mistake, don't hesitate to contact us by e-mail at the following address",
    "Site internet": "Website",
    "Sont à facturer en sus": "The following will be invoiced in addition",
    "Souhaitez vous vraiment supprimer la vidéo": "Do you really want to delete the video",
    "Souhaitez vous vraiment supprimer le clap": "Do you really want to delete the clap",
    "Sous-traitance": "Subcontracting",
    "Suite à votre demande, votre abonnement Premium ne sera pas renouvelé et prendra fin le": "Following your request, your Premium subscription will not be renewed and will expire on",
    "Suppression du logo clap.video": "Removal of the clap.video logo",
    "Suppression du logo clap.video en fin de vidéo": "Removal of the clap.video logo at the end of the video",
    "Supprimer le clap": "Delete clap",
    "Sélectionner": "Select",
    "Sélectionner l'annonce à importer": "Select an ad to import",
    "Sélectionner la couleur par défaut que vous souhaitez utiliser pour vos vidéos.": "Select the default color you wish to use for your videos.",
    "Sélectionnez une image provenant de l’annonce web": "Select an image from the web ad",
    "Sélectionnez votre page Facebook": "Select your Facebook page",
    "Sélectionné": "Selected",
    "Sélectionnée": "Selected",
    "S’assurer de la mise à disposition de tous les moyens nécessaires pour permettre au Prestataire de réaliser les Services dans ses locaux et/ou à distance.": "Ensure that all necessary means are made available to enable the Provider to perform the Services on its premises and/or remotely.",
    "Tapez un nouveau mot de passe": "Enter a new password",
    "Tapez votre mot de passe actuel": "Fill in your current password",
    "Template": "Template",
    "Template sélectionné": "Selected template",
    "Terminer": "Finish",
    "Terminer et générer la vidéo": "Finish and generate the video",
    "Terminé": "Done",
    "Titre de la vidéo": "Title of the video",
    "Titre du clap": "Clap title",
    "Toute les informations que nous recueillons auprès de vous peuvent être utilisées pour": "Any information we collect from you may be used for",
    "Toute modification ultérieure ou demande complémentaire demandée par le Client fera l’objet d’une facturation supplémentaire.": "Any subsequent modification or additional request will be invoiced separately.",
    "Toute notification devra être faite par écrit et être soit remise en mains propres, soit adressée par lettre recommandée avec accusé de réception, soit faite par acte extra judiciaire à l’adresse indiquée dans la commande.": "All notifications must be made in writing and either delivered personally, sent by registered letter with acknowledgement of receipt, or made by extra-judicial document to the address indicated in the order.",
    "Toute réserve concernant les CGV, avancée par le Client sera, donc, à défaut d'acceptation expresse par le Prestataire, inopposable à ce dernier, quel que soit le moment où elle aura pu être portée à sa connaissance.": "In the absence of express acceptance by the Provider, any reservation made by the Client concerning the GTCS shall not be binding on the Provider, irrespective of the time at which it may have been brought to the Service Provider's attention.",
    "Toute utilisation par le Client des dénominations sociales, marques et signes distincts appartenant au Prestataire est strictement prohibée sauf en cas d’accord exprès et préalable du Prestataire. En cas d’accord exprès et préalable du Prestataire, ce dernier concède alors au Client un droit strictement personnel, non exclusif, et non transférable d'utiliser ses dénominations sociales, marques et signes distincts, dans le monde entier et pour toute la durée de validité du Contrat.": "Any use by the Client of the company names, trademarks and distinctive signs belonging to the Provider is strictly prohibited unless the Provider has given its express prior consent. In the event of the Provider's express prior consent, the Provider grants the Client a strictly personal, non-exclusive and non-transferable right to use the Provider's company names, trademarks and distinctive signs, worldwide and for the entire duration of the Contract.",
    "Toutes dispositions dérogeant aux présentes CGV devront résulter d’un accord exprès des Parties, reflété dans les Commandes confirmées par le Prestataire ou tout autre document faisant foi de l’accord des deux Parties.": "Any provisions derogating from these GTCS must be the result of an express agreement between the Parties, reflected in the Orders confirmed by the Provider or any other document evidencing the agreement of both Parties.",
    "Travail dissimulé": "Concealed work",
    "Type": "Type",
    "Télécharger": "Download",
    "Télécharger cette vidéo": "Save this video",
    "Un extrait Kbis attestant de l’immatriculation au registre du commerce et des sociétés,": "A Certificate of Incorporation extract attesting to registration in the Trade and Companies Register,",
    "Un problème a été rencontré lors de la création de la vidéo. Veuillez réessayer.": "A problem was encountered during video creation. Please try again.",
    "Un problème a été rencontré lors de la création du clap. Veuillez réessayer.": "A problem was encountered while creating the clap. Please try again.",
    "Un retard sur les délais indiqués ne pourra donc donner lieu au paiement de dommages et intérêts, ni autoriser le Client à résilier le Contrat où à refuser la livraison des Services.": "Any delay in meeting the specified deadlines shall not give rise to the payment of damages, nor authorize the Client to unsubs the Contract or refuse delivery of the Services.",
    "Une attestation sur l’honneur établie par le Prestataire, certifiant que le travail est réalisé par des salariés employés régulièrement eu égard aux articles D.8222-5, D.8222-7 et D.8222-8 du Code du travail.": "A sworn statement from the Provider certifying that the work has been carried out by employees regularly employed in accordance with articles D.8222-5, D.8222-7 and D.8222-8 of the French Labor Code.",
    "Une copie de l’avis d’imposition afférent à la taxe professionnelle,": "A copy of the business tax assessment,",
    "Une erreur est survenue": "An error has occurred",
    "Une erreur est survenue lors de la récupération des biens.": "An error occurred during the recovery of the properties.",
    "Une erreur est survenue lors de la sélection de cette musique. Veuillez en choisir une autre.": "An error has occurred while selecting this music. Please select another.",
    "Une erreur est survenue lors du chargement de votre fichier. Veuillez essayer avec un fichier plus léger.": "An error has occurred while uploading your file. Please try with a lighter file.",
    "Une erreur est survenue lors du lancement du rendu. Veuillez réessayer.": "An error has occurred while rendering. Please try again.",
    "Une erreur est survenue. Merci de réessayer.": "An error has occurred. Please try again.",
    "Une erreur inconnue est survenue.": "An unknown error has occurred.",
    "Une erreur inconnue s'est produite ! Contactez support@clap.video.": "An unknown error has occurred! Contact support@clap.video.",
    "Valable jusqu'au": "Valid till",
    "Valider": "Validate",
    "Veuillez accepter les conditions générales de vente.": "Please accept our terms and conditions of sale.",
    "Veuillez compléter toutes les images.": "Please complete all images.",
    "Veuillez entrer un code promo.": "Please enter your promo code.",
    "Veuillez entrer un email et un mot de passe.": "Please enter an email and a password.",
    "Veuillez entrer un titre valide": "Please enter a valid title",
    "Veuillez remplir les informations bancaires.": "Please fill in the bank details.",
    "Veuillez remplir votre nom et prénom.": "Please fill in your name and last name",
    "Veuillez utiliser la": "Please use the",
    "Vidéo automatique": "Automatic video",
    "Vidéos": "Videos",
    "Vidéos illimitées": "Unlimited videos",
    "Ville": "City",
    "Voir mes autoclaps": "Watch my audioclaps",
    "Vos clients apprécient votre travail et votre entreprise ? Faites-le savoir en créant une vidéo présentant vos meilleurs avis.": "Do your customers appreciate your work and your company? Let them know by creating a video featuring your best reviews.",
    "Vos modifications ont bien été enregistrées": "Your changes have been saved",
    "Votre adresse email": "Your email address",
    "Votre adresse postale": "Your zipcode",
    "Votre clap est en file d'attente, veuillez patienter quelques instants avant le début de la génération.": "Your clap is in queue, please wait a few moments before the generation starts.",
    "Votre code postale": "Your zipcode",
    "Votre couleur personnalisée": "Your customized color",
    "Votre demande de résiliation a bien été prise en compte.": "Your cancellation request has been processed.",
    "Votre email personnel": "Your personal email",
    "Votre email professionnel": "Your professional email",
    "Votre nom": "Your lastname",
    "Votre nom d'entreprise": "Your company name",
    "Votre nouveau mot de passe a bien été enregistré": "Your new password has been registered",
    "Votre numéro de téléphone professionnel": "Your professional telephone number",
    "Votre photo de profil a bien été modifiée": "Your profile picture has been updated successfully",
    "Votre photo de profil professionnelle a bien été modifiée": "Your professional profile picture has been modified",
    "Votre prénom": "Your name",
    "Votre résiliation a bien été annulée.": "Your cancellation has been cancelled.",
    "Votre vidéo est en cours de génération !": "Your video is being generated!",
    "Votre vidéo est en file d'attente, veuillez patienter quelques instants avant le début de la génération.": "Your video is in the queue, please wait a few moments before the generation begins.",
    "Votre ville": "Your city",
    "Voulez-vous vraiment réactiver votre abonnement": "Do you really want to reactivate your subscription",
    "Voulez-vous vraiment résilier votre abonnement Premium ? Votre abonnement continuera jusqu'à la date de fin d'abonnement et ne sera pas renouvelé.": "Do you really want to cancel your Premium subscription? Your subscription will continue until the end date and will not be renewed.",
    "Vous avez atteint le nombre maximum de vidéos gratuites.": "You've reached the maximum number of free videos.",
    "Vous avez déjà un compte": "Do you already have an account",
    "Vous contacter pour vous présenter de nouvelles fonctionnalités et offres qui pourraient vous intéresser": "Contact you about new features and offers that may be of interest to you",
    "Vous devez lier Facebook": "You must link Facebook",
    "Vous donnez accès à notre service de création vidéo": "You give access to our video creation service",
    "Vous n'avez aucune vidéo pour le moment.": "You don't have any video yet.",
    "Vous n'avez pas de compte": "Don't you have an account",
    "Vous n'avez pas lié de compte": "You have not linked an account",
    "Vous possédez un abonnement": "You have a subscription",
    "Vous pouvez envoyer cette vidéo sur votre page Facebook en vous": "You can send this video to your Facebook page",
    "Vous pouvez quitter cette page, votre vidéo sera directement ajoutée dans votre logiciel métier.": "You can leave this page, and your video will be added directly to your business software.",
    "Vous pouvez résilier votre abonnement à tout moment": "You can cancel your subscription at any time",
    "Vous serez de nouveau prélevé tous les": "You will be billed again every",
    "Vous serez prélevé automatiquement à chaque échéance.": "You will be debited automatically at each due date.",
    "Vous êtes actuellement connecté via Topi": "You are currently logged in via Topi",
    "Zone de danger": "Danger zone",
    "agency": {
      "address": "Agency address",
      "logo": "Logo",
      "name": "Agency name",
      "photo": "picture",
      "web": "Website"
    },
    "agent": {
      "email": "Email",
      "name": "Agent full name",
      "phone": "Phone number",
      "photo": "Agent photo",
      "web": "Website"
    },
    "chambre(s)": "room(s)",
    "conditions générales de vente": "Terms and conditions of sale.",
    "connectant sur clap.video": "Loging into clap.video",
    "connus par l’une des Parties sur une base non-confidentielle avant leur divulgation par l'autre Partie": "known by one of the Parties on a non-confidential basis prior to their disclosure by the other Party",
    "création manuelle": "manual creation",
    "d'un montant de": "in the amount of",
    "de Clap.": "of Clap.",
    "depuis le": "since",
    "divulgués en vertu d'une disposition législative ou réglementaire.": "disclosed by virtue of a legislative or regulatory provision.",
    "développés de façon indépendante par la Partie réceptrice qui n’a eu accès à aucune information de la Partie divulgatrice": "independently developed by the receiving party, which has not had access to any information from the disclosing party",
    "fournir une solution de remplacement permettant au Client de pouvoir utiliser les Services conformément à la Commande": "provide an alternative solution enabling the Client to use the Services in accordance with the Order",
    "ici": "here",
    "la diffusion des œuvres créées par tout moyen, notamment par voie hertzienne, câble-satellite ainsi que par tout réseau, et plus généralement par tout moyen de transmission de données numérisées ou non.": "the distribution of works created by any means, including over-the-air, cable-satellite and any network, and more generally by any means of digital or non-digital data transmission.",
    "le droit de diffuser et de communiquer à tout public les éléments, supports, composants des œuvres créées, par tout procédé de représentation connu ou inconnu à ce jour, pour toute utilisation quelle qu’elle soit ": "the right to distribute and communicate to any public the elements, media and components of the works created, by any representation process known or unknown to date, for any use whatsoever",
    "le droit de mettre en circulation et d’exploiter les œuvres créées, commercialement ou non, les reproductions ainsi réalisées, en nombre illimité, à titre gratuit ou onéreux, et ce quelle qu’en soit la destination.": "the right to circulate and exploit the works created, commercially or otherwise, and the reproductions thus made, in unlimited numbers, free of charge or against payment, for any purpose whatsoever.",
    "le droit de reproduire et/ou faire reproduire les œuvres créées en nombre illimité, par tout procédé et sur tout support actuel ou futur, et nomment graphique, magnétique, numérique ou électronique (interactif ou non) ": "the right to reproduce and/or have reproduced the works created in unlimited numbers, by any process and on any current or future medium, whether graphic, magnetic, digital or electronic (interactive or not)",
    "les légendes de vos photos se sont complétées automatiquement, pensez à les vérifier !": "The description of your pictures are completed automatically, remember to check them !",
    "les modifications demandées par le Client en cours de réalisation, si elles impliquent un remaniement du projet.": "modifications requested by the customer during the course of the project, if they involve a reworking of the project.",
    "légitimement obtenus d'un tiers non tenu par une obligation de confidentialité": "legitimately obtained from a third party not bound by an obligation of confidentiality",
    "ma photo": "my picture",
    "mon logo": "my logo",
    "n'existe pas ou vous ne vous appartient pas.": "doesn't exist or doesn't belong to you.",
    "nous vous notifions par email.": "we will notify you by email.",
    "obtenir toute concession de licence ou autorisation pour permettre au Client de continuer à utiliser les Services": "obtain any license or authorization to allow the Client to continue to use the Services",
    "ou": "or",
    "ou la": "or the",
    "page": "page",
    "paramètres de connecteurs": "connectors' settings",
    "photos": {
      "1": "Image 1",
      "2": "Image 2",
      "3": "Image 3",
      "4": "Image 4",
      "5": "Image 5"
    },
    "publier votre vidéo sur Facebook en quelques clics, liez vos pages dans vos": "To post your video on Facebook in a few clicks, link your pages in your",
    "quartier": {
      "1": "Neighbourhood of the good"
    },
    "realty": {
      "bedrooms": "Number of bedrooms",
      "city": "City",
      "furnished": "Furnished or unfurnished",
      "living_area": "Living area",
      "operation": "Operation (Sale, rental)",
      "price": "Price",
      "title": "Main title",
      "type": "Realty type (appartment, house)"
    },
    "recherche sur le web": "web search",
    "s": "s",
    "secondes": "seconds",
    "si aucune des deux possibilités n’est réalisable, rembourser le Client des sommes versées au titre des Services, déduction faite des sommes déjà payées par le Client pour la période d’utilisation effective des Services.": "if neither of the two possibilities is feasible, reimburse the Client for the sums paid for the Services, less any sums already paid by the Client for the period of actual use of the Services.",
    "text": {
      "1": "Search text",
      "2": "Title of the explanatory text",
      "3": "Explanatory text"
    },
    "tombés ou qui tomberont dans le domaine public au jour de leur divulgation": "which have fallen or will fall into the public domain on the day of their disclosure",
    "validé": "validated",
    "via AutoClap ou manuellement": "using AutoClap or manually",
    "Éditer cette vidéo": "Edit this video",
    "Établir un cahier des charges détaillé qui ne subira plus de modification, sauf accord des Parties, après avoir été approuvé par le Prestataire. Au besoin, le Prestataire pourra intervenir dans l’élaboration du cahier des charges, conjointement avec le Client. Dans le cas où des modifications impliqueraient un remaniement substantiel du cahier des charges initial, ces dernières seront facturées en sus du devis initial.": "Establish detailed specifications which will not be modified, except by agreement of the Parties, once they have been approved by the Service Provider. If necessary, the Service Provider may intervene in the drafting of the specifications, jointly with the Customer. In the event of modifications involving a substantial reworking of the initial specifications, these will be invoiced in addition to the initial quotation.",
    "à appliquer aux Informations Confidentielles les mêmes mesures de protection que celles qu'elle applique à ses propres informations confidentielles": "to apply the same protection measures to Confidential Information as it applies to its own confidential information",
    "à clap.video.": "for clap.video.",
    "à compter du": "from",
    "à n'utiliser les Informations Confidentielles que pour les seuls besoins de réalisation des Services.": "to use the Confidential Information solely for the purposes of providing the Services.",
    "à ne communiquer les Informations Confidentielles qu'à ses seuls employés et collaborateurs amenés à les connaître dans le cadre de la réalisation des Services": "to communicate Confidential Information only to its employees and collaborators who need to know it in order to provide the Services",
    "à ne pas divulguer, publier ou transmettre à des tiers les Informations Confidentielles, sous quelque forme que ce soit, sans l’accord préalable écrit de l’autre Partie": "not to disclose, publish or transmit to third parties the Confidential Information, in any form whatsoever, without the prior written consent of the other Party"
  }], ["fr", {
    "(version longue)": "(version longue)",
    "1. Collecte de l’information": "1. Collecte de l’information",
    "15 templates": "15 templates",
    "2. Utilisation des informations": "2. Utilisation des informations",
    "29,90€ / mois": "29,90€ / mois",
    "29,90€/mois": "29,90€/mois",
    "299,00€ / an": "299,00€ / an",
    "299,00€/an": "299,00€/an",
    "3 templates": "3 templates",
    "3. Divulgation à des tiers": "3. Divulgation à des tiers",
    "4. Protection des informations": "4. Protection des informations",
    "5 vidéos gratuites": "5 vidéos gratuites",
    "5. Cookies": "5. Cookies",
    "6. Se désabonner": "6. Se désabonner",
    "7. Consentement": "7. Consentement",
    "8 caractères minimum": "8 caractères minimum",
    "<": "<",
    "A ce titre, le Prestataire s’engage à prendre les mesures nécessaires afin d’assurer la protection, la sécurité et la confidentialité des données personnelles qui lui sont transmises par le Client.": "A ce titre, le Prestataire s’engage à prendre les mesures nécessaires afin d’assurer la protection, la sécurité et la confidentialité des données personnelles qui lui sont transmises par le Client.",
    "A défaut de dispositions spécifiques dans le devis, les délais d’exécution des Services sont communiqués au Client à titre indicatif. Les délais de réalisation des Services ne sont en aucun cas garantis par le Prestataire ni ne peuvent engager sa responsabilité, ni entraîner une obligation de payer une quelconque indemnité ou pénalité de retard, ni justifier l’annulation de la Commande en cause.": "A défaut de dispositions spécifiques dans le devis, les délais d’exécution des Services sont communiqués au Client à titre indicatif. Les délais de réalisation des Services ne sont en aucun cas garantis par le Prestataire ni ne peuvent engager sa responsabilité, ni entraîner une obligation de payer une quelconque indemnité ou pénalité de retard, ni justifier l’annulation de la Commande en cause.",
    "A défaut de solution amiable trouvée entre les Parties, seront seuls compétents pour connaître des litiges de toutes natures ou de contestations relatives à l'interprétation ou à l'exécution du présent Contrat, les tribunaux du ressort de la Cour d’Appel de Paris, nonobstant pluralité de défendeurs, demande incidente ou référé ou appels en garantie, à moins que le Prestataire ne préfère saisir toute autre juridiction compétente.": "A défaut de solution amiable trouvée entre les Parties, seront seuls compétents pour connaître des litiges de toutes natures ou de contestations relatives à l'interprétation ou à l'exécution du présent Contrat, les tribunaux du ressort de la Cour d’Appel de Paris, nonobstant pluralité de défendeurs, demande incidente ou référé ou appels en garantie, à moins que le Prestataire ne préfère saisir toute autre juridiction compétente.",
    "A l’expiration de ce délai de quinze (15) jours, les modifications des CGV seront définitivement considérées comme acceptées par le Client.": "A l’expiration de ce délai de quinze (15) jours, les modifications des CGV seront définitivement considérées comme acceptées par le Client.",
    "Abonnement": "Abonnement",
    "Abonnement actuel": "Abonnement actuel",
    "Abonnement clap.video Premium": "Abonnement clap.video Premium",
    "Abonnement en cours": "Abonnement en cours",
    "Accepter": "Accepter",
    "Accès à tous les templates": "Accès à tous les templates",
    "Activer AutoClap": "Activer AutoClap",
    "Administrer un concours, une promotion, ou un enquête": "Administrer un concours, une promotion, ou un enquête",
    "Adresse email": "Adresse email",
    "Adresse mail": "Adresse mail",
    "Adresse postale": "Adresse postale",
    "Afficher votre adresse postale professionnelle dans les vidéos": "Afficher votre adresse postale professionnelle dans les vidéos",
    "Afin de demander la réinitialisation de votre mot de passe, veuillez renseigner votre adresse email. Un email vous sera adressé afin de vous indiquer la procédure à suivre.": "Afin de demander la réinitialisation de votre mot de passe, veuillez renseigner votre adresse email. Un email vous sera adressé afin de vous indiquer la procédure à suivre.",
    "Ajouter un code promo": "Ajouter un code promo",
    "Améliorer le service client et vos besoins de prise en charge par notre équipe support": "Améliorer le service client et vos besoins de prise en charge par notre équipe support",
    "Analyser l'usage du site (données anonymisées et agrégées)": "Analyser l'usage du site (données anonymisées et agrégées)",
    "Annonce immobilière": "Annonce immobilière",
    "Annonce immobilière - Choix d'une annonce dans votre logiciel métier": "Annonce immobilière - Choix d'une annonce dans votre logiciel métier",
    "Annuel": "Annuel",
    "Annuler": "Annuler",
    "Appliquer": "Appliquer",
    "Après encaissement des factures par le Prestataire, celui-ci cède au Client l’ensemble des droits de propriété intellectuelle sur les œuvres créées spécifiquement et à la demande du Client, dans le cadre de l’exécution du Contrat, pour leur durée de protection et pour le monde entier.": "Après encaissement des factures par le Prestataire, celui-ci cède au Client l’ensemble des droits de propriété intellectuelle sur les œuvres créées spécifiquement et à la demande du Client, dans le cadre de l’exécution du Contrat, pour leur durée de protection et pour le monde entier.",
    "Assurance": "Assurance",
    "Astuce": "Astuce",
    "Aucun bien n'a été trouvé pour": "Aucun bien n'a été trouvé pour",
    "Aucun changement ni aucune modification du Contrat, notamment sur les caractéristiques des Services, ne sera pris en considération s'il n'a pas été accepté par écrit par le Prestataire.": "Aucun changement ni aucune modification du Contrat, notamment sur les caractéristiques des Services, ne sera pris en considération s'il n'a pas été accepté par écrit par le Prestataire.",
    "Aucun escompte n’est prévu en cas de paiement anticipé.": "Aucun escompte n’est prévu en cas de paiement anticipé.",
    "Aucun résultat": "Aucun résultat",
    "Aucun résultat.": "Aucun résultat.",
    "Aucune annonce n'a pu être trouvée.": "Aucune annonce n'a pu être trouvée.",
    "AutoClap génère automatiquement une video quand vous publiez un nouveau bien sur votre outil métier.": "AutoClap génère automatiquement une video quand vous publiez un nouveau bien sur votre outil métier.",
    "Autonomie des clauses": "Autonomie des clauses",
    "Avant chaque intervention du Prestataire, le Client s’engage à réaliser toutes les procédures de sauvegarde nécessaires à la protection et à la sauvegarde de ses données, programmes et fichiers informatiques.": "Avant chaque intervention du Prestataire, le Client s’engage à réaliser toutes les procédures de sauvegarde nécessaires à la protection et à la sauvegarde de ses données, programmes et fichiers informatiques.",
    "Avec l’abonnement Premium, vous profiterez de clap.video dans son intégralité et débloquerez les fonctionnalités suivantes": "Avec l’abonnement Premium, vous profiterez de clap.video dans son intégralité et débloquerez les fonctionnalités suivantes",
    "Avis clients": "Avis clients",
    "Bientôt disponible": "Bientôt disponible",
    "Bienvenue sur Clap !": "Bienvenue sur Clap !",
    "Bienvenue sur clap.video !": "Bienvenue sur clap.video !",
    "Carré": "Carré",
    "Carte bancaire": "Carte bancaire",
    "Ces CGV régissent intégralement les relations entre le Prestataire et le Client. Aucune condition générale d'achat ne pourra prévaloir ni être opposée par le Client au Prestataire et aucune condition particulière communiquée par le Client au Prestataire ne peut prévaloir sur les CGV, sauf acceptation formelle et écrite du Prestataire.": "Ces CGV régissent intégralement les relations entre le Prestataire et le Client. Aucune condition générale d'achat ne pourra prévaloir ni être opposée par le Client au Prestataire et aucune condition particulière communiquée par le Client au Prestataire ne peut prévaloir sur les CGV, sauf acceptation formelle et écrite du Prestataire.",
    "Ces cookies ne sont pas indispensables au fonctionnement du site, mais ils facilitent votre navigation. Vous pouvez les bloquer ou les supprimer, mais vous risquez alors d'empêcher le bon fonctionnement de certains éléments du site.": "Ces cookies ne sont pas indispensables au fonctionnement du site, mais ils facilitent votre navigation. Vous pouvez les bloquer ou les supprimer, mais vous risquez alors d'empêcher le bon fonctionnement de certains éléments du site.",
    "Ces pénalités de retard sont exigibles de plein droit et sans qu’un rappel par le Prestataire ne soit nécessaire.": "Ces pénalités de retard sont exigibles de plein droit et sans qu’un rappel par le Prestataire ne soit nécessaire.",
    "Cette abonnement sera automatiquement renouvelé le": "Cette abonnement sera automatiquement renouvelé le",
    "Cette action est irréversible.": "Cette action est irréversible.",
    "Cette disposition ne peut être remplacée par un accord verbal.": "Cette disposition ne peut être remplacée par un accord verbal.",
    "Cette publication sera postée sur": "Cette publication sera postée sur",
    "Chacune des Parties renonce, sauf accord écrit préalable, à faire directement ou indirectement des offres d’engagement à un collaborateur de l’autre Partie ayant travaillé dans le cadre des Services, objet du présent Contrat, ou à le prendre à son service, sous quelque statut que ce soit. Cette renonciation est valable pour une durée de deux (2) ans à compter de la fin du présent Contrat.": "Chacune des Parties renonce, sauf accord écrit préalable, à faire directement ou indirectement des offres d’engagement à un collaborateur de l’autre Partie ayant travaillé dans le cadre des Services, objet du présent Contrat, ou à le prendre à son service, sous quelque statut que ce soit. Cette renonciation est valable pour une durée de deux (2) ans à compter de la fin du présent Contrat.",
    "Chacune des Parties s’engage à maintenir en vigueur, pendant toute la durée de réalisation du Contrat, auprès d’une compagnie d’assurance notoirement solvable, une police d’assurance garantissant les dommages pouvant survenir à ses biens et personnel, ainsi qu’une police couvrant sa responsabilité professionnelle, de manière à couvrir les conséquences pécuniaires des dommages corporels, matériels et immatériels dont elles auraient à répondre, causés par tout évènement et qui serait le fait de ses collaborateurs et/ou sociétés partenaires éventuels lors de l’exécution du Contrat.": "Chacune des Parties s’engage à maintenir en vigueur, pendant toute la durée de réalisation du Contrat, auprès d’une compagnie d’assurance notoirement solvable, une police d’assurance garantissant les dommages pouvant survenir à ses biens et personnel, ainsi qu’une police couvrant sa responsabilité professionnelle, de manière à couvrir les conséquences pécuniaires des dommages corporels, matériels et immatériels dont elles auraient à répondre, causés par tout évènement et qui serait le fait de ses collaborateurs et/ou sociétés partenaires éventuels lors de l’exécution du Contrat.",
    "Changer": "Changer",
    "Chaque Partie s’engage": "Chaque Partie s’engage",
    "Chargement...": "Chargement...",
    "Choisir une autre image sur l’annonce": "Choisir une autre image sur l’annonce",
    "Choisir une musique": "Choisir une musique",
    "Choisir une page": "Choisir une page",
    "Choisissez la langue de l'interface": "Choisissez la langue de l'interface",
    "Choisissez un template": "Choisissez un template",
    "Choisissez votre type de vidéo": "Choisissez votre type de vidéo",
    "Code postal": "Code postal",
    "Code postale": "Code postale",
    "Code promo": "Code promo",
    "Code promotionnel": "Code promotionnel",
    "Collaborer activement à la réussite du projet en apportant au Prestataire dans les délais préalablement définis toutes les informations et tous les documents nécessaires à la bonne appréhension des besoins et à la bonne exécution des Services.": "Collaborer activement à la réussite du projet en apportant au Prestataire dans les délais préalablement définis toutes les informations et tous les documents nécessaires à la bonne appréhension des besoins et à la bonne exécution des Services.",
    "Collaborer étroitement avec le Prestataire et fournir toute information, documentation, prestation, et tous moyens utiles pour la réalisation des Services et s’engage à mettre à disposition du Prestataire tous les éléments permettant de satisfaire à son obligation, incluant le personnel dédié à la bonne réalisation des Services.": "Collaborer étroitement avec le Prestataire et fournir toute information, documentation, prestation, et tous moyens utiles pour la réalisation des Services et s’engage à mettre à disposition du Prestataire tous les éléments permettant de satisfaire à son obligation, incluant le personnel dédié à la bonne réalisation des Services.",
    "Commencer": "Commencer",
    "Complément d'adresse postale": "Complément d'adresse postale",
    "Concernant les logiciels, données ou documents utilisés par le Prestataire dans le cadre de la fourniture des Services, dont le Client a acquis les droits d’utilisation auprès de tiers ou dont il est propriétaire, le Client garantit le Prestataire de toutes les conséquences ou suites dommageables que le Prestataire aurait à subir au titre de l’utilisation desdits logiciels, données ou documents contre toute action de la part d’une personne revendiquant un droit de propriété intellectuelle ou se fondant sur une demande en concurrence déloyale et/ou parasitisme sur ces logiciels, données ou documents.": "Concernant les logiciels, données ou documents utilisés par le Prestataire dans le cadre de la fourniture des Services, dont le Client a acquis les droits d’utilisation auprès de tiers ou dont il est propriétaire, le Client garantit le Prestataire de toutes les conséquences ou suites dommageables que le Prestataire aurait à subir au titre de l’utilisation desdits logiciels, données ou documents contre toute action de la part d’une personne revendiquant un droit de propriété intellectuelle ou se fondant sur une demande en concurrence déloyale et/ou parasitisme sur ces logiciels, données ou documents.",
    "Conclusion du Contrat": "Conclusion du Contrat",
    "Conditions Générales de Vente - clap.video": "Conditions Générales de Vente - clap.video",
    "Conditions de paiement": "Conditions de paiement",
    "Confidentialité": "Confidentialité",
    "Confirmation de suppression": "Confirmation de suppression",
    "Confirmation du nouveau mot de passe": "Confirmation du nouveau mot de passe",
    "Confirmer le mot de passe": "Confirmer le mot de passe",
    "Confirmer le paiement": "Confirmer le paiement",
    "Confirmez votre mot de passe": "Confirmez votre mot de passe",
    "Conformément aux dispositions de la Loi Informatique et Libertés n° 78-17 du 6 janvier 1978 telle que modifiée, le Client dispose de la qualité de responsable de traitement dans le cadre de l’exécution du Contrat.": "Conformément aux dispositions de la Loi Informatique et Libertés n° 78-17 du 6 janvier 1978 telle que modifiée, le Client dispose de la qualité de responsable de traitement dans le cadre de l’exécution du Contrat.",
    "Conformément aux dispositions du Règlement Général sur la Protection des Données (RGPD) entré en vigueur le 25 mai 2018, le Client dispose notamment d’un droit d’accès, de rectification, d’opposition et de portabilité de ses données personnelles. La garantie de ces droits est affirmée au sein de la politique de confidentialité qui accompagne les présentes, et qui en conséquence, forment un ensemble contractuel.": "Conformément aux dispositions du Règlement Général sur la Protection des Données (RGPD) entré en vigueur le 25 mai 2018, le Client dispose notamment d’un droit d’accès, de rectification, d’opposition et de portabilité de ses données personnelles. La garantie de ces droits est affirmée au sein de la politique de confidentialité qui accompagne les présentes, et qui en conséquence, forment un ensemble contractuel.",
    "Connecter l'ensemble de vos plateformes à clap.video": "Connecter l'ensemble de vos plateformes à clap.video",
    "Connecteurs": "Connecteurs",
    "Connectez-vous !": "Connectez-vous !",
    "Connexion": "Connexion",
    "Connexion via Topi": "Connexion via Topi",
    "Conserver la musique par défaut": "Conserver la musique par défaut",
    "Copier le lien de la page publique": "Copier le lien de la page publique",
    "Copier le lien de la vidéo": "Copier le lien de la vidéo",
    "Copier le lien public": "Copier le lien public",
    "Couleur du thème": "Couleur du thème",
    "Couleur incorrecte. Veuillez choisir une couleur au format hexadécimal sans transparence.": "Couleur incorrecte. Veuillez choisir une couleur au format hexadécimal sans transparence.",
    "Couleur précédente": "Couleur précédente",
    "Couleur préférée": "Couleur préférée",
    "Couleurs prédéfinies": "Couleurs prédéfinies",
    "Création d'un clap vidéo": "Création d'un clap vidéo",
    "Création d'une vidéo": "Création d'une vidéo",
    "Création manuelle": "Création manuelle",
    "Créer le nouveau mot de passe": "Créer le nouveau mot de passe",
    "Créer manuellement": "Créer manuellement",
    "Créer une vidéo": "Créer une vidéo",
    "Créez votre première vidéo en cliquant sur le bouton ci-dessous.": "Créez votre première vidéo en cliquant sur le bouton ci-dessous.",
    "Créez-en un": "Créez-en un",
    "Créér ou administrer votre compte clap.video": "Créér ou administrer votre compte clap.video",
    "Dans la file d'attente": "Dans la file d'attente",
    "Dans le cadre de la fourniture des Services et en tant que de besoin, le Client concède également au Prestataire un droit d’utilisation de ses logiciels, données et documents, à titre personnel, gratuit, non exclusif et non transférable pour la durée de réalisation des Services.": "Dans le cadre de la fourniture des Services et en tant que de besoin, le Client concède également au Prestataire un droit d’utilisation de ses logiciels, données et documents, à titre personnel, gratuit, non exclusif et non transférable pour la durée de réalisation des Services.",
    "Dans le cadre des présentes CGV et la réalisation des Services, le Prestataire s’engage à se donner tous les moyens nécessaires et à mettre tout en œuvre pour la réalisation de sa mission dans les règles de l’art. Cette obligation ne saurait constituer une obligation de résultat, le Prestataire ne fournissant les Services que dans le cadre d’une obligation de moyen.": "Dans le cadre des présentes CGV et la réalisation des Services, le Prestataire s’engage à se donner tous les moyens nécessaires et à mettre tout en œuvre pour la réalisation de sa mission dans les règles de l’art. Cette obligation ne saurait constituer une obligation de résultat, le Prestataire ne fournissant les Services que dans le cadre d’une obligation de moyen.",
    "Dans le respect des articles L 8221-1 et suivants du Code du travail et conformément à l’article D 8222-5 du même code, le Prestataire s’engage à remettre au Client lors de la conclusion du Contrat, et tous les six mois jusqu’à la fin de son exécution, les documents suivants ": "Dans le respect des articles L 8221-1 et suivants du Code du travail et conformément à l’article D 8222-5 du même code, le Prestataire s’engage à remettre au Client lors de la conclusion du Contrat, et tous les six mois jusqu’à la fin de son exécution, les documents suivants ",
    "Dans l’hypothèse où le Client aurait signé un procès-verbal de réception des Services et/ou la maquette de la ou les créations ou a validé la réception des Services et/ou la maquette, par tout moyen et notamment par l’utilisation des Services et/ou la maquette, le Prestataire sera réputé avoir réalisé ses obligations de manière conforme au Contrat. Le Client sera alors réputé avoir renoncé irrévocablement à toutes réclamation de plein droit à ce titre.": "Dans l’hypothèse où le Client aurait signé un procès-verbal de réception des Services et/ou la maquette de la ou les créations ou a validé la réception des Services et/ou la maquette, par tout moyen et notamment par l’utilisation des Services et/ou la maquette, le Prestataire sera réputé avoir réalisé ses obligations de manière conforme au Contrat. Le Client sera alors réputé avoir renoncé irrévocablement à toutes réclamation de plein droit à ce titre.",
    "Demande de mot de passe": "Demande de mot de passe",
    "Dernière modification": "Dernière modification",
    "Disposer des droits nécessaires sur les éléments fournis ci-dessus.": "Disposer des droits nécessaires sur les éléments fournis ci-dessus.",
    "Dispositions générales": "Dispositions générales",
    "Documents antérieurs ou autres accords": "Documents antérieurs ou autres accords",
    "Donnez vie à vos biens immobiliers en vidéo et améliorez votre engagement sur les réseaux sociaux.": "Donnez vie à vos biens immobiliers en vidéo et améliorez votre engagement sur les réseaux sociaux.",
    "Données personnelles": "Données personnelles",
    "Droit applicable et juridictions compétentes": "Droit applicable et juridictions compétentes",
    "Dupliquez cette vidéo": "Dupliquez cette vidéo",
    "Durée": "Durée",
    "Déconnexion": "Déconnexion",
    "Découvrez notre nouveau bien, une magnifique maison de campagne de 156m² et 3 chambres dont une suite parentale. Piscine, garage.": "Découvrez notre nouveau bien, une magnifique maison de campagne de 156m² et 3 chambres dont une suite parentale. Piscine, garage.",
    "Définissez votre nouveau mot de passe.": "Définissez votre nouveau mot de passe.",
    "Définitions": "Définitions",
    "Désolé, votre navigateur ne supporte pas ce type de vidéo.": "Désolé, votre navigateur ne supporte pas ce type de vidéo.",
    "D’une façon générale, le Client et le Prestataire s’engagent à collaborer activement afin d’assurer la bonne exécution du Contrat. Chacune des Parties s’engage à communiquer toutes les difficultés dont elle aurait connaissance au fur et à mesure de l'avancement du projet, pour permettre à l’autre Partie de prendre les décisions nécessaires.": "D’une façon générale, le Client et le Prestataire s’engagent à collaborer activement afin d’assurer la bonne exécution du Contrat. Chacune des Parties s’engage à communiquer toutes les difficultés dont elle aurait connaissance au fur et à mesure de l'avancement du projet, pour permettre à l’autre Partie de prendre les décisions nécessaires.",
    "E-commerce": "E-commerce",
    "Email": "Email",
    "Email pro": "Email pro",
    "En aucun cas, le Prestataire ne sera tenu pour responsable des dommages indirects, accessoires ou particuliers tels que définis par la jurisprudence des tribunaux français, notamment, le coût de l’obtention de services de substitution, pertes de bénéfices, de données ou périodes d’immobilisation, que sa responsabilité soit contractuelle ou délictuelle et qu’elle ait ou non son fondement dans l’utilisation ou le fonctionnement des Services, même si le Prestataire a averti le Client de la possibilité de tels dommages.": "En aucun cas, le Prestataire ne sera tenu pour responsable des dommages indirects, accessoires ou particuliers tels que définis par la jurisprudence des tribunaux français, notamment, le coût de l’obtention de services de substitution, pertes de bénéfices, de données ou périodes d’immobilisation, que sa responsabilité soit contractuelle ou délictuelle et qu’elle ait ou non son fondement dans l’utilisation ou le fonctionnement des Services, même si le Prestataire a averti le Client de la possibilité de tels dommages.",
    "En cas de contradiction entre des dispositions du devis et les présentes CGV, les dispositions concernées du devis prévaudront sur les CGV.": "En cas de contradiction entre des dispositions du devis et les présentes CGV, les dispositions concernées du devis prévaudront sur les CGV.",
    "En cas de différend entre les Parties, celles-ci tenteront de trouver une solution amiable à ce différend dans un délai de trente jours à compter de la notification du différend de la Partie en demande à l’autre Partie par lettre recommandée avec accusé de réception.": "En cas de différend entre les Parties, celles-ci tenteront de trouver une solution amiable à ce différend dans un délai de trente jours à compter de la notification du différend de la Partie en demande à l’autre Partie par lettre recommandée avec accusé de réception.",
    "En cas de manquement quelconque du Prestataire dans l’exécution de ses obligations (défaut d’exécution ou mauvaise exécution), le Client devra en faire part à celui-ci dans le délai de huit (8) jours ouvrés à compter de la constatation du manquement par lettre recommandée avec accusé de réception. A défaut, le manquement sera inopposable au Prestataire.": "En cas de manquement quelconque du Prestataire dans l’exécution de ses obligations (défaut d’exécution ou mauvaise exécution), le Client devra en faire part à celui-ci dans le délai de huit (8) jours ouvrés à compter de la constatation du manquement par lettre recommandée avec accusé de réception. A défaut, le manquement sera inopposable au Prestataire.",
    "En cas de non-paiement à l’échéance, toute somme due fera courir des pénalités de retard. Celles-ci courent à compter du jour suivant la date d’échéance figurant sur la facture et jusqu’au jour du paiement effectif et intégral de la somme. Le taux des pénalités de retard est fixé à trois faux le taux d’intérêt légal en vigueur.": "En cas de non-paiement à l’échéance, toute somme due fera courir des pénalités de retard. Celles-ci courent à compter du jour suivant la date d’échéance figurant sur la facture et jusqu’au jour du paiement effectif et intégral de la somme. Le taux des pénalités de retard est fixé à trois faux le taux d’intérêt légal en vigueur.",
    "En cas de rupture du Contrat avant son terme par le Client, celui-ci s’engage formellement à régulariser et rétribuer les montants relatifs au calendrier en cours, aux postes réalisés ou en cours de réalisation, ainsi qu’aux services complémentaires effectués. Les fichiers et données sources créés et utilisés par le Prestataire ne sauraient dès lors être revendiqués par le Client sans une contribution financière. L’acompte déjà versé restera acquis par le Prestataire, constituant un dédommagement pour le travail entrepris.": "En cas de rupture du Contrat avant son terme par le Client, celui-ci s’engage formellement à régulariser et rétribuer les montants relatifs au calendrier en cours, aux postes réalisés ou en cours de réalisation, ainsi qu’aux services complémentaires effectués. Les fichiers et données sources créés et utilisés par le Prestataire ne sauraient dès lors être revendiqués par le Client sans une contribution financière. L’acompte déjà versé restera acquis par le Prestataire, constituant un dédommagement pour le travail entrepris.",
    "En cas d’atteinte avérée aux droits d’un tiers, le Prestataire pourra, à son choix ": "En cas d’atteinte avérée aux droits d’un tiers, le Prestataire pourra, à son choix ",
    "En cliquant sur le bouton d'inscription, vous acceptez les": "En cliquant sur le bouton d'inscription, vous acceptez les",
    "En cours de traitement": "En cours de traitement",
    "En file d'attente": "En file d'attente",
    "En particulier, le Prestataire cède au Client les droits suivants ": "En particulier, le Prestataire cède au Client les droits suivants ",
    "En utilisant notre site, vous consentez à notre politique de confidentialité.": "En utilisant notre site, vous consentez à notre politique de confidentialité.",
    "Encodage en cours": "Encodage en cours",
    "Enfin, en cas de retard de paiement, le Prestataire se réserve le droit de suspendre ou de surseoir à l’exécution des Services prévus dans la Commande dont le paiement fait l’objet du retard.": "Enfin, en cas de retard de paiement, le Prestataire se réserve le droit de suspendre ou de surseoir à l’exécution des Services prévus dans la Commande dont le paiement fait l’objet du retard.",
    "Enfin, le Client fait son affaire et est seul responsable des lois et réglementations applicables aux Services notamment au regard de la protection des droits de propriété intellectuelle, mentions légales, protection des données personnelles, protection des mineurs (si applicable) et droit de la consommation (si applicable).": "Enfin, le Client fait son affaire et est seul responsable des lois et réglementations applicables aux Services notamment au regard de la protection des droits de propriété intellectuelle, mentions légales, protection des données personnelles, protection des mineurs (si applicable) et droit de la consommation (si applicable).",
    "Enregistrer les modifications": "Enregistrer les modifications",
    "Enregistrer l’image sélectionnée": "Enregistrer l’image sélectionnée",
    "Entrer une couleur manuellement": "Entrer une couleur manuellement",
    "Envoi en cours": "Envoi en cours",
    "Envoyer": "Envoyer",
    "Erreur": "Erreur",
    "Erreur de connexion au serveur, veuillez réessayer s'il vous plait.": "Erreur de connexion au serveur, veuillez réessayer s'il vous plait.",
    "Erreur lors de l'envoi sur Facebook. Merci de vérifier vos informations et réessayer.": "Erreur lors de l'envoi sur Facebook. Merci de vérifier vos informations et réessayer.",
    "Erreur lors de l'envoi sur Instagram. Merci de vérifier vos informations et réessayer.": "Erreur lors de l'envoi sur Instagram. Merci de vérifier vos informations et réessayer.",
    "Erreur lors de l'envoi sur Tiktok. Merci de vérifier vos informations et réessayer.": "Erreur lors de l'envoi sur Tiktok. Merci de vérifier vos informations et réessayer.",
    "Erreur lors de la copie du lien, veuillez réessayer": "Erreur lors de la copie du lien, veuillez réessayer",
    "Erreur pendant l'enregistrement de vos informations": "Erreur pendant l'enregistrement de vos informations",
    "Erreur pendant la suppression du projet.": "Erreur pendant la suppression du projet.",
    "Fait main": "Fait main",
    "Fonctionnalités": "Fonctionnalités",
    "Force Majeure": "Force Majeure",
    "Format": "Format",
    "Fournir tous les éléments documentaires, graphiques et textuels nécessaires à la bonne réalisation du Contrat (notamment dans les bons formats exploitables en fonction des supports visés), le Client s'engage à fournir toutes les informations légales à ajouter dans les documents et endosse la responsabilité de fournir le contenu des documents qu'il édite.": "Fournir tous les éléments documentaires, graphiques et textuels nécessaires à la bonne réalisation du Contrat (notamment dans les bons formats exploitables en fonction des supports visés), le Client s'engage à fournir toutes les informations légales à ajouter dans les documents et endosse la responsabilité de fournir le contenu des documents qu'il édite.",
    "Frais Annexes": "Frais Annexes",
    "Garantie d’éviction": "Garantie d’éviction",
    "Garanties": "Garanties",
    "Garantir le Prestataire contre toute action qui pourrait lui être intentée du fait du caractère des données ou informations (textes, images, sons) qui auraient été fournies ou choisies par le Client.": "Garantir le Prestataire contre toute action qui pourrait lui être intentée du fait du caractère des données ou informations (textes, images, sons) qui auraient été fournies ou choisies par le Client.",
    "Gratuit": "Gratuit",
    "Généralités": "Généralités",
    "HT": "HT",
    "Hormis les dommages corporels ou décès, et sauf en cas de négligence grossière ou de faute intentionnelle causant un dommage direct prouvé ou en cas de manquement à une obligation essentielle du Contrat la vidant de sa substance, le Client reconnaît que la responsabilité du Prestataire est limitée au montant versé pour les Services en cause.": "Hormis les dommages corporels ou décès, et sauf en cas de négligence grossière ou de faute intentionnelle causant un dommage direct prouvé ou en cas de manquement à une obligation essentielle du Contrat la vidant de sa substance, le Client reconnaît que la responsabilité du Prestataire est limitée au montant versé pour les Services en cause.",
    "Il semble y avoir une erreur de notre côté.": "Il semble y avoir une erreur de notre côté.",
    "Images": "Images",
    "Immobilier": "Immobilier",
    "Importer cette annonce": "Importer cette annonce",
    "Importer une image": "Importer une image",
    "Informer le Prestataire d’une éventuelle mise en concurrence avec d’autres prestataires.": "Informer le Prestataire d’une éventuelle mise en concurrence avec d’autres prestataires.",
    "Infos générales": "Infos générales",
    "Inscription": "Inscription",
    "L'email a été envoyé": "L'email a été envoyé",
    "L'enregistrement des champs a échoué.": "L'enregistrement des champs a échoué.",
    "L'importation de cette annonce n'a pas fonctionné. Si le problème persiste, veuillez contacter le support.": "L'importation de cette annonce n'a pas fonctionné. Si le problème persiste, veuillez contacter le support.",
    "L'url de votre site Internet": "L'url de votre site Internet",
    "La Partie touchée par un événement de force majeure en avisera l'autre Partie dans les cinq (5) jours ouvrables suivant la date à laquelle elle en aura eu connaissance. Les deux Parties conviendront alors des conditions dans lesquelles l'exécution du Contrat sera poursuivie.": "La Partie touchée par un événement de force majeure en avisera l'autre Partie dans les cinq (5) jours ouvrables suivant la date à laquelle elle en aura eu connaissance. Les deux Parties conviendront alors des conditions dans lesquelles l'exécution du Contrat sera poursuivie.",
    "La connexion automatique a échouée.": "La connexion automatique a échouée.",
    "La liaison avec Facebook a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Facebook.": "La liaison avec Facebook a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Facebook.",
    "La liaison avec Instagram a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Instagram.": "La liaison avec Instagram a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Instagram.",
    "La liaison avec Tiktok a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Tiktok.": "La liaison avec Tiktok a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Tiktok.",
    "La loi du Contrat est la Loi Française. Les parties conviennent expressément que la Convention de Viennes sur la vente internationale de marchandises en date du 11 avril 1980 n’est pas applicable au Contrat.": "La loi du Contrat est la Loi Française. Les parties conviennent expressément que la Convention de Viennes sur la vente internationale de marchandises en date du 11 avril 1980 n’est pas applicable au Contrat.",
    "La responsabilité du Prestataire est limitée aux seuls dommages directs et résultant d'un défaut des Services ou de la violation du Contrat, même si le défaut en question était prévisible au moment de la Commande.": "La responsabilité du Prestataire est limitée aux seuls dommages directs et résultant d'un défaut des Services ou de la violation du Contrat, même si le défaut en question était prévisible au moment de la Commande.",
    "La réalisation des Services confiés au Prestataire dépendant directement du respect par le Client de ses propres obligations, les Parties reconnaissent expressément que les délais de livraison visés au devis sont donnés à titre purement indicatif et sans garantie.": "La réalisation des Services confiés au Prestataire dépendant directement du respect par le Client de ses propres obligations, les Parties reconnaissent expressément que les délais de livraison visés au devis sont donnés à titre purement indicatif et sans garantie.",
    "La vidéo est en cours d'envoi sur Facebook. Vous recevrez une notification Facebook dès qu'elle sera prête.": "La vidéo est en cours d'envoi sur Facebook. Vous recevrez une notification Facebook dès qu'elle sera prête.",
    "La vidéo est en cours d'envoi sur Instagram. Vous recevrez une notification Instagram dès qu'elle sera prête.": "La vidéo est en cours d'envoi sur Instagram. Vous recevrez une notification Instagram dès qu'elle sera prête.",
    "La vidéo est en cours d'envoi sur Tiktok. Vous recevrez une notification Tiktok dès qu'elle sera prête.": "La vidéo est en cours d'envoi sur Tiktok. Vous recevrez une notification Tiktok dès qu'elle sera prête.",
    "Langue du Contrat": "Langue du Contrat",
    "Langue mise à jour avec succès.": "Langue mise à jour avec succès.",
    "Langues": "Langues",
    "Le Client déclare expressément avoir reçu du Prestataire toutes les informations et tous les conseils nécessaires à la réalisation des Services et renonce à rechercher la responsabilité du Prestataire de ce fait.": "Le Client déclare expressément avoir reçu du Prestataire toutes les informations et tous les conseils nécessaires à la réalisation des Services et renonce à rechercher la responsabilité du Prestataire de ce fait.",
    "Le Client s'engage à fournir des informations justes et sincères et s'engage aussi à prévenir le Prestataire de tout changement concernant les informations, données, documentations fournies.": "Le Client s'engage à fournir des informations justes et sincères et s'engage aussi à prévenir le Prestataire de tout changement concernant les informations, données, documentations fournies.",
    "Le Client sera seul responsable des éventuels dysfonctionnements qui pourraient résulter d'informations erronées. Le Client doit maintenir une adresse e-mail et une adresse postale valides.": "Le Client sera seul responsable des éventuels dysfonctionnements qui pourraient résulter d'informations erronées. Le Client doit maintenir une adresse e-mail et une adresse postale valides.",
    "Le Client sera également débiteur de plein droit d’une indemnité forfaitaire minimum de recouvrement de quarante (40) euros des sommes dues par le Client au Prestataire.": "Le Client sera également débiteur de plein droit d’une indemnité forfaitaire minimum de recouvrement de quarante (40) euros des sommes dues par le Client au Prestataire.",
    "Le Client s’engage à fournir au Prestataire sous forme exploitable l’ensemble des documents nécessaires à la réalisation des Services confiés au Prestataire.": "Le Client s’engage à fournir au Prestataire sous forme exploitable l’ensemble des documents nécessaires à la réalisation des Services confiés au Prestataire.",
    "Le Client s’engage à obtenir de tous tiers, si besoin est, le droit de concéder au Prestataire les droits d’utilisation des logiciels, données et équipements appartenant à ces tiers pour les besoins de la fourniture des Services.": "Le Client s’engage à obtenir de tous tiers, si besoin est, le droit de concéder au Prestataire les droits d’utilisation des logiciels, données et équipements appartenant à ces tiers pour les besoins de la fourniture des Services.",
    "Le Contrat est rédigé en langue française. Une traduction en langue étrangère peut être fournie à titre d’information. En cas de contradiction, seule la version française fera foi entre les Parties.": "Le Contrat est rédigé en langue française. Une traduction en langue étrangère peut être fournie à titre d’information. En cas de contradiction, seule la version française fera foi entre les Parties.",
    "Le Contrat se substitue à tout autre document antérieur, à tout autre accord écrit ou verbal en relation avec le même objet, à l’exception du devis, de la Commande et prévaut sur toute disposition contraire qui pourrait être contenue dans les documents émanant du Client.": "Le Contrat se substitue à tout autre document antérieur, à tout autre accord écrit ou verbal en relation avec le même objet, à l’exception du devis, de la Commande et prévaut sur toute disposition contraire qui pourrait être contenue dans les documents émanant du Client.",
    "Le Prestataire a la faculté de sous-traiter tout ou partie de la réalisation des Services à des sous-traitants. Dans ce cas, le Prestataire demeure responsable de la réalisation des Services vis-à-vis du Client.": "Le Prestataire a la faculté de sous-traiter tout ou partie de la réalisation des Services à des sous-traitants. Dans ce cas, le Prestataire demeure responsable de la réalisation des Services vis-à-vis du Client.",
    "Le Prestataire agissant au nom et pour le compte du Client dans le traitement des données personnelles qui lui sont communiquées par le Client, il dispose de la qualité de sous-traitant.": "Le Prestataire agissant au nom et pour le compte du Client dans le traitement des données personnelles qui lui sont communiquées par le Client, il dispose de la qualité de sous-traitant.",
    "Le Prestataire concède au Client, le cas échéant et dans la limite strictement nécessaire à l’exécution des Services, à titre personnel, non exclusif et non-transférable, le droit d'utiliser lesdits Droits de Propriété Intellectuelle pour la durée de réalisation des Services.": "Le Prestataire concède au Client, le cas échéant et dans la limite strictement nécessaire à l’exécution des Services, à titre personnel, non exclusif et non-transférable, le droit d'utiliser lesdits Droits de Propriété Intellectuelle pour la durée de réalisation des Services.",
    "Le Prestataire déclare être immatriculé au RCS, ainsi qu’auprès de l’URSSAF et que ses immatriculations couvrent expressément toutes ses activités pour l’exécution des Services définis dans le devis et/ou la Commande.": "Le Prestataire déclare être immatriculé au RCS, ainsi qu’auprès de l’URSSAF et que ses immatriculations couvrent expressément toutes ses activités pour l’exécution des Services définis dans le devis et/ou la Commande.",
    "Le Prestataire est quant à lui autorisé à utiliser la dénomination sociale/la marque du Client dans le cadre de ses activités à des fins de promotion commerciale.": "Le Prestataire est quant à lui autorisé à utiliser la dénomination sociale/la marque du Client dans le cadre de ses activités à des fins de promotion commerciale.",
    "Le Prestataire est une entreprise spécialisée notamment dans Génération automatique de vidéo basée sur des informations fournies statiques (textes, images).": "Le Prestataire est une entreprise spécialisée notamment dans Génération automatique de vidéo basée sur des informations fournies statiques (textes, images).",
    "Le Prestataire garantit le Client contre toute action, réclamation, revendication ou opposition de la part de toute personne invoquant un droit de propriété intellectuelle auxquels la fourniture des Services aurait porté atteinte, sous réserve que le Client informe le Prestataire, dès qu’il en a connaissance, de toute demande, réclamation ou instance présentée ou engagée pour un tel motif, par voie judiciaire ou extrajudiciaire. Le Client s’engage à apporter au Prestataire, tous les documents et renseignements en sa possession ainsi que toute l’assistance requise qui pourraient être nécessaires à sa défense.": "Le Prestataire garantit le Client contre toute action, réclamation, revendication ou opposition de la part de toute personne invoquant un droit de propriété intellectuelle auxquels la fourniture des Services aurait porté atteinte, sous réserve que le Client informe le Prestataire, dès qu’il en a connaissance, de toute demande, réclamation ou instance présentée ou engagée pour un tel motif, par voie judiciaire ou extrajudiciaire. Le Client s’engage à apporter au Prestataire, tous les documents et renseignements en sa possession ainsi que toute l’assistance requise qui pourraient être nécessaires à sa défense.",
    "Le Prestataire garantit que les Services sont fournis de manière substantiellement conforme à la Commande.": "Le Prestataire garantit que les Services sont fournis de manière substantiellement conforme à la Commande.",
    "Le Prestataire garantit que les créations sont juridiquement disponibles et ne sont pas grevées de droit des tiers pour les utilisations prévues au titre du Contrat.": "Le Prestataire garantit que les créations sont juridiquement disponibles et ne sont pas grevées de droit des tiers pour les utilisations prévues au titre du Contrat.",
    "Le Prestataire n'aura aucune obligation d’indemnisation ou autre obligation au titre d’une action en contrefaçon ayant pour origine (a) une utilisation des Services autrement que conformément au Contrat, (b) une combinaison des Services avec d’autres services ou matériels non fournis par le Prestataire.": "Le Prestataire n'aura aucune obligation d’indemnisation ou autre obligation au titre d’une action en contrefaçon ayant pour origine (a) une utilisation des Services autrement que conformément au Contrat, (b) une combinaison des Services avec d’autres services ou matériels non fournis par le Prestataire.",
    "Le Prestataire ne peut être tenu d’aucune garantie notamment lorsque le Client a modifié ou fait modifier les Services ou a utilisé d’autres services que les Services fournis par le Prestataire, sans son accord préalable et écrit ou lorsque le Client ou des tiers sont intervenus sur les éléments des Services sans l’accord préalable du Prestataire.": "Le Prestataire ne peut être tenu d’aucune garantie notamment lorsque le Client a modifié ou fait modifier les Services ou a utilisé d’autres services que les Services fournis par le Prestataire, sans son accord préalable et écrit ou lorsque le Client ou des tiers sont intervenus sur les éléments des Services sans l’accord préalable du Prestataire.",
    "Le Prestataire peut décider de céder ou transférer les droits ou obligations que lui confère le présent Contrat sous réserve que le Client bénéficie des Services dans les mêmes conditions.": "Le Prestataire peut décider de céder ou transférer les droits ou obligations que lui confère le présent Contrat sous réserve que le Client bénéficie des Services dans les mêmes conditions.",
    "Le Prestataire peut mettre fin aux Services fournis au Client en cas de manquement par le Client à ses obligations au titre du Contrat, non réparé dans un délai de quinze (15) jours à compter de la notification par le Prestataire de ce manquement par lettre recommandée avec accusé de réception, indépendamment de la possibilité pour le Prestataire de demander le paiement de dommages et intérêts.": "Le Prestataire peut mettre fin aux Services fournis au Client en cas de manquement par le Client à ses obligations au titre du Contrat, non réparé dans un délai de quinze (15) jours à compter de la notification par le Prestataire de ce manquement par lettre recommandée avec accusé de réception, indépendamment de la possibilité pour le Prestataire de demander le paiement de dommages et intérêts.",
    "Le Prestataire peut également mettre fin au Contrat en cas de non paiement de la ou des facture(s) non acquittée(s) par le Client.": "Le Prestataire peut également mettre fin au Contrat en cas de non paiement de la ou des facture(s) non acquittée(s) par le Client.",
    "Le Prestataire se réserve le droit de modifier ces CGV, les Services et les tarifs à tout moment et sans préavis. Ces modifications n'auront aucune incidence sur les Commandes en cours.": "Le Prestataire se réserve le droit de modifier ces CGV, les Services et les tarifs à tout moment et sans préavis. Ces modifications n'auront aucune incidence sur les Commandes en cours.",
    "Le Prestataire se réserve le droit de modifier les présentes CGV et d’en notifier le Client. Si une modification substantielle des termes des CGV n’est pas acceptable par le Client, celui-ci dispose d’un délai de quinze (15) jours à compter de la notification par le Prestataire des modifications pour en informer le Prestataire. En cas de désaccord des Parties sur ces modifications, les Parties pourront résilier le Contrat.": "Le Prestataire se réserve le droit de modifier les présentes CGV et d’en notifier le Client. Si une modification substantielle des termes des CGV n’est pas acceptable par le Client, celui-ci dispose d’un délai de quinze (15) jours à compter de la notification par le Prestataire des modifications pour en informer le Prestataire. En cas de désaccord des Parties sur ces modifications, les Parties pourront résilier le Contrat.",
    "Le Prestataire se réserve le droit de ne pas accepter une Commande de la part du Client lorsque le Prestataire a déjà rencontré des problèmes de paiement (non-paiement ou retard de paiement) avec le Client pour une ou plusieurs Commande(s) précédente(s).": "Le Prestataire se réserve le droit de ne pas accepter une Commande de la part du Client lorsque le Prestataire a déjà rencontré des problèmes de paiement (non-paiement ou retard de paiement) avec le Client pour une ou plusieurs Commande(s) précédente(s).",
    "Le Prestataire se réserve également le droit de mentionner les réalisations effectuées pour le Client sur ses documents de communication externe, de publicité (site internet, portfolio, etc.) et lors de démarchages de prospection commerciale.": "Le Prestataire se réserve également le droit de mentionner les réalisations effectuées pour le Client sur ses documents de communication externe, de publicité (site internet, portfolio, etc.) et lors de démarchages de prospection commerciale.",
    "Le Prestataire s’engage à informer de manière régulière le Client de l’avancée de la réalisation du projet et ce, notamment, au travers de validations soumises au Client dans le rétro-planning tel que prévu dans le devis.": "Le Prestataire s’engage à informer de manière régulière le Client de l’avancée de la réalisation du projet et ce, notamment, au travers de validations soumises au Client dans le rétro-planning tel que prévu dans le devis.",
    "Le changement de template a échoué.": "Le changement de template a échoué.",
    "Le code promo est expiré.": "Le code promo est expiré.",
    "Le code promo n'existe pas.": "Le code promo n'existe pas.",
    "Le droit de reproduction comprend notamment, et de manière non exhaustive": "Le droit de reproduction comprend notamment, et de manière non exhaustive",
    "Le droit de représentation comprend notamment, et de manière non exhaustive": "Le droit de représentation comprend notamment, et de manière non exhaustive",
    "Le fait que le Prestataire ne se prévale pas à un moment donné d'une quelconque condition des présentes CGV ne peut être interprété comme valant renonciation à se prévaloir ultérieurement de quelconque desdites conditions.": "Le fait que le Prestataire ne se prévale pas à un moment donné d'une quelconque condition des présentes CGV ne peut être interprété comme valant renonciation à se prévaloir ultérieurement de quelconque desdites conditions.",
    "Le lien a été copié": "Le lien a été copié",
    "Le message d'erreur": "Le message d'erreur",
    "Le paiement du solde des Services doit être effectué dans un délai de trente (30) jours à compter de la date de la facture émise par le Prestataire et pourra être effectué sous forme de chèque, de virement, ou de prélèvement automatique à l’ordre du Prestataire.": "Le paiement du solde des Services doit être effectué dans un délai de trente (30) jours à compter de la date de la facture émise par le Prestataire et pourra être effectué sous forme de chèque, de virement, ou de prélèvement automatique à l’ordre du Prestataire.",
    "Le projet": "Le projet",
    "Le template n'est pas disponible dans la langue sélectionnée.": "Le template n'est pas disponible dans la langue sélectionnée.",
    "Le terme \"Client\" désigne toute personne morale ou physique, ayant requis les compétences du Prestataire.": "Le terme \"Client\" désigne toute personne morale ou physique, ayant requis les compétences du Prestataire.",
    "Le terme \"Tiers\" désigne toute personne physique ou morale non partie à ces Conditions Générales de Vente (CGV).": "Le terme \"Tiers\" désigne toute personne physique ou morale non partie à ces Conditions Générales de Vente (CGV).",
    "Le terme le \"Prestataire\" désigne la société SYNAPSE, Société par action simplifiée au capital de 200 000 euros, immatriculée au Registre du Commerce et des Sociétés de Grenoble sous le numéro RCS 452156102, et dont le siège social est situé 19 chemin des prés, 38240 Meylan.": "Le terme le \"Prestataire\" désigne la société SYNAPSE, Société par action simplifiée au capital de 200 000 euros, immatriculée au Registre du Commerce et des Sociétés de Grenoble sous le numéro RCS 452156102, et dont le siège social est situé 19 chemin des prés, 38240 Meylan.",
    "Le texte descriptif de votre clap (facultatif)": "Le texte descriptif de votre clap (facultatif)",
    "Le texte descriptif de votre vidéo (facultatif)": "Le texte descriptif de votre vidéo (facultatif)",
    "Le versement de l’acompte conditionnera la mise en œuvre des Services. Le paiement de l’acompte pourra être effectué sous forme de chèque, de virement, ou de prélèvement automatique à l’ordre du Prestataire.": "Le versement de l’acompte conditionnera la mise en œuvre des Services. Le paiement de l’acompte pourra être effectué sous forme de chèque, de virement, ou de prélèvement automatique à l’ordre du Prestataire.",
    "Les CGV s'appliquent aux seuls professionnels à l’exclusion des consommateurs. A ce titre, le Client reconnaît avoir la qualité de professionnel, conformément aux dispositions du Code de la consommation applicables.": "Les CGV s'appliquent aux seuls professionnels à l’exclusion des consommateurs. A ce titre, le Client reconnaît avoir la qualité de professionnel, conformément aux dispositions du Code de la consommation applicables.",
    "Les CGV sont systématiquement adressées ou remises à chaque Client avant la passation de toute commande (la Commande). En conséquence, le fait de passer Commande implique l'adhésion entière et sans réserve du Client à ces CGV, à l'exclusion de tous autres documents en sa possession tels que prospectus, catalogues ou plaquettes publicitaires émis par le Prestataire, lesquels n'auront qu'une valeur indicative et non contractuelle.": "Les CGV sont systématiquement adressées ou remises à chaque Client avant la passation de toute commande (la Commande). En conséquence, le fait de passer Commande implique l'adhésion entière et sans réserve du Client à ces CGV, à l'exclusion de tous autres documents en sa possession tels que prospectus, catalogues ou plaquettes publicitaires émis par le Prestataire, lesquels n'auront qu'une valeur indicative et non contractuelle.",
    "Les Parties": "Les Parties",
    "Les Parties ne peuvent être considérées comme responsables ou ayant failli à leurs obligations contractuelles, lorsque le défaut d'exécution des obligations respectives a pour origine la force majeure telle que définie par la jurisprudence des tribunaux français. Le Contrat entre les parties est suspendu jusqu'à l'extinction des causes ayant engendrées la force majeure. La force majeure prend en compte des faits ou circonstances irrésistibles, extérieurs aux parties, imprévisibles et indépendants de la volonté des parties, malgré tous les efforts raisonnablement possibles pour les empêcher.": "Les Parties ne peuvent être considérées comme responsables ou ayant failli à leurs obligations contractuelles, lorsque le défaut d'exécution des obligations respectives a pour origine la force majeure telle que définie par la jurisprudence des tribunaux français. Le Contrat entre les parties est suspendu jusqu'à l'extinction des causes ayant engendrées la force majeure. La force majeure prend en compte des faits ou circonstances irrésistibles, extérieurs aux parties, imprévisibles et indépendants de la volonté des parties, malgré tous les efforts raisonnablement possibles pour les empêcher.",
    "Les Services peuvent être fournis soit à distance, soit dans les locaux du Client, au choix du Prestataire et du Client.": "Les Services peuvent être fournis soit à distance, soit dans les locaux du Client, au choix du Prestataire et du Client.",
    "Les conditions tarifaires du Prestataire relatives à la fourniture des Services sont prévues dans le devis du Prestataire.": "Les conditions tarifaires du Prestataire relatives à la fourniture des Services sont prévues dans le devis du Prestataire.",
    "Les logiciels, données, documentations, procédés, méthodologies, technologies et documents appartenant au Prestataire (ci-après Droits de Propriété Intellectuelle) utilisés dans le cadre de la mise en œuvre des Services restent la propriété exclusive du Prestataire.": "Les logiciels, données, documentations, procédés, méthodologies, technologies et documents appartenant au Prestataire (ci-après Droits de Propriété Intellectuelle) utilisés dans le cadre de la mise en œuvre des Services restent la propriété exclusive du Prestataire.",
    "Les mots de passe ne correspondent pas": "Les mots de passe ne correspondent pas",
    "Les mots de passe ne correspondent pas.": "Les mots de passe ne correspondent pas.",
    "Les paramètres ont été enregistrés.": "Les paramètres ont été enregistrés.",
    "Les prix des Services n’incluent pas les éventuels frais de déplacement ou d’hébergement qui pourraient être facturés en sus par le Prestataire au Client selon les modalités indiquées dans le devis.": "Les prix des Services n’incluent pas les éventuels frais de déplacement ou d’hébergement qui pourraient être facturés en sus par le Prestataire au Client selon les modalités indiquées dans le devis.",
    "Les prix des Services sont exprimés et payables en Euros et sont exprimés hors taxe sur la valeur ajoutée et hors toute autre taxe, le Client étant responsable du paiement desdites taxes.": "Les prix des Services sont exprimés et payables en Euros et sont exprimés hors taxe sur la valeur ajoutée et hors toute autre taxe, le Client étant responsable du paiement desdites taxes.",
    "Les prix sont donnés à titre indicatif et sont donc susceptibles de variation. Le prix facturé est celui prévu dans la Commande validée par le Prestataire.": "Les prix sont donnés à titre indicatif et sont donc susceptibles de variation. Le prix facturé est celui prévu dans la Commande validée par le Prestataire.",
    "Les présentes CGV ont pour objet de définir les droits et obligations des Parties lors de la réalisation de prestations de services réalisées par le Prestataire pour le Client dans le cadre de ses activités.": "Les présentes CGV ont pour objet de définir les droits et obligations des Parties lors de la réalisation de prestations de services réalisées par le Prestataire pour le Client dans le cadre de ses activités.",
    "Les présentes Conditions Générales de Vente (ci-après désignées les \"CGV\") s'appliquent à tout contrat conclu entre le Prestataire et le Client, dans le cadre de la fourniture de prestations de services (les Services) telles que définies dans le devis validé par le Client.": "Les présentes Conditions Générales de Vente (ci-après désignées les \"CGV\") s'appliquent à tout contrat conclu entre le Prestataire et le Client, dans le cadre de la fourniture de prestations de services (les Services) telles que définies dans le devis validé par le Client.",
    "Les présentes Conditions Générales de Vente sont à jour au 1 janvier 2021.": "Les présentes Conditions Générales de Vente sont à jour au 1 janvier 2021.",
    "Les tarifs sont HT.": "Les tarifs sont HT.",
    "Les œuvres créées par le Prestataire pour le Client, dans le cadre de l’exécution du Contrat, demeurent la propriété entière et exclusive du Prestataire tant que les factures émises par le Prestataire ne sont pas entièrement acquittées par le Client.": "Les œuvres créées par le Prestataire pour le Client, dans le cadre de l’exécution du Contrat, demeurent la propriété entière et exclusive du Prestataire tant que les factures émises par le Prestataire ne sont pas entièrement acquittées par le Client.",
    "Lier un compte": "Lier un compte",
    "Logo de l'agence": "Logo de l'agence",
    "Légende pour": "Légende pour",
    "L’acceptation du devis et de la Commande du Client qui en découle, doit s’accompagner du paiement d’un acompte tel qu’indiqué dans le devis.": "L’acceptation du devis et de la Commande du Client qui en découle, doit s’accompagner du paiement d’un acompte tel qu’indiqué dans le devis.",
    "L’intelligence artificielle est en bêta chez clap.video": "L’intelligence artificielle est en bêta chez clap.video",
    "Ma vidéo": "Ma vidéo",
    "Maison de campagne 156m²": "Maison de campagne 156m²",
    "Marques et dénominations sociales": "Marques et dénominations sociales",
    "Mensuel": "Mensuel",
    "Merci de réessayer en cliquant": "Merci de réessayer en cliquant",
    "Merci pour votre abonnement ! Bienvenue sur clap.video premium.": "Merci pour votre abonnement ! Bienvenue sur clap.video premium.",
    "Mes informations": "Mes informations",
    "Mes vidéos": "Mes vidéos",
    "Mise à jour d'une vidéo": "Mise à jour d'une vidéo",
    "Modalités d’exécution des Services et livraison des livrables": "Modalités d’exécution des Services et livraison des livrables",
    "Modifications des CGV – cession du Contrat": "Modifications des CGV – cession du Contrat",
    "Modifier": "Modifier",
    "Mon compte": "Mon compte",
    "Mot de passe": "Mot de passe",
    "Mot de passe actuel": "Mot de passe actuel",
    "Mot de passe oublié": "Mot de passe oublié",
    "Moyen de paiement": "Moyen de paiement",
    "Musique": "Musique",
    "Médias": "Médias",
    "NOUVEAU": "NOUVEAU",
    "Nom": "Nom",
    "Nom de l'entreprise": "Nom de l'entreprise",
    "Non sollicitation": "Non sollicitation",
    "Notification": "Notification",
    "Notifications": "Notifications",
    "Nous avons trouvé des photos provenant votre annonce web, cliquez sur celle que vous souhaitez ajouter à votre vidéo": "Nous avons trouvé des photos provenant votre annonce web, cliquez sur celle que vous souhaitez ajouter à votre vidéo",
    "Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles. Nous utilisons un cryptage pour protéger les informations sensibles transmises en ligne. Nous protégeons également vos informations hors ligne. Seuls les employés qui ont besoin d’effectuer un travail spécifique (par exemple, la facturation ou le service à la clientèle) ont accès aux informations personnelles identifiables. Les ordinateurs et serveurs utilisés pour stocker des informations personnelles identifiables sont conservés dans un environnement sécurisé.": "Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles. Nous utilisons un cryptage pour protéger les informations sensibles transmises en ligne. Nous protégeons également vos informations hors ligne. Seuls les employés qui ont besoin d’effectuer un travail spécifique (par exemple, la facturation ou le service à la clientèle) ont accès aux informations personnelles identifiables. Les ordinateurs et serveurs utilisés pour stocker des informations personnelles identifiables sont conservés dans un environnement sécurisé.",
    "Nous ne vendons, n’échangeons et ne transférons pas vos informations personnelles identifiables à des tiers. Cela ne comprend pas les tierce parties de confiance qui nous aident à exploiter notre site Web ou à mener nos affaires, tant que ces parties conviennent de garder ces informations confidentielles.": "Nous ne vendons, n’échangeons et ne transférons pas vos informations personnelles identifiables à des tiers. Cela ne comprend pas les tierce parties de confiance qui nous aident à exploiter notre site Web ou à mener nos affaires, tant que ces parties conviennent de garder ces informations confidentielles.",
    "Nous pensons qu’il est nécessaire de partager des informations afin d’enquêter, de prévenir ou de prendre des mesures concernant des activités illégales, fraudes présumées, situations impliquant des menaces potentielles à la sécurité physique de toute personne, violations de nos conditions d’utilisation, ou quand la loi nous y contraint.": "Nous pensons qu’il est nécessaire de partager des informations afin d’enquêter, de prévenir ou de prendre des mesures concernant des activités illégales, fraudes présumées, situations impliquant des menaces potentielles à la sécurité physique de toute personne, violations de nos conditions d’utilisation, ou quand la loi nous y contraint.",
    "Nous recueillons des informations lorsque vous visitez notre site, vous inscrivez sur notre site et lorsque vous vous connectez à votre compte. Les informations recueillies incluent votre prénom, votre nom, votre entreprise, votre adresse e-mail et votre numéro de téléphone (optionnel). En outre, nous recevons et enregistrons automatiquement des informations à partir de votre ordinateur et navigateur, y compris votre adresse IP, vos logiciels, votre matériel et la page que vous demandez.": "Nous recueillons des informations lorsque vous visitez notre site, vous inscrivez sur notre site et lorsque vous vous connectez à votre compte. Les informations recueillies incluent votre prénom, votre nom, votre entreprise, votre adresse e-mail et votre numéro de téléphone (optionnel). En outre, nous recevons et enregistrons automatiquement des informations à partir de votre ordinateur et navigateur, y compris votre adresse IP, vos logiciels, votre matériel et la page que vous demandez.",
    "Nous récupérons vos biens": "Nous récupérons vos biens",
    "Nous utilisons des cookies. Nos cookies améliorent l’accès à notre site et identifient les visiteurs réguliers. Les cookies sont également conservés par nos partenaires Google Analytics et Hubspot afin d'améliorer l’expérience utilisateur grâce au suivi et au ciblage de ses intérêts. Cependant, cette utilisation des cookies n’est en aucune façon liée à des informations personnelles identifiables sur notre site.": "Nous utilisons des cookies. Nos cookies améliorent l’accès à notre site et identifient les visiteurs réguliers. Les cookies sont également conservés par nos partenaires Google Analytics et Hubspot afin d'améliorer l’expérience utilisateur grâce au suivi et au ciblage de ses intérêts. Cependant, cette utilisation des cookies n’est en aucune façon liée à des informations personnelles identifiables sur notre site.",
    "Nous utilisons l’adresse e-mail que vous fournissez pour vous envoyer des informations et mises à jour relatives à votre commande, des nouvelles de l’entreprise de façon occasionnelle, des informations sur des produits liés, etc. Si à n’importe quel moment vous souhaitez vous désinscrire et ne plus recevoir d’e-mails, des instructions de désabonnement détaillées sont incluses en bas de chaque e-mail.": "Nous utilisons l’adresse e-mail que vous fournissez pour vous envoyer des informations et mises à jour relatives à votre commande, des nouvelles de l’entreprise de façon occasionnelle, des informations sur des produits liés, etc. Si à n’importe quel moment vous souhaitez vous désinscrire et ne plus recevoir d’e-mails, des instructions de désabonnement détaillées sont incluses en bas de chaque e-mail.",
    "Nouveau": "Nouveau",
    "Nouveau mot de passe": "Nouveau mot de passe",
    "Nouvelle vidéo": "Nouvelle vidéo",
    "N° de téléphone pro": "N° de téléphone pro",
    "Obligations des Parties": "Obligations des Parties",
    "Obligations du Client": "Obligations du Client",
    "Obligations du Prestataire": "Obligations du Prestataire",
    "Obtenir un lien de téléchargement": "Obtenir un lien de téléchargement",
    "On s'occupe de tout...": "On s'occupe de tout...",
    "Oui, supprimer": "Oui, supprimer",
    "Oups !": "Oups !",
    "Paiement": "Paiement",
    "Par ailleurs, le Prestataire ne peut être tenu pour responsable de l’inexécution du Contrat en cas de force majeure telle que définie à l’article 15, et en cas de dommages du fait d’un tiers ou imputables à une mauvaise utilisation ou une utilisation non-conforme par le Client des Services, en violation des prescriptions du Prestataire ou des règles de l’art.": "Par ailleurs, le Prestataire ne peut être tenu pour responsable de l’inexécution du Contrat en cas de force majeure telle que définie à l’article 15, et en cas de dommages du fait d’un tiers ou imputables à une mauvaise utilisation ou une utilisation non-conforme par le Client des Services, en violation des prescriptions du Prestataire ou des règles de l’art.",
    "Par défaut": "Par défaut",
    "Paramètres": "Paramètres",
    "Partage automatique": "Partage automatique",
    "Partager sur les réseaux sociaux": "Partager sur les réseaux sociaux",
    "Passer Premium": "Passer Premium",
    "Passer Premium !": "Passer Premium !",
    "Paysage": "Paysage",
    "Personnalisation des couleurs": "Personnalisation des couleurs",
    "Personnaliser": "Personnaliser",
    "Personnaliser votre expérience": "Personnaliser votre expérience",
    "Personnelles": "Personnelles",
    "Politique de confidentialité": "Politique de confidentialité",
    "Portrait": "Portrait",
    "Pour l'intégrer à votre logiciel": "Pour l'intégrer à votre logiciel",
    "Pour partager cette page": "Pour partager cette page",
    "Pour permettre au Prestataire de réaliser sa mission, le Client s’engage à": "Pour permettre au Prestataire de réaliser sa mission, le Client s’engage à",
    "Pour récupérer vos photos et pré-remplir votre vidéo": "Pour récupérer vos photos et pré-remplir votre vidéo",
    "Premium": "Premium",
    "Prix": "Prix",
    "Prix de l'annonce": "Prix de l'annonce",
    "Prochain prélèvement le": "Prochain prélèvement le",
    "Professionnelles": "Professionnelles",
    "Profil": "Profil",
    "Projet supprimé.": "Projet supprimé.",
    "Propriété intellectuelle": "Propriété intellectuelle",
    "Prénom": "Prénom",
    "Publication en cours sur": "Publication en cours sur",
    "Publier": "Publier",
    "Publier votre clap sur vos réseaux sociaux": "Publier votre clap sur vos réseaux sociaux",
    "Publier votre vidéo sur vos réseaux sociaux": "Publier votre vidéo sur vos réseaux sociaux",
    "Pénalités de retard": "Pénalités de retard",
    "Quand un clap est généré": "Quand un clap est généré",
    "Recevoir un mail quand une vidéo est générée": "Recevoir un mail quand une vidéo est générée",
    "Recherche sur le web": "Recherche sur le web",
    "Rechercher mon annonce": "Rechercher mon annonce",
    "Rechercher un bien par titre, référence ou prix.": "Rechercher un bien par titre, référence ou prix.",
    "Rechercher un clap": "Rechercher un clap",
    "Rechercher un clap...": "Rechercher un clap...",
    "Rechercher une annonce": "Rechercher une annonce",
    "Rechercher votre annonce sur le web en un coup de baguette": "Rechercher votre annonce sur le web en un coup de baguette",
    "Refus": "Refus",
    "Remettre au Prestataire le devis (daté, signé et tamponné).": "Remettre au Prestataire le devis (daté, signé et tamponné).",
    "Rendre publique et visible votre adresse postale professionnelle dans chaque vidéo que vous créez.": "Rendre publique et visible votre adresse postale professionnelle dans chaque vidéo que vous créez.",
    "Responsabilités": "Responsabilités",
    "Retapez votre mot de passe": "Retapez votre mot de passe",
    "Retapez votre nouveau mot de passe": "Retapez votre nouveau mot de passe",
    "Retour": "Retour",
    "Retour aux connecteurs": "Retour aux connecteurs",
    "Retour à l'accueil": "Retour à l'accueil",
    "Retour à mes vidéos": "Retour à mes vidéos",
    "Retourner à la page de connexion": "Retourner à la page de connexion",
    "Réactiver mon abonnement et annuler ma résiliation": "Réactiver mon abonnement et annuler ma résiliation",
    "Référence": "Référence",
    "Régler dans les délais prédéfinis dans le devis et dans les présentes CGV, les sommes dues au Prestataire.": "Régler dans les délais prédéfinis dans le devis et dans les présentes CGV, les sommes dues au Prestataire.",
    "Réinitialisation du mot de passe": "Réinitialisation du mot de passe",
    "Réseaux sociaux": "Réseaux sociaux",
    "Résiliation": "Résiliation",
    "Résilier": "Résilier",
    "S'inscrire": "S'inscrire",
    "Sauf convention contraire dans le devis, le Contrat est réputé formé et prend effet entre les Parties à la date de réception par le Prestataire de la Commande du Client soit par email, soit par courrier postal à l’adresse du Prestataire.": "Sauf convention contraire dans le devis, le Contrat est réputé formé et prend effet entre les Parties à la date de réception par le Prestataire de la Commande du Client soit par email, soit par courrier postal à l’adresse du Prestataire.",
    "Sauf disposition légale contraire, toute autre garantie, expresse ou implicite est exclue.": "Sauf disposition légale contraire, toute autre garantie, expresse ou implicite est exclue.",
    "Se conformer strictement aux préconisations techniques et aux suggestions artistiques faites par le Prestataire.": "Se conformer strictement aux préconisations techniques et aux suggestions artistiques faites par le Prestataire.",
    "Se souvenir de moi": "Se souvenir de moi",
    "Si le problème persiste, n'hésitez pas à nous contacter par mail à l'adresse suivante": "Si le problème persiste, n'hésitez pas à nous contacter par mail à l'adresse suivante",
    "Si l’une quelconque disposition de ces CGV ou son application à toute personne ou circonstance est jugée nulle, cette nullité ne concernera pas les autres dispositions ou applications de ces CGV, qui resteront en vigueur, séparément de la disposition jugée nulle. A cette fin, les dispositions de ces CGV sont déclarées autonomes.": "Si l’une quelconque disposition de ces CGV ou son application à toute personne ou circonstance est jugée nulle, cette nullité ne concernera pas les autres dispositions ou applications de ces CGV, qui resteront en vigueur, séparément de la disposition jugée nulle. A cette fin, les dispositions de ces CGV sont déclarées autonomes.",
    "Si vous pensez que c'est une erreur, n'hésitez pas à nous contacter par mail à l'adresse suivante": "Si vous pensez que c'est une erreur, n'hésitez pas à nous contacter par mail à l'adresse suivante",
    "Site internet": "Site internet",
    "Sont à facturer en sus": "Sont à facturer en sus",
    "Souhaitez vous vraiment supprimer la vidéo": "Souhaitez vous vraiment supprimer la vidéo",
    "Souhaitez vous vraiment supprimer le clap": "Souhaitez vous vraiment supprimer le clap",
    "Sous-traitance": "Sous-traitance",
    "Suite à votre demande, votre abonnement Premium ne sera pas renouvelé et prendra fin le": "Suite à votre demande, votre abonnement Premium ne sera pas renouvelé et prendra fin le",
    "Suppression du logo clap.video": "Suppression du logo clap.video",
    "Suppression du logo clap.video en fin de vidéo": "Suppression du logo clap.video en fin de vidéo",
    "Supprimer le clap": "Supprimer le clap",
    "Sélectionner": "Sélectionner",
    "Sélectionner l'annonce à importer": "Sélectionner l'annonce à importer",
    "Sélectionner la couleur par défaut que vous souhaitez utiliser pour vos vidéos.": "Sélectionner la couleur par défaut que vous souhaitez utiliser pour vos vidéos.",
    "Sélectionnez une image provenant de l’annonce web": "Sélectionnez une image provenant de l’annonce web",
    "Sélectionnez votre page Facebook": "Sélectionnez votre page Facebook",
    "Sélectionné": "Sélectionné",
    "Sélectionnée": "Sélectionnée",
    "S’assurer de la mise à disposition de tous les moyens nécessaires pour permettre au Prestataire de réaliser les Services dans ses locaux et/ou à distance.": "S’assurer de la mise à disposition de tous les moyens nécessaires pour permettre au Prestataire de réaliser les Services dans ses locaux et/ou à distance.",
    "Tapez un nouveau mot de passe": "Tapez un nouveau mot de passe",
    "Tapez votre mot de passe actuel": "Tapez votre mot de passe actuel",
    "Template": "Template",
    "Template sélectionné": "Template sélectionné",
    "Terminer": "Terminer",
    "Terminer et générer la vidéo": "Terminer et générer la vidéo",
    "Terminé": "Terminé",
    "Titre de la vidéo": "Titre de la vidéo",
    "Titre du clap": "Titre du clap",
    "Toute les informations que nous recueillons auprès de vous peuvent être utilisées pour": "Toute les informations que nous recueillons auprès de vous peuvent être utilisées pour",
    "Toute modification ultérieure ou demande complémentaire demandée par le Client fera l’objet d’une facturation supplémentaire.": "Toute modification ultérieure ou demande complémentaire demandée par le Client fera l’objet d’une facturation supplémentaire.",
    "Toute notification devra être faite par écrit et être soit remise en mains propres, soit adressée par lettre recommandée avec accusé de réception, soit faite par acte extra judiciaire à l’adresse indiquée dans la commande.": "Toute notification devra être faite par écrit et être soit remise en mains propres, soit adressée par lettre recommandée avec accusé de réception, soit faite par acte extra judiciaire à l’adresse indiquée dans la commande.",
    "Toute réserve concernant les CGV, avancée par le Client sera, donc, à défaut d'acceptation expresse par le Prestataire, inopposable à ce dernier, quel que soit le moment où elle aura pu être portée à sa connaissance.": "Toute réserve concernant les CGV, avancée par le Client sera, donc, à défaut d'acceptation expresse par le Prestataire, inopposable à ce dernier, quel que soit le moment où elle aura pu être portée à sa connaissance.",
    "Toute utilisation par le Client des dénominations sociales, marques et signes distincts appartenant au Prestataire est strictement prohibée sauf en cas d’accord exprès et préalable du Prestataire. En cas d’accord exprès et préalable du Prestataire, ce dernier concède alors au Client un droit strictement personnel, non exclusif, et non transférable d'utiliser ses dénominations sociales, marques et signes distincts, dans le monde entier et pour toute la durée de validité du Contrat.": "Toute utilisation par le Client des dénominations sociales, marques et signes distincts appartenant au Prestataire est strictement prohibée sauf en cas d’accord exprès et préalable du Prestataire. En cas d’accord exprès et préalable du Prestataire, ce dernier concède alors au Client un droit strictement personnel, non exclusif, et non transférable d'utiliser ses dénominations sociales, marques et signes distincts, dans le monde entier et pour toute la durée de validité du Contrat.",
    "Toutes dispositions dérogeant aux présentes CGV devront résulter d’un accord exprès des Parties, reflété dans les Commandes confirmées par le Prestataire ou tout autre document faisant foi de l’accord des deux Parties.": "Toutes dispositions dérogeant aux présentes CGV devront résulter d’un accord exprès des Parties, reflété dans les Commandes confirmées par le Prestataire ou tout autre document faisant foi de l’accord des deux Parties.",
    "Travail dissimulé": "Travail dissimulé",
    "Type": "Type",
    "Télécharger": "Télécharger",
    "Télécharger cette vidéo": "Télécharger cette vidéo",
    "Un extrait Kbis attestant de l’immatriculation au registre du commerce et des sociétés,": "Un extrait Kbis attestant de l’immatriculation au registre du commerce et des sociétés,",
    "Un problème a été rencontré lors de la création de la vidéo. Veuillez réessayer.": "Un problème a été rencontré lors de la création de la vidéo. Veuillez réessayer.",
    "Un problème a été rencontré lors de la création du clap. Veuillez réessayer.": "Un problème a été rencontré lors de la création du clap. Veuillez réessayer.",
    "Un retard sur les délais indiqués ne pourra donc donner lieu au paiement de dommages et intérêts, ni autoriser le Client à résilier le Contrat où à refuser la livraison des Services.": "Un retard sur les délais indiqués ne pourra donc donner lieu au paiement de dommages et intérêts, ni autoriser le Client à résilier le Contrat où à refuser la livraison des Services.",
    "Une attestation sur l’honneur établie par le Prestataire, certifiant que le travail est réalisé par des salariés employés régulièrement eu égard aux articles D.8222-5, D.8222-7 et D.8222-8 du Code du travail.": "Une attestation sur l’honneur établie par le Prestataire, certifiant que le travail est réalisé par des salariés employés régulièrement eu égard aux articles D.8222-5, D.8222-7 et D.8222-8 du Code du travail.",
    "Une copie de l’avis d’imposition afférent à la taxe professionnelle,": "Une copie de l’avis d’imposition afférent à la taxe professionnelle,",
    "Une erreur est survenue": "Une erreur est survenue",
    "Une erreur est survenue lors de la récupération des biens.": "Une erreur est survenue lors de la récupération des biens.",
    "Une erreur est survenue lors de la sélection de cette musique. Veuillez en choisir une autre.": "Une erreur est survenue lors de la sélection de cette musique. Veuillez en choisir une autre.",
    "Une erreur est survenue lors du chargement de votre fichier. Veuillez essayer avec un fichier plus léger.": "Une erreur est survenue lors du chargement de votre fichier. Veuillez essayer avec un fichier plus léger.",
    "Une erreur est survenue lors du lancement du rendu. Veuillez réessayer.": "Une erreur est survenue lors du lancement du rendu. Veuillez réessayer",
    "Une erreur est survenue. Merci de réessayer.": "Une erreur est survenue. Merci de réessayer.",
    "Une erreur inconnue est survenue.": "Une erreur inconnue est survenue.",
    "Une erreur inconnue s'est produite ! Contactez support@clap.video.": "Une erreur inconnue s'est produite ! Contactez support@clap.video.",
    "Valable jusqu'au": "Valable jusqu'au",
    "Valider": "Valider",
    "Veuillez accepter les conditions générales de vente.": "Veuillez accepter les conditions générales de vente.",
    "Veuillez compléter toutes les images.": "Veuillez compléter toutes les images.",
    "Veuillez entrer un code promo.": "Veuillez entrer un code promo.",
    "Veuillez entrer un email et un mot de passe.": "Veuillez entrer un email et un mot de passe.",
    "Veuillez entrer un titre valide": "Veuillez entrer un titre valide",
    "Veuillez remplir les informations bancaires.": "Veuillez remplir les informations bancaires.",
    "Veuillez remplir votre nom et prénom.": "Veuillez remplir votre nom et prénom.",
    "Veuillez utiliser la": "Veuillez utiliser la",
    "Vidéo automatique": "Vidéo automatique",
    "Vidéos": "Vidéos",
    "Vidéos illimitées": "Vidéos illimitées",
    "Ville": "Ville",
    "Voir mes autoclaps": "Voir mes autoclaps",
    "Vos clients apprécient votre travail et votre entreprise ? Faites-le savoir en créant une vidéo présentant vos meilleurs avis.": "Vos clients apprécient votre travail et votre entreprise ? Faites-le savoir en créant une vidéo présentant vos meilleurs avis.",
    "Vos modifications ont bien été enregistrées": "Vos modifications ont bien été enregistrées",
    "Votre adresse email": "Votre adresse email",
    "Votre adresse postale": "Votre adresse postale",
    "Votre clap est en file d'attente, veuillez patienter quelques instants avant le début de la génération.": "Votre clap est en file d'attente, veuillez patienter quelques instants avant le début de la génération.",
    "Votre code postale": "Votre code postale",
    "Votre couleur personnalisée": "Votre couleur personnalisée",
    "Votre demande de résiliation a bien été prise en compte.": "Votre demande de résiliation a bien été prise en compte.",
    "Votre email personnel": "Votre email personnel",
    "Votre email professionnel": "Votre email professionnel",
    "Votre nom": "Votre nom",
    "Votre nom d'entreprise": "Votre nom d'entreprise",
    "Votre nouveau mot de passe a bien été enregistré": "Votre nouveau mot de passe a bien été enregistré",
    "Votre numéro de téléphone professionnel": "Votre numéro de téléphone professionnel",
    "Votre photo de profil a bien été modifiée": "Votre photo de profil a bien été modifiée",
    "Votre photo de profil professionnelle a bien été modifiée": "Votre photo de profil professionnelle a bien été modifiée",
    "Votre prénom": "Votre prénom",
    "Votre résiliation a bien été annulée.": "Votre résiliation a bien été annulée.",
    "Votre vidéo est en cours de génération !": "Votre vidéo est en cours de génération !",
    "Votre vidéo est en file d'attente, veuillez patienter quelques instants avant le début de la génération.": "Votre vidéo est en file d'attente, veuillez patienter quelques instants avant le début de la génération.",
    "Votre ville": "Votre ville",
    "Voulez-vous vraiment réactiver votre abonnement": "Voulez-vous vraiment réactiver votre abonnement",
    "Voulez-vous vraiment résilier votre abonnement Premium ? Votre abonnement continuera jusqu'à la date de fin d'abonnement et ne sera pas renouvelé.": "Voulez-vous vraiment résilier votre abonnement Premium ? Votre abonnement continuera jusqu'à la date de fin d'abonnement et ne sera pas renouvelé.",
    "Vous avez atteint le nombre maximum de vidéos gratuites.": "Vous avez atteint le nombre maximum de vidéos gratuites.",
    "Vous avez déjà un compte": "Vous avez déjà un compte",
    "Vous contacter pour vous présenter de nouvelles fonctionnalités et offres qui pourraient vous intéresser": "Vous contacter pour vous présenter de nouvelles fonctionnalités et offres qui pourraient vous intéresser",
    "Vous devez lier Facebook": "Vous devez lier Facebook",
    "Vous donnez accès à notre service de création vidéo": "Vous donnez accès à notre service de création vidéo",
    "Vous n'avez aucune vidéo pour le moment.": "Vous n'avez aucune vidéo pour le moment.",
    "Vous n'avez pas de compte": "Vous n'avez pas de compte",
    "Vous n'avez pas lié de compte": "Vous n'avez pas lié de compte",
    "Vous possédez un abonnement": "Vous possédez un abonnement",
    "Vous pouvez envoyer cette vidéo sur votre page Facebook en vous": "Vous pouvez envoyer cette vidéo sur votre page Facebook en vous",
    "Vous pouvez quitter cette page, votre vidéo sera directement ajoutée dans votre logiciel métier.": "Vous pouvez quitter cette page, votre vidéo sera directement ajoutée dans votre logiciel métier.",
    "Vous pouvez résilier votre abonnement à tout moment": "Vous pouvez résilier votre abonnement à tout moment",
    "Vous serez de nouveau prélevé tous les": "Vous serez de nouveau prélevé tous les",
    "Vous serez prélevé automatiquement à chaque échéance.": "Vous serez prélevé automatiquement à chaque échéance.",
    "Vous êtes actuellement connecté via Topi": "Vous êtes actuellement connecté via Topi",
    "Zone de danger": "Zone de danger",
    "agency": {
      "address": "Adresse de l'agence",
      "logo": "Logo",
      "name": "Nom de l'agence",
      "photo": "photo",
      "web": "Site internet"
    },
    "agent": {
      "email": "Adresse email",
      "name": "Nom du responsable",
      "phone": "Numéro de téléphone",
      "photo": "Photo du responsable",
      "web": "Site internet"
    },
    "chambre(s)": "chambre(s)",
    "conditions générales de vente": "conditions générales de vente",
    "connectant sur clap.video": "connectant sur clap.video",
    "connus par l’une des Parties sur une base non-confidentielle avant leur divulgation par l'autre Partie": "connus par l’une des Parties sur une base non-confidentielle avant leur divulgation par l'autre Partie",
    "création manuelle": "création manuelle",
    "d'un montant de": "d'un montant de",
    "de Clap.": "de Clap.",
    "depuis le": "depuis le",
    "divulgués en vertu d'une disposition législative ou réglementaire.": "divulgués en vertu d'une disposition législative ou réglementaire.",
    "développés de façon indépendante par la Partie réceptrice qui n’a eu accès à aucune information de la Partie divulgatrice": "développés de façon indépendante par la Partie réceptrice qui n’a eu accès à aucune information de la Partie divulgatrice",
    "fournir une solution de remplacement permettant au Client de pouvoir utiliser les Services conformément à la Commande": "fournir une solution de remplacement permettant au Client de pouvoir utiliser les Services conformément à la Commande",
    "ici": "ici",
    "la diffusion des œuvres créées par tout moyen, notamment par voie hertzienne, câble-satellite ainsi que par tout réseau, et plus généralement par tout moyen de transmission de données numérisées ou non.": "la diffusion des œuvres créées par tout moyen, notamment par voie hertzienne, câble-satellite ainsi que par tout réseau, et plus généralement par tout moyen de transmission de données numérisées ou non.",
    "le droit de diffuser et de communiquer à tout public les éléments, supports, composants des œuvres créées, par tout procédé de représentation connu ou inconnu à ce jour, pour toute utilisation quelle qu’elle soit ": "le droit de diffuser et de communiquer à tout public les éléments, supports, composants des œuvres créées, par tout procédé de représentation connu ou inconnu à ce jour, pour toute utilisation quelle qu’elle soit ",
    "le droit de mettre en circulation et d’exploiter les œuvres créées, commercialement ou non, les reproductions ainsi réalisées, en nombre illimité, à titre gratuit ou onéreux, et ce quelle qu’en soit la destination.": "le droit de mettre en circulation et d’exploiter les œuvres créées, commercialement ou non, les reproductions ainsi réalisées, en nombre illimité, à titre gratuit ou onéreux, et ce quelle qu’en soit la destination.",
    "le droit de reproduire et/ou faire reproduire les œuvres créées en nombre illimité, par tout procédé et sur tout support actuel ou futur, et nomment graphique, magnétique, numérique ou électronique (interactif ou non) ": "le droit de reproduire et/ou faire reproduire les œuvres créées en nombre illimité, par tout procédé et sur tout support actuel ou futur, et nomment graphique, magnétique, numérique ou électronique (interactif ou non) ",
    "les légendes de vos photos se sont complétées automatiquement, pensez à les vérifier !": "les légendes de vos photos se sont complétées automatiquement, pensez à les vérifier !",
    "les modifications demandées par le Client en cours de réalisation, si elles impliquent un remaniement du projet.": "les modifications demandées par le Client en cours de réalisation, si elles impliquent un remaniement du projet.",
    "légitimement obtenus d'un tiers non tenu par une obligation de confidentialité": "légitimement obtenus d'un tiers non tenu par une obligation de confidentialité",
    "ma photo": "ma photo",
    "mon logo": "mon logo",
    "n'existe pas ou vous ne vous appartient pas.": "n'existe pas ou vous ne vous appartient pas.",
    "nous vous notifions par email.": "nous vous notifions par email.",
    "obtenir toute concession de licence ou autorisation pour permettre au Client de continuer à utiliser les Services": "obtenir toute concession de licence ou autorisation pour permettre au Client de continuer à utiliser les Services",
    "ou": "ou",
    "ou la": "ou la",
    "page": "page",
    "paramètres de connecteurs": "paramètres de connecteurs",
    "photos": {
      "1": "Image 1",
      "2": "Image 2",
      "3": "Image 3",
      "4": "Image 4",
      "5": "Image 5"
    },
    "publier votre vidéo sur Facebook en quelques clics, liez vos pages dans vos": "publier votre vidéo sur Facebook en quelques clics, liez vos pages dans vos",
    "quartier": {
      "1": "Quartier du bien"
    },
    "realty": {
      "bedrooms": "Nombre de chambres",
      "city": "Ville",
      "furnished": "Meublé ou non meublé",
      "living_area": "Surface",
      "operation": "Opération (vente, location)",
      "price": "Prix",
      "title": "Titre principal",
      "type": "Type de bien (appartement, maison)"
    },
    "recherche sur le web": "recherche sur le web",
    "s": "s",
    "secondes": "secondes",
    "si aucune des deux possibilités n’est réalisable, rembourser le Client des sommes versées au titre des Services, déduction faite des sommes déjà payées par le Client pour la période d’utilisation effective des Services.": "si aucune des deux possibilités n’est réalisable, rembourser le Client des sommes versées au titre des Services, déduction faite des sommes déjà payées par le Client pour la période d’utilisation effective des Services.",
    "text": {
      "1": "Texte de la recherche",
      "2": "Titre du texte explicatif",
      "3": "Texte explicatif"
    },
    "tombés ou qui tomberont dans le domaine public au jour de leur divulgation": "tombés ou qui tomberont dans le domaine public au jour de leur divulgation",
    "validé": "validé",
    "via AutoClap ou manuellement": "via AutoClap ou manuellement",
    "Éditer cette vidéo": "Éditer cette vidéo",
    "Établir un cahier des charges détaillé qui ne subira plus de modification, sauf accord des Parties, après avoir été approuvé par le Prestataire. Au besoin, le Prestataire pourra intervenir dans l’élaboration du cahier des charges, conjointement avec le Client. Dans le cas où des modifications impliqueraient un remaniement substantiel du cahier des charges initial, ces dernières seront facturées en sus du devis initial.": "Établir un cahier des charges détaillé qui ne subira plus de modification, sauf accord des Parties, après avoir été approuvé par le Prestataire. Au besoin, le Prestataire pourra intervenir dans l’élaboration du cahier des charges, conjointement avec le Client. Dans le cas où des modifications impliqueraient un remaniement substantiel du cahier des charges initial, ces dernières seront facturées en sus du devis initial.",
    "à appliquer aux Informations Confidentielles les mêmes mesures de protection que celles qu'elle applique à ses propres informations confidentielles": "à appliquer aux Informations Confidentielles les mêmes mesures de protection que celles qu'elle applique à ses propres informations confidentielles",
    "à clap.video.": "à clap.video.",
    "à compter du": "à compter du",
    "à n'utiliser les Informations Confidentielles que pour les seuls besoins de réalisation des Services.": "à n'utiliser les Informations Confidentielles que pour les seuls besoins de réalisation des Services.",
    "à ne communiquer les Informations Confidentielles qu'à ses seuls employés et collaborateurs amenés à les connaître dans le cadre de la réalisation des Services": "à ne communiquer les Informations Confidentielles qu'à ses seuls employés et collaborateurs amenés à les connaître dans le cadre de la réalisation des Services",
    "à ne pas divulguer, publier ou transmettre à des tiers les Informations Confidentielles, sous quelque forme que ce soit, sans l’accord préalable écrit de l’autre Partie": "à ne pas divulguer, publier ou transmettre à des tiers les Informations Confidentielles, sous quelque forme que ce soit, sans l’accord préalable écrit de l’autre Partie"
  }], ["it", {
    "(version longue)": "(versione lunga)",
    "1. Collecte de l’information": "1. Raccolta di informazioni",
    "15 templates": "15 modelli",
    "2. Utilisation des informations": "2. Utilizzo delle informazioni",
    "29,90€ / mois": "29,90 € / mese",
    "29,90€/mois": "29,90 € / mese",
    "299,00€ / an": "299,00€ / anno",
    "299,00€/an": "299,00 € / anno",
    "3 templates": "3 modelli",
    "3. Divulgation à des tiers": "3. Divulgazione a terzi",
    "4. Protection des informations": "4. Protezione delle informazioni",
    "5 vidéos gratuites": "5 video gratuiti",
    "5. Cookies": "5. Cookies",
    "6. Se désabonner": "6. Annullare l'iscrizione",
    "7. Consentement": "7. Consenso",
    "8 caractères minimum": "Minimo 8 caratteri",
    "<": "<",
    "A ce titre, le Prestataire s’engage à prendre les mesures nécessaires afin d’assurer la protection, la sécurité et la confidentialité des données personnelles qui lui sont transmises par le Client.": "Pertanto, il Fornitore si impegna ad adottare le misure necessarie per garantire la protezione, la sicurezza e la riservatezza dei dati personali trasmessi dal Cliente.",
    "A défaut de dispositions spécifiques dans le devis, les délais d’exécution des Services sont communiqués au Client à titre indicatif. Les délais de réalisation des Services ne sont en aucun cas garantis par le Prestataire ni ne peuvent engager sa responsabilité, ni entraîner une obligation de payer une quelconque indemnité ou pénalité de retard, ni justifier l’annulation de la Commande en cause.": "In assenza di specifiche previsioni nel preventivo, i tempi di esecuzione dei Servizi vengono comunicati al Cliente a solo titolo informativo. I tempi di esecuzione dei Servizi non sono in alcun modo garantiti dal Fornitore e non possono comportare responsabilità, né comportare l'obbligo di pagare alcun risarcimento o penalità tardiva, né giustificare l'annullamento dell'Ordine in questione.",
    "A défaut de solution amiable trouvée entre les Parties, seront seuls compétents pour connaître des litiges de toutes natures ou de contestations relatives à l'interprétation ou à l'exécution du présent Contrat, les tribunaux du ressort de la Cour d’Appel de Paris, nonobstant pluralité de défendeurs, demande incidente ou référé ou appels en garantie, à moins que le Prestataire ne préfère saisir toute autre juridiction compétente.": "In assenza di una soluzione amichevole tra le Parti, solo i tribunali della Corte d'Appello di Parigi saranno competenti a conoscere delle controversie di ogni tipo o relative all'interpretazione o all'esecuzione del presente Contratto, nonostante la pluralità dei convenuti, richieste incidentali o sommarie o richieste di garanzia, a meno che il Fornitore non preferisca deferire la questione a qualsiasi altro tribunale competente.",
    "A l’expiration de ce délai de quinze (15) jours, les modifications des CGV seront définitivement considérées comme acceptées par le Client.": "Decorso tale termine di quindici (15) giorni, le modifiche alle CGV si intenderanno definitivamente accettate dal Cliente.",
    "Abonnement": "Abbonamento",
    "Abonnement actuel": "Abbonamento attuale",
    "Abonnement clap.video Premium": "Abbonamento Clap.video premium",
    "Abonnement en cours": "In coda",
    "Accepter": "Accettare",
    "Accès à tous les templates": "Accesso a tutti i modelli",
    "Activer AutoClap": "Attivare AutoClap",
    "Administrer un concours, une promotion, ou un enquête": "Amministrare un concorso, una promozione o un sondaggio",
    "Adresse email": "Indirizzo e-mail",
    "Adresse mail": "Indirizzo e-mail",
    "Adresse postale": "Codice postale",
    "Afficher votre adresse postale professionnelle dans les vidéos": "Mostrare l'indirizzo professionale nei video",
    "Afin de demander la réinitialisation de votre mot de passe, veuillez renseigner votre adresse email. Un email vous sera adressé afin de vous indiquer la procédure à suivre.": "Per richiedere la reimpostazione della password, inserisca il suo indirizzo email. Le verrà inviata una email per indicarle la procedura da seguire.",
    "Ajouter un code promo": "Aggiungere un codice sconto",
    "Améliorer le service client et vos besoins de prise en charge par notre équipe support": "Migliorare il servizio clienti e le tue esigenze di supporto dal nostro team di supporto",
    "Analyser l'usage du site (données anonymisées et agrégées)": "Analizzare l'utilizzo del sito (dati anonimizzati e aggregati)",
    "Annonce immobilière": "Annuncio immobiliare",
    "Annonce immobilière - Choix d'une annonce dans votre logiciel métier": "Annuncio immobiliare -  Scelta di un annuncio nel suo CRM",
    "Annuel": "Annuale",
    "Annuler": "Cancellare",
    "Appliquer": "Applicare",
    "Après encaissement des factures par le Prestataire, celui-ci cède au Client l’ensemble des droits de propriété intellectuelle sur les œuvres créées spécifiquement et à la demande du Client, dans le cadre de l’exécution du Contrat, pour leur durée de protection et pour le monde entier.": "Dopo aver ricevuto le fatture da parte del Fornitore, quest'ultimo trasferisce al Cliente tutti i diritti di proprietà intellettuale sulle opere realizzate appositamente e su richiesta del Cliente, nell'ambito dell'esecuzione del Contratto, per la loro durata di protezione e per il mondo intero.",
    "Assurance": "Assicurazione",
    "Astuce": "Consiglio",
    "Aucun bien n'a été trouvé pour": "Nessun annuncio trovato per",
    "Aucun changement ni aucune modification du Contrat, notamment sur les caractéristiques des Services, ne sera pris en considération s'il n'a pas été accepté par écrit par le Prestataire.": "Nessun cambiamento o modifica del Contratto, in particolare delle caratteristiche dei Servizi, sarà preso in considerazione se non sarà stato accettato per iscritto dal Fornitore.",
    "Aucun escompte n’est prévu en cas de paiement anticipé.": "Non sono previsti sconti per i pagamenti anticipati",
    "Aucun résultat": "Nessun risultato",
    "Aucun résultat.": "Nessun risultato",
    "Aucune annonce n'a pu être trouvée.": "Nessun annuncio trovato.",
    "AutoClap génère automatiquement une video quand vous publiez un nouveau bien sur votre outil métier.": "AutoClap genera automaticamante un video quando pubblica un nuovo annuncio sul suo CRM.",
    "Autonomie des clauses": "Autonomia delle clausole",
    "Avant chaque intervention du Prestataire, le Client s’engage à réaliser toutes les procédures de sauvegarde nécessaires à la protection et à la sauvegarde de ses données, programmes et fichiers informatiques.": "Prima di ogni intervento da parte del Fornitore, il Cliente si impegna ad effettuare tutte le procedure di backup necessarie per proteggere e salvare i propri dati, programmi e file informatici.",
    "Avec l’abonnement Premium, vous profiterez de clap.video dans son intégralité et débloquerez les fonctionnalités suivantes": "Con l'abbonamento Premium, potrà sfruttare clap.video nella sua interezza e sbloccare le seguenti funzionalità",
    "Avis clients": "Recensioni dei clienti",
    "Bientôt disponible": "Presto disponibile",
    "Bienvenue sur Clap !": "Benvenuto su Clap.video!",
    "Bienvenue sur clap.video !": "Benvenuto su Clap.video!",
    "Carré": "Quadrato",
    "Carte bancaire": "Carta di credito",
    "Ces CGV régissent intégralement les relations entre le Prestataire et le Client. Aucune condition générale d'achat ne pourra prévaloir ni être opposée par le Client au Prestataire et aucune condition particulière communiquée par le Client au Prestataire ne peut prévaloir sur les CGV, sauf acceptation formelle et écrite du Prestataire.": "Le presenti CGV regolano integralmente il rapporto tra il Fornitore e il Cliente. Nessuna condizione generale di acquisto potrà prevalere o essere osteggiata dal Cliente al Fornitore e nessuna condizione specifica comunicata dal Cliente al Fornitore potrà prevalere sulle CGV, salvo formale e scritta accettazione da parte del Fornitore.",
    "Ces cookies ne sont pas indispensables au fonctionnement du site, mais ils facilitent votre navigation. Vous pouvez les bloquer ou les supprimer, mais vous risquez alors d'empêcher le bon fonctionnement de certains éléments du site.": "Questi cookie non sono indispensabili al funzionamento del sito, ma facilitano la tua navigazione. Puoi bloccarli o eliminarli, ma rischi di impedire il corretto funzionamento di alcuni elementi del sito.",
    "Ces pénalités de retard sont exigibles de plein droit et sans qu’un rappel par le Prestataire ne soit nécessaire.": "Queste penalità tardive sono dovute automaticamente e senza che sia necessario un sollecito da parte del Fornitore di servizi.",
    "Cette abonnement sera automatiquement renouvelé le": "L'abbonamento sarà rinnovato automaticamente il",
    "Cette action est irréversible.": "L'azione è irreversibile.",
    "Cette disposition ne peut être remplacée par un accord verbal.": "Questa disposizione non può essere sostituita da un accordo verbale.",
    "Cette publication sera postée sur": "Questa pubblicazione sarà pubblicata su",
    "Chacune des Parties renonce, sauf accord écrit préalable, à faire directement ou indirectement des offres d’engagement à un collaborateur de l’autre Partie ayant travaillé dans le cadre des Services, objet du présent Contrat, ou à le prendre à son service, sous quelque statut que ce soit. Cette renonciation est valable pour une durée de deux (2) ans à compter de la fin du présent Contrat.": "Ciascuna delle Parti rinuncia, salvo previo accordo scritto, a proporre direttamente o indirettamente un impiego ad un dipendente dell'altra Parte che abbia lavorato nell'ambito dei Servizi oggetto del presente Contratto, o a metterlo al suo servizio, a qualunque titolo stato potrebbe essere. La presente rinuncia è valida per un periodo di due (2) anni dalla fine del presente Contratto.",
    "Chacune des Parties s’engage à maintenir en vigueur, pendant toute la durée de réalisation du Contrat, auprès d’une compagnie d’assurance notoirement solvable, une police d’assurance garantissant les dommages pouvant survenir à ses biens et personnel, ainsi qu’une police couvrant sa responsabilité professionnelle, de manière à couvrir les conséquences pécuniaires des dommages corporels, matériels et immatériels dont elles auraient à répondre, causés par tout évènement et qui serait le fait de ses collaborateurs et/ou sociétés partenaires éventuels lors de l’exécution du Contrat.": "Ciascuna delle Parti si impegna a mantenere in vigore, per tutta la durata del Contratto, presso una compagnia di assicurazione solvibile, una polizza assicurativa che garantisca i danni che potrebbero verificarsi alle sue cose e al suo personale, nonché una polizza che copra la loro responsabilità professionale, in modo da a coprire le conseguenze pecuniarie dei danni fisici, materiali ed immateriali di cui dovesse rispondere, causati da qualsiasi evento e che sarebbero opera dei propri collaboratori e/o delle eventuali società partner durante l'esecuzione del Contratto.",
    "Changer": "Cambiare",
    "Chaque Partie s’engage": "Ogni Parte si impegna a",
    "Chargement...": "In caricamento...",
    "Choisir une autre image sur l’annonce": "Scegli un'altra immagine dall'annuncio",
    "Choisir une musique": "Scegli una musica",
    "Choisir une page": "Scegliere una pagina",
    "Choisissez la langue de l'interface": "Scegli la lingua dell'interfaccia",
    "Choisissez un template": "Scegli un template",
    "Choisissez votre type de vidéo": "Scelga il tipo di video",
    "Code postal": "Codice postale",
    "Code postale": "Codice postale",
    "Code promo": "Codice sconto",
    "Code promotionnel": "Codice sconto",
    "Collaborer activement à la réussite du projet en apportant au Prestataire dans les délais préalablement définis toutes les informations et tous les documents nécessaires à la bonne appréhension des besoins et à la bonne exécution des Services.": "Collaborare attivamente al successo del progetto fornendo al Fornitore entro le scadenze precedentemente definite tutte le informazioni e i documenti necessari per una corretta comprensione delle esigenze e la corretta esecuzione dei Servizi.",
    "Collaborer étroitement avec le Prestataire et fournir toute information, documentation, prestation, et tous moyens utiles pour la réalisation des Services et s’engage à mettre à disposition du Prestataire tous les éléments permettant de satisfaire à son obligation, incluant le personnel dédié à la bonne réalisation des Services.": "Collaborare strettamente con il Fornitore e fornire tutte le informazioni, la documentazione, i servizi e tutti i mezzi utili per l'esecuzione dei Servizi e si impegna a fornire al Fornitore tutti gli elementi che gli consentano di adempiere ai propri obblighi, compreso il personale dedicato alla corretta esecuzione dei Servizi.",
    "Commencer": "Iniziare",
    "Complément d'adresse postale": "Complemento dell'indirizzo",
    "Concernant les logiciels, données ou documents utilisés par le Prestataire dans le cadre de la fourniture des Services, dont le Client a acquis les droits d’utilisation auprès de tiers ou dont il est propriétaire, le Client garantit le Prestataire de toutes les conséquences ou suites dommageables que le Prestataire aurait à subir au titre de l’utilisation desdits logiciels, données ou documents contre toute action de la part d’une personne revendiquant un droit de propriété intellectuelle ou se fondant sur une demande en concurrence déloyale et/ou parasitisme sur ces logiciels, données ou documents.": "Per quanto riguarda il software, i dati o i documenti utilizzati dal Fornitore nell'ambito della fornitura dei Servizi, per i quali il Cliente ha acquisito i diritti d'uso da terzi o di cui è proprietario, il Cliente garantisce il Fornitore contro tutte le conseguenze o danni che il Fornitore dovrebbe subire in relazione all'utilizzo di detti software, dati o documenti contro qualsiasi azione da parte di una persona che rivendica un diritto di proprietà intellettuale o basata su una richiesta di concorrenza sleale e/o. o parassitismo su tali software, dati o documenti.",
    "Conclusion du Contrat": "Conclusione del Contratto",
    "Conditions Générales de Vente - clap.video": "Termini d'uso generali e CGV - clap.video",
    "Conditions de paiement": "Condizioni di pagamento",
    "Confidentialité": "Privacy",
    "Confirmation de suppression": "Conferma della soppressione",
    "Confirmation du nouveau mot de passe": "Conferma della nuova password",
    "Confirmer le mot de passe": "Confermare la password",
    "Confirmer le paiement": "Confermare il pagamento",
    "Confirmez votre mot de passe": "Confermare la password",
    "Conformément aux dispositions de la Loi Informatique et Libertés n° 78-17 du 6 janvier 1978 telle que modifiée, le Client dispose de la qualité de responsable de traitement dans le cadre de l’exécution du Contrat.": "Conformemente alle disposizioni della legge sulla protezione dei dati n. 78-17 del 6 gennaio 1978 e successive modifiche, il Cliente ha la qualità di responsabile del trattamento dei dati nell'ambito dell'esecuzione del Contratto.",
    "Conformément aux dispositions du Règlement Général sur la Protection des Données (RGPD) entré en vigueur le 25 mai 2018, le Client dispose notamment d’un droit d’accès, de rectification, d’opposition et de portabilité de ses données personnelles. La garantie de ces droits est affirmée au sein de la politique de confidentialité qui accompagne les présentes, et qui en conséquence, forment un ensemble contractuel.": "In conformità con le disposizioni del Regolamento generale sulla protezione dei dati (GDPR) entrato in vigore il 25 maggio 2018, il Cliente ha in particolare il diritto di accesso, rettifica, opposizione e portabilità dei suoi dati personali. La garanzia di tali diritti è affermata nell'ambito della politica di riservatezza che accompagna la presente e che di conseguenza costituisce un insieme contrattuale.",
    "Connecter l'ensemble de vos plateformes à clap.video": "Connetta tutti i suoi supporti a Clap.video",
    "Connecteurs": "Connettori",
    "Connectez-vous !": "Connessione !",
    "Connexion": "Connessione",
    "Connexion via Topi": "Connessione via Topi",
    "Conserver la musique par défaut": "Mantieni la musica predefinita",
    "Copier le lien de la page publique": "Copia il link alla pagina pubblica",
    "Copier le lien de la vidéo": "Copiare il link del video",
    "Copier le lien public": "Copiare il link pubblico",
    "Couleur du thème": "Colore del tema",
    "Couleur incorrecte. Veuillez choisir une couleur au format hexadécimal sans transparence.": "Colore non corretto. Scegli un colore esadecimale senza trasparenza.",
    "Couleur précédente": "Colore precedente",
    "Couleur préférée": "Colore preferito",
    "Couleurs prédéfinies": "Colori predefiniti",
    "Création d'un clap vidéo": "Creazione di un Clap video",
    "Création d'une vidéo": "Creazione di un video",
    "Création manuelle": "Creazione manuale",
    "Créer le nouveau mot de passe": "Creare la nuova password",
    "Créer manuellement": "Creare manualmente",
    "Créer une vidéo": "Creare un video",
    "Créez votre première vidéo en cliquant sur le bouton ci-dessous.": "Crei il suo primo video cliccando sul pulsante qui in basso.",
    "Créez-en un": "Ne crei uno",
    "Créér ou administrer votre compte clap.video": "Creare o amministrare il tuo account clap.video",
    "Dans la file d'attente": "In coda",
    "Dans le cadre de la fourniture des Services et en tant que de besoin, le Client concède également au Prestataire un droit d’utilisation de ses logiciels, données et documents, à titre personnel, gratuit, non exclusif et non transférable pour la durée de réalisation des Services.": "Nell'ambito della fornitura dei Servizi e se necessario, il Cliente concede al Fornitore anche il diritto di utilizzare il suo software, i suoi dati e i suoi documenti, a titolo personale, a titolo gratuito, non esclusivo e non trasferibile per la durata di servizi.",
    "Dans le cadre des présentes CGV et la réalisation des Services, le Prestataire s’engage à se donner tous les moyens nécessaires et à mettre tout en œuvre pour la réalisation de sa mission dans les règles de l’art. Cette obligation ne saurait constituer une obligation de résultat, le Prestataire ne fournissant les Services que dans le cadre d’une obligation de moyen.": "Nell'ambito delle presenti CGV e della prestazione dei Servizi, il Fornitore si impegna a dotarsi di tutti i mezzi necessari e a compiere ogni sforzo per svolgere la propria missione in conformità con le regole dell'art. Questo obbligo non può costituire un obbligo di risultato, il Fornitore fornisce i Servizi solo nell'ambito di un obbligo di mezzi.",
    "Dans le respect des articles L 8221-1 et suivants du Code du travail et conformément à l’article D 8222-5 du même code, le Prestataire s’engage à remettre au Client lors de la conclusion du Contrat, et tous les six mois jusqu’à la fin de son exécution, les documents suivants ": "In conformità agli articoli L 8221-1 e seguenti del Codice del lavoro e in conformità all'articolo D 8222-5 dello stesso codice, il Fornitore di servizi si impegna a fornire al Cliente al momento della conclusione del Contratto e ogni sei mesi fino alla fine. della sua esecuzione, i seguenti documenti",
    "Dans l’hypothèse où le Client aurait signé un procès-verbal de réception des Services et/ou la maquette de la ou les créations ou a validé la réception des Services et/ou la maquette, par tout moyen et notamment par l’utilisation des Services et/ou la maquette, le Prestataire sera réputé avoir réalisé ses obligations de manière conforme au Contrat. Le Client sera alors réputé avoir renoncé irrévocablement à toutes réclamation de plein droit à ce titre.": "Nel caso in cui il Cliente abbia firmato un verbale di ricezione dei Servizi e/o del modello della/e creazione/i o abbia convalidato la ricezione dei Servizi e/o del modello, con qualsiasi mezzo e in particolare mediante l'uso del Servizi e/o il modello, si riterrà che il Fornitore abbia adempiuto ai propri obblighi in conformità al Contratto. Si riterrà quindi che il Cliente abbia rinunciato irrevocabilmente a tutte le pretese legali a questo riguardo.",
    "Demande de mot de passe": "Richiesta di password",
    "Dernière modification": "Ultima modifica",
    "Disposer des droits nécessaires sur les éléments fournis ci-dessus.": "Avere i diritti necessari sugli elementi sopra forniti.",
    "Dispositions générales": "Disposizioni generali",
    "Documents antérieurs ou autres accords": "Documenti precedenti o altri accordi",
    "Donnez vie à vos biens immobiliers en vidéo et améliorez votre engagement sur les réseaux sociaux.": "Dia vita al suo immobile in video e migliori i suoi riscontri sui social media.",
    "Données personnelles": "Dati personali",
    "Droit applicable et juridictions compétentes": "Legge applicabile e giurisdizioni competenti",
    "Dupliquez cette vidéo": "Duplicare questo video",
    "Durée": "Durata",
    "Déconnexion": "Disconnessione",
    "Découvrez notre nouveau bien, une magnifique maison de campagne de 156m² et 3 chambres dont une suite parentale. Piscine, garage.": "Scoprite la nostra nuova proprietà, una magnifica casa di campagna di 156m² e 3 camere da letto di cui una master suite. Piscina, garage.",
    "Définissez votre nouveau mot de passe.": "Scriva la nuova password.",
    "Définitions": "Definizioni",
    "Désolé, votre navigateur ne supporte pas ce type de vidéo.": "Spiacenti, il suo navigatore non supporta questo tipo di video.",
    "D’une façon générale, le Client et le Prestataire s’engagent à collaborer activement afin d’assurer la bonne exécution du Contrat. Chacune des Parties s’engage à communiquer toutes les difficultés dont elle aurait connaissance au fur et à mesure de l'avancement du projet, pour permettre à l’autre Partie de prendre les décisions nécessaires.": "In generale, il Cliente e il Fornitore si impegnano a collaborare attivamente al fine di garantire la corretta esecuzione del Contratto. Ciascuna Parte si impegna a comunicare eventuali difficoltà di cui venga a conoscenza nel corso dell'avanzamento del progetto, per consentire all'altra Parte di prendere le decisioni necessarie.",
    "E-commerce": "Commercio elettronico",
    "Email": "E-mail",
    "Email pro": "E-mail professionale",
    "En aucun cas, le Prestataire ne sera tenu pour responsable des dommages indirects, accessoires ou particuliers tels que définis par la jurisprudence des tribunaux français, notamment, le coût de l’obtention de services de substitution, pertes de bénéfices, de données ou périodes d’immobilisation, que sa responsabilité soit contractuelle ou délictuelle et qu’elle ait ou non son fondement dans l’utilisation ou le fonctionnement des Services, même si le Prestataire a averti le Client de la possibilité de tels dommages.": "In nessun caso il Fornitore sarà ritenuto responsabile dei danni indiretti, incidentali o speciali definiti dalla giurisprudenza dei tribunali francesi, in particolare dei costi per l'ottenimento di servizi sostitutivi, della perdita di profitti, di dati o dei periodi di immobilizzazione, sia che la responsabilità è contrattuale o extracontrattuale e ha o meno la sua base nell'uso o nel funzionamento dei Servizi, anche se il Fornitore ha avvertito il Cliente della possibilità di tale danno.",
    "En cas de contradiction entre des dispositions du devis et les présentes CGV, les dispositions concernées du devis prévaudront sur les CGV.": "In caso di contraddizione tra le disposizioni del preventivo e le presenti CGV, le disposizioni pertinenti del preventivo prevarranno sulle CGV.",
    "En cas de différend entre les Parties, celles-ci tenteront de trouver une solution amiable à ce différend dans un délai de trente jours à compter de la notification du différend de la Partie en demande à l’autre Partie par lettre recommandée avec accusé de réception.": "In caso di controversia tra le Parti, queste cercheranno di trovare una soluzione amichevole entro trenta giorni dalla notifica della controversia da parte della Parte, richiedendola all'altra Parte mediante lettera raccomandata con avviso di ricevimento.",
    "En cas de manquement quelconque du Prestataire dans l’exécution de ses obligations (défaut d’exécution ou mauvaise exécution), le Client devra en faire part à celui-ci dans le délai de huit (8) jours ouvrés à compter de la constatation du manquement par lettre recommandée avec accusé de réception. A défaut, le manquement sera inopposable au Prestataire.": "In caso di inadempimento da parte del Fornitore di servizi (mancata esecuzione o cattiva esecuzione), il Cliente dovrà darne comunicazione al Fornitore di servizi entro otto (8) giorni lavorativi dalla constatazione dell'inadempimento tramite lettera raccomandata con avviso di ricevimento di ricevuta. In mancanza di ciò, la violazione sarà inopponibile nei confronti del Fornitore.",
    "En cas de non-paiement à l’échéance, toute somme due fera courir des pénalités de retard. Celles-ci courent à compter du jour suivant la date d’échéance figurant sur la facture et jusqu’au jour du paiement effectif et intégral de la somme. Le taux des pénalités de retard est fixé à trois faux le taux d’intérêt légal en vigueur.": "In caso di mancato pagamento entro la data di scadenza, qualsiasi importo dovuto comporterà sanzioni per ritardato pagamento. Questi decorrono dal giorno successivo alla data di scadenza riportata sulla fattura e fino al giorno dell'effettivo ed integrale pagamento dell'importo. Il tasso di penalità per ritardato pagamento è fissato a tre volte il tasso di interesse legale in vigore.",
    "En cas de rupture du Contrat avant son terme par le Client, celui-ci s’engage formellement à régulariser et rétribuer les montants relatifs au calendrier en cours, aux postes réalisés ou en cours de réalisation, ainsi qu’aux services complémentaires effectués. Les fichiers et données sources créés et utilisés par le Prestataire ne sauraient dès lors être revendiqués par le Client sans une contribution financière. L’acompte déjà versé restera acquis par le Prestataire, constituant un dédommagement pour le travail entrepris.": "In caso di risoluzione anticipata del Contratto da parte del Cliente, quest'ultimo si impegna formalmente a regolarizzare e remunerare gli importi relativi al programma in vigore, alle posizioni completate o in corso, nonché ai servizi aggiuntivi eseguiti. I file sorgente e i dati creati e utilizzati dal Fornitore non possono quindi essere rivendicati dal Cliente senza un contributo finanziario. L'acconto già versato resterà acquisito dal Fornitore, costituendo compenso per il lavoro svolto.",
    "En cas d’atteinte avérée aux droits d’un tiers, le Prestataire pourra, à son choix ": "In caso di violazione accertata dei diritti di terzi, il Fornitore può, a sua scelta",
    "En cliquant sur le bouton d'inscription, vous acceptez les": "Utilizzando il nostro sito, accetta",
    "En cours de traitement": "In corso",
    "En file d'attente": "In coda",
    "En particulier, le Prestataire cède au Client les droits suivants ": "In particolare, il Fornitore attribuisce al Cliente i seguenti diritti",
    "En utilisant notre site, vous consentez à notre politique de confidentialité.": "Utilizzando il nostro sito, accetti la nostra politica sulla privacy.",
    "Encodage en cours": "Codifica in corso",
    "Enfin, en cas de retard de paiement, le Prestataire se réserve le droit de suspendre ou de surseoir à l’exécution des Services prévus dans la Commande dont le paiement fait l’objet du retard.": "Infine, in caso di ritardo nel pagamento, il Fornitore si riserva il diritto di sospendere o rinviare l'esecuzione dei Servizi previsti nell'Ordine per i quali il pagamento è in ritardo.",
    "Enfin, le Client fait son affaire et est seul responsable des lois et réglementations applicables aux Services notamment au regard de la protection des droits de propriété intellectuelle, mentions légales, protection des données personnelles, protection des mineurs (si applicable) et droit de la consommation (si applicable).": "Infine, il Cliente svolge la propria attività ed è l'unico responsabile delle leggi e dei regolamenti applicabili ai Servizi, in particolare per quanto riguarda la tutela dei diritti di proprietà intellettuale, le note legali, la protezione dei dati personali, la tutela dei minori (se applicabile) e il diritto dei consumatori. (se applicabile).",
    "Enregistrer les modifications": "Salvare le modifiche",
    "Enregistrer l’image sélectionnée": "Salva l'immagine selezionata",
    "Entrer une couleur manuellement": "Inserisci un colore manualmente",
    "Envoi en cours": "Invio di",
    "Envoyer": "Inviare",
    "Erreur": "Errore",
    "Erreur de connexion au serveur, veuillez réessayer s'il vous plait.": "Errore di connessione al server, prego ritentare.",
    "Erreur lors de l'envoi sur Facebook. Merci de vérifier vos informations et réessayer.": "Errore nell'invio a Facebook. Prego verifichi le sue informazioni e riprovi.",
    "Erreur lors de l'envoi sur Instagram. Merci de vérifier vos informations et réessayer.": "Errore nel caricamento su Instagram. Prego verifichi le sue informazioni e riprovi.",
    "Erreur lors de l'envoi sur Tiktok. Merci de vérifier vos informations et réessayer.": "Errore nel caricamento su TikTok. Prego verifichi le sue informazioni e riprovi.",
    "Erreur lors de la copie du lien, veuillez réessayer": "Errore nella copia del link, prego ritentare",
    "Erreur pendant l'enregistrement de vos informations": "Errore nel salvataggio delle informazioni",
    "Erreur pendant la suppression du projet.": "Errore nell'eliminazione del progetto.",
    "Fait main": "Fatto a mano",
    "Fonctionnalités": "Funzionalità",
    "Force Majeure": "Forza maggiore",
    "Format": "Formato",
    "Fournir tous les éléments documentaires, graphiques et textuels nécessaires à la bonne réalisation du Contrat (notamment dans les bons formats exploitables en fonction des supports visés), le Client s'engage à fournir toutes les informations légales à ajouter dans les documents et endosse la responsabilité de fournir le contenu des documents qu'il édite.": "Fornire tutti gli elementi documentali, grafici e testuali necessari per la corretta conclusione del Contratto (in particolare nei formati corretti utilizzabili a seconda del supporto previsto), il Cliente si impegna a fornire tutte le informazioni legali da inserire nei documenti e si assume la responsabilità di fornire il contenuto dei documenti che pubblica.",
    "Frais Annexes": "Spese supplementari",
    "Garantie d’éviction": "Garanzie d'evizione",
    "Garanties": "Garanzie",
    "Garantir le Prestataire contre toute action qui pourrait lui être intentée du fait du caractère des données ou informations (textes, images, sons) qui auraient été fournies ou choisies par le Client.": "Garantire il Fornitore contro qualsiasi azione che possa essere intentata contro di esso a causa della natura dei dati o delle informazioni (testi, immagini, suoni) forniti o scelti dal Cliente.",
    "Gratuit": "Gratuito",
    "Généralités": "Informazioni generali",
    "HT": "Al netto delle imposte",
    "Hormis les dommages corporels ou décès, et sauf en cas de négligence grossière ou de faute intentionnelle causant un dommage direct prouvé ou en cas de manquement à une obligation essentielle du Contrat la vidant de sa substance, le Client reconnaît que la responsabilité du Prestataire est limitée au montant versé pour les Services en cause.": "Fatta eccezione per lesioni personali o morte, e salvo il caso di negligenza grave o dolo che causi un danno diretto provato o in caso di mancato adempimento di un obbligo essenziale del Contratto privandolo della sua sostanza, il Cliente riconosce che la responsabilità del Fornitore è limitato all'importo pagato per i Servizi in questione.",
    "Il semble y avoir une erreur de notre côté.": "Sembra che abbiamo fatto un errore.",
    "Images": "Immagini",
    "Immobilier": "Immobiliare",
    "Importer cette annonce": "Importare questo annuncio",
    "Importer une image": "Importa un'immagine",
    "Informer le Prestataire d’une éventuelle mise en concurrence avec d’autres prestataires.": "Informare il fornitore di servizi della possibile concorrenza con altri fornitori di servizi.",
    "Infos générales": "Informazioni generali",
    "Inscription": "Iscrizione",
    "L'email a été envoyé": "E-mail inviata",
    "L'enregistrement des champs a échoué.": "Salvataggio del campo non riuscito.",
    "L'importation de cette annonce n'a pas fonctionné. Si le problème persiste, veuillez contacter le support.": "L'importazione di questo annuncio non ha funzionato. Se il problema persiste, contatti l'assistenza.",
    "L'url de votre site Internet": "L'URL del suo sito internet",
    "La Partie touchée par un événement de force majeure en avisera l'autre Partie dans les cinq (5) jours ouvrables suivant la date à laquelle elle en aura eu connaissance. Les deux Parties conviendront alors des conditions dans lesquelles l'exécution du Contrat sera poursuivie.": "La Parte colpita da un evento di forza maggiore ne darà comunicazione all'altra Parte entro cinque (5) giorni lavorativi successivi alla data in cui ne viene a conoscenza. Le due Parti concorderanno quindi le condizioni alle quali proseguirà l'esecuzione del Contratto.",
    "La connexion automatique a échouée.": "Connessione automatica non riuscita.",
    "La liaison avec Facebook a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Facebook.": "Il collegamento con Facebook è stato creato con successo. Può ora mandare i suoi video direttamente a Facebook.",
    "La liaison avec Instagram a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Instagram.": "Il collegamento con Instagram ha avuto successo. Ora può inviare i video direttamente su Instagram.",
    "La liaison avec Tiktok a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Tiktok.": "Il collegamento con TikTok ha avuto successo. Ora può inviare i video direttamente su TikTok.",
    "La loi du Contrat est la Loi Française. Les parties conviennent expressément que la Convention de Viennes sur la vente internationale de marchandises en date du 11 avril 1980 n’est pas applicable au Contrat.": "La legge applicabile al Contratto è quella francese. Le parti concordano espressamente che la Convenzione di Vienna sulla vendita internazionale di beni mobili dell'11 aprile 1980 non è applicabile al Contratto.",
    "La responsabilité du Prestataire est limitée aux seuls dommages directs et résultant d'un défaut des Services ou de la violation du Contrat, même si le défaut en question était prévisible au moment de la Commande.": "La responsabilità del Fornitore è limitata esclusivamente ai danni diretti derivanti da un difetto dei Servizi o dalla violazione del Contratto, anche se il difetto in questione era prevedibile al momento dell'Ordine.",
    "La réalisation des Services confiés au Prestataire dépendant directement du respect par le Client de ses propres obligations, les Parties reconnaissent expressément que les délais de livraison visés au devis sont donnés à titre purement indicatif et sans garantie.": "Poiché l'esecuzione dei Servizi affidati al Fornitore dipende direttamente dal rispetto da parte del Cliente delle proprie obbligazioni, le Parti riconoscono espressamente che i tempi di consegna di cui al preventivo sono forniti a titolo puramente indicativo e senza garanzia.",
    "La vidéo est en cours d'envoi sur Facebook. Vous recevrez une notification Facebook dès qu'elle sera prête.": "Invio del video a Facebook in corso. Riceverà una notifica da Facebook quando sarà pronto.",
    "La vidéo est en cours d'envoi sur Instagram. Vous recevrez une notification Instagram dès qu'elle sera prête.": "Il video sta venendo caricato su Instagram. Riceverà una notifica da Instagram quando sarà pronto.",
    "La vidéo est en cours d'envoi sur Tiktok. Vous recevrez une notification Tiktok dès qu'elle sera prête.": "Il video sta venendo caricato su TikTok. Riceverà una notifica da TikTok quando sarà pronto.",
    "Langue du Contrat": "Lingua del contratto",
    "Langue mise à jour avec succès.": "Lingua aggiornata con successo.",
    "Langues": "Lingue",
    "Le Client déclare expressément avoir reçu du Prestataire toutes les informations et tous les conseils nécessaires à la réalisation des Services et renonce à rechercher la responsabilité du Prestataire de ce fait.": "Il Cliente dichiara espressamente di aver ricevuto dal Fornitore tutte le informazioni e i consigli necessari per l'esecuzione dei Servizi e rinuncia per questo motivo a rivendicare la responsabilità del Fornitore.",
    "Le Client s'engage à fournir des informations justes et sincères et s'engage aussi à prévenir le Prestataire de tout changement concernant les informations, données, documentations fournies.": "Il Cliente si impegna a fornire informazioni corrette e sincere e si impegna inoltre a comunicare al Fornitore qualsiasi modifica riguardante le informazioni, i dati, la documentazione fornita.",
    "Le Client sera seul responsable des éventuels dysfonctionnements qui pourraient résulter d'informations erronées. Le Client doit maintenir une adresse e-mail et une adresse postale valides.": "Il Cliente sarà l'unico responsabile per eventuali malfunzionamenti che dovessero derivare da informazioni errate. Il Cliente deve mantenere un indirizzo email e un indirizzo postale validi.",
    "Le Client sera également débiteur de plein droit d’une indemnité forfaitaire minimum de recouvrement de quarante (40) euros des sommes dues par le Client au Prestataire.": "Il Cliente sarà inoltre automaticamente responsabile di un risarcimento minimo fisso di recupero pari a quaranta (40) euro delle somme dovute dal Cliente al Fornitore.",
    "Le Client s’engage à fournir au Prestataire sous forme exploitable l’ensemble des documents nécessaires à la réalisation des Services confiés au Prestataire.": "Il Cliente si impegna a fornire al Fornitore in forma utilizzabile tutti i documenti necessari per eseguire i Servizi affidati al Fornitore.",
    "Le Client s’engage à obtenir de tous tiers, si besoin est, le droit de concéder au Prestataire les droits d’utilisation des logiciels, données et équipements appartenant à ces tiers pour les besoins de la fourniture des Services.": "Il Cliente si impegna a ottenere da tutti i terzi, se necessario, il diritto di concedere al Fornitore il diritto di utilizzare software, dati e apparecchiature appartenenti a questi terzi ai fini della fornitura dei Servizi.",
    "Le Contrat est rédigé en langue française. Une traduction en langue étrangère peut être fournie à titre d’information. En cas de contradiction, seule la version française fera foi entre les Parties.": "Il Contratto è redatto in lingua francese. Per vostra informazione è stato tradotto in lingua straniera. In caso di contraddizione, tra le Parti farà fede soltanto la versione francese.",
    "Le Contrat se substitue à tout autre document antérieur, à tout autre accord écrit ou verbal en relation avec le même objet, à l’exception du devis, de la Commande et prévaut sur toute disposition contraire qui pourrait être contenue dans les documents émanant du Client.": "Il Contratto sostituisce ogni altro documento precedente, ogni altro accordo scritto o verbale in relazione allo stesso oggetto, ad eccezione del preventivo, dell'Ordine e prevale su qualsiasi disposizione contraria che dovesse essere contenuta nei documenti emananti dal Cliente.",
    "Le Prestataire a la faculté de sous-traiter tout ou partie de la réalisation des Services à des sous-traitants. Dans ce cas, le Prestataire demeure responsable de la réalisation des Services vis-à-vis du Client.": "Il Fornitore ha la possibilità di subappaltare tutta o parte della prestazione dei Servizi a subappaltatori. In questo caso, il Fornitore rimane responsabile della fornitura dei Servizi al Cliente.",
    "Le Prestataire agissant au nom et pour le compte du Client dans le traitement des données personnelles qui lui sont communiquées par le Client, il dispose de la qualité de sous-traitant.": "Il Fornitore che agisce in nome e per conto del Cliente nel trattamento dei dati personali comunicatigli dal Cliente, ha lo status di subappaltatore.",
    "Le Prestataire concède au Client, le cas échéant et dans la limite strictement nécessaire à l’exécution des Services, à titre personnel, non exclusif et non-transférable, le droit d'utiliser lesdits Droits de Propriété Intellectuelle pour la durée de réalisation des Services.": "Il Fornitore concede al Cliente, ove applicabile e nei limiti strettamente necessari per l'esecuzione dei Servizi, su base personale, non esclusiva e non trasferibile, il diritto di utilizzare detti Diritti di Proprietà Intellettuale per la durata della prestazione dei Servizi.",
    "Le Prestataire déclare être immatriculé au RCS, ainsi qu’auprès de l’URSSAF et que ses immatriculations couvrent expressément toutes ses activités pour l’exécution des Services définis dans le devis et/ou la Commande.": "Il Fornitore di Servizi dichiara di essere iscritto alla RCS, nonché all'URSSAF e che le sue iscrizioni coprono espressamente tutte le sue attività per l'esecuzione dei Servizi definiti nel Preventivo e/o nell'Ordine.",
    "Le Prestataire est quant à lui autorisé à utiliser la dénomination sociale/la marque du Client dans le cadre de ses activités à des fins de promotion commerciale.": "Il Fornitore è dal canto suo autorizzato ad utilizzare il nome/marchio dell'azienda del Cliente nell'ambito delle proprie attività per scopi promozionali commerciali.",
    "Le Prestataire est une entreprise spécialisée notamment dans Génération automatique de vidéo basée sur des informations fournies statiques (textes, images).": "Il Fornitore è un'azienda specializzata nella generazione automatica di video basati su informazioni statiche (testo, immagini).",
    "Le Prestataire garantit le Client contre toute action, réclamation, revendication ou opposition de la part de toute personne invoquant un droit de propriété intellectuelle auxquels la fourniture des Services aurait porté atteinte, sous réserve que le Client informe le Prestataire, dès qu’il en a connaissance, de toute demande, réclamation ou instance présentée ou engagée pour un tel motif, par voie judiciaire ou extrajudiciaire. Le Client s’engage à apporter au Prestataire, tous les documents et renseignements en sa possession ainsi que toute l’assistance requise qui pourraient être nécessaires à sa défense.": "Il Fornitore di servizi garantisce il Cliente contro qualsiasi azione, reclamo, richiesta o opposizione da parte di chiunque invochi un diritto di proprietà intellettuale che la fornitura dei Servizi potrebbe aver violato, a condizione che il Cliente informi il Fornitore di servizi non appena ne venga a conoscenza. conoscenza, di qualsiasi richiesta, reclamo o procedimento presentato o avviato per tale motivo, per via giudiziale o stragiudiziale. Il Cliente si impegna a fornire al Fornitore tutti i documenti e le informazioni in suo possesso nonché tutta l'assistenza necessaria per la sua difesa.",
    "Le Prestataire garantit que les Services sont fournis de manière substantiellement conforme à la Commande.": "Il Fornitore garantisce che i Servizi vengono forniti in modo sostanzialmente coerente con l'Ordine.",
    "Le Prestataire garantit que les créations sont juridiquement disponibles et ne sont pas grevées de droit des tiers pour les utilisations prévues au titre du Contrat.": "Il Fornitore garantisce che le creazioni sono legalmente disponibili e non sono gravate da diritti di terzi per gli usi previsti dal Contratto.",
    "Le Prestataire n'aura aucune obligation d’indemnisation ou autre obligation au titre d’une action en contrefaçon ayant pour origine (a) une utilisation des Services autrement que conformément au Contrat, (b) une combinaison des Services avec d’autres services ou matériels non fournis par le Prestataire.": "Il Fornitore non avrà alcun indennizzo o altro obbligo in relazione ad un'azione per violazione derivante da (a) un utilizzo dei Servizi diverso da quello previsto dal Contratto, (b) una combinazione dei Servizi con altri servizi o materiali non forniti dal fornitore di servizi.",
    "Le Prestataire ne peut être tenu d’aucune garantie notamment lorsque le Client a modifié ou fait modifier les Services ou a utilisé d’autres services que les Services fournis par le Prestataire, sans son accord préalable et écrit ou lorsque le Client ou des tiers sont intervenus sur les éléments des Services sans l’accord préalable du Prestataire.": "Il Fornitore non può essere tenuto ad alcuna garanzia, in particolare quando il Cliente ha modificato o ha fatto modificare i Servizi o ha utilizzato servizi diversi dai Servizi forniti dal Fornitore, senza il suo previo consenso scritto o quando il Cliente o terzi sono intervenuti su elementi dei Servizi senza il previo consenso del Fornitore.",
    "Le Prestataire peut décider de céder ou transférer les droits ou obligations que lui confère le présent Contrat sous réserve que le Client bénéficie des Services dans les mêmes conditions.": "Il Fornitore può decidere di cedere o trasferire i diritti o gli obblighi conferitigli dal presente Contratto a condizione che il Cliente benefici dei Servizi alle stesse condizioni.",
    "Le Prestataire peut mettre fin aux Services fournis au Client en cas de manquement par le Client à ses obligations au titre du Contrat, non réparé dans un délai de quinze (15) jours à compter de la notification par le Prestataire de ce manquement par lettre recommandée avec accusé de réception, indépendamment de la possibilité pour le Prestataire de demander le paiement de dommages et intérêts.": "Il Fornitore può risolvere i Servizi forniti al Cliente in caso di violazione da parte del Cliente dei suoi obblighi ai sensi del Contratto, alla quale non viene posto rimedio entro quindici (15) giorni dalla notifica da parte del Fornitore di tale violazione tramite lettera raccomandata. con avviso di ricevimento, indipendentemente dalla possibilità per il Fornitore di richiedere il risarcimento dei danni.",
    "Le Prestataire peut également mettre fin au Contrat en cas de non paiement de la ou des facture(s) non acquittée(s) par le Client.": "Il Fornitore può risolvere il Contratto anche in caso di mancato pagamento della/e fattura/e non pagata/e dal Cliente.",
    "Le Prestataire se réserve le droit de modifier ces CGV, les Services et les tarifs à tout moment et sans préavis. Ces modifications n'auront aucune incidence sur les Commandes en cours.": "Il Fornitore si riserva il diritto di modificare le presenti CGV, i Servizi e i prezzi in qualsiasi momento e senza preavviso. Tali modifiche non avranno alcun impatto sugli Ordini in corso.",
    "Le Prestataire se réserve le droit de modifier les présentes CGV et d’en notifier le Client. Si une modification substantielle des termes des CGV n’est pas acceptable par le Client, celui-ci dispose d’un délai de quinze (15) jours à compter de la notification par le Prestataire des modifications pour en informer le Prestataire. En cas de désaccord des Parties sur ces modifications, les Parties pourront résilier le Contrat.": "Il Fornitore si riserva il diritto di modificare le presenti CGV e di comunicarlo al Cliente. Se una modifica sostanziale dei termini delle CGV di Vendita non è accettabile per il Cliente, il Cliente dispone di un periodo di quindici (15) giorni dalla notifica delle modifiche da parte del Fornitore per informare il Fornitore. In caso di disaccordo tra le Parti su tali modifiche, le Parti potranno risolvere il Contratto.",
    "Le Prestataire se réserve le droit de ne pas accepter une Commande de la part du Client lorsque le Prestataire a déjà rencontré des problèmes de paiement (non-paiement ou retard de paiement) avec le Client pour une ou plusieurs Commande(s) précédente(s).": "Il Fornitore si riserva il diritto di non accettare un Ordine del Cliente quando ha già riscontrato problemi di pagamento (mancato pagamento o ritardo di pagamento) con il Cliente per uno o più Ordini precedenti.",
    "Le Prestataire se réserve également le droit de mentionner les réalisations effectuées pour le Client sur ses documents de communication externe, de publicité (site internet, portfolio, etc.) et lors de démarchages de prospection commerciale.": "Il Fornitore si riserva inoltre il diritto di menzionare i risultati realizzati per il Cliente nei suoi documenti di comunicazione esterna e pubblicitaria (sito web, portfolio, ecc.) e durante la prospezione commerciale.",
    "Le Prestataire s’engage à informer de manière régulière le Client de l’avancée de la réalisation du projet et ce, notamment, au travers de validations soumises au Client dans le rétro-planning tel que prévu dans le devis.": "Il Fornitore si impegna a informare regolarmente il Cliente sullo stato di avanzamento del progetto, in particolare attraverso le convalide presentate al Cliente nella pianificazione retrospettiva prevista nel preventivo.",
    "Le changement de template a échoué.": "Cambio di modello non riuscito.",
    "Le code promo est expiré.": "Il codice sconto non è più valido.",
    "Le code promo n'existe pas.": "Il codice sconto non risulta.",
    "Le droit de reproduction comprend notamment, et de manière non exhaustive": "Il diritto di riproduzione comprende in particolare e in modo non esaustivo",
    "Le droit de représentation comprend notamment, et de manière non exhaustive": "Il diritto di rappresentazione comprende in particolare, e non esaustivamente",
    "Le fait que le Prestataire ne se prévale pas à un moment donné d'une quelconque condition des présentes CGV ne peut être interprété comme valant renonciation à se prévaloir ultérieurement de quelconque desdites conditions.": "Il fatto che il Fornitore di servizi non si avvalga in un dato momento di una qualsiasi delle presenti CGV non può essere interpretato come una rinuncia al suo diritto di avvalersi successivamente di una qualsiasi di dette condizioni.",
    "Le lien a été copié": "Link copiato",
    "Le message d'erreur": "Il messaggio d'errore",
    "Le paiement du solde des Services doit être effectué dans un délai de trente (30) jours à compter de la date de la facture émise par le Prestataire et pourra être effectué sous forme de chèque, de virement, ou de prélèvement automatique à l’ordre du Prestataire.": "Il pagamento del saldo dei Servizi deve essere effettuato entro trenta (30) giorni dalla data della fattura emessa dal Fornitore e può essere effettuato sotto forma di assegno, bonifico o addebito diretto sull'ordine del Fornitore.",
    "Le projet": "Il progetto",
    "Le template n'est pas disponible dans la langue sélectionnée.": "Il template non è disponibile nella lingua selezionata.",
    "Le terme \"Client\" désigne toute personne morale ou physique, ayant requis les compétences du Prestataire.": "Il termine “Cliente” designa qualsiasi persona fisica o giuridica, che abbia richiesto le competenze del Fornitore.",
    "Le terme \"Tiers\" désigne toute personne physique ou morale non partie à ces Conditions Générales de Vente (CGV).": "Con il termine \"Terza Parte\" si intende qualsiasi persona fisica o giuridica che non sia parte delle presenti CGV di Vendita (CGV).",
    "Le terme le \"Prestataire\" désigne la société SYNAPSE, Société par action simplifiée au capital de 200 000 euros, immatriculée au Registre du Commerce et des Sociétés de Grenoble sous le numéro RCS 452156102, et dont le siège social est situé 19 chemin des prés, 38240 Meylan.": "Il termine \"Fornitore\" si riferisce a Synapse, una società congiunta semplificata con capitale azionario di 200.000 euro, registrata presso la Camera di Commercio di Grenoble con il numero RCS 452156102 e la cui sede legale si trova al 19 Chemin Dès Prés, 3840 Meylan",
    "Le texte descriptif de votre clap (facultatif)": "Il testo descrittivo del tuo clap (opzionale)",
    "Le texte descriptif de votre vidéo (facultatif)": "Il testo descrittivo del tuo clap (opzionale)",
    "Le versement de l’acompte conditionnera la mise en œuvre des Services. Le paiement de l’acompte pourra être effectué sous forme de chèque, de virement, ou de prélèvement automatique à l’ordre du Prestataire.": "Il versamento dell'acconto condizionerà l'esecuzione dei Servizi. Il pagamento dell'acconto può essere effettuato sotto forma di assegno, bonifico o addebito diretto intestato al Fornitore.",
    "Les CGV s'appliquent aux seuls professionnels à l’exclusion des consommateurs. A ce titre, le Client reconnaît avoir la qualité de professionnel, conformément aux dispositions du Code de la consommation applicables.": "Il Fornitore si riserva il diritto di modificare le presenti CGV, i Servizi e i prezzi in qualsiasi momento e senza preavviso. Tali modifiche non avranno alcun impatto sugli Ordini in corso.",
    "Les CGV sont systématiquement adressées ou remises à chaque Client avant la passation de toute commande (la Commande). En conséquence, le fait de passer Commande implique l'adhésion entière et sans réserve du Client à ces CGV, à l'exclusion de tous autres documents en sa possession tels que prospectus, catalogues ou plaquettes publicitaires émis par le Prestataire, lesquels n'auront qu'une valeur indicative et non contractuelle.": "Le CGV vengono sistematicamente inviate o consegnate a ciascun Cliente prima dell'effettuazione di qualsiasi ordine (l'Ordine). Di conseguenza, l'effettuazione di un Ordine implica l'accettazione piena e senza riserve da parte del Cliente delle presenti CGV, ad esclusione di tutti gli altri documenti in suo possesso come prospetti, cataloghi o opuscoli pubblicitari emessi dal Fornitore di servizi, che avranno solo valore indicativo e extracontrattuale.",
    "Les Parties": "Le parti",
    "Les Parties ne peuvent être considérées comme responsables ou ayant failli à leurs obligations contractuelles, lorsque le défaut d'exécution des obligations respectives a pour origine la force majeure telle que définie par la jurisprudence des tribunaux français. Le Contrat entre les parties est suspendu jusqu'à l'extinction des causes ayant engendrées la force majeure. La force majeure prend en compte des faits ou circonstances irrésistibles, extérieurs aux parties, imprévisibles et indépendants de la volonté des parties, malgré tous les efforts raisonnablement possibles pour les empêcher.": "Le Parti non possono essere considerate responsabili o inadempienti ai loro obblighi contrattuali, quando l'inadempimento dei rispettivi obblighi è causato da forza maggiore come definito dalla giurisprudenza dei tribunali francesi. Il Contratto tra le parti è sospeso fino all'estinzione delle cause che hanno dato luogo all'evento di forza maggiore. La forza maggiore tiene conto di fatti o circostanze irresistibili, esterni alle parti, imprevedibili e fuori dal controllo delle parti, nonostante tutti gli sforzi ragionevolmente possibili per prevenirli.",
    "Les Services peuvent être fournis soit à distance, soit dans les locaux du Client, au choix du Prestataire et du Client.": "I Servizi possono essere forniti sia a distanza che presso la sede del Cliente, a scelta del Fornitore e del Cliente.",
    "Les conditions tarifaires du Prestataire relatives à la fourniture des Services sont prévues dans le devis du Prestataire.": "Le condizioni tariffarie del Fornitore relative alla fornitura dei Servizi sono previste nel preventivo del Fornitore.",
    "Les logiciels, données, documentations, procédés, méthodologies, technologies et documents appartenant au Prestataire (ci-après Droits de Propriété Intellectuelle) utilisés dans le cadre de la mise en œuvre des Services restent la propriété exclusive du Prestataire.": "Il software, i dati, la documentazione, i processi, le metodologie, le tecnologie e i documenti appartenenti al Fornitore (di seguito Diritti di proprietà intellettuale) utilizzati nel contesto dell'implementazione dei Servizi rimangono di proprietà esclusiva del Fornitore.",
    "Les mots de passe ne correspondent pas": "Le password non corrispondono",
    "Les mots de passe ne correspondent pas.": "Le password non corrispondono.",
    "Les paramètres ont été enregistrés.": "Modifiche salvate",
    "Les prix des Services n’incluent pas les éventuels frais de déplacement ou d’hébergement qui pourraient être facturés en sus par le Prestataire au Client selon les modalités indiquées dans le devis.": "I prezzi dei Servizi non comprendono eventuali spese di viaggio o soggiorno che potranno essere ulteriormente fatturate dal Fornitore al Cliente secondo i termini indicati nel preventivo.",
    "Les prix des Services sont exprimés et payables en Euros et sont exprimés hors taxe sur la valeur ajoutée et hors toute autre taxe, le Client étant responsable du paiement desdites taxes.": "I prezzi dei Servizi sono espressi e pagabili in Euro e sono espressi al netto dell'imposta sul valore aggiunto e di qualsiasi altra imposta, essendo il Cliente responsabile del pagamento di tali imposte.",
    "Les prix sont donnés à titre indicatif et sont donc susceptibles de variation. Le prix facturé est celui prévu dans la Commande validée par le Prestataire.": "I prezzi sono forniti a solo scopo informativo e sono pertanto soggetti a variazioni. Il prezzo fatturato è quello previsto nell'Ordine convalidato dal Fornitore di servizi.",
    "Les présentes CGV ont pour objet de définir les droits et obligations des Parties lors de la réalisation de prestations de services réalisées par le Prestataire pour le Client dans le cadre de ses activités.": "Lo scopo delle presenti CGV è definire i diritti e gli obblighi delle parti durante la fornitura di servizi da parte del Fornitore al Cliente nell'ambito delle sue attività.",
    "Les présentes Conditions Générales de Vente (ci-après désignées les \"CGV\") s'appliquent à tout contrat conclu entre le Prestataire et le Client, dans le cadre de la fourniture de prestations de services (les Services) telles que définies dans le devis validé par le Client.": "Le presenti CGV di Vendita (di seguito “CGV”) si applicano a qualsiasi contratto concluso tra il Fornitore e il Cliente, nell'ambito della fornitura di servizi (i Servizi) come definito nel preventivo convalidato dal Cliente .",
    "Les présentes Conditions Générales de Vente sont à jour au 1 janvier 2021.": "Le presenti Condizioni Generali di Vendita sono in vigore dal 1° gennaio 2021.",
    "Les tarifs sont HT.": "Le tariffe sono al netto delle imposte.",
    "Les œuvres créées par le Prestataire pour le Client, dans le cadre de l’exécution du Contrat, demeurent la propriété entière et exclusive du Prestataire tant que les factures émises par le Prestataire ne sont pas entièrement acquittées par le Client.": "Le opere realizzate dal Fornitore per il Cliente, nell'ambito dell'esecuzione del Contratto, rimangono di piena ed esclusiva proprietà del Fornitore finché le fatture emesse dal Fornitore non vengono interamente pagate dal Cliente.",
    "Lier un compte": "Collegare un account",
    "Logo de l'agence": "Logo dell'agenzia",
    "Légende pour": "Didascalia per",
    "L’acceptation du devis et de la Commande du Client qui en découle, doit s’accompagner du paiement d’un acompte tel qu’indiqué dans le devis.": "L'accettazione del preventivo ed il conseguente Ordine del Cliente dovranno essere accompagnati dal versamento di un acconto come indicato nel preventivo.",
    "L’intelligence artificielle est en bêta chez clap.video": "l'intelligenza artificiale è in versione beta su clap.video",
    "Ma vidéo": "Il mio video",
    "Maison de campagne 156m²": "Casa di campagna 156m²",
    "Marques et dénominations sociales": "Marchi e denominazioni sociali",
    "Mensuel": "Mensile",
    "Merci de réessayer en cliquant": "Prego ritentare cliccando",
    "Merci pour votre abonnement ! Bienvenue sur clap.video premium.": "Grazie per essersi abbonato! Benvenuto su Clap.video premium.",
    "Mes informations": "Le mie informazioni",
    "Mes vidéos": "I miei clap",
    "Mise à jour d'une vidéo": "Aggiornamento di un video",
    "Modalités d’exécution des Services et livraison des livrables": "Termini di esecuzione dei Servizi e consegna dei risultati finali",
    "Modifications des CGV – cession du Contrat": "Modifiche alle CGV – cessione del Contratto",
    "Modifier": "Modifica",
    "Mon compte": "Il mio account",
    "Mot de passe": "Password",
    "Mot de passe actuel": "Password attuale",
    "Mot de passe oublié": "Password dimenticata",
    "Moyen de paiement": "Metodo di pagamento",
    "Musique": "Musica",
    "Médias": "Media",
    "NOUVEAU": "NOVITÀ",
    "Nom": "Cognome",
    "Nom de l'entreprise": "Nome dell'agenzia",
    "Non sollicitation": "Proposte di impiego",
    "Notification": "Notifiche",
    "Notifications": "Notifiche",
    "Nous avons trouvé des photos provenant votre annonce web, cliquez sur celle que vous souhaitez ajouter à votre vidéo": "Abbiamo trovato delle foto dal tuo annuncio web, clicca su quella che desideri aggiungere al tuo video",
    "Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles. Nous utilisons un cryptage pour protéger les informations sensibles transmises en ligne. Nous protégeons également vos informations hors ligne. Seuls les employés qui ont besoin d’effectuer un travail spécifique (par exemple, la facturation ou le service à la clientèle) ont accès aux informations personnelles identifiables. Les ordinateurs et serveurs utilisés pour stocker des informations personnelles identifiables sont conservés dans un environnement sécurisé.": "Implementiamo una serie di misure di sicurezza per mantenere le tue informazioni personali al sicuro. Utilizziamo la crittografia per proteggere le informazioni sensibili trasmesse online. Proteggiamo le tue informazioni anche offline. Solo i dipendenti che devono svolgere un lavoro specifico (ad esempio, fatturazione o servizio clienti) hanno accesso alle informazioni di identificazione personale. I computer e i server utilizzati per archiviare informazioni di identificazione personale sono conservati in un ambiente sicuro.",
    "Nous ne vendons, n’échangeons et ne transférons pas vos informations personnelles identifiables à des tiers. Cela ne comprend pas les tierce parties de confiance qui nous aident à exploiter notre site Web ou à mener nos affaires, tant que ces parties conviennent de garder ces informations confidentielles.": "Non vendiamo, scambiamo o trasferiamo le tue informazioni di identificazione personale a terzi. Ciò non include terze parti fidate che ci assistono nella gestione del nostro sito Web o nella conduzione della nostra attività, a condizione che tali parti accettino di mantenere riservate queste informazioni.",
    "Nous pensons qu’il est nécessaire de partager des informations afin d’enquêter, de prévenir ou de prendre des mesures concernant des activités illégales, fraudes présumées, situations impliquant des menaces potentielles à la sécurité physique de toute personne, violations de nos conditions d’utilisation, ou quand la loi nous y contraint.": "Riteniamo che sia necessario condividere le informazioni al fine di indagare, prevenire o intraprendere azioni riguardanti attività illegali, sospette frodi, situazioni che comportano potenziali minacce alla sicurezza fisica di qualsiasi persona, violazioni dei nostri termini di utilizzo o quando la legge lo richiede per farlo.",
    "Nous recueillons des informations lorsque vous visitez notre site, vous inscrivez sur notre site et lorsque vous vous connectez à votre compte. Les informations recueillies incluent votre prénom, votre nom, votre entreprise, votre adresse e-mail et votre numéro de téléphone (optionnel). En outre, nous recevons et enregistrons automatiquement des informations à partir de votre ordinateur et navigateur, y compris votre adresse IP, vos logiciels, votre matériel et la page que vous demandez.": "Raccogliamo informazioni quando visiti il ​​nostro sito, ti registri sul nostro sito e quando accedi al tuo account. Le informazioni raccolte includono nome, cognome, azienda, indirizzo email e numero di telefono (facoltativo). Inoltre, riceviamo e registriamo automaticamente informazioni dal tuo computer e browser, inclusi il tuo indirizzo IP, il software, l'hardware e la pagina richiesta.",
    "Nous récupérons vos biens": "Stiamo recuperando gli annunci",
    "Nous utilisons des cookies. Nos cookies améliorent l’accès à notre site et identifient les visiteurs réguliers. Les cookies sont également conservés par nos partenaires Google Analytics et Hubspot afin d'améliorer l’expérience utilisateur grâce au suivi et au ciblage de ses intérêts. Cependant, cette utilisation des cookies n’est en aucune façon liée à des informations personnelles identifiables sur notre site.": "Utilizziamo i cookie. I nostri cookie migliorano l'accesso al nostro sito e identificano i visitatori abituali. I cookie vengono memorizzati anche dai nostri partner Google Analytics e Hubspot per migliorare l'esperienza dell'utente monitorando e mirando ai suoi interessi. Tuttavia, questo utilizzo dei cookie non è in alcun modo collegato ad alcuna informazione di identificazione personale sul nostro sito.",
    "Nous utilisons l’adresse e-mail que vous fournissez pour vous envoyer des informations et mises à jour relatives à votre commande, des nouvelles de l’entreprise de façon occasionnelle, des informations sur des produits liés, etc. Si à n’importe quel moment vous souhaitez vous désinscrire et ne plus recevoir d’e-mails, des instructions de désabonnement détaillées sont incluses en bas de chaque e-mail.": "Utilizziamo l'indirizzo e-mail fornito per inviarti informazioni e aggiornamenti relativi al tuo ordine, notizie occasionali dell'azienda, informazioni sui prodotti correlati, ecc. Se in qualsiasi momento desideri annullare l'iscrizione alla ricezione di e-mail, le istruzioni dettagliate per annullare l'iscrizione sono incluse nella parte inferiore di ciascuna e-mail.",
    "Nouveau": "Nuovo",
    "Nouveau mot de passe": "Nuova password",
    "Nouvelle vidéo": "Nuovo video",
    "N° de téléphone pro": "Telefono professionale",
    "Obligations des Parties": "Obblighi delle Parti",
    "Obligations du Client": "Obblighi del Cliente",
    "Obligations du Prestataire": "Obblighi del Fornitore",
    "Obtenir un lien de téléchargement": "Ottenere un link per il download",
    "On s'occupe de tout...": "Ci occupiamo di tutto...",
    "Oui, supprimer": "Sì, eliminare",
    "Oups !": "Ops!",
    "Paiement": "Pagamento",
    "Par ailleurs, le Prestataire ne peut être tenu pour responsable de l’inexécution du Contrat en cas de force majeure telle que définie à l’article 15, et en cas de dommages du fait d’un tiers ou imputables à une mauvaise utilisation ou une utilisation non-conforme par le Client des Services, en violation des prescriptions du Prestataire ou des règles de l’art.": "Inoltre, il Fornitore non potrà essere ritenuto responsabile per l'inadempimento del Contratto in caso di forza maggiore, come definito all'articolo 15, e in caso di danni causati da terzi o imputabili ad un uso improprio o non conforme da parte del Fornitore Cliente dei Servizi, in violazione delle prescrizioni del Fornitore o delle norme dell'art.",
    "Par défaut": "Predefinito",
    "Paramètres": "Impostazioni",
    "Partage automatique": "Condivisione automatica",
    "Partager sur les réseaux sociaux": "Condividere sui social",
    "Passer Premium": "Passare a Premium",
    "Passer Premium !": "Passare a Premium!",
    "Paysage": "Paesaggio",
    "Personnalisation des couleurs": "Colori personalizzati",
    "Personnaliser": "Personalizza",
    "Personnaliser votre expérience": "Personalizzare la tua esperienza",
    "Personnelles": "Personali",
    "Politique de confidentialité": "Politica sulla privacy",
    "Portrait": "Ritratto",
    "Pour l'intégrer à votre logiciel": "Per integrarlo nel suo programma",
    "Pour partager cette page": "Per condividere questa pagina",
    "Pour permettre au Prestataire de réaliser sa mission, le Client s’engage à": "Per consentire al Fornitore di svolgere la propria missione, il Cliente si impegna a",
    "Pour récupérer vos photos et pré-remplir votre vidéo": "Per recuperare le sue foto e precompilare il suo video",
    "Premium": "Premium",
    "Prix": "Prezzo",
    "Prix de l'annonce": "Prezzo dell'annuncio",
    "Prochain prélèvement le": "Prossima fattura il",
    "Professionnelles": "Professionali",
    "Profil": "Profilo",
    "Projet supprimé.": "Progetto eliminato.",
    "Propriété intellectuelle": "Proprietà intellettuale",
    "Prénom": "Nome",
    "Publication en cours sur": "Pubblicazione in corso su",
    "Publier": "Pubblica",
    "Publier votre clap sur vos réseaux sociaux": "Pubblicare il vostro clap sui social",
    "Publier votre vidéo sur vos réseaux sociaux": "Pubblicare il video sui social",
    "Pénalités de retard": "Penalità per ritardi sui pagamenti",
    "Quand un clap est généré": "Quando un Clap è generato",
    "Recevoir un mail quand une vidéo est générée": "Ricevere una mail quando un video è generato",
    "Recherche sur le web": "Ricerca sul web",
    "Rechercher mon annonce": "Cercare il mio annuncio",
    "Rechercher un bien par titre, référence ou prix.": "Ricerca di un annuncio per titolo, riferimento o prezzo.",
    "Rechercher un clap": "Cercare un clap",
    "Rechercher un clap...": "Cercare un Clap...",
    "Rechercher une annonce": "Selezionare un annuncio",
    "Rechercher votre annonce sur le web en un coup de baguette": "Cercare il suo annuncio sul web con un tocco di bacchetta.",
    "Refus": "Diritto di rifiuto",
    "Remettre au Prestataire le devis (daté, signé et tamponné).": "Fornire al Fornitore il preventivo (datato, firmato e timbrato).",
    "Rendre publique et visible votre adresse postale professionnelle dans chaque vidéo que vous créez.": "Rendere pubblico e visibile l'indirizzo professionale.",
    "Responsabilités": "Responsabilità",
    "Retapez votre mot de passe": "Confermare la password",
    "Retapez votre nouveau mot de passe": "Riscriva la sua nuova password",
    "Retour": "Indietro",
    "Retour aux connecteurs": "Ritorno ai connettori",
    "Retour à l'accueil": "Tornare alla home page",
    "Retour à mes vidéos": "Tornare ai miei video",
    "Retourner à la page de connexion": "Tornare alla pagina di login",
    "Réactiver mon abonnement et annuler ma résiliation": "Riattivare il mio abbonamento e cancellare la disdetta",
    "Référence": "Riferimento",
    "Régler dans les délais prédéfinis dans le devis et dans les présentes CGV, les sommes dues au Prestataire.": "Pagare le somme dovute al Fornitore di servizi entro le scadenze predefinite nel preventivo e nelle presenti CGV.",
    "Réinitialisation du mot de passe": "Resettare la password",
    "Réseaux sociaux": "Social media",
    "Résiliation": "Disdetta",
    "Résilier": "Dare disdetta",
    "S'inscrire": "Iscrizione",
    "Sauf convention contraire dans le devis, le Contrat est réputé formé et prend effet entre les Parties à la date de réception par le Prestataire de la Commande du Client soit par email, soit par courrier postal à l’adresse du Prestataire.": "Salvo diverso accordo nel preventivo, il Contratto si considera concluso e entra in vigore tra le Parti alla data di ricezione da parte del Fornitore dell'Ordine del Cliente tramite e-mail o per posta all'indirizzo del Fornitore.",
    "Sauf disposition légale contraire, toute autre garantie, expresse ou implicite est exclue.": "Salvo diversa disposizione di legge, qualsiasi altra garanzia, espressa o implicita, è esclusa.",
    "Se conformer strictement aux préconisations techniques et aux suggestions artistiques faites par le Prestataire.": "Rispettare rigorosamente le raccomandazioni tecniche e i suggerimenti artistici forniti dal Fornitore.",
    "Se souvenir de moi": "Salvare le credenziali",
    "Si le problème persiste, n'hésitez pas à nous contacter par mail à l'adresse suivante": "Se il problema si ripresentasse, ci contatti via e-mail al",
    "Si l’une quelconque disposition de ces CGV ou son application à toute personne ou circonstance est jugée nulle, cette nullité ne concernera pas les autres dispositions ou applications de ces CGV, qui resteront en vigueur, séparément de la disposition jugée nulle. A cette fin, les dispositions de ces CGV sont déclarées autonomes.": "Se qualsiasi disposizione di queste CGV o la sua applicazione a qualsiasi persona o circostanza è ritenuta nulla, tale nullità non riguarderà le altre disposizioni o applicazioni di queste CGV, che rimarranno in vigore, separatamente dalla disposizione ritenuta nulla. A tal fine, le disposizioni delle presenti CGV si dichiarano autonome.",
    "Si vous pensez que c'est une erreur, n'hésitez pas à nous contacter par mail à l'adresse suivante": "Se pensa che sia un errore, non esiti a contattarci a questo indirizzo e-mail",
    "Site internet": "Sito internet",
    "Sont à facturer en sus": "Sono inoltre da fatturare",
    "Souhaitez vous vraiment supprimer la vidéo": "Vuoi davvero eliminare questo video",
    "Souhaitez vous vraiment supprimer le clap": "Vuoi davvero eliminare il clap",
    "Sous-traitance": "Subappaltatori",
    "Suite à votre demande, votre abonnement Premium ne sera pas renouvelé et prendra fin le": "In seguito alla sua richiesta, il suo abbonamento Premium non sarà più rinnovato e finirà il",
    "Suppression du logo clap.video": "Rimozione del logo Clap.video",
    "Suppression du logo clap.video en fin de vidéo": "Rimozione del logo Clap.video alla fine del video",
    "Supprimer le clap": "Eliminare il clap",
    "Sélectionner": "Seleziona",
    "Sélectionner l'annonce à importer": "Selezionare l'annuncio da importare",
    "Sélectionner la couleur par défaut que vous souhaitez utiliser pour vos vidéos.": "Selezioni il colore che vorrà utilizzare di default.",
    "Sélectionnez une image provenant de l’annonce web": "Seleziona un'immagine dall'annuncio web",
    "Sélectionnez votre page Facebook": "Seleziona la tua pagina Facebook",
    "Sélectionné": "Selezionato",
    "Sélectionnée": "Selezionata",
    "S’assurer de la mise à disposition de tous les moyens nécessaires pour permettre au Prestataire de réaliser les Services dans ses locaux et/ou à distance.": "Garantire la fornitura di tutti i mezzi necessari per consentire al Fornitore di eseguire i Servizi nei suoi locali e/o a distanza.",
    "Tapez un nouveau mot de passe": "Scriva la nuova password",
    "Tapez votre mot de passe actuel": "Scriva la sua attuale password",
    "Template": "Modello",
    "Template sélectionné": "Template selezionato",
    "Terminer": "Fine",
    "Terminer et générer la vidéo": "Concludi e genera il video",
    "Terminé": "Finito",
    "Titre de la vidéo": "Titolo del video",
    "Titre du clap": "Titolo del clap",
    "Toute les informations que nous recueillons auprès de vous peuvent être utilisées pour": "Tutte le informazioni che raccogliamo da te possono essere utilizzate per",
    "Toute modification ultérieure ou demande complémentaire demandée par le Client fera l’objet d’une facturation supplémentaire.": "Ogni successiva modifica o richiesta aggiuntiva richiesta dal Cliente sarà oggetto di fatturazione aggiuntiva.",
    "Toute notification devra être faite par écrit et être soit remise en mains propres, soit adressée par lettre recommandée avec accusé de réception, soit faite par acte extra judiciaire à l’adresse indiquée dans la commande.": "Qualsiasi comunicazione dovrà essere effettuata per iscritto e consegnata a mano, inviata tramite lettera raccomandata con avviso di ricevimento, oppure effettuata mediante atto extragiudiziale all'indirizzo indicato nell'ordine.",
    "Toute réserve concernant les CGV, avancée par le Client sera, donc, à défaut d'acceptation expresse par le Prestataire, inopposable à ce dernier, quel que soit le moment où elle aura pu être portée à sa connaissance.": "Qualsiasi riserva relativa alle CGV avanzata dal Cliente sarà, pertanto, in mancanza di espressa accettazione da parte del Fornitore, inopponibile nei confronti di quest'ultimo, indipendentemente dal momento in cui sia stata portata alla sua conoscenza.",
    "Toute utilisation par le Client des dénominations sociales, marques et signes distincts appartenant au Prestataire est strictement prohibée sauf en cas d’accord exprès et préalable du Prestataire. En cas d’accord exprès et préalable du Prestataire, ce dernier concède alors au Client un droit strictement personnel, non exclusif, et non transférable d'utiliser ses dénominations sociales, marques et signes distincts, dans le monde entier et pour toute la durée de validité du Contrat.": "Qualsiasi utilizzo da parte del Cliente di nomi di società, marchi e segni distintivi appartenenti al Fornitore di servizi è severamente vietato salvo consenso espresso e preventivo da parte del Fornitore di servizi. In caso di consenso espresso e preventivo del Fornitore, quest'ultimo concede al Cliente il diritto strettamente personale, non esclusivo e non trasferibile di utilizzare le sue denominazioni, marchi e segni distintivi, in tutto il mondo e per tutto il territorio durata del servizio. validità del contratto.",
    "Toutes dispositions dérogeant aux présentes CGV devront résulter d’un accord exprès des Parties, reflété dans les Commandes confirmées par le Prestataire ou tout autre document faisant foi de l’accord des deux Parties.": "Eventuali disposizioni divergenti dalle presenti CGV devono risultare da un accordo esplicito delle Parti, riflesso negli Ordini confermati dal Fornitore o in qualsiasi altro documento che dimostri l'accordo di entrambe le Parti.",
    "Travail dissimulé": "Lavoro nero",
    "Type": "Tipo",
    "Télécharger": "Download",
    "Télécharger cette vidéo": "Salvare questo video",
    "Un extrait Kbis attestant de l’immatriculation au registre du commerce et des sociétés,": "Un estratto Kbis attestante l'iscrizione nel registro del commercio e delle imprese,",
    "Un problème a été rencontré lors de la création de la vidéo. Veuillez réessayer.": "Si è verificato un problema nella creazione del video. Prego ritentare.",
    "Un problème a été rencontré lors de la création du clap. Veuillez réessayer.": "È stato riscontrato un problema durante la creazione del clap. Si prega di riprovare",
    "Un retard sur les délais indiqués ne pourra donc donner lieu au paiement de dommages et intérêts, ni autoriser le Client à résilier le Contrat où à refuser la livraison des Services.": "Un ritardo oltre i termini indicati non potrà quindi dar luogo al pagamento di danni, né autorizzare il Cliente a risolvere il Contratto o a rifiutare la fornitura dei Servizi.",
    "Une attestation sur l’honneur établie par le Prestataire, certifiant que le travail est réalisé par des salariés employés régulièrement eu égard aux articles D.8222-5, D.8222-7 et D.8222-8 du Code du travail.": "Una dichiarazione giurata rilasciata dal Fornitore di servizi, attestante che il lavoro è svolto da dipendenti regolarmente assunti in riferimento agli articoli D.8222-5, D.8222-7 e D.8222-8 del Codice del lavoro.",
    "Une copie de l’avis d’imposition afférent à la taxe professionnelle,": "Una copia dell'avviso fiscale relativo all'imposta professionale,",
    "Une erreur est survenue": "Si è verificato un errore",
    "Une erreur est survenue lors de la récupération des biens.": "Errore nel recupero degli annunci.",
    "Une erreur est survenue lors de la sélection de cette musique. Veuillez en choisir une autre.": "Si è verificato un errore nella selezione della musica.",
    "Une erreur est survenue lors du chargement de votre fichier. Veuillez essayer avec un fichier plus léger.": "Si è verificato un errore nel caticamento del file.",
    "Une erreur est survenue lors du lancement du rendu. Veuillez réessayer.": "Errore nel rendering. Prego ritentare.",
    "Une erreur est survenue. Merci de réessayer.": "Si è verificato un errore. Prego riprovare.",
    "Une erreur inconnue est survenue.": "Si è verificato un errore sconosciuto.",
    "Une erreur inconnue s'est produite ! Contactez support@clap.video.": "Si è verificato un errore sconosciuto. Prego contattare support@clap.video.",
    "Valable jusqu'au": "Valido fino al",
    "Valider": "Conferma",
    "Veuillez accepter les conditions générales de vente.": "Prego accettare le condizioni generali di vendita.",
    "Veuillez compléter toutes les images.": "Prego completare tutte le immagini.",
    "Veuillez entrer un code promo.": "Prego inserisca il codice sconto.",
    "Veuillez entrer un email et un mot de passe.": "Prego inserire e-mail e password.",
    "Veuillez entrer un titre valide": "Prego inserire un titolo valido",
    "Veuillez remplir les informations bancaires.": "Prego inserire i dati bancari.",
    "Veuillez remplir votre nom et prénom.": "Prego inserire cognome e nome.",
    "Veuillez utiliser la": "Prego usare la",
    "Vidéo automatique": "Video automatico",
    "Vidéos": "Video",
    "Vidéos illimitées": "Video illimitati",
    "Ville": "Città",
    "Voir mes autoclaps": "Vedere i miei autoclap",
    "Vos clients apprécient votre travail et votre entreprise ? Faites-le savoir en créant une vidéo présentant vos meilleurs avis.": "I suoi clienti apprezzano il suo lavoro e la sua azienda? Lo faccia sapere alle persone creando un video con le migliori recensioni.",
    "Vos modifications ont bien été enregistrées": "Modifiche salvate",
    "Votre adresse email": "Indirizzo e-mail",
    "Votre adresse postale": "Il suo indirizzo postale",
    "Votre clap est en file d'attente, veuillez patienter quelques instants avant le début de la génération.": "Il tuo clap è in coda, per favore aspetta qualche istante prima che la generazione inizi",
    "Votre code postale": "Il suo codice postale",
    "Votre couleur personnalisée": "Il suo colore personalizzato",
    "Votre demande de résiliation a bien été prise en compte.": "La richiesta di disdetta è stata ricevuta.",
    "Votre email personnel": "La sua e-mail personale",
    "Votre email professionnel": "La sua e-mail professionale",
    "Votre nom": "Il suo cognome",
    "Votre nom d'entreprise": "Il nome della sua agenzia",
    "Votre nouveau mot de passe a bien été enregistré": "Password salvata",
    "Votre numéro de téléphone professionnel": "Il suo numero di telefono professionale",
    "Votre photo de profil a bien été modifiée": "La sua foto di profilo è stata modificata",
    "Votre photo de profil professionnelle a bien été modifiée": "La sua foto di profilo professionale è stata modificata",
    "Votre prénom": "Il suo nome",
    "Votre résiliation a bien été annulée.": "La disdetta è stata cancellata.",
    "Votre vidéo est en cours de génération !": "Il suo video è in corso di creazione !",
    "Votre vidéo est en file d'attente, veuillez patienter quelques instants avant le début de la génération.": "Il suo video è in coda, prego attenda alcuni istanti prima che ne inizi la generazione.",
    "Votre ville": "La sua città",
    "Voulez-vous vraiment réactiver votre abonnement": "È sicuro di voler riattivare il suo abbonamento",
    "Voulez-vous vraiment résilier votre abonnement Premium ? Votre abonnement continuera jusqu'à la date de fin d'abonnement et ne sera pas renouvelé.": "È sicuro di voler annullare il suo abbonamento Premium? Il suo abbonamento continuerà fino alla data di fine dell'abbonamento e non verrà rinnovato.",
    "Vous avez atteint le nombre maximum de vidéos gratuites.": "Ha raggiunto il numero massimo di video gratuiti.",
    "Vous avez déjà un compte": "Ha già un account",
    "Vous contacter pour vous présenter de nouvelles fonctionnalités et offres qui pourraient vous intéresser": "Contattarti per presentarti nuove funzionalità e offerte che potrebbero interessarti",
    "Vous devez lier Facebook": "Deve collegare Facebook",
    "Vous donnez accès à notre service de création vidéo": "Darti accesso al nostro servizio di creazione video",
    "Vous n'avez aucune vidéo pour le moment.": "Non ha ancora nessun video.",
    "Vous n'avez pas de compte": "Non ha ancora un account",
    "Vous n'avez pas lié de compte": "Non ha collegato un account",
    "Vous possédez un abonnement": "Ha già un abbonamento",
    "Vous pouvez envoyer cette vidéo sur votre page Facebook en vous": "Può inviare questo video alla sua pagina Facebook",
    "Vous pouvez quitter cette page, votre vidéo sera directement ajoutée dans votre logiciel métier.": "Può lasciare questa pagina, il suo video verrà aggiunto direttamente al suo CRM.",
    "Vous pouvez résilier votre abonnement à tout moment": "Potrà cancellare l'abbonamento in qualsiasi momento",
    "Vous serez de nouveau prélevé tous les": "Sarà fatturato di nuovo ogni",
    "Vous serez prélevé automatiquement à chaque échéance.": "Sarà fatturato automaticamente a ogni scadenza.",
    "Vous êtes actuellement connecté via Topi": "È attualmente connesso via Topi",
    "Zone de danger": "Zona di pericolo",
    "agency": {
      "address": "indirizzo dell'agenzia",
      "logo": "Logo",
      "name": "Nome dell'agenzia",
      "photo": "foto",
      "web": "Sito web"
    },
    "agent": {
      "email": "Indirizzo e-mail",
      "name": "Nome dell'agente",
      "phone": "Numero di telefono",
      "photo": "Foto dell'agente",
      "web": "Sito web"
    },
    "chambre(s)": "stanza(e)",
    "conditions générales de vente": "le CGV",
    "connectant sur clap.video": "connettendosi su Clap.video",
    "connus par l’une des Parties sur une base non-confidentielle avant leur divulgation par l'autre Partie": "conosciuti da una delle Parti in via non riservata prima della loro divulgazione da parte dell'altra Parte",
    "création manuelle": "creazione manuale",
    "d'un montant de": "per un importo di",
    "de Clap.": "di Clap.",
    "depuis le": "dal",
    "divulgués en vertu d'une disposition législative ou réglementaire.": "divulgati in forza di un provvedimento legislativo o regolamentare.",
    "développés de façon indépendante par la Partie réceptrice qui n’a eu accès à aucune information de la Partie divulgatrice": "sviluppati in modo indipendente dalla Parte Ricevente che non ha avuto accesso ad alcuna informazione della Parte Divulgante",
    "fournir une solution de remplacement permettant au Client de pouvoir utiliser les Services conformément à la Commande": "ottenere qualsiasi concessione di licenza o autorizzazione per consentire al Cliente di continuare a utilizzare i Servizi",
    "ici": "qui",
    "la diffusion des œuvres créées par tout moyen, notamment par voie hertzienne, câble-satellite ainsi que par tout réseau, et plus généralement par tout moyen de transmission de données numérisées ou non.": "la diffusione delle opere realizzate con qualsiasi mezzo, in particolare radiofonico, via cavo-satellite nonché con qualsiasi rete, e più in generale con qualsiasi mezzo di trasmissione di dati digitalizzati o non digitali.",
    "le droit de diffuser et de communiquer à tout public les éléments, supports, composants des œuvres créées, par tout procédé de représentation connu ou inconnu à ce jour, pour toute utilisation quelle qu’elle soit ": "il diritto di diffondere e comunicare a qualsiasi pubblico gli elementi, i supporti, i componenti delle opere realizzate, mediante qualsiasi procedimento di rappresentazione ad oggi noto o sconosciuto, per qualsiasi utilizzo",
    "le droit de mettre en circulation et d’exploiter les œuvres créées, commercialement ou non, les reproductions ainsi réalisées, en nombre illimité, à titre gratuit ou onéreux, et ce quelle qu’en soit la destination.": "il diritto di mettere in circolazione e di sfruttare, commercialmente o meno, le opere realizzate, le riproduzioni così realizzate, in numero illimitato, gratuitamente o dietro compenso, qualunque sia la destinazione.",
    "le droit de reproduire et/ou faire reproduire les œuvres créées en nombre illimité, par tout procédé et sur tout support actuel ou futur, et nomment graphique, magnétique, numérique ou électronique (interactif ou non) ": "il diritto di riprodurre e/o far riprodurre opere realizzate in numero illimitato, con qualsiasi procedimento e su qualsiasi supporto attuale o futuro, compreso quello grafico, magnetico, digitale o elettronico (interattivo e non)",
    "les légendes de vos photos se sont complétées automatiquement, pensez à les vérifier !": "le didascalie delle foto sono state completate automaticamente, si ricordi di controllarle!",
    "les modifications demandées par le Client en cours de réalisation, si elles impliquent un remaniement du projet.": "le modifiche richieste dal Cliente durante la realizzazione, se comportano una rielaborazione del progetto.",
    "légitimement obtenus d'un tiers non tenu par une obligation de confidentialité": "ottenuti legittimamente da un soggetto terzo non vincolato da un obbligo di riservatezza",
    "ma photo": "la mia foto",
    "mon logo": "il mio logo",
    "n'existe pas ou vous ne vous appartient pas.": "non esiste o non le appartiene.",
    "nous vous notifions par email.": "le invieremo una notifica via e-mail.",
    "obtenir toute concession de licence ou autorisation pour permettre au Client de continuer à utiliser les Services": "ottenere qualsiasi concessione di licenza o autorizzazione per consentire al Cliente di continuare a utilizzare i Servizi",
    "ou": "o",
    "ou la": "o la",
    "page": "pagina",
    "paramètres de connecteurs": "parametri del connettore",
    "photos": {
      "1": "Immagine 1",
      "2": "Immagine 2",
      "3": "Immagine 3",
      "4": "Immagine 4",
      "5": "Immagine 5"
    },
    "publier votre vidéo sur Facebook en quelques clics, liez vos pages dans vos": "Per pubblicare il video su Facebook in pochi clic, colleghi le sue pagine nel suo",
    "quartier": {
      "1": "Quartiere del bene"
    },
    "realty": {
      "bedrooms": "Numero di camere da letto",
      "city": "Città",
      "furnished": "Arredato o non arredato",
      "living_area": "Superficie",
      "operation": "Operazione (vendita, noleggio)",
      "price": "Premi",
      "title": "Titolo principale",
      "type": "Tipo di proprietà (appartamento, casa)"
    },
    "recherche sur le web": "ricerca web",
    "s": "e",
    "secondes": "secondi",
    "si aucune des deux possibilités n’est réalisable, rembourser le Client des sommes versées au titre des Services, déduction faite des sommes déjà payées par le Client pour la période d’utilisation effective des Services.": "qualora nessuna delle due possibilità sia fattibile, rimborsare al Cliente le somme pagate per i Servizi, detratte le somme già pagate dal Cliente per il periodo di effettivo utilizzo dei Servizi.",
    "text": {
      "1": "Testo della ricerca",
      "2": "Titolo del testo esplicativo",
      "3": "Testo esplicativo"
    },
    "tombés ou qui tomberont dans le domaine public au jour de leur divulgation": "caduti o che diventeranno di pubblico dominio il giorno della loro divulgazione",
    "validé": "convalidato",
    "via AutoClap ou manuellement": "via AutoClap o manualmente",
    "Éditer cette vidéo": "Modificare questo video",
    "Établir un cahier des charges détaillé qui ne subira plus de modification, sauf accord des Parties, après avoir été approuvé par le Prestataire. Au besoin, le Prestataire pourra intervenir dans l’élaboration du cahier des charges, conjointement avec le Client. Dans le cas où des modifications impliqueraient un remaniement substantiel du cahier des charges initial, ces dernières seront facturées en sus du devis initial.": "Stabilire specifiche dettagliate che non saranno più soggette a modifiche, salvo accordo tra le Parti, dopo essere state approvate dal Fornitore. Se necessario, il Fornitore può intervenire nello sviluppo delle specifiche, insieme al Cliente. Nel caso in cui le modifiche comportino un sostanziale riassetto delle specifiche iniziali, queste verranno fatturate in aggiunta al preventivo iniziale.",
    "à appliquer aux Informations Confidentielles les mêmes mesures de protection que celles qu'elle applique à ses propres informations confidentielles": "applicare alle Informazioni Riservate le stesse misure di protezione che applica alle proprie informazioni riservate",
    "à clap.video.": "a Clap.video",
    "à compter du": "a partire dal",
    "à n'utiliser les Informations Confidentielles que pour les seuls besoins de réalisation des Services.": "utilizzare le Informazioni Riservate solo ai fini della prestazione dei Servizi.",
    "à ne communiquer les Informations Confidentielles qu'à ses seuls employés et collaborateurs amenés à les connaître dans le cadre de la réalisation des Services": "comunicare le Informazioni Riservate esclusivamente ai propri dipendenti e collaboratori che ne vengano a conoscenza nell'ambito dello svolgimento dei Servizi",
    "à ne pas divulguer, publier ou transmettre à des tiers les Informations Confidentielles, sous quelque forme que ce soit, sans l’accord préalable écrit de l’autre Partie": "non divulgare, pubblicare o trasmettere a terzi Informazioni Riservate, in qualsiasi forma, senza il preventivo consenso scritto dell'altra Parte"
  }], ["nl", {
    "(version longue)": "(lange versie)",
    "1. Collecte de l’information": "1. Verzameling van informatie",
    "15 templates": "15 sjablonen",
    "2. Utilisation des informations": "2. Gebruik van informatie",
    "29,90€ / mois": "€ 29,90 / maand",
    "29,90€/mois": "€ 29,90/maand",
    "299,00€ / an": "€ 299,00 / jaar",
    "299,00€/an": "€ 299,00/jaar",
    "3 templates": "3 sjablonen",
    "3. Divulgation à des tiers": "3. Openbaarmaking aan derden",
    "4. Protection des informations": "4. Bescherming van informatie",
    "5 vidéos gratuites": "5 gratis video's",
    "5. Cookies": "5. Koekjes",
    "6. Se désabonner": "6. Afmelden",
    "7. Consentement": "7. Toestemming",
    "8 caractères minimum": "Minimaal 8 tekens",
    "<": "<",
    "A ce titre, le Prestataire s’engage à prendre les mesures nécessaires afin d’assurer la protection, la sécurité et la confidentialité des données personnelles qui lui sont transmises par le Client.": "Als zodanig verbindt de Dienstverlener zich ertoe de nodige maatregelen te nemen om de bescherming, veiligheid en vertrouwelijkheid van de persoonlijke gegevens die hem door de Klant worden doorgegeven te garanderen.",
    "A défaut de dispositions spécifiques dans le devis, les délais d’exécution des Services sont communiqués au Client à titre indicatif. Les délais de réalisation des Services ne sont en aucun cas garantis par le Prestataire ni ne peuvent engager sa responsabilité, ni entraîner une obligation de payer une quelconque indemnité ou pénalité de retard, ni justifier l’annulation de la Commande en cause.": "Bij gebrek aan specifieke bepalingen in de offerte, wordt de tijd die nodig is om de Diensten te voltooien aan de Klant louter als aanwijzing gegeven. In geen geval zal de Dienstverlener de voltooiing van de Diensten garanderen of aansprakelijk kunnen worden gesteld voor de tijd die nodig is om de Diensten te voltooien, noch zal hij gehouden zijn tot betaling van enige schadevergoeding of boete voor laattijdige voltooiing, noch zal hij gehouden zijn tot annulering van de betreffende Order.",
    "A défaut de solution amiable trouvée entre les Parties, seront seuls compétents pour connaître des litiges de toutes natures ou de contestations relatives à l'interprétation ou à l'exécution du présent Contrat, les tribunaux du ressort de la Cour d’Appel de Paris, nonobstant pluralité de défendeurs, demande incidente ou référé ou appels en garantie, à moins que le Prestataire ne préfère saisir toute autre juridiction compétente.": "Bij ontstentenis van een minnelijke schikking tussen de Partijen, zullen alleen de rechtbanken binnen de jurisdictie van het Hof van Beroep van Parijs bevoegd zijn om kennis te nemen van alle soorten geschillen of geschillen met betrekking tot de interpretatie of uitvoering van dit Contract, ondanks het grote aantal gedaagden. incidentele of summiere verzoeken of garantieclaims, tenzij de Dienstverlener er de voorkeur aan geeft de zaak voor te leggen aan een andere bevoegde rechtbank.",
    "A l’expiration de ce délai de quinze (15) jours, les modifications des CGV seront définitivement considérées comme acceptées par le Client.": "Na het verstrijken van deze periode van vijftien (15) dagen worden de wijzigingen aan de Algemene Voorwaarden definitief geacht door de Klant te zijn aanvaard.",
    "Abonnement": "Abonnement",
    "Abonnement actuel": "Huidig abonnement",
    "Abonnement clap.video Premium": "clap.video Premium-abonnement",
    "Abonnement en cours": "Huidig abonnement",
    "Accepter": "Accepteren",
    "Accès à tous les templates": "Toegang tot alle templates",
    "Activer AutoClap": "AutoClap activeren",
    "Administrer un concours, une promotion, ou un enquête": "Een wedstrijd, promotie of enquête te beheren",
    "Adresse email": "E-mailadres",
    "Adresse mail": "E-mailadres",
    "Adresse postale": "Postadres",
    "Afficher votre adresse postale professionnelle dans les vidéos": "Je zakelijke postadres weergeven in video's",
    "Afin de demander la réinitialisation de votre mot de passe, veuillez renseigner votre adresse email. Un email vous sera adressé afin de vous indiquer la procédure à suivre.": "Om een wachtwoordreset aan te vragen, voert u uw e-mailadres in. Er wordt een e-mail naar u verzonden met de te volgen procedure.",
    "Ajouter un code promo": "Voeg een promotiecode toe",
    "Améliorer le service client et vos besoins de prise en charge par notre équipe support": "De klantenservice te verbeteren en uw ondersteuningsbehoeften van ons supportteam",
    "Analyser l'usage du site (données anonymisées et agrégées)": "Het sitegebruik te analyseren (geanonimiseerde en geaggregeerde gegevens)",
    "Annonce immobilière": "Vastgoedadvertentie",
    "Annonce immobilière - Choix d'une annonce dans votre logiciel métier": "Vastgoedadvertentie - Een advertentie kiezen in uw bedrijfssoftware",
    "Annuel": "Jaarlijks",
    "Annuler": "Annuleren",
    "Appliquer": "Toepassen",
    "Après encaissement des factures par le Prestataire, celui-ci cède au Client l’ensemble des droits de propriété intellectuelle sur les œuvres créées spécifiquement et à la demande du Client, dans le cadre de l’exécution du Contrat, pour leur durée de protection et pour le monde entier.": "Zodra de facturen door de Dienstverlener zijn ontvangen, draagt de Dienstverlener aan de Klant alle intellectuele eigendomsrechten over op de werken die specifiek en op verzoek van de Klant zijn gecreëerd in het kader van de uitvoering van de Overeenkomst, voor de duur van hun bescherming en voor de hele wereld.",
    "Assurance": "Verzekering",
    "Astuce": "Tip",
    "Aucun bien n'a été trouvé pour": "Er is geen woning gevonden voor",
    "Aucun changement ni aucune modification du Contrat, notamment sur les caractéristiques des Services, ne sera pris en considération s'il n'a pas été accepté par écrit par le Prestataire.": "Geen enkele wijziging of aanpassing van de Overeenkomst, in het bijzonder van de kenmerken van de Diensten, zal in aanmerking worden genomen tenzij schriftelijk aanvaard door de Dienstverlener.",
    "Aucun escompte n’est prévu en cas de paiement anticipé.": "Er is geen korting voor vroegtijdige betaling.",
    "Aucun résultat": "Geen resultaat",
    "Aucun résultat.": "Geen resultaten.",
    "Aucune annonce n'a pu être trouvée.": "Er zijn geen advertenties gevonden.",
    "AutoClap génère automatiquement une video quand vous publiez un nouveau bien sur votre outil métier.": "AutoClap genereert automatisch een video wanneer je een nieuwe woning",
    "Autonomie des clauses": "Autonomie van clausules",
    "Avant chaque intervention du Prestataire, le Client s’engage à réaliser toutes les procédures de sauvegarde nécessaires à la protection et à la sauvegarde de ses données, programmes et fichiers informatiques.": "Voorafgaand aan elke interventie door de Dienstverlener verbindt de Klant zich ertoe alle nodige back-upprocedures uit te voeren om zijn gegevens, programma's en computerbestanden te beschermen en te beveiligen.",
    "Avec l’abonnement Premium, vous profiterez de clap.video dans son intégralité et débloquerez les fonctionnalités suivantes": "Met het Premium-abonnement geniet je in zijn geheel van clap.video en ontgrendel je de volgende functies",
    "Avis clients": "Klantrecensies",
    "Bientôt disponible": "Binnenkort beschikbaar",
    "Bienvenue sur Clap !": "Welkom bij clap!",
    "Bienvenue sur clap.video !": "Welkom bij clap.video!",
    "Carré": "Vierkant",
    "Carte bancaire": "Bankkaart",
    "Ces CGV régissent intégralement les relations entre le Prestataire et le Client. Aucune condition générale d'achat ne pourra prévaloir ni être opposée par le Client au Prestataire et aucune condition particulière communiquée par le Client au Prestataire ne peut prévaloir sur les CGV, sauf acceptation formelle et écrite du Prestataire.": "Deze AV beheersen de volledige relatie tussen de Dienstverlener en de Klant. Geen enkele algemene aankoopvoorwaarde zal voorrang hebben of afgedwongen worden door de Klant ten opzichte van de Dienstverlener en geen enkele bijzondere voorwaarde die door de Klant aan de Dienstverlener wordt meegedeeld zal voorrang hebben op de AV, tenzij formeel schriftelijk aanvaard door de Dienstverlener.",
    "Ces cookies ne sont pas indispensables au fonctionnement du site, mais ils facilitent votre navigation. Vous pouvez les bloquer ou les supprimer, mais vous risquez alors d'empêcher le bon fonctionnement de certains éléments du site.": "Deze cookies zijn niet essentieel voor de werking van de site, maar maken uw navigatie eenvoudiger. U kunt ze blokkeren of verwijderen, maar dan loopt u het risico dat bepaalde elementen van de site niet goed functioneren.",
    "Ces pénalités de retard sont exigibles de plein droit et sans qu’un rappel par le Prestataire ne soit nécessaire.": "Deze boetes voor laattijdige betaling zijn van rechtswege verschuldigd, zonder dat de Dienstverlener een herinnering hoeft te sturen.",
    "Cette abonnement sera automatiquement renouvelé le": "Dit abonnement wordt automatisch verlengd op",
    "Cette action est irréversible.": "Deze actie is onomkeerbaar",
    "Cette disposition ne peut être remplacée par un accord verbal.": "Deze bepaling kan niet worden vervangen door een mondelinge overeenkomst.",
    "Cette publication sera postée sur": "Deze publicatie wordt geplaatst op",
    "Chacune des Parties renonce, sauf accord écrit préalable, à faire directement ou indirectement des offres d’engagement à un collaborateur de l’autre Partie ayant travaillé dans le cadre des Services, objet du présent Contrat, ou à le prendre à son service, sous quelque statut que ce soit. Cette renonciation est valable pour une durée de deux (2) ans à compter de la fin du présent Contrat.": "Elk van de Partijen ziet af om, zonder voorafgaande schriftelijke overeenkomst, rechtstreeks of onrechtstreeks werkaanbiedingen te doen aan een werknemer van de andere Partij die heeft gewerkt in het kader van de Diensten waarop dit Contract betrekking heeft, of om hem in dienst te nemen, op welke wijze dan ook. status het mag zijn. Deze afstandsverklaring is geldig voor een periode van twee (2) jaar vanaf het einde van dit Contract.",
    "Chacune des Parties s’engage à maintenir en vigueur, pendant toute la durée de réalisation du Contrat, auprès d’une compagnie d’assurance notoirement solvable, une police d’assurance garantissant les dommages pouvant survenir à ses biens et personnel, ainsi qu’une police couvrant sa responsabilité professionnelle, de manière à couvrir les conséquences pécuniaires des dommages corporels, matériels et immatériels dont elles auraient à répondre, causés par tout évènement et qui serait le fait de ses collaborateurs et/ou sociétés partenaires éventuels lors de l’exécution du Contrat.": "Elk van de Partijen verbindt zich ertoe om tijdens de looptijd van het Contract bij een gerenommeerd solvabele verzekeringsmaatschappij een verzekeringspolis van kracht te houden die de schade garandeert die kan optreden aan zijn eigendommen en personeel, evenals een polis die hun beroepsaansprakelijkheid dekt, zodat ter dekking van de financiële gevolgen van lichamelijke, materiële en immateriële schade waarvoor zij zouden moeten instaan, veroorzaakt door welke gebeurtenis dan ook en die het werk zou zijn van hun medewerkers en/of eventuele partnerbedrijven tijdens de uitvoering van het Contract.",
    "Changer": "Veranderen",
    "Chaque Partie s’engage": "Elke partij verbindt zich ertoe",
    "Chargement...": "Laden...",
    "Choisir une autre image sur l’annonce": "Kies een andere afbeelding uit de advertentie",
    "Choisir une musique": "Muziek kiezen",
    "Choisir une page": "Kies een pagina",
    "Choisissez la langue de l'interface": "Kies de interfacetaal",
    "Choisissez un template": "Kies een sjabloon",
    "Choisissez votre type de vidéo": "Kies je videotype",
    "Code postal": "Postcode",
    "Code postale": "Postcode",
    "Code promo": "Promotiecode",
    "Code promotionnel": "Promotiecode",
    "Collaborer activement à la réussite du projet en apportant au Prestataire dans les délais préalablement définis toutes les informations et tous les documents nécessaires à la bonne appréhension des besoins et à la bonne exécution des Services.": "Actief bijdragen aan het succes van het project door de Dienstverlener te voorzien van alle informatie en documenten die nodig zijn voor een goed begrip van de behoeften van de Klant en de correcte uitvoering van de Diensten, binnen de vooraf vastgestelde termijnen.",
    "Collaborer étroitement avec le Prestataire et fournir toute information, documentation, prestation, et tous moyens utiles pour la réalisation des Services et s’engage à mettre à disposition du Prestataire tous les éléments permettant de satisfaire à son obligation, incluant le personnel dédié à la bonne réalisation des Services.": "Nauw samen te werken met de Dienstverlener en alle informatie, documentatie, diensten en middelen te leveren die nodig zijn voor de uitvoering van de Diensten en de Dienstverlener te voorzien van alle elementen die nodig zijn om zijn verplichting na te komen, met inbegrip van het personeel dat bestemd is voor de goede uitvoering van de Diensten.",
    "Commencer": "Beginnen",
    "Complément d'adresse postale": "Extra postadres",
    "Concernant les logiciels, données ou documents utilisés par le Prestataire dans le cadre de la fourniture des Services, dont le Client a acquis les droits d’utilisation auprès de tiers ou dont il est propriétaire, le Client garantit le Prestataire de toutes les conséquences ou suites dommageables que le Prestataire aurait à subir au titre de l’utilisation desdits logiciels, données ou documents contre toute action de la part d’une personne revendiquant un droit de propriété intellectuelle ou se fondant sur une demande en concurrence déloyale et/ou parasitisme sur ces logiciels, données ou documents.": "Met betrekking tot de door Dienstverlener in het kader van de dienstverlening gebruikte programmatuur, gegevens of documenten, waarvoor Klant de gebruiksrechten van derden heeft verkregen of waarvan hij eigenaar is, garandeert Klant Dienstverlener tegen alle gevolgen of gevolgen die de Dienstverlener zou moeten lijden in verband met het gebruik van genoemde software, gegevens of documenten tegen enige actie van een persoon die aanspraak maakt op een intellectueel eigendomsrecht of gebaseerd is op een verzoek tot oneerlijke concurrentie en/of. of parasitisme op deze software, gegevens of documenten.",
    "Conclusion du Contrat": "Aangaan van de Overeenkomst",
    "Conditions Générales de Vente - clap.video": "Algemene verkoopvoorwaarden - clap.video",
    "Conditions de paiement": "Betalingsvoorwaarden",
    "Confidentialité": "Privacy",
    "Confirmation de suppression": "Bevestiging van verwijdering",
    "Confirmation du nouveau mot de passe": "Nieuw wachtwoord bevestigen",
    "Confirmer le mot de passe": "Bevestig wachtwoord",
    "Confirmer le paiement": "Bevestig de betaling",
    "Confirmez votre mot de passe": "Bevestig uw wachtwoord",
    "Conformément aux dispositions de la Loi Informatique et Libertés n° 78-17 du 6 janvier 1978 telle que modifiée, le Client dispose de la qualité de responsable de traitement dans le cadre de l’exécution du Contrat.": "In overeenstemming met de bepalingen van de Gegevensbeschermingswet nr. 78-17 van 6 januari 1978, zoals gewijzigd, heeft de Klant de hoedanigheid van gegevensbeheerder in het kader van de uitvoering van het Contract.",
    "Conformément aux dispositions du Règlement Général sur la Protection des Données (RGPD) entré en vigueur le 25 mai 2018, le Client dispose notamment d’un droit d’accès, de rectification, d’opposition et de portabilité de ses données personnelles. La garantie de ces droits est affirmée au sein de la politique de confidentialité qui accompagne les présentes, et qui en conséquence, forment un ensemble contractuel.": "In overeenstemming met de bepalingen van de Algemene Verordening Gegevensbescherming (AVG) die op 25 mei 2018 van kracht werd, beschikt de Klant in het bijzonder over een recht op toegang, rectificatie, verzet en overdraagbaarheid van zijn persoonsgegevens. De garantie van deze rechten wordt bevestigd in het vertrouwelijkheidsbeleid dat bij dit document hoort en dat bijgevolg een contractueel geheel vormt.",
    "Connecter l'ensemble de vos plateformes à clap.video": "Verbind al uw platforms met clap.video",
    "Connecteurs": "Connectoren",
    "Connectez-vous !": "Log in!",
    "Connexion": "Verbinding",
    "Connexion via Topi": "Verbinding via Topi",
    "Conserver la musique par défaut": "Standaardmuziek behouden",
    "Copier le lien de la page publique": "Kopieer de link naar de openbare pagina",
    "Copier le lien de la vidéo": "Kopieer de videolink",
    "Copier le lien public": "Kopieer de openbare link",
    "Couleur du thème": "Themakleur",
    "Couleur incorrecte. Veuillez choisir une couleur au format hexadécimal sans transparence.": "Onjuiste kleur. Kies een kleur in hexadecimaal formaat zonder transparantie.",
    "Couleur précédente": "Couleur précédente",
    "Couleur préférée": "Voorkeurskleur",
    "Couleurs prédéfinies": "Vooraf gedefinieerde kleuren",
    "Création d'un clap vidéo": "Een clap video maken",
    "Création d'une vidéo": "Een video maken",
    "Création manuelle": "Handmatige creatie",
    "Créer le nouveau mot de passe": "Maak het nieuwe wachtwoord aan",
    "Créer manuellement": "Handmatig maken",
    "Créer une vidéo": "Maak een video",
    "Créez votre première vidéo en cliquant sur le bouton ci-dessous.": "Maak uw eerste video door op de onderstaande knop te klikken.",
    "Créez-en un": "Maak er een",
    "Créér ou administrer votre compte clap.video": "Uw clap.video-account te creëren of te beheren",
    "Dans la file d'attente": "In de wachtrij",
    "Dans le cadre de la fourniture des Services et en tant que de besoin, le Client concède également au Prestataire un droit d’utilisation de ses logiciels, données et documents, à titre personnel, gratuit, non exclusif et non transférable pour la durée de réalisation des Services.": "In het kader van de levering van de Diensten en indien nodig, verleent de Klant de Dienstverlener ook het recht om zijn software, gegevens en documenten te gebruiken, op persoonlijke basis, gratis, niet-exclusief en niet-overdraagbaar voor de duur van de levering van de Diensten.",
    "Dans le cadre des présentes CGV et la réalisation des Services, le Prestataire s’engage à se donner tous les moyens nécessaires et à mettre tout en œuvre pour la réalisation de sa mission dans les règles de l’art. Cette obligation ne saurait constituer une obligation de résultat, le Prestataire ne fournissant les Services que dans le cadre d’une obligation de moyen.": "In het kader van deze AV en de levering van de Diensten verbindt de Dienstverlener zich ertoe om alles in het werk te stellen en alles te doen wat in zijn vermogen ligt om zijn opdracht uit te voeren in overeenstemming met de regels van het vak. Deze verplichting vormt geen resultaatsverbintenis, aangezien de Dienstverlener de Diensten enkel levert in het kader van een middelenverbintenis.",
    "Dans le respect des articles L 8221-1 et suivants du Code du travail et conformément à l’article D 8222-5 du même code, le Prestataire s’engage à remettre au Client lors de la conclusion du Contrat, et tous les six mois jusqu’à la fin de son exécution, les documents suivants ": "In overeenstemming met de artikelen L 8221-1 en volgende van de Arbeidswet en in overeenstemming met artikel D 8222-5 van dezelfde wet, verbindt de Dienstverlener zich ertoe om de Klant bij het afsluiten van het Contract, en om de zes maanden tot het einde van het Contract, te voorzien van informatie. van de uitvoering ervan, de volgende documenten",
    "Dans l’hypothèse où le Client aurait signé un procès-verbal de réception des Services et/ou la maquette de la ou les créations ou a validé la réception des Services et/ou la maquette, par tout moyen et notamment par l’utilisation des Services et/ou la maquette, le Prestataire sera réputé avoir réalisé ses obligations de manière conforme au Contrat. Le Client sera alors réputé avoir renoncé irrévocablement à toutes réclamation de plein droit à ce titre.": "In het geval dat de Klant een ontvangstbewijs van de Diensten en/of het model van de creatie(s) heeft ondertekend of de ontvangst van de Diensten en/of het model heeft gevalideerd, op welke wijze dan ook en in het bijzonder door gebruik te maken van de Diensten en/of het model, wordt de Dienstverlener geacht zijn verplichtingen uit hoofde van de Overeenkomst te hebben uitgevoerd. De Klant wordt dan geacht onherroepelijk afstand te hebben gedaan van alle rechtsvorderingen dienaangaande.",
    "Demande de mot de passe": "Wachtwoord aanvragen",
    "Dernière modification": "Laatste wijziging",
    "Disposer des droits nécessaires sur les éléments fournis ci-dessus.": "Beschikken over de nodige rechten op de hierboven vermelde elementen.",
    "Dispositions générales": "Algemene bepalingen",
    "Documents antérieurs ou autres accords": "Eerdere documenten of andere overeenkomsten",
    "Donnez vie à vos biens immobiliers en vidéo et améliorez votre engagement sur les réseaux sociaux.": "Breng uw onroerend goed tot leven op video en verbeter uw betrokkenheid op sociale media.",
    "Données personnelles": "Persoonlijke gegevens",
    "Droit applicable et juridictions compétentes": "Toepasselijk recht en bevoegde rechtsgebieden",
    "Dupliquez cette vidéo": "Deze video dupliceren",
    "Durée": "Duur",
    "Déconnexion": "Verbreek de verbinding",
    "Découvrez notre nouveau bien, une magnifique maison de campagne de 156m² et 3 chambres dont une suite parentale. Piscine, garage.": "Ontdek ons ​​nieuwe pand, een prachtig landhuis van 156m² en 3 slaapkamers waarvan een master suite. Zwembad, garage.",
    "Définissez votre nouveau mot de passe.": "Stel uw nieuwe wachtwoord in.",
    "Définitions": "Definities",
    "Désolé, votre navigateur ne supporte pas ce type de vidéo.": "Sorry, uw browser ondersteunt dit type video niet.",
    "D’une façon générale, le Client et le Prestataire s’engagent à collaborer activement afin d’assurer la bonne exécution du Contrat. Chacune des Parties s’engage à communiquer toutes les difficultés dont elle aurait connaissance au fur et à mesure de l'avancement du projet, pour permettre à l’autre Partie de prendre les décisions nécessaires.": "In het algemeen verbinden de Klant en de Leverancier zich ertoe om actief samen te werken met het oog op de goede uitvoering van het Contract. Elk van de Partijen verbindt zich ertoe de moeilijkheden waarvan hij kennis krijgt naarmate het project vordert, mee te delen om de andere Partij in staat te stellen de nodige beslissingen te nemen.",
    "E-commerce": "E-commerce",
    "Email": "E-mail",
    "Email pro": "Professioneel e-mailadres",
    "En aucun cas, le Prestataire ne sera tenu pour responsable des dommages indirects, accessoires ou particuliers tels que définis par la jurisprudence des tribunaux français, notamment, le coût de l’obtention de services de substitution, pertes de bénéfices, de données ou périodes d’immobilisation, que sa responsabilité soit contractuelle ou délictuelle et qu’elle ait ou non son fondement dans l’utilisation ou le fonctionnement des Services, même si le Prestataire a averti le Client de la possibilité de tels dommages.": "In geen geval kan de Dienstverlener aansprakelijk worden gesteld voor indirecte, incidentele of bijzondere schade zoals gedefinieerd in de jurisprudentie van de Franse rechtbanken, in het bijzonder de kosten voor het verkrijgen van vervangende diensten, winstderving, gegevensverlies of perioden van immobilisatie, ongeacht of deze aansprakelijkheid contractueel of onrechtmatig is en ongeacht of deze zijn basis vindt in het gebruik of de werking van de Diensten, zelfs als de Dienstverlener de Klant heeft gewaarschuwd voor de mogelijkheid van dergelijke schade.",
    "En cas de contradiction entre des dispositions du devis et les présentes CGV, les dispositions concernées du devis prévaudront sur les CGV.": "In geval van tegenstrijdigheid tussen de bepalingen van de offerte en deze AVV, prevaleren de relevante bepalingen van de offerte boven de AVV.",
    "En cas de différend entre les Parties, celles-ci tenteront de trouver une solution amiable à ce différend dans un délai de trente jours à compter de la notification du différend de la Partie en demande à l’autre Partie par lettre recommandée avec accusé de réception.": "In geval van een geschil tussen de Partijen zullen zij proberen een minnelijke oplossing voor dit geschil te vinden binnen dertig dagen vanaf de kennisgeving van het geschil door de Partij op verzoek aan de andere Partij per aangetekende brief met ontvangstbevestiging.",
    "En cas de manquement quelconque du Prestataire dans l’exécution de ses obligations (défaut d’exécution ou mauvaise exécution), le Client devra en faire part à celui-ci dans le délai de huit (8) jours ouvrés à compter de la constatation du manquement par lettre recommandée avec accusé de réception. A défaut, le manquement sera inopposable au Prestataire.": "In geval van niet-nakoming door de Dienstverlener van zijn verplichtingen (niet-uitvoering of slechte uitvoering), moet de Klant de Dienstverlener binnen acht (8) werkdagen na de erkenning van de tekortkoming op de hoogte stellen per aangetekende brief met ontvangstbevestiging van ontvangst. Als dit niet gebeurt, is de inbreuk niet afdwingbaar jegens de Dienstverlener.",
    "En cas de non-paiement à l’échéance, toute somme due fera courir des pénalités de retard. Celles-ci courent à compter du jour suivant la date d’échéance figurant sur la facture et jusqu’au jour du paiement effectif et intégral de la somme. Le taux des pénalités de retard est fixé à trois faux le taux d’intérêt légal en vigueur.": "In geval van niet-betaling op de vervaldag worden voor elk verschuldigd bedrag nalatigheidsboetes aangerekend. Deze lopen vanaf de dag volgend op de vervaldag vermeld op de factuur tot de dag van de volledige en effectieve betaling van het bedrag. Het tarief van de nalatigheidsinterest is vastgesteld op driemaal de geldende wettelijke interestvoet.",
    "En cas de rupture du Contrat avant son terme par le Client, celui-ci s’engage formellement à régulariser et rétribuer les montants relatifs au calendrier en cours, aux postes réalisés ou en cours de réalisation, ainsi qu’aux services complémentaires effectués. Les fichiers et données sources créés et utilisés par le Prestataire ne sauraient dès lors être revendiqués par le Client sans une contribution financière. L’acompte déjà versé restera acquis par le Prestataire, constituant un dédommagement pour le travail entrepris.": "De Dienstverlener kan de Overeenkomst eveneens beëindigen in geval van niet-betaling van de openstaande factu(u)r(en) door de Klant.",
    "En cas d’atteinte avérée aux droits d’un tiers, le Prestataire pourra, à son choix ": "In geval van bewezen inbreuk op de rechten van een derde, kan de Dienstverlener, naar eigen keuze",
    "En cliquant sur le bouton d'inscription, vous acceptez les": "Door op de registratieknop te klikken, accepteert u de",
    "En cours de traitement": "In behandeling",
    "En file d'attente": "In de wachtrij",
    "En particulier, le Prestataire cède au Client les droits suivants ": "In het bijzonder verleent de Dienstverlener de Klant de volgende rechten",
    "En utilisant notre site, vous consentez à notre politique de confidentialité.": "Door onze site te gebruiken, gaat u akkoord met ons privacybeleid.",
    "Encodage en cours": "Codering bezig",
    "Enfin, en cas de retard de paiement, le Prestataire se réserve le droit de suspendre ou de surseoir à l’exécution des Services prévus dans la Commande dont le paiement fait l’objet du retard.": "Ten slotte behoudt de Dienstverlener zich het recht voor om in geval van laattijdige betaling de uitvoering van de Diensten voorzien in de Bestelling waarvoor de betaling achterstallig is, op te schorten of uit te stellen.",
    "Enfin, le Client fait son affaire et est seul responsable des lois et réglementations applicables aux Services notamment au regard de la protection des droits de propriété intellectuelle, mentions légales, protection des données personnelles, protection des mineurs (si applicable) et droit de la consommation (si applicable).": "Tot slot is de Klant als enige verantwoordelijk voor het naleven van de wet- en regelgeving die van toepassing is op de Diensten, in het bijzonder met betrekking tot de bescherming van intellectuele eigendomsrechten, wettelijke kennisgevingen, bescherming van persoonsgegevens, bescherming van minderjarigen (indien van toepassing) en consumentenrecht (indien van toepassing).",
    "Enregistrer les modifications": "Wijzigingen opslaan",
    "Enregistrer l’image sélectionnée": "De geselecteerde afbeelding opslaan",
    "Entrer une couleur manuellement": "Handmatig een kleur invoeren",
    "Envoi en cours": "Bezig met verzenden",
    "Envoyer": "Versturen",
    "Erreur": "Fout",
    "Erreur de connexion au serveur, veuillez réessayer s'il vous plait.": "Fout bij verbinding met server, probeer het opnieuw.",
    "Erreur lors de l'envoi sur Facebook. Merci de vérifier vos informations et réessayer.": "Er is een fout opgetreden bij het ophalen van de panden.",
    "Erreur lors de l'envoi sur Instagram. Merci de vérifier vos informations et réessayer.": "Fout bij het uploaden naar Instagram. Controleer je gegevens en probeer het opnieuw.",
    "Erreur lors de l'envoi sur Tiktok. Merci de vérifier vos informations et réessayer.": "Fout bij het uploaden naar Tiktok. Controleer je gegevens en probeer het opnieuw.",
    "Erreur lors de la copie du lien, veuillez réessayer": "Fout bij het kopiëren van de link. Probeer het opnieuw",
    "Erreur pendant l'enregistrement de vos informations": "Fout bij het opslaan van uw gegevens",
    "Erreur pendant la suppression du projet.": "Fout bij het verwijderen van het project.",
    "Fait main": "Handgemaakt",
    "Fonctionnalités": "Functies",
    "Force Majeure": "Overmacht",
    "Format": "Formaat",
    "Fournir tous les éléments documentaires, graphiques et textuels nécessaires à la bonne réalisation du Contrat (notamment dans les bons formats exploitables en fonction des supports visés), le Client s'engage à fournir toutes les informations légales à ajouter dans les documents et endosse la responsabilité de fournir le contenu des documents qu'il édite.": "Alle documentaire, grafische en tekstuele elementen verschaffen die nodig zijn voor de goede uitvoering van het Contract (met name in de juiste bruikbare formaten volgens de beoogde media). De Klant verbindt zich ertoe alle wettelijke informatie te verschaffen die aan de documenten moet worden toegevoegd en neemt de verantwoordelijkheid op zich voor het verschaffen van de inhoud van de documenten die hij publiceert.",
    "Frais Annexes": "Extra kosten",
    "Garantie d’éviction": "Uitzettingsgarantie",
    "Garanties": "Garanties",
    "Garantir le Prestataire contre toute action qui pourrait lui être intentée du fait du caractère des données ou informations (textes, images, sons) qui auraient été fournies ou choisies par le Client.": "De Dienstverlener te vrijwaren tegen elke actie die tegen hem zou kunnen worden ingesteld op grond van de aard van de gegevens of informatie (tekst, beelden, geluiden) die door de Klant worden geleverd of gekozen.",
    "Gratuit": "Gratis",
    "Généralités": "Algemeen",
    "HT": "exclusief belasting",
    "Hormis les dommages corporels ou décès, et sauf en cas de négligence grossière ou de faute intentionnelle causant un dommage direct prouvé ou en cas de manquement à une obligation essentielle du Contrat la vidant de sa substance, le Client reconnaît que la responsabilité du Prestataire est limitée au montant versé pour les Services en cause.": "Behalve in geval van lichamelijk letsel of overlijden, en behalve in geval van grove nalatigheid of opzettelijk wangedrag die bewezen directe schade veroorzaakt of in geval van niet-nakoming van een essentiële verplichting van het Contract waardoor deze van zijn inhoud wordt beroofd, erkent de Klant dat de aansprakelijkheid van de Dienstverlener is beperkt tot het bedrag dat voor de betreffende Diensten wordt betaald.",
    "Il semble y avoir une erreur de notre côté.": "Er lijkt een fout aan onze kant te zijn.",
    "Images": "Afbeeldingen",
    "Immobilier": "Onroerend goed",
    "Importer cette annonce": "Importeer deze advertentie",
    "Importer une image": "Een afbeelding importeren",
    "Informer le Prestataire d’une éventuelle mise en concurrence avec d’autres prestataires.": "De Dienstverlener op de hoogte stellen van eventuele concurrentie met andere dienstverleners.",
    "Infos générales": "Algemene info",
    "Inscription": "Inschrijving",
    "L'email a été envoyé": "De e-mail is verzonden",
    "L'enregistrement des champs a échoué.": "Het opslaan van velden is mislukt.",
    "L'importation de cette annonce n'a pas fonctionné. Si le problème persiste, veuillez contacter le support.": "Het importeren van deze advertentie werkte niet. Als het probleem zich blijft voordoen, neem dan contact op met onze support.",
    "L'url de votre site Internet": "Uw website url",
    "La Partie touchée par un événement de force majeure en avisera l'autre Partie dans les cinq (5) jours ouvrables suivant la date à laquelle elle en aura eu connaissance. Les deux Parties conviendront alors des conditions dans lesquelles l'exécution du Contrat sera poursuivie.": "De Partij die door een geval van overmacht wordt getroffen, zal de andere Partij hiervan binnen vijf (5) werkdagen na de datum waarop zij hiervan kennis krijgt, op de hoogte stellen. Beide Partijen zullen dan overeenstemming bereiken over de voorwaarden waaronder de uitvoering van de Overeenkomst zal worden voortgezet.",
    "La connexion automatique a échouée.": "Automatische verbinding mislukt.",
    "La liaison avec Facebook a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Facebook.": "De verbinding met Facebook is succesvol uitgevoerd. Je kunt je video's nu rechtstreeks naar Facebook sturen.",
    "La liaison avec Instagram a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Instagram.": "De koppeling met Instagram is succesvol tot stand gebracht. Je kunt je video's nu rechtstreeks naar Instagram sturen.",
    "La liaison avec Tiktok a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Tiktok.": "De verbinding met Tiktok is tot stand gebracht. Je kunt je video's nu rechtstreeks naar Tiktok sturen.",
    "La loi du Contrat est la Loi Française. Les parties conviennent expressément que la Convention de Viennes sur la vente internationale de marchandises en date du 11 avril 1980 n’est pas applicable au Contrat.": "Het recht van het contract is Frans recht. De partijen komen uitdrukkelijk overeen dat het Weens Koopverdrag van 11 april 1980 niet van toepassing is op het Contract.",
    "La responsabilité du Prestataire est limitée aux seuls dommages directs et résultant d'un défaut des Services ou de la violation du Contrat, même si le défaut en question était prévisible au moment de la Commande.": "De aansprakelijkheid van de Dienstverlener is uitsluitend beperkt tot directe schade die voortvloeit uit een gebrek aan de Diensten of een schending van het Contract, zelfs als het betreffende gebrek voorzienbaar was op het moment van de Bestelling.",
    "La réalisation des Services confiés au Prestataire dépendant directement du respect par le Client de ses propres obligations, les Parties reconnaissent expressément que les délais de livraison visés au devis sont donnés à titre purement indicatif et sans garantie.": "Aangezien de uitvoering van de aan de Dienstverlener toevertrouwde Diensten rechtstreeks afhankelijk is van de naleving door de Klant van zijn eigen verplichtingen, erkennen de Partijen uitdrukkelijk dat de in de offerte vermelde leveringstermijnen louter indicatief zijn en geen garantie inhouden.",
    "La vidéo est en cours d'envoi sur Facebook. Vous recevrez une notification Facebook dès qu'elle sera prête.": "De video wordt geüpload naar Facebook. Je ontvangt een Facebook-melding zodra deze klaar is.",
    "La vidéo est en cours d'envoi sur Instagram. Vous recevrez une notification Instagram dès qu'elle sera prête.": "De video wordt geüpload naar Instagram. Je ontvangt een melding van Instagram zodra de video klaar is.",
    "La vidéo est en cours d'envoi sur Tiktok. Vous recevrez une notification Tiktok dès qu'elle sera prête.": "De video wordt naar Tiktok verzonden. Je ontvangt een melding van Tiktok  zodra de video klaar is.",
    "Langue du Contrat": "Contracttaal",
    "Langue mise à jour avec succès.": "Taal succesvol bijgewerkt.",
    "Langues": "Talen",
    "Le Client déclare expressément avoir reçu du Prestataire toutes les informations et tous les conseils nécessaires à la réalisation des Services et renonce à rechercher la responsabilité du Prestataire de ce fait.": "De Klant verklaart uitdrukkelijk dat hij van de Dienstverlener alle informatie en adviezen heeft ontvangen die nodig zijn om de Diensten uit te voeren en ziet af van elke aansprakelijkheid van de Dienstverlener in dit verband.",
    "Le Client s'engage à fournir des informations justes et sincères et s'engage aussi à prévenir le Prestataire de tout changement concernant les informations, données, documentations fournies.": "De Klant verbindt zich ertoe nauwkeurige en waarheidsgetrouwe informatie te verstrekken en verbindt zich er tevens toe de Dienstverlener op de hoogte te brengen van alle wijzigingen van de verstrekte informatie, gegevens en documentatie.",
    "Le Client sera seul responsable des éventuels dysfonctionnements qui pourraient résulter d'informations erronées. Le Client doit maintenir une adresse e-mail et une adresse postale valides.": "De Klant is als enige verantwoordelijk voor eventuele storingen die het gevolg zijn van onjuiste informatie. De Klant moet een geldig e-mailadres en postadres bijhouden.",
    "Le Client sera également débiteur de plein droit d’une indemnité forfaitaire minimum de recouvrement de quarante (40) euros des sommes dues par le Client au Prestataire.": "De Klant is ook van rechtswege een forfaitair minimumbedrag aan invorderingskosten verschuldigd van veertig (40) euro van de bedragen die de Klant aan de Dienstverlener verschuldigd is.",
    "Le Client s’engage à fournir au Prestataire sous forme exploitable l’ensemble des documents nécessaires à la réalisation des Services confiés au Prestataire.": "De Klant verbindt zich ertoe de Dienstverlener in bruikbare vorm alle documenten te bezorgen die nodig zijn voor de uitvoering van de Diensten die aan de Dienstverlener worden toevertrouwd.",
    "Le Client s’engage à obtenir de tous tiers, si besoin est, le droit de concéder au Prestataire les droits d’utilisation des logiciels, données et équipements appartenant à ces tiers pour les besoins de la fourniture des Services.": "De Klant verbindt zich ertoe om, indien nodig, van elke derde het recht te verkrijgen om aan de Dienstverlener het recht te verlenen om de software, gegevens en uitrusting van deze derde te gebruiken voor de levering van de Diensten.",
    "Le Contrat est rédigé en langue française. Une traduction en langue étrangère peut être fournie à titre d’information. En cas de contradiction, seule la version française fera foi entre les Parties.": "Het Contract is opgesteld in het Frans. Ter informatie kan er een vertaling in een vreemde taal worden verstrekt. In geval van tegenstrijdigheid zal tussen de partijen alleen de Franse versie authentiek zijn.",
    "Le Contrat se substitue à tout autre document antérieur, à tout autre accord écrit ou verbal en relation avec le même objet, à l’exception du devis, de la Commande et prévaut sur toute disposition contraire qui pourrait être contenue dans les documents émanant du Client.": "Het Contract vervangt elk ander voorafgaand document, elke andere schriftelijke of mondelinge overeenkomst met betrekking tot hetzelfde onderwerp, met uitzondering van de offerte, de Bestelling, en heeft voorrang op elke tegenstrijdige bepaling die mogelijk is opgenomen in de documenten uitgaande van de Klant.",
    "Le Prestataire a la faculté de sous-traiter tout ou partie de la réalisation des Services à des sous-traitants. Dans ce cas, le Prestataire demeure responsable de la réalisation des Services vis-à-vis du Client.": "De Dienstverlener heeft de mogelijkheid om de uitvoering van de Diensten geheel of gedeeltelijk uit te besteden aan onderaannemers. In dit geval blijft de Dienstverlener verantwoordelijk voor het leveren van de Diensten aan de Klant.",
    "Le Prestataire agissant au nom et pour le compte du Client dans le traitement des données personnelles qui lui sont communiquées par le Client, il dispose de la qualité de sous-traitant.": "De Dienstverlener die in naam en voor rekening van de Klant handelt bij de verwerking van de persoonsgegevens die hem door de Klant worden meegedeeld, heeft de status van onderaannemer.",
    "Le Prestataire concède au Client, le cas échéant et dans la limite strictement nécessaire à l’exécution des Services, à titre personnel, non exclusif et non-transférable, le droit d'utiliser lesdits Droits de Propriété Intellectuelle pour la durée de réalisation des Services.": "De Dienstverlener verleent de Klant, indien van toepassing en voor zover strikt noodzakelijk voor de uitvoering van de Diensten, op persoonlijke, niet-exclusieve en niet-overdraagbare basis, het recht om de genoemde Intellectuele Eigendomsrechten te gebruiken voor de duur van de uitvoering van de Diensten.",
    "Le Prestataire déclare être immatriculé au RCS, ainsi qu’auprès de l’URSSAF et que ses immatriculations couvrent expressément toutes ses activités pour l’exécution des Services définis dans le devis et/ou la Commande.": "De Dienstverlener verklaart dat hij zowel bij de RCS als bij de URSSAF is ingeschreven en dat zijn registraties uitdrukkelijk al zijn activiteiten omvatten voor de uitvoering van de Diensten zoals gedefinieerd in de offerte en/of de Bestelling.",
    "Le Prestataire est quant à lui autorisé à utiliser la dénomination sociale/la marque du Client dans le cadre de ses activités à des fins de promotion commerciale.": "De Dienstverlener mag de bedrijfsnaam/het merk van de Klant gebruiken in het kader van zijn activiteiten met het oog op commerciële promotie.",
    "Le Prestataire est une entreprise spécialisée notamment dans Génération automatique de vidéo basée sur des informations fournies statiques (textes, images).": "De Dienstverlener is een bedrijf dat met name gespecialiseerd is in het automatisch genereren van video op basis van aangeleverde statische informatie (tekst, afbeeldingen).",
    "Le Prestataire garantit le Client contre toute action, réclamation, revendication ou opposition de la part de toute personne invoquant un droit de propriété intellectuelle auxquels la fourniture des Services aurait porté atteinte, sous réserve que le Client informe le Prestataire, dès qu’il en a connaissance, de toute demande, réclamation ou instance présentée ou engagée pour un tel motif, par voie judiciaire ou extrajudiciaire. Le Client s’engage à apporter au Prestataire, tous les documents et renseignements en sa possession ainsi que toute l’assistance requise qui pourraient être nécessaires à sa défense.": "De Dienstverlener vrijwaart de Klant tegen elke actie, klacht, eis of verzet van iedere persoon die een beroep doet op een intellectueel eigendomsrecht waarop de levering van de Diensten mogelijk inbreuk heeft gemaakt, op voorwaarde dat de Klant de Dienstverlener op de hoogte brengt zodra hij hiervan kennis krijgt. kennis heeft van enig verzoek, claim of procedure die om een ​​dergelijke reden wordt ingediend of gestart, via gerechtelijke of buitengerechtelijke middelen. De Klant verbindt zich ertoe om de Dienstverlener alle documenten en informatie waarover hij beschikt te verstrekken, evenals alle vereiste bijstand die nodig kan zijn voor zijn verdediging.",
    "Le Prestataire garantit que les Services sont fournis de manière substantiellement conforme à la Commande.": "De Dienstverlener garandeert dat de Diensten worden geleverd op een wijze die in hoofdzaak overeenkomt met de Bestelling.",
    "Le Prestataire garantit que les créations sont juridiquement disponibles et ne sont pas grevées de droit des tiers pour les utilisations prévues au titre du Contrat.": "De Dienstverlener garandeert dat de creaties wettelijk beschikbaar zijn en niet bezwaard zijn door rechten van derden voor de toepassingen voorzien in het Contract.",
    "Le Prestataire n'aura aucune obligation d’indemnisation ou autre obligation au titre d’une action en contrefaçon ayant pour origine (a) une utilisation des Services autrement que conformément au Contrat, (b) une combinaison des Services avec d’autres services ou matériels non fournis par le Prestataire.": "De Dienstverlener heeft geen schadeloosstelling of andere verplichting met betrekking tot een actie wegens inbreuk die voortvloeit uit (a) gebruik van de Diensten anders dan in overeenstemming met het Contract, (b) een combinatie van de Diensten met andere diensten of materialen die niet door de Dienstverlener.",
    "Le Prestataire ne peut être tenu d’aucune garantie notamment lorsque le Client a modifié ou fait modifier les Services ou a utilisé d’autres services que les Services fournis par le Prestataire, sans son accord préalable et écrit ou lorsque le Client ou des tiers sont intervenus sur les éléments des Services sans l’accord préalable du Prestataire.": "De Dienstverlener kan niet tot enige garantie gehouden worden, in het bijzonder wanneer de Klant de Diensten heeft aangepast of heeft laten wijzigen of gebruik heeft gemaakt van andere diensten dan de door de Dienstverlener geleverde Diensten, zonder zijn voorafgaande schriftelijke toestemming of wanneer de Klant of derden tussenbeide komen elementen van de Diensten zonder voorafgaande toestemming van de Dienstverlener.",
    "Le Prestataire peut décider de céder ou transférer les droits ou obligations que lui confère le présent Contrat sous réserve que le Client bénéficie des Services dans les mêmes conditions.": "De Dienstverlener kan beslissen om de rechten of verplichtingen die hem door dit Contract worden toegekend, toe te wijzen of over te dragen, op voorwaarde dat de Klant onder dezelfde voorwaarden van de Diensten profiteert.",
    "Le Prestataire peut mettre fin aux Services fournis au Client en cas de manquement par le Client à ses obligations au titre du Contrat, non réparé dans un délai de quinze (15) jours à compter de la notification par le Prestataire de ce manquement par lettre recommandée avec accusé de réception, indépendamment de la possibilité pour le Prestataire de demander le paiement de dommages et intérêts.": "De Dienstverlener kan de aan de Klant geleverde Diensten beëindigen in geval van een inbreuk door de Klant op zijn verplichtingen uit hoofde van de Overeenkomst die niet is verholpen binnen vijftien (15) dagen nadat de Dienstverlener de Klant per aangetekende brief met ontvangstbevestiging van deze inbreuk in kennis heeft gesteld, ongeacht het recht van de Dienstverlener om betaling van schadevergoeding te eisen.",
    "Le Prestataire peut également mettre fin au Contrat en cas de non paiement de la ou des facture(s) non acquittée(s) par le Client.": "De Dienstverlener kan de Overeenkomst eveneens beëindigen in geval van niet-betaling van de openstaande factu(u)r(en) door de Klant.",
    "Le Prestataire se réserve le droit de modifier ces CGV, les Services et les tarifs à tout moment et sans préavis. Ces modifications n'auront aucune incidence sur les Commandes en cours.": "De Dienstverlener behoudt zich het recht voor om deze AV, de Diensten en de prijzen op elk moment en zonder voorafgaande kennisgeving te wijzigen. Deze wijzigingen zullen geen effect hebben op lopende Bestellingen.",
    "Le Prestataire se réserve le droit de modifier les présentes CGV et d’en notifier le Client. Si une modification substantielle des termes des CGV n’est pas acceptable par le Client, celui-ci dispose d’un délai de quinze (15) jours à compter de la notification par le Prestataire des modifications pour en informer le Prestataire. En cas de désaccord des Parties sur ces modifications, les Parties pourront résilier le Contrat.": "De Dienstverlener behoudt zich het recht voor om deze Algemene Voorwaarden te wijzigen en de Klant hiervan op de hoogte te stellen. Indien een substantiële wijziging van de voorwaarden van de Algemene Verkoopvoorwaarden niet aanvaardbaar is voor de Klant, beschikt de Klant over een termijn van vijftien (15) dagen vanaf de kennisgeving door de Dienstverlener van de wijzigingen om de Dienstverlener hiervan op de hoogte te stellen. In geval van onenigheid tussen de Partijen over deze wijzigingen, kunnen de Partijen het Contract beëindigen.",
    "Le Prestataire se réserve le droit de ne pas accepter une Commande de la part du Client lorsque le Prestataire a déjà rencontré des problèmes de paiement (non-paiement ou retard de paiement) avec le Client pour une ou plusieurs Commande(s) précédente(s).": "De Dienstverlener behoudt zich het recht voor om een ​​Bestelling van de Klant niet te aanvaarden wanneer de Dienstverlener reeds betalingsproblemen (niet-betaling of laattijdige betaling) bij de Klant heeft ondervonden voor één of meerdere eerdere Bestelling(en).",
    "Le Prestataire se réserve également le droit de mentionner les réalisations effectuées pour le Client sur ses documents de communication externe, de publicité (site internet, portfolio, etc.) et lors de démarchages de prospection commerciale.": "De Dienstverlener behoudt zich eveneens het recht voor om de verwezenlijkingen voor de Klant te vermelden op zijn externe communicatie- en reclamedocumenten (website, portfolio, enz.) en tijdens commerciële prospectie.",
    "Le Prestataire s’engage à informer de manière régulière le Client de l’avancée de la réalisation du projet et ce, notamment, au travers de validations soumises au Client dans le rétro-planning tel que prévu dans le devis.": "De Dienstverlener verbindt zich ertoe de Klant regelmatig te informeren over de voortgang van het project, met name door middel van goedkeuringen die aan de Klant worden voorgelegd in de back-planning zoals voorzien in de offerte.",
    "Le changement de template a échoué.": "De sjabloonwijziging is mislukt.",
    "Le code promo est expiré.": "De promotiecode is verlopen.",
    "Le code promo n'existe pas.": "De promotiecode bestaat niet.",
    "Le droit de reproduction comprend notamment, et de manière non exhaustive": "Het reproductierecht omvat, maar is niet beperkt tot",
    "Le droit de représentation comprend notamment, et de manière non exhaustive": "Het recht van vertegenwoordiging omvat, maar is niet beperkt tot, de volgende rechten",
    "Le fait que le Prestataire ne se prévale pas à un moment donné d'une quelconque condition des présentes CGV ne peut être interprété comme valant renonciation à se prévaloir ultérieurement de quelconque desdites conditions.": "Het feit dat de Dienstverlener op een bepaald moment geen gebruik maakt van een voorwaarde van deze AV, mag niet worden geïnterpreteerd als een verklaring van afstand van het recht om op een later tijdstip gebruik te maken van een van de genoemde voorwaarden.",
    "Le lien a été copié": "De link is gekopieerd",
    "Le message d'erreur": "De foutmelding",
    "Le paiement du solde des Services doit être effectué dans un délai de trente (30) jours à compter de la date de la facture émise par le Prestataire et pourra être effectué sous forme de chèque, de virement, ou de prélèvement automatique à l’ordre du Prestataire.": "De betaling van het saldo van de Diensten moet worden uitgevoerd binnen dertig (30) dagen na de datum van de factuur van de Dienstverlener en kan worden uitgevoerd per cheque, bankoverschrijving of automatische incasso ten gunste van de Dienstverlener.",
    "Le projet": "Het project",
    "Le template n'est pas disponible dans la langue sélectionnée.": "Het sjabloon is niet beschikbaar in de geselecteerde taal.",
    "Le terme \"Client\" désigne toute personne morale ou physique, ayant requis les compétences du Prestataire.": "De term “Klant” verwijst naar elke rechtspersoon of natuurlijke persoon die de diensten van de Dienstverlener heeft aangevraagd.",
    "Le terme \"Tiers\" désigne toute personne physique ou morale non partie à ces Conditions Générales de Vente (CGV).": "De term “Derde” verwijst naar elke natuurlijke of rechtspersoon die geen partij is bij deze Algemene Voorwaarden (AV).",
    "Le terme le \"Prestataire\" désigne la société SYNAPSE, Société par action simplifiée au capital de 200 000 euros, immatriculée au Registre du Commerce et des Sociétés de Grenoble sous le numéro RCS 452156102, et dont le siège social est situé 19 chemin des prés, 38240 Meylan.": "De term “Dienstverlener” verwijst naar de onderneming SYNAPSE, een vereenvoudigde naamloze vennootschap met een kapitaal van 200.000 euro, ingeschreven in het handels- en bedrijvenregister van Grenoble onder het nummer RCS 452156102, en waarvan de maatschappelijke zetel gevestigd is te 19 chemin des prés, 38240 Meylan.",
    "Le texte descriptif de votre clap (facultatif)": "Beschrijvende tekst van je clap (optioneel)",
    "Le texte descriptif de votre vidéo (facultatif)": "De beschrijvende tekst van je video (optioneel)",
    "Le versement de l’acompte conditionnera la mise en œuvre des Services. Le paiement de l’acompte pourra être effectué sous forme de chèque, de virement, ou de prélèvement automatique à l’ordre du Prestataire.": "Betaling van het voorschot is een voorwaarde voor de uitvoering van de Diensten. De betaling van het voorschot kan gebeuren per cheque, overschrijving of domiciliëring op rekening van de Dienstverlener.",
    "Les CGV s'appliquent aux seuls professionnels à l’exclusion des consommateurs. A ce titre, le Client reconnaît avoir la qualité de professionnel, conformément aux dispositions du Code de la consommation applicables.": "De AV zijn alleen van toepassing op professionals, met uitsluiting van consumenten. In dit opzicht erkent de Klant dat hij/zij gekwalificeerd is als een professional in overeenstemming met de toepasselijke bepalingen van de Franse consumentenwetgeving.",
    "Les CGV sont systématiquement adressées ou remises à chaque Client avant la passation de toute commande (la Commande). En conséquence, le fait de passer Commande implique l'adhésion entière et sans réserve du Client à ces CGV, à l'exclusion de tous autres documents en sa possession tels que prospectus, catalogues ou plaquettes publicitaires émis par le Prestataire, lesquels n'auront qu'une valeur indicative et non contractuelle.": "De Algemene Voorwaarden worden systematisch naar elke Klant gestuurd of overhandigd voordat een bestelling wordt geplaatst (de Bestelling). Bijgevolg impliceert het plaatsen van een Bestelling de volledige en onvoorwaardelijke aanvaarding door de Klant van deze Algemene Verkoopsvoorwaarden, met uitsluiting van alle andere documenten in het bezit van de Klant, zoals brochures, catalogi of reclamefolders uitgegeven door de Dienstverlener, die slechts een indicatieve en niet-contractuele waarde hebben.",
    "Les Parties": "De partijen",
    "Les Parties ne peuvent être considérées comme responsables ou ayant failli à leurs obligations contractuelles, lorsque le défaut d'exécution des obligations respectives a pour origine la force majeure telle que définie par la jurisprudence des tribunaux français. Le Contrat entre les parties est suspendu jusqu'à l'extinction des causes ayant engendrées la force majeure. La force majeure prend en compte des faits ou circonstances irrésistibles, extérieurs aux parties, imprévisibles et indépendants de la volonté des parties, malgré tous les efforts raisonnablement possibles pour les empêcher.": "De partijen kunnen niet als verantwoordelijk worden beschouwd of zijn tekortgeschoten in hun contractuele verplichtingen, wanneer het niet nakomen van de respectievelijke verplichtingen wordt veroorzaakt door overmacht zoals gedefinieerd door de jurisprudentie van de Franse rechtbanken. De Overeenkomst tussen partijen wordt opgeschort totdat de oorzaken die aanleiding gaven tot de overmacht zijn opgeheven. Bij overmacht wordt rekening gehouden met onweerstaanbare feiten of omstandigheden, extern aan de partijen, niet te voorzien en buiten de wil van de partijen, ondanks alle redelijkerwijs mogelijke pogingen om deze te voorkomen.",
    "Les Services peuvent être fournis soit à distance, soit dans les locaux du Client, au choix du Prestataire et du Client.": "De Diensten kunnen op afstand of bij de Klant worden geleverd, naar goeddunken van de Dienstverlener en de Klant.",
    "Les conditions tarifaires du Prestataire relatives à la fourniture des Services sont prévues dans le devis du Prestataire.": "De prijsvoorwaarden van de Aanbieder voor de levering van de Diensten worden uiteengezet in de offerte van de Aanbieder.",
    "Les logiciels, données, documentations, procédés, méthodologies, technologies et documents appartenant au Prestataire (ci-après Droits de Propriété Intellectuelle) utilisés dans le cadre de la mise en œuvre des Services restent la propriété exclusive du Prestataire.": "De software, gegevens, documentatie, processen, methodologieën, technologieën en documenten die eigendom zijn van de Dienstverlener (hierna genoemd Intellectuele Eigendomsrechten) en die gebruikt worden in verband met de implementatie van de Diensten blijven het exclusieve eigendom van de Dienstverlener.",
    "Les mots de passe ne correspondent pas": "Wachtwoorden komen niet overeen",
    "Les mots de passe ne correspondent pas.": "Wachtwoorden komen niet overeen.",
    "Les paramètres ont été enregistrés.": "De instellingen zijn opgeslagen.",
    "Les prix des Services n’incluent pas les éventuels frais de déplacement ou d’hébergement qui pourraient être facturés en sus par le Prestataire au Client selon les modalités indiquées dans le devis.": "De prijzen van de Diensten omvatten geen reis- of verblijfkosten die aanvullend door de Dienstverlener aan de Klant in rekening kunnen worden gebracht overeenkomstig de voorwaarden die in de offerte zijn vermeld.",
    "Les prix des Services sont exprimés et payables en Euros et sont exprimés hors taxe sur la valeur ajoutée et hors toute autre taxe, le Client étant responsable du paiement desdites taxes.": "De prijzen van de Diensten zijn uitgedrukt en betaalbaar in euro en zijn exclusief belasting over de toegevoegde waarde en eventuele andere belastingen, waarbij de Klant verantwoordelijk is voor de betaling van deze belastingen.",
    "Les prix sont donnés à titre indicatif et sont donc susceptibles de variation. Le prix facturé est celui prévu dans la Commande validée par le Prestataire.": "Prijzen worden alleen ter informatie gegeven en zijn daarom onderhevig aan wijzigingen. De prijs die gefactureerd wordt is de prijs die vermeld wordt in de Bestelling die gevalideerd is door de Dienstverlener.",
    "Les présentes CGV ont pour objet de définir les droits et obligations des Parties lors de la réalisation de prestations de services réalisées par le Prestataire pour le Client dans le cadre de ses activités.": "Het doel van deze Algemene Voorwaarden is het definiëren van de rechten en plichten van de Partijen met betrekking tot de levering van diensten door de Dienstverlener aan de Klant als onderdeel van zijn bedrijfsactiviteiten.",
    "Les présentes Conditions Générales de Vente (ci-après désignées les \"CGV\") s'appliquent à tout contrat conclu entre le Prestataire et le Client, dans le cadre de la fourniture de prestations de services (les Services) telles que définies dans le devis validé par le Client.": "Deze Algemene Verkoopvoorwaarden (hierna de “AV” genoemd) zijn van toepassing op elk contract dat wordt gesloten tussen de Dienstverlener en de Klant voor de levering van diensten (de “Diensten”) zoals gedefinieerd in de offerte die door de Klant is goedgekeurd.",
    "Les présentes Conditions Générales de Vente sont à jour au 1 janvier 2021.": "Deze Algemene Verkoopvoorwaarden zijn geldig vanaf 1 januari 2021.",
    "Les tarifs sont HT.": "Prijzen zijn exclusief belasting.",
    "Les œuvres créées par le Prestataire pour le Client, dans le cadre de l’exécution du Contrat, demeurent la propriété entière et exclusive du Prestataire tant que les factures émises par le Prestataire ne sont pas entièrement acquittées par le Client.": "De werken die door de Dienstverlener voor de Klant worden gecreëerd in het kader van de uitvoering van de Overeenkomst blijven de volledige en exclusieve eigendom van de Dienstverlener tot de facturen van de Dienstverlener volledig door de Klant zijn betaald.",
    "Lier un compte": "Koppel een account",
    "Logo de l'agence": "Logo van het kantoor",
    "Légende pour": "Onderschrift voor",
    "L’acceptation du devis et de la Commande du Client qui en découle, doit s’accompagner du paiement d’un acompte tel qu’indiqué dans le devis.": "Aanvaarding van de offerte en de daaruit voortvloeiende Klantorder moet gepaard gaan met betaling van een aanbetaling zoals aangegeven in de offerte.",
    "L’intelligence artificielle est en bêta chez clap.video": "Atrificiële intelligentie is in bèta bij clap.video",
    "Ma vidéo": "Mijn video",
    "Maison de campagne 156m²": "Landhuis 156m2",
    "Marques et dénominations sociales": "Handelsmerken en bedrijfsnamen",
    "Mensuel": "Maandelijks",
    "Merci de réessayer en cliquant": "Probeer het opnieuw en klik",
    "Merci pour votre abonnement ! Bienvenue sur clap.video premium.": "Bedankt voor uw abonnement! Welkom bij clap.video premium.",
    "Mes informations": "Mijn informatie",
    "Mes vidéos": "Mijn video's",
    "Mise à jour d'une vidéo": "Een video bijwerken",
    "Modalités d’exécution des Services et livraison des livrables": "Voorwaarden voor de uitvoering van de Diensten en levering hiervan",
    "Modifications des CGV – cession du Contrat": "Wijzigingen aan de Algemene Verkoopvoorwaarden – overdracht van het Contract",
    "Modifier": "Bewerken",
    "Mon compte": "Mijn rekening",
    "Mot de passe": "Wachtwoord",
    "Mot de passe actuel": "Huidig wachtwoord",
    "Mot de passe oublié": "Wachtwoord vergeten",
    "Moyen de paiement": "Betaalmethode",
    "Musique": "Muziek",
    "Médias": "Media",
    "NOUVEAU": "NIEUW",
    "Nom": "Naam",
    "Nom de l'entreprise": "Bedrijfsnaam",
    "Non sollicitation": "Geen verzoek",
    "Notification": "Kennisgeving",
    "Notifications": "Meldingen",
    "Nous avons trouvé des photos provenant votre annonce web, cliquez sur celle que vous souhaitez ajouter à votre vidéo": "We hebben foto's uit je webadvertentie gevonden, klik op de foto die je aan je video wilt toevoegen",
    "Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles. Nous utilisons un cryptage pour protéger les informations sensibles transmises en ligne. Nous protégeons également vos informations hors ligne. Seuls les employés qui ont besoin d’effectuer un travail spécifique (par exemple, la facturation ou le service à la clientèle) ont accès aux informations personnelles identifiables. Les ordinateurs et serveurs utilisés pour stocker des informations personnelles identifiables sont conservés dans un environnement sécurisé.": "We implementeren verschillende beveiligingsmaatregelen om uw persoonlijke gegevens veilig te houden. We gebruiken encryptie om gevoelige informatie die online wordt verzonden te beschermen. We beschermen uw gegevens ook offline. Alleen werknemers die een specifieke taak moeten uitvoeren (bijvoorbeeld facturering of klantenservice) hebben toegang tot persoonlijk identificeerbare informatie. Computers en servers die worden gebruikt om persoonlijk identificeerbare informatie op te slaan, worden in een beveiligde omgeving bewaard.",
    "Nous ne vendons, n’échangeons et ne transférons pas vos informations personnelles identifiables à des tiers. Cela ne comprend pas les tierce parties de confiance qui nous aident à exploiter notre site Web ou à mener nos affaires, tant que ces parties conviennent de garder ces informations confidentielles.": "Wij verkopen, verhandelen of dragen uw persoonlijk identificeerbare informatie niet over aan derden. Dit geldt niet voor vertrouwde derde partijen die ons helpen bij het exploiteren van onze website of het uitvoeren van onze activiteiten, zolang deze partijen ermee instemmen deze informatie vertrouwelijk te houden.",
    "Nous pensons qu’il est nécessaire de partager des informations afin d’enquêter, de prévenir ou de prendre des mesures concernant des activités illégales, fraudes présumées, situations impliquant des menaces potentielles à la sécurité physique de toute personne, violations de nos conditions d’utilisation, ou quand la loi nous y contraint.": "Wij zijn van mening dat het noodzakelijk is om informatie te delen om illegale activiteiten, vermoedelijke fraude, situaties met potentiële bedreigingen voor de fysieke veiligheid van een persoon, schendingen van onze gebruiksvoorwaarden te onderzoeken, te voorkomen of actie te ondernemen, of wanneer de wet ons daartoe verplicht om dat te doen.",
    "Nous recueillons des informations lorsque vous visitez notre site, vous inscrivez sur notre site et lorsque vous vous connectez à votre compte. Les informations recueillies incluent votre prénom, votre nom, votre entreprise, votre adresse e-mail et votre numéro de téléphone (optionnel). En outre, nous recevons et enregistrons automatiquement des informations à partir de votre ordinateur et navigateur, y compris votre adresse IP, vos logiciels, votre matériel et la page que vous demandez.": "Wij verzamelen informatie wanneer u onze site bezoekt, u registreert op onze site en wanneer u inlogt op uw account. De verzamelde informatie omvat uw voornaam, achternaam, bedrijf, e-mailadres en telefoonnummer (optioneel). Bovendien ontvangen en registreren we automatisch informatie van uw computer en browser, inclusief uw IP-adres, software, hardware en de pagina die u opvraagt.",
    "Nous récupérons vos biens": "Wij halen uw panden op",
    "Nous utilisons des cookies. Nos cookies améliorent l’accès à notre site et identifient les visiteurs réguliers. Les cookies sont également conservés par nos partenaires Google Analytics et Hubspot afin d'améliorer l’expérience utilisateur grâce au suivi et au ciblage de ses intérêts. Cependant, cette utilisation des cookies n’est en aucune façon liée à des informations personnelles identifiables sur notre site.": "Wij gebruiken cookies. Onze cookies verbeteren de toegang tot onze site en identificeren terugkerende bezoekers. Cookies worden ook opgeslagen door onze partners Google Analytics en Hubspot om de gebruikerservaring te verbeteren door hun interesses te volgen en te targeten. Dit gebruik van cookies is echter op geen enkele manier gekoppeld aan persoonlijk identificeerbare informatie op onze site.",
    "Nous utilisons l’adresse e-mail que vous fournissez pour vous envoyer des informations et mises à jour relatives à votre commande, des nouvelles de l’entreprise de façon occasionnelle, des informations sur des produits liés, etc. Si à n’importe quel moment vous souhaitez vous désinscrire et ne plus recevoir d’e-mails, des instructions de désabonnement détaillées sont incluses en bas de chaque e-mail.": "We gebruiken het door u opgegeven e-mailadres om u informatie en updates te sturen met betrekking tot uw bestelling, incidenteel bedrijfsnieuws, gerelateerde productinformatie, enz. Als u zich op enig moment wilt afmelden voor het ontvangen van e-mails, vindt u onderaan elke e-mail gedetailleerde instructies voor het afmelden.",
    "Nouveau": "Nieuw",
    "Nouveau mot de passe": "Nieuw wachtwoord",
    "Nouvelle vidéo": "Nieuwe video",
    "N° de téléphone pro": "Telefoonnummer bedrijf",
    "Obligations des Parties": "Verplichtingen van de Partijen",
    "Obligations du Client": "Verplichtingen van de Klant",
    "Obligations du Prestataire": "Verplichtingen van de dienstverlener",
    "Obtenir un lien de téléchargement": "Een downloadlink ophalen",
    "On s'occupe de tout...": "Wij zorgen voor alles...",
    "Oui, supprimer": "Ja, verwijderen",
    "Oups !": "Oeps!",
    "Paiement": "Betaling",
    "Par ailleurs, le Prestataire ne peut être tenu pour responsable de l’inexécution du Contrat en cas de force majeure telle que définie à l’article 15, et en cas de dommages du fait d’un tiers ou imputables à une mauvaise utilisation ou une utilisation non-conforme par le Client des Services, en violation des prescriptions du Prestataire ou des règles de l’art.": "Bovendien kan de Dienstverlener niet aansprakelijk worden gesteld voor de niet-uitvoering van het Contract in geval van overmacht zoals gedefinieerd in artikel 15, en in geval van schade veroorzaakt door een derde of te wijten aan misbruik of niet-conform gebruik door de Dienstverlener. Klant van de Diensten, in strijd met de eisen van de Dienstverlener of de regels van de kunst.",
    "Par défaut": "Standaard",
    "Paramètres": "Instellingen",
    "Partage automatique": "Automatisch delen",
    "Partager sur les réseaux sociaux": "Deel op sociale netwerken",
    "Passer Premium": "Ga voor Premium",
    "Passer Premium !": "Ga voor premium!",
    "Paysage": "Liggend",
    "Personnalisation des couleurs": "Personaliseer je kleuren",
    "Personnaliser": "Aanpassen",
    "Personnaliser votre expérience": "Uw ervaring te personaliseren",
    "Personnelles": "Persoonlijk",
    "Politique de confidentialité": "Privacybeleid",
    "Portrait": "Staand",
    "Pour l'intégrer à votre logiciel": "Om het in uw software te integreren",
    "Pour partager cette page": "Om deze pagina te delen",
    "Pour permettre au Prestataire de réaliser sa mission, le Client s’engage à": "Om de Dienstverlener in staat te stellen zijn opdracht uit te voeren, verbindt de Klant zich ertoe om",
    "Pour récupérer vos photos et pré-remplir votre vidéo": "Om uw foto's op te halen en uw video vooraf in te vullen",
    "Premium": "Premium",
    "Prix": "Prijs",
    "Prix de l'annonce": "Advertentieprijs",
    "Prochain prélèvement le": "Volgende betaling op",
    "Professionnelles": "Professionals",
    "Profil": "Profiel",
    "Projet supprimé.": "Project verwijderd.",
    "Propriété intellectuelle": "Intellectuele eigendomsrechten",
    "Prénom": "Voornaam",
    "Publication en cours sur": "Publicatie bezig op",
    "Publier": "Publiceren",
    "Publier votre clap sur vos réseaux sociaux": "Publiceer je clap op je sociale netwerken",
    "Publier votre vidéo sur vos réseaux sociaux": "Publiceer je video op je sociale netwerken",
    "Pénalités de retard": "Boetes voor te late betaling",
    "Quand un clap est généré": "Wanneer een clap wordt gegenereerd",
    "Recevoir un mail quand une vidéo est générée": "Ontvang een e-mail wanneer een video wordt gegenereerd",
    "Recherche sur le web": "Zoeken op internet",
    "Rechercher mon annonce": "Zoek in mijn advertentie",
    "Rechercher un bien par titre, référence ou prix.": "Zoek een woning op titel, referentie of prijs.",
    "Rechercher un clap": "Zoeken naar een clap",
    "Rechercher un clap...": "Zoek een clap...",
    "Rechercher une annonce": "Zoek naar een advertentie",
    "Rechercher votre annonce sur le web en un coup de baguette": "Doorzoek uw advertentie op internet met het toverstokje",
    "Refus": "Weigering",
    "Remettre au Prestataire le devis (daté, signé et tamponné).": "De Dienstverlener de offerte bezorgen (gedateerd, ondertekend en afgestempeld).",
    "Rendre publique et visible votre adresse postale professionnelle dans chaque vidéo que vous créez.": "Maak je zakelijke postadres openbaar en zichtbaar in elke video die je maakt.",
    "Responsabilités": "Verantwoordelijkheden",
    "Retapez votre mot de passe": "Typ uw wachtwoord opnieuw",
    "Retapez votre nouveau mot de passe": "Typ je nieuwe wachtwoord opnieuw",
    "Retour": "Terug",
    "Retour aux connecteurs": "Terug naar connectors",
    "Retour à l'accueil": "Keer terug naar de startpagina",
    "Retour à mes vidéos": "Terug naar mijn video's",
    "Retourner à la page de connexion": "Keer terug naar de inlogpagina",
    "Réactiver mon abonnement et annuler ma résiliation": "Heractiveer mijn abonnement en annuleer mijn opzegging",
    "Référence": "Referentie",
    "Régler dans les délais prédéfinis dans le devis et dans les présentes CGV, les sommes dues au Prestataire.": "Alle aan de Dienstverlener verschuldigde bedragen te betalen binnen de termijnen die in de offerte en deze AV zijn aangegeven.",
    "Réinitialisation du mot de passe": "Wachtwoord opnieuw instellen",
    "Réseaux sociaux": "Sociale netwerken",
    "Résiliation": "Beëindiging",
    "Résilier": "Opzeggen",
    "S'inscrire": "Inschrijven",
    "Sauf convention contraire dans le devis, le Contrat est réputé formé et prend effet entre les Parties à la date de réception par le Prestataire de la Commande du Client soit par email, soit par courrier postal à l’adresse du Prestataire.": "Tenzij anders overeengekomen in de Offerte, wordt de Overeenkomst geacht te zijn aangegaan en treedt deze in werking tussen de Partijen op de datum van ontvangst door de Dienstverlener van de Order van de Klant per e-mail of per post naar het adres van de Dienstverlener.",
    "Sauf disposition légale contraire, toute autre garantie, expresse ou implicite est exclue.": "Tenzij anders bepaald door de wet, is elke andere garantie, expliciet of impliciet, uitgesloten.",
    "Se conformer strictement aux préconisations techniques et aux suggestions artistiques faites par le Prestataire.": "De technische aanbevelingen en artistieke suggesties van de Dienstverlener strikt opvolgen.",
    "Se souvenir de moi": "Onthoud mij",
    "Si le problème persiste, n'hésitez pas à nous contacter par mail à l'adresse suivante": "Als het probleem zich blijft voordoen, aarzel dan niet om contact met ons op te nemen via e-mail op het volgende adres",
    "Si l’une quelconque disposition de ces CGV ou son application à toute personne ou circonstance est jugée nulle, cette nullité ne concernera pas les autres dispositions ou applications de ces CGV, qui resteront en vigueur, séparément de la disposition jugée nulle. A cette fin, les dispositions de ces CGV sont déclarées autonomes.": "Als een bepaling van deze Algemene Voorwaarden of de toepassing ervan op een persoon of omstandigheid nietig wordt geacht, heeft deze nietigheid geen betrekking op de andere bepalingen of toepassingen van deze Algemene Voorwaarden, die van kracht blijven, los van de bepaling die als nietig wordt beschouwd. Daartoe worden de bepalingen van deze Algemene Voorwaarden autonoom verklaard.",
    "Si vous pensez que c'est une erreur, n'hésitez pas à nous contacter par mail à l'adresse suivante": "Als u denkt dat dit een fout is, aarzel dan niet om contact met ons op te nemen via e-mail op het volgende adres",
    "Site internet": "Website",
    "Sont à facturer en sus": "Aanvullend worden in rekening gebracht",
    "Souhaitez vous vraiment supprimer la vidéo": "Wil je de video echt verwijderen",
    "Souhaitez vous vraiment supprimer le clap": "Wilt u de clap echt verwijderen",
    "Sous-traitance": "Onderaanneming",
    "Suite à votre demande, votre abonnement Premium ne sera pas renouvelé et prendra fin le": "Naar aanleiding van je aanvraag wordt je Premium abonnement niet verlengd",
    "Suppression du logo clap.video": "Kleuren aanpassen",
    "Suppression du logo clap.video en fin de vidéo": "Verwijdering van het clap.video-logo aan het einde van de video",
    "Supprimer le clap": "Clap verwijderen",
    "Sélectionner": "Kies",
    "Sélectionner l'annonce à importer": "Selecteer de advertentie die u wilt importeren",
    "Sélectionner la couleur par défaut que vous souhaitez utiliser pour vos vidéos.": "Selecteer de standaardkleur die je wilt gebruiken voor je video's.",
    "Sélectionnez une image provenant de l’annonce web": "Selecteer een afbeelding uit de webadvertentie",
    "Sélectionnez votre page Facebook": "Selecteer uw Facebook-pagina",
    "Sélectionné": "Geselecteerd",
    "Sélectionnée": "Geselecteerd",
    "S’assurer de la mise à disposition de tous les moyens nécessaires pour permettre au Prestataire de réaliser les Services dans ses locaux et/ou à distance.": "Ervoor zorgen dat alle nodige middelen ter beschikking worden gesteld om de Dienstverlener in staat te stellen de Diensten uit te voeren in zijn lokalen en/of op afstand.",
    "Tapez un nouveau mot de passe": "Voer een nieuw wachtwoord in",
    "Tapez votre mot de passe actuel": "Voer je huidige wachtwoord in",
    "Template": "Sjabloon",
    "Template sélectionné": "Gekozen sjabloon",
    "Terminer": "Beëindigen",
    "Terminer et générer la vidéo": "Voltooien en de video genereren",
    "Terminé": "Voltooid",
    "Titre de la vidéo": "Titel van de video",
    "Titre du clap": "Titel",
    "Toute les informations que nous recueillons auprès de vous peuvent être utilisées pour": "Alle informatie die wij van u verzamelen, kan worden gebruikt om",
    "Toute modification ultérieure ou demande complémentaire demandée par le Client fera l’objet d’une facturation supplémentaire.": "Elke latere wijziging of bijkomend verzoek van de Klant zal het voorwerp uitmaken van een bijkomende facturatie.",
    "Toute notification devra être faite par écrit et être soit remise en mains propres, soit adressée par lettre recommandée avec accusé de réception, soit faite par acte extra judiciaire à l’adresse indiquée dans la commande.": "Elke kennisgeving moet schriftelijk worden gedaan en hetzij persoonlijk worden afgeleverd, hetzij per aangetekende brief met ontvangstbevestiging, of via een buitengerechtelijk document worden verzonden naar het adres dat in de bestelling is vermeld.",
    "Toute réserve concernant les CGV, avancée par le Client sera, donc, à défaut d'acceptation expresse par le Prestataire, inopposable à ce dernier, quel que soit le moment où elle aura pu être portée à sa connaissance.": "Bij gebrek aan uitdrukkelijke aanvaarding door de Dienstverlener, zal elk voorbehoud gemaakt door de Klant met betrekking tot de AVV niet bindend zijn voor de Dienstverlener, ongeacht het tijdstip waarop het onder de aandacht van de Dienstverlener werd gebracht.",
    "Toute utilisation par le Client des dénominations sociales, marques et signes distincts appartenant au Prestataire est strictement prohibée sauf en cas d’accord exprès et préalable du Prestataire. En cas d’accord exprès et préalable du Prestataire, ce dernier concède alors au Client un droit strictement personnel, non exclusif, et non transférable d'utiliser ses dénominations sociales, marques et signes distincts, dans le monde entier et pour toute la durée de validité du Contrat.": "Toute utilisation par le Client des dénominations sociales, marques et signes distincts appartenant au Prestataire est strictement prohibée sauf en cas d’accord exprès et préalable du Prestataire. En cas d’accord exprès et préalable du Prestataire, ce dernier concède alors au Client un droit strictement personnel, non exclusif, et non transférable d'utiliser ses dénominations sociales, marques et signes distincts, dans le monde entier et pour toute la durée de validité du Contrat.",
    "Toutes dispositions dérogeant aux présentes CGV devront résulter d’un accord exprès des Parties, reflété dans les Commandes confirmées par le Prestataire ou tout autre document faisant foi de l’accord des deux Parties.": "Alle bepalingen die afwijken van deze AVV dienen het resultaat te zijn van een uitdrukkelijke overeenkomst tussen Partijen, die tot uitdrukking komt in de door de Dienstverlener bevestigde Bestellingen of in enig ander document waaruit de instemming van beide Partijen blijkt.",
    "Travail dissimulé": "Verborgen werk",
    "Type": "Type",
    "Télécharger": "Downloaden",
    "Télécharger cette vidéo": "Deze video uploaden",
    "Un extrait Kbis attestant de l’immatriculation au registre du commerce et des sociétés,": "Een Kbis-uittreksel waaruit blijkt dat u in het handels- en vennootschapsregister bent ingeschreven,",
    "Un problème a été rencontré lors de la création de la vidéo. Veuillez réessayer.": "Er is een probleem opgetreden bij het maken van de video. Probeer het opnieuw.",
    "Un problème a été rencontré lors de la création du clap. Veuillez réessayer.": "Er is een probleem opgetreden bij het maken van de clap. Probeer het opnieuw.",
    "Un retard sur les délais indiqués ne pourra donc donner lieu au paiement de dommages et intérêts, ni autoriser le Client à résilier le Contrat où à refuser la livraison des Services.": "Elke vertraging in de naleving van de vermelde termijnen kan bijgevolg geen aanleiding geven tot de betaling van een schadevergoeding en geeft de Klant niet het recht om het Contract op te zeggen of de levering van de Diensten te weigeren.",
    "Une attestation sur l’honneur établie par le Prestataire, certifiant que le travail est réalisé par des salariés employés régulièrement eu égard aux articles D.8222-5, D.8222-7 et D.8222-8 du Code du travail.": "Een beëdigde verklaring opgesteld door de Dienstverlener, waarin wordt verklaard dat de werkzaamheden worden uitgevoerd door regelmatig tewerkgestelde werknemers met betrekking tot de artikelen D.8222-5, D.8222-7 en D.8222-8 van de Arbeidswet.",
    "Une copie de l’avis d’imposition afférent à la taxe professionnelle,": "Een kopie van het belastingaanslag betreffende de beroepsbelasting,",
    "Une erreur est survenue": "Er is een fout opgetreden",
    "Une erreur est survenue lors de la récupération des biens.": "Er is een fout opgetreden bij het ophalen van de panden.",
    "Une erreur est survenue lors de la sélection de cette musique. Veuillez en choisir une autre.": "Er is een fout opgetreden bij het selecteren van deze muziek. Kies een andere.",
    "Une erreur est survenue lors du chargement de votre fichier. Veuillez essayer avec un fichier plus léger.": "Er is een fout opgetreden tijdens het laden van uw bestand. Probeer het met een lichter bestand.",
    "Une erreur est survenue lors du lancement du rendu. Veuillez réessayer.": "Er is een fout opgetreden bij het starten van het renderen. Probeer het.",
    "Une erreur est survenue. Merci de réessayer.": "Er is een fout opgetreden. Probeer het opnieuw.",
    "Une erreur inconnue est survenue.": "Er is een onbekende fout opgetreden.",
    "Une erreur inconnue s'est produite ! Contactez support@clap.video.": "Er is een onbekende fout opgetreden! Neem contact op met support@clap.video.",
    "Valable jusqu'au": "Geldig tot",
    "Valider": "Valideren",
    "Veuillez accepter les conditions générales de vente.": "Accepteer alstublieft de algemene verkoopvoorwaarden.",
    "Veuillez compléter toutes les images.": "Vul alle afbeeldingen in.",
    "Veuillez entrer un code promo.": "Voer een promotiecode in.",
    "Veuillez entrer un email et un mot de passe.": "Voer een e-mailadres en wachtwoord in.",
    "Veuillez entrer un titre valide": "Voer een geldige titel in",
    "Veuillez remplir les informations bancaires.": "Vul de bankgegevens in.",
    "Veuillez remplir votre nom et prénom.": "Vul uw voor- en achternaam in.",
    "Veuillez utiliser la": "Gebruik alstublieft de",
    "Vidéo automatique": "Automatische video",
    "Vidéos": "Video's",
    "Vidéos illimitées": "Ongelimiteerde video's",
    "Ville": "Stad",
    "Voir mes autoclaps": "Bekijk mijn autoclaps",
    "Vos clients apprécient votre travail et votre entreprise ? Faites-le savoir en créant une vidéo présentant vos meilleurs avis.": "Waarderen uw klanten uw werk en uw bedrijf? Laat het mensen weten door een video te maken met uw beste recensies.",
    "Vos modifications ont bien été enregistrées": "Uw wijzigingen zijn opgeslagen",
    "Votre adresse email": "Uw e-mailadres",
    "Votre adresse postale": "Uw postadres",
    "Votre clap est en file d'attente, veuillez patienter quelques instants avant le début de la génération.": "Uw clap staat in de wachtrij. Wacht even voordat u begint met genereren.",
    "Votre code postale": "Uw postcode",
    "Votre couleur personnalisée": "Uw persoonlijke kleur",
    "Votre demande de résiliation a bien été prise en compte.": "We hebben uw opzegverzoek goed ontvangen",
    "Votre email personnel": "Uw persoonlijke e-mail",
    "Votre email professionnel": "Je professionele e-mail",
    "Votre nom": "Jouw naam",
    "Votre nom d'entreprise": "Uw bedrijfsnaam",
    "Votre nouveau mot de passe a bien été enregistré": "Uw nieuwe wachtwoord is succesvol opgeslagen",
    "Votre numéro de téléphone professionnel": "Je zakelijke telefoonnummer",
    "Votre photo de profil a bien été modifiée": "Je profielfoto is gewijzigd",
    "Votre photo de profil professionnelle a bien été modifiée": "Uw professionele profielfoto is succesvol aangepast",
    "Votre prénom": "Jouw voornaam",
    "Votre résiliation a bien été annulée.": "Uw opzegging is ingetrokken.",
    "Votre vidéo est en cours de génération !": "Je video wordt gegenereerd!",
    "Votre vidéo est en file d'attente, veuillez patienter quelques instants avant le début de la génération.": "Uw video wordt in de wachtrij geplaatst. Wacht even voordat het genereren begint.",
    "Votre ville": "Uw stad",
    "Voulez-vous vraiment réactiver votre abonnement": "Weet u zeker dat u uw abonnement opnieuw wilt activeren",
    "Voulez-vous vraiment résilier votre abonnement Premium ? Votre abonnement continuera jusqu'à la date de fin d'abonnement et ne sera pas renouvelé.": "Weet u zeker dat u uw Premium-abonnement wilt opzeggen? Uw abonnement loopt door tot de einddatum van het abonnement en wordt niet verlengd.",
    "Vous avez atteint le nombre maximum de vidéos gratuites.": "Je hebt het maximale aantal gratis video's bereikt.",
    "Vous avez déjà un compte": "Heeft u al een account",
    "Vous contacter pour vous présenter de nouvelles fonctionnalités et offres qui pourraient vous intéresser": "Contact met u op te nemen om nieuwe functies en aanbiedingen voor te stellen die u mogelijk interesseren",
    "Vous devez lier Facebook": "Je moet Facebook linken",
    "Vous donnez accès à notre service de création vidéo": "U toegang te geven tot onze videocreatieservice",
    "Vous n'avez aucune vidéo pour le moment.": "Je hebt momenteel geen video's.",
    "Vous n'avez pas de compte": "Heeft u geen account",
    "Vous n'avez pas lié de compte": "U heeft geen account gekoppeld",
    "Vous possédez un abonnement": "Je hebt een abonnement",
    "Vous pouvez envoyer cette vidéo sur votre page Facebook en vous": "U kunt deze video naar uw Facebook-pagina sturen door u te",
    "Vous pouvez quitter cette page, votre vidéo sera directement ajoutée dans votre logiciel métier.": "U kunt deze pagina verlaten, uw video wordt direct toegevoegd aan uw bedrijfssoftware.",
    "Vous pouvez résilier votre abonnement à tout moment": "U kan uw abonnement op eender welk moment opzeggen",
    "Vous serez de nouveau prélevé tous les": "Elke keer worden er opnieuw kosten in rekening gebracht",
    "Vous serez prélevé automatiquement à chaque échéance.": "Op elke vervaldatum wordt het bedrag automatisch overgeschreven",
    "Vous êtes actuellement connecté via Topi": "Je bent momenteel verbonden via Topi",
    "Zone de danger": "Gevarenzone",
    "agency": {
      "address": "Adres van het kantoor",
      "logo": "Logo",
      "name": "Naam van het kantoor",
      "photo": "foto",
      "web": "Website"
    },
    "agent": {
      "email": "E-mailadres",
      "name": "Naam van de verantwoordelijke",
      "phone": "Telefoonnummer",
      "photo": "Foto van de verantwoordelijke",
      "web": "Website"
    },
    "chambre(s)": "kamer(s)",
    "conditions générales de vente": "algemene verkoopvoorwaarden",
    "connectant sur clap.video": "verbinden met clap.video",
    "connus par l’une des Parties sur une base non-confidentielle avant leur divulgation par l'autre Partie": "op niet-vertrouwelijke basis bekend bij een van de Partijen vóór de bekendmaking ervan door de andere Partij",
    "création manuelle": "handmatige creatie",
    "d'un montant de": "in de hoeveelheid van",
    "de Clap.": "van Clap.",
    "depuis le": "sinds",
    "divulgués en vertu d'une disposition législative ou réglementaire.": "openbaar gemaakt op grond van een wet- of regelgevingsbepaling.",
    "développés de façon indépendante par la Partie réceptrice qui n’a eu accès à aucune information de la Partie divulgatrice": "onafhankelijk ontwikkeld door de Ontvangende Partij die geen toegang heeft gehad tot informatie van de Bekendmakende Partij",
    "fournir une solution de remplacement permettant au Client de pouvoir utiliser les Services conformément à la Commande": "een alternatieve oplossing bieden waardoor de Klant de Diensten kan gebruiken in overeenstemming met de Bestelling",
    "ici": "hier",
    "la diffusion des œuvres créées par tout moyen, notamment par voie hertzienne, câble-satellite ainsi que par tout réseau, et plus généralement par tout moyen de transmission de données numérisées ou non.": "de verspreiding van de gecreëerde werken via om het even welk middel, in het bijzonder via radio, kabel-satelliet of om het even welk ander netwerk, en meer in het algemeen via om het even welk middel van gegevenstransmissie, al dan niet gedigitaliseerd.",
    "le droit de diffuser et de communiquer à tout public les éléments, supports, composants des œuvres créées, par tout procédé de représentation connu ou inconnu à ce jour, pour toute utilisation quelle qu’elle soit ": "het recht om de elementen, media en componenten van de werken die gecreëerd zijn door middel van elk representatieproces dat tot op heden bekend of onbekend is, te verspreiden en mee te delen aan elk publiek, voor welk gebruik dan ook",
    "le droit de mettre en circulation et d’exploiter les œuvres créées, commercialement ou non, les reproductions ainsi réalisées, en nombre illimité, à titre gratuit ou onéreux, et ce quelle qu’en soit la destination.": "het recht om de gecreëerde werken in omloop te brengen en te exploiteren, commercieel of anderszins, de aldus gemaakte reproducties, in onbeperkte aantallen, gratis of tegen betaling, en voor welk doel dan ook.",
    "le droit de reproduire et/ou faire reproduire les œuvres créées en nombre illimité, par tout procédé et sur tout support actuel ou futur, et nomment graphique, magnétique, numérique ou électronique (interactif ou non) ": "het recht om de gecreëerde werken in een onbeperkt aantal te reproduceren en/of te laten reproduceren, door middel van elk procédé en op elk huidig of toekomstig medium, hetzij grafisch, magnetisch, digitaal of elektronisch (al dan niet interactief)",
    "les légendes de vos photos se sont complétées automatiquement, pensez à les vérifier !": "Je fotobijschriften zijn automatisch ingevuld, dus vergeet ze niet te controleren!",
    "les modifications demandées par le Client en cours de réalisation, si elles impliquent un remaniement du projet.": "wijzigingen die in de loop van het project door de Klant worden aangevraagd, indien deze een herbewerking van het project inhouden.",
    "légitimement obtenus d'un tiers non tenu par une obligation de confidentialité": "op rechtmatige wijze verkregen van een derde partij die niet gebonden is aan een geheimhoudingsplicht",
    "ma photo": "mijn foto",
    "mon logo": "mijn logo",
    "n'existe pas ou vous ne vous appartient pas.": "bestaat niet of is niet van jou.",
    "nous vous notifions par email.": "brengen we je op de hoogte via e-mail.",
    "obtenir toute concession de licence ou autorisation pour permettre au Client de continuer à utiliser les Services": "het verkrijgen van een licentieverlening of toestemming om de Klant in staat te stellen de Services te blijven gebruiken",
    "ou": "Of",
    "ou la": "of de",
    "page": "pagina",
    "paramètres de connecteurs": "connectorinstellingen",
    "photos": {
      "1": "Afbeelding 1",
      "2": "Afbeelding 2",
      "3": "Afbeelding 3",
      "4": "Afbeelding 4",
      "5": "Afbeelding 5"
    },
    "publier votre vidéo sur Facebook en quelques clics, liez vos pages dans vos": "Om uw video met slechts een paar klikken op Facebook te publiceren, koppelt u uw pagina's in uw",
    "quartier": {
      "1": "Wijk van goed"
    },
    "realty": {
      "bedrooms": "Aantal kamers",
      "city": "Stad",
      "furnished": "Gemeubileerd of ongemeubileerd",
      "living_area": "Oppervlakte",
      "operation": "Exploitatie (verkoop, verhuur)",
      "price": "Prijs",
      "title": "Hoofdtitel",
      "type": "Type woning (appartement, huis)"
    },
    "recherche sur le web": "zoekfunctie op internet",
    "s": "'s",
    "secondes": "seconden",
    "si aucune des deux possibilités n’est réalisable, rembourser le Client des sommes versées au titre des Services, déduction faite des sommes déjà payées par le Client pour la période d’utilisation effective des Services.": "indien geen van beide mogelijkheden haalbaar is, vergoedt u de Klant de voor de Diensten betaalde bedragen, verminderd met de bedragen die de Klant reeds heeft betaald voor de periode van feitelijk gebruik van de Diensten.",
    "text": {
      "1": "Zoektekst",
      "2": "Titel van de verklarende tekst",
      "3": "verklarende tekst"
    },
    "tombés ou qui tomberont dans le domaine public au jour de leur divulgation": "zijn gevallen of die op de dag van openbaarmaking tot het publieke domein zullen behoren",
    "validé": "Goedgekeurd",
    "via AutoClap ou manuellement": "via AutoClap of handmatig",
    "Éditer cette vidéo": "Bewerk deze video",
    "Établir un cahier des charges détaillé qui ne subira plus de modification, sauf accord des Parties, après avoir été approuvé par le Prestataire. Au besoin, le Prestataire pourra intervenir dans l’élaboration du cahier des charges, conjointement avec le Client. Dans le cas où des modifications impliqueraient un remaniement substantiel du cahier des charges initial, ces dernières seront facturées en sus du devis initial.": "Gedetailleerde specificaties opstellen die niet gewijzigd kunnen worden, behalve in overleg tussen de Partijen, zodra ze goedgekeurd zijn door de Dienstverlener. Indien nodig kan de Dienstverlener samen met de Klant tussenkomen bij het opstellen van de specificaties. Als de wijzigingen een substantiële herziening van het oorspronkelijke bestek inhouden, zullen deze wijzigingen bovenop de oorspronkelijke offerte worden gefactureerd.",
    "à appliquer aux Informations Confidentielles les mêmes mesures de protection que celles qu'elle applique à ses propres informations confidentielles": "op Vertrouwelijke Informatie dezelfde beschermingsmaatregelen toe te passen als op zijn eigen vertrouwelijke informatie",
    "à clap.video.": "op clap.video.",
    "à compter du": "van",
    "à n'utiliser les Informations Confidentielles que pour les seuls besoins de réalisation des Services.": "om de Vertrouwelijke Informatie uitsluitend te gebruiken voor het uitvoeren van de Diensten.",
    "à ne communiquer les Informations Confidentielles qu'à ses seuls employés et collaborateurs amenés à les connaître dans le cadre de la réalisation des Services": "om Vertrouwelijke Informatie alleen te communiceren aan haar werknemers en medewerkers die er kennis van krijgen in de context van het uitvoeren van de Diensten",
    "à ne pas divulguer, publier ou transmettre à des tiers les Informations Confidentielles, sous quelque forme que ce soit, sans l’accord préalable écrit de l’autre Partie": "geen Vertrouwelijke Informatie openbaar te maken, te publiceren of over te dragen aan derden, in welke vorm dan ook, zonder voorafgaande schriftelijke toestemming van de andere Partij"
  }]];
});